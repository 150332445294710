import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { Row, Col, Label, Button, Form } from 'reactstrap';
import CommonModal from '../../Home/modal';
import { useFormik } from 'formik';
import apiUrl from '../../server';
import locationIcon from '../../../assets/images/MettaMateImages/location.png';
import languageIcon from '../../../assets/images/MettaMateImages/translate.png';
const RegionalFunction = () => {
  const [currentmapvalue, setcurrentmapValue] = useState(null);
  const [nativemapvalue, setnativemapValue] = useState(null);
  const [motherTongue, setMotherTongue] = useState({});
  const [otherlang1, setOtherlang1] = useState({});
  const [otherlang2, setOtherlang2] = useState({});
  const [languageList, setlanguageList] = useState([]);
  const [currentAddress, setCurrentAddress] = useState('');
  const [nativeAddress, setNativeAddress] = useState('');
  const [nativecity, setnativeCity] = useState('');
  const [nativestate, setnativeState] = useState('');
  const [nativecountry, setnativeCountry] = useState('');
  const [currentcity, setcurrentCity] = useState('');
  const [currentstate, setcurrentState] = useState('');
  const [currentcountry, setcurrentCountry] = useState('');
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const [isLoading, setIsLoading] = useState(false);

  let memberID = localStorage.getItem('memberID');

  useEffect(() => {
    fetchRegionalData();
    fetchlanguageList();
  }, []);

  const fetchlanguageList = async () => {
    try {
      const response = await axios.get(apiUrl + 'codes/language', {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setlanguageList(response.data.list);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const fetchRegionalData = async () => {
    try {
      const response = await axios.get(
        apiUrl + 'members/regional/' + memberID,
        {
          headers: {
            Authorization: 'Bearer' + ' ' + localStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
        },
      );
      if (response.data.length > 0) {
        setMotherTongue(response.data[0].mothertongue);
        setOtherlang1(response.data[0].otherlang1);
        setOtherlang2(response.data[0].otherlang2);
        if (response.data[0].currentlocationid) {
          setcurrentmapValue(response.data[0].currentlocationid);
          setCurrentAddress(response.data[0].currentlocationname);
        }
        if (response.data[0].nativelocationid) {
          setnativemapValue(response.data[0].nativelocationid);
          setNativeAddress(response.data[0].nativelocationname);
        }
      } else {
        setMotherTongue({});
        setOtherlang1({});
        setOtherlang2({});
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleCurrentAddressChange = (newAddress) => {
    setCurrentAddress(newAddress);
  };
  const handlenativeAddressChange = (newAddress) => {
    setNativeAddress(newAddress);
  };

  const handlecurrentAddressSelect = async (address, placeid) => {
    setcurrentmapValue(placeid);
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);

      setCurrentAddress(address);
      const addressComponents = results[0].address_components;
      const city =
        addressComponents.find((component) =>
          component.types.includes('locality'),
        )?.long_name || '';
      const state =
        addressComponents.find((component) =>
          component.types.includes('administrative_area_level_1'),
        )?.long_name || '';
      const country =
        addressComponents.find((component) =>
          component.types.includes('country'),
        )?.long_name || '';

      // Store the city, state, and country in your state or do something else with this information
      setcurrentCity(city);
      setcurrentState(state);
      setcurrentCountry(country);
      alert('Location here : '+ [city, state, country].filter(Boolean).join(', '));
      let locationDetails = [city, state, country].filter(Boolean).join(', ');
      setCurrentAddress(locationDetails);
      console.error('location details of current', locationDetails);

      // Use latLng if needed
    } catch (error) {
      console.error('Error selecting address:', error);
    }
  };

  const handlenativeAddressSelect = async (address, placeid) => {
    setnativemapValue(placeid);
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);

      let initualAddress = address.split(',');
      let finalAddress = initualAddress.slice(-3);
      const addressComponents = results[0].address_components;
      const city =
        addressComponents.find((component) =>
          component.types.includes('locality'),
        )?.long_name || '';
      const state =
        addressComponents.find((component) =>
          component.types.includes('administrative_area_level_1'),
        )?.long_name || '';
      const country =
        addressComponents.find((component) =>
          component.types.includes('country'),
        )?.long_name || '';

      // Store the city, state, and country in your state or do something else with this information
      setnativeCity(city);
      setnativeState(state);
      setnativeCountry(country);
      let locationDetails = [city, state, country].filter(Boolean).join(', ');
      setNativeAddress(locationDetails);
      console.error('location details of native', locationDetails);

      // Use latLng if needed
    } catch (error) {
      console.error('Error selecting address:', error);
    }
  };
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      memberuuid: memberID,
      currentlocationid: '',
      currentlocationname:'',
      nativelocationid: '',
      nativelocationname:'',
      mothertongue: '',
      otherlang1: '',
      otherlang2: '',
      currentcity: '',
      currentstate: '',
      currentcountry: '',
      nativecity: '',
      nativestate: '',
      nativecountry: ''
    },

    onSubmit: async (values) => {
      setIsLoading(true);

      var selectElement = document.getElementById('mothertongue');
      values.mothertongue = parseInt(selectElement.value);
      var otherlang1 = document.getElementById('otherlang1');
      values.otherlang1 = parseInt(otherlang1.value);
      var otherlang2 = document.getElementById('otherlang2');
      values.otherlang2 = parseInt(otherlang2.value);
      values.currentlocationid = currentmapvalue ? currentmapvalue : '';
      values.currentlocationname = currentAddress;
      values.nativelocationid = nativemapvalue ? nativemapvalue : '';
      values.nativelocationname = nativeAddress;
      
      values.currentcity = currentcity;
      values.currentstate = currentstate;
      values.currentcountry = currentcountry;
      values.nativecity = nativecity;
      values.nativestate = nativestate;
      values.nativecountry = nativecountry;
      try {
      console.log('Native:-', nativeAddress,nativemapvalue);
      console.log('current:-', currentAddress,currentmapvalue);
        const response = await axios
          .post(apiUrl + 'members/regional/', values, {
            headers: {
              Authorization: 'Bearer' + ' ' + localStorage.getItem('token'),
              'Content-Type': 'application/json',
            },
          })
          .then((res) => {
            fetchRegionalData();
            setIsLoading(false);
            setSuccess({
              title: 'SUCCESS!',
              message: res.data.message,
            });
          })
          .catch((err) => {
            setIsLoading(false);
            setError({
              title: 'An Error Occured!',
              message: err.response.data.message,
            });
          });
      } catch (error) { }
    },
  });
  const errorHandler = () => {
    setError(null);
  };
  const successHandler = () => {
    setSuccess(null);
  };
  const handleReset = () => {
    setMotherTongue({});
    setOtherlang1({});
    setOtherlang2({});
  };
  const LoaderComponent = () => (
    <div id="preloader">
      <div id="status">
        <div className="spinner">
          <div className="double-bounce1"></div>
          <div className="double-bounce2"></div>
        </div>
      </div>
    </div>
  );
  return (
    <React.Fragment>
      {error && (
        <CommonModal
          title={error.title}
          message={error.message}
          onClose={errorHandler}
          status="error"
        />
      )}

      {success && (
        <CommonModal
          title={success.title}
          message={success.message}
          onClose={successHandler}
          status="success"
        />
      )}

      {
        isLoading ? <LoaderComponent /> :
          (
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                validation.handleSubmit();
              }}
            >
              <Row>
                <Col md={12} className='mt-3'>
                  <img src={locationIcon} className='legendIcon' style={{ width: `40px` }} />
                  &nbsp; <span className="m-2 legendName" >Locality</span>
                  <Row className="mt-3">
                    <Col md={4} sm={12}>
                      <div className=" features feature-primary p-1">
                        <div className="mb-3">
                          <Label className="form-label label_text">
                            Current Location

                          </Label>
                          <PlacesAutocomplete
                            value={currentAddress}
                            onChange={handleCurrentAddressChange}
                            onSelect={handlecurrentAddressSelect}
                            className="form-control form_input"
                          >
                            {({
                              getInputProps,
                              suggestions,
                              getSuggestionItemProps,
                              loading,
                            }) => {
                              const uniqueSuggestions = new Set();

                              return (
                                <div>
                                  <input
                                    {...getInputProps({
                                      placeholder: 'Search Places ...',
                                      className: 'location-search-input',
                                    })}
                                  />
                                  <div className="autocomplete-dropdown-container">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map((suggestion, index) => {
                                      const className = suggestion.active
                                        ? 'suggestion-item--active'
                                        : 'suggestion-item';

                                      const words = suggestion.description.split(',');
                                      const trimmedArray = words.map((str) => str.trim());
                                      const lastThreeWords = trimmedArray
                                        .slice(-3)
                                        .join(',');
                                      // Check if the suggestion is unique based on place_id
                                      if (!uniqueSuggestions.has(lastThreeWords)) {
                                        uniqueSuggestions.add(lastThreeWords);

                                        // inline style for demonstration purpose
                                        const style = suggestion.active
                                          ? {
                                            backgroundColor: '#fafafa',
                                            cursor: 'pointer',
                                          }
                                          : {
                                            backgroundColor: '#ffffff',
                                            cursor: 'pointer',
                                          };

                                        return (
                                          <div
                                            key={index}
                                            {...getSuggestionItemProps(suggestion, {
                                              className,
                                              style,
                                            })}
                                          >
                                            <span>{lastThreeWords}</span>
                                          </div>
                                        );
                                      } else {
                                        return null; // Skip rendering duplicate suggestions
                                      }
                                    })}
                                  </div>
                                </div>
                              );
                            }}
                          </PlacesAutocomplete>
                        </div>
                      </div>
                    </Col>

                    <Col md={4} sm={12}>
                      <div className="features feature-primary p-1">
                        <div className="mb-3">
                          <Label className="form-label label_text">
                            Native Location

                          </Label>
                          <PlacesAutocomplete
                            value={nativeAddress}
                            onChange={handlenativeAddressChange}
                            onSelect={handlenativeAddressSelect}
                            className="form-control form_input"
                          >
                            {({
                              getInputProps,
                              suggestions,
                              getSuggestionItemProps,
                              loading,
                            }) => {
                              const uniqueSuggestions = new Set();

                              return (
                                <div>
                                  <input
                                    {...getInputProps({
                                      placeholder: 'Search Places ...',
                                      className: 'location-search-input',
                                    })}
                                  />
                                  <div className="autocomplete-dropdown-container">
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map((suggestion, index) => {
                                      const className = suggestion.active
                                        ? 'suggestion-item--active'
                                        : 'suggestion-item';
                                      const words = suggestion.description.split(',');
                                      const trimmedArray = words.map((str) => str.trim());
                                      const lastThreeWords = trimmedArray
                                        .slice(-3)
                                        .join(',');

                                      // Check if the suggestion is unique based on place_id
                                      if (!uniqueSuggestions.has(lastThreeWords)) {
                                        uniqueSuggestions.add(lastThreeWords);
                                        // inline style for demonstration purpose
                                        const style = suggestion.active
                                          ? {
                                            backgroundColor: '#fafafa',
                                            cursor: 'pointer',
                                          }
                                          : {
                                            backgroundColor: '#ffffff',
                                            cursor: 'pointer',
                                          };

                                        return (
                                          <div
                                            key={index}
                                            {...getSuggestionItemProps(suggestion, {
                                              className,
                                              style,
                                            })}
                                          >
                                            <span>{lastThreeWords}</span>
                                          </div>
                                        );
                                      } else {
                                        return null; // Skip rendering duplicate suggestions
                                      }
                                    })}
                                  </div>
                                </div>
                              );
                            }}
                          </PlacesAutocomplete>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <hr style={{ boxShadow: `0px 1px 1px` }} />
                </Col>

                <Col md={12} className='mt-3'>
                  <img src={languageIcon} className='legendIcon' style={{ width: `40px` }} />
                  &nbsp; <span className="m-2 legendName" >Language</span>
                  <Row className="mt-3">
                    <Col md={3} sm={12}>
                      <div className="features feature-primary p-1">
                        <div className="mb-3">
                          <Label className="form-label label_text">
                            Mother Tongue

                          </Label>
                          <select
                            id="mothertongue"
                            className="form-select form-control form_Input"
                            value={motherTongue}
                            onChange={(e) => setMotherTongue(e.target.value)}
                          >
                            {(languageList || []).map((item, key) => (
                              <option key={key} value={item.value}>
                                {item.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </Col>

                    <Col md={3} sm={12}>
                      <div className="features feature-primary p-1">
                        <div className="mb-3">
                          <Label className="form-label label_text">
                            Other Language 1
                          </Label>
                          <select
                            id="otherlang1"
                            className="form-select form-control form_Input"
                            value={otherlang1}
                            onChange={(e) => setOtherlang1(e.target.value)}
                          >
                            {(languageList || []).map((item, key) => (
                              <option key={key} value={item.value}>
                                {item.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </Col>

                    <Col md={3} sm={12}>
                      <div className="features feature-primary p-1">
                        <div className="mb-3">
                          <Label className="form-label label_text">
                            Other Language 2
                          </Label>
                          <select
                            id="otherlang2"
                            className="form-select form-control form_Input"
                            value={otherlang2}
                            onChange={(e) => setOtherlang2(e.target.value)}
                          >
                            {(languageList || []).map((item, key) => (
                              <option key={key} value={item.value}>
                                {item.label}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    </Col>
                  </Row>
                  <hr style={{ boxShadow: `0px 1px 1px` }} />

                </Col>
                <div className="mt-md-4 mt-3 mb-2 mt-sm-0 text-md-end">
                  <button
                    type="submit"
                    className="btn btn-outline-primary mt-2 "
                  >
                    {' '}
                    Save Changes{' '}
                  </button>{' '}
                  <button
                    onClick={handleReset} type="reset"
                    className="btn btn-outline-secondary mt-2 ms-2"
                  >
                    Reset
                  </button>
                </div>
              </Row>
            </Form>
          )
      }
    </React.Fragment>
  );
};
export default RegionalFunction;
