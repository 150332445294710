import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ component, condition, redirectTo }) => {
  console.log('ProtectedRoute condition:', condition, 'RedirectTo:', redirectTo);

  if (condition) {
    return component;
  } else {
    return <Navigate to={redirectTo} />;
  }
};

export default ProtectedRoute;
