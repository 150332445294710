// const apiUrl = 'http://72.167.148.188:8080/'
// const apiUrl = 'http://118.139.166.68:8080/';
//const apiUrl = 'https://76kznm-8080.csb.app';
const apiUrl = 'https://mettamate.ai:8080/';
// const apiUrl = 'http://localhost:8080/';
// const apiUrl = 'http://118.139.166.68:8080/';
// const apiUrl = 'http://192.168.6.137:8080/';

// const apiUrl = 'https://Dns-mm-backend-env.eba-kpxvkk7m.ap-south-1.elasticbeanstalk.com/';
export default apiUrl;
