// import ReactQuill from 'react-quill';
//import 'react-quill/dist/quill.snow.css';
import React, { useState, useEffect } from 'react';
import MentorCard from './MentorCard';
import { useNavigate } from 'react-router-dom';

import {
  Container,
  Row,
  Col,
  Card,
  Form,
  CardBody,
  Label,
  Button,
  Input,
  FormFeedback,
  CardTitle,
  Table,
  ControlledCarousel,
  UncontrolledCollapse,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { useFormik } from 'formik';
import Select from 'react-select';
import axios from 'axios';
import '../css/userHomePage.css';
import apiUrl from '../../server';
import appUrl from '../../config';
import razorKeyID from '../../config';
import CommonModal from '../modal';
import { Link } from 'react-router-dom';
import becomeMentorImg from '../../../assets/images/mentorship/become-mentor-3.jpeg';

const SelfMentorPage = () => {
  const navigate = useNavigate();

  const [success, setSuccess] = useState();

  const [configurationData, setConfigurationData] = useState(null);
  const [error, setError] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [mentorStatus, setMentorStatus] = useState(null); // 'pending', 'approved', 'rejected', or null
  const [isEditing, setIsEditing] = useState(false);
  const [existingMentorData, setExistingMentorData] = useState(null); // Mentorship details
  const [existingMentorDetails, setExistingMentorDetails] = useState(null); // Community Members
  const [isLoading, setIsLoading] = useState(true);
  const [isUpdating, setIsUpdating] = useState(false);

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  useEffect(() => {
    let configurationDataFromStore = localStorage.getItem('configurationData');
    if (configurationDataFromStore) {
      const configurationDataLocal = JSON.parse(configurationDataFromStore);
      setConfigurationData(configurationDataLocal);
      setFirstName(configurationDataLocal.firstname);
    } else {
      // Redirect it to the index page
      window.location.href = '/index';
    }
    checkMentorStatus();
    fetchProfileData();
  }, []);

  // Helper function to get status-specific styling
  const getStatusStyle = (status) => {
    switch (status) {
      case 'PENDING':
        return { borderColor: '#FCD34D', backgroundColor: '#FEF3C7' }; // Yellow
      case 'APPROVED':
        return { borderColor: '#34D399', backgroundColor: '#D1FAE5' }; // Green
      case 'REJECTED':
        return { borderColor: '#EF4444', backgroundColor: '#FEE2E2' }; // Red
      case 'BLOCKED':
        return { borderColor: '#6B7280', backgroundColor: '#F3F4F6' }; // Gray
      default:
        return { borderColor: 'blue', backgroundColor: '#ffffff' };
    }
  };

  // Helper function to get status message
  const getStatusMessage = (status) => {
    switch (status) {
      case 'PENDING':
        return 'Your mentor application is under review';
      case 'APPROVED':
        return 'You are an active mentor';
      case 'REJECTED':
        return 'Your mentor application was not approved';
      case 'BLOCKED':
        return 'Your mentor account is currently blocked';
      default:
        return '';
    }
  };

  const handleMentorStatusToggle = async () => {
    setIsConfirmModalOpen(true);
    setModalMessage(
      `Are you sure you want to ${mentorStatus === 'APPROVED' ? 'deactivate' : 'activate'} your mentor profile?`,
    );
  };

  const confirmMentorStatusToggle = async () => {
    try {
      //alert('Mentor-Details : ' + JSON.stringify(existingMentorData));
      //alert('Mentor-Status : ' + mentorStatus);
      const newStatus = mentorStatus === 'APPROVED' ? 'BLOCKED' : 'APPROVED';
      const response = await axios.post(
        apiUrl + 'mentorship/update-mentor-status',
        {
          // This is the request body
          status: newStatus,
          mentorUuid: existingMentorData.mentor_uuid,
        },
        {
          // This is the config object with headers
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        },
      );

      if (response.status != 200) {
        setError({
          title: 'Error',
          message: 'Failed to update profile status. Please try again.',
        });
        return;
      }

      // Update the status in your app state
      setMentorStatus(newStatus);

      // Show success message
      setSuccess({
        title: 'Success !',
        message:
          'Profile successfully ' + mentorStatus === 'APPROVED'
            ? 'deactivated'
            : 'activated',
      });
    } catch (error) {
      if (error.response?.status === 401) {
        navigate('/user-login-page');
      }
      console.error('Error updating mentor status:', error);
      setError({
        title: 'Error',
        message: 'Failed to update profile status. Please try again.',
      });
    } finally {
      setIsConfirmModalOpen(false);
    }
  };

  const checkMentorStatus = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(apiUrl + 'mentorship/mentor-status', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      });

      if (response.data.isMentor) {
        setMentorStatus(response.data.status);
        setExistingMentorData(response.data.mentorDetails);
      }
    } catch (error) {
      console.error('Error checking mentor status:', error);
      setError({
        title: 'Error',
        message: 'Failed to fetch mentor status',
      });
      if (error.response?.status === 401) {
        navigate('/user-login-page');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchProfileData = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        apiUrl + 'mentorship/fetch-mentor-details',
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        },
      );

      // alert('Mentor-Details : ' + JSON.stringify(response));
      if (response.data) {
        setExistingMentorDetails(response.data.mentorDetails);
      }
    } catch (error) {
      console.error('Error checking mentor status:', error);
      setError({
        title: 'Error',
        message: 'Failed to fetch mentor status',
      });
      if (error.response?.status === 401) {
        navigate('/user-login-page');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <React.Fragment>
      <section className="section">
        <Container>
          <Row>
            <div className="text-center mb-8">
              <p className="fs-4 font-bold">
                <span className="text-primary"> JaiBheem </span> {firstName} !
              </p>
              <p className="fs-5 mt-4 text-xl text-gray-600">
                It is always great to support our community people and help them
                to reach to their full potenti als. Share your skills,
                knowledge, and passion with others who can benefit from your
                guidance.
              </p>
            </div>
          </Row>
          <hr />
          <Row>
            {/* Show Become Mentor only if not a mentor */}
            {!mentorStatus && (
              <Col xs="12" lg="12" md="12" className="mb-4">
                <div
                  style={{
                    // border: '0px solid blue',
                    // borderRadius: '8px',
                    padding: '16px',
                    backgroundColor: '#ffffff',
                    // boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    textAlign: 'start',
                  }}
                >
                  {/* Image */}

                  <p className="fs-4 font-bold">Become Mentor</p>
                  <p className="fs-5 mt-4 text-xl text-gray-600">
                    Please fill out the form to become mentor at MettaMate. By
                    becoming a mentor, you'll not only make a positive impact on
                    someone's life but also enhance your own personal and
                    professional growth.
                  </p>
                  <Link to={'/become_mentor'}>
                    <div className="text-start">
                      <Button
                        color="primary"
                        style={{
                          height: '30px',
                          fontSize: '12px',
                        }}
                      >
                        Become Mentor
                      </Button>
                      <p className="fs-6 mt-4 text-xl text-gray-600">
                        Mentoring application will go through the approval
                        process. Once approved, your mentorship profile would be
                        visible as a mentor to all the MettaMate users.
                      </p>
                      <div className="d-flex justify-content-center">
                        <img
                          src={becomeMentorImg}
                          className="img-fluid"
                          alt="..."
                          style={{
                            width: '80%',
                            height: 'auto',
                            borderRadius: '10px',
                            objectFit: 'cover',
                          }}
                        />
                      </div>
                    </div>
                  </Link>
                </div>
              </Col>
            )}

            {/* Show Edit Profile if already a mentor */}
            {mentorStatus && (
              <Col md="12" className="mb-4">
                <div
                  style={{
                    border: '1px solid blue',
                    borderRadius: '8px',
                    padding: '16px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    textAlign: 'start',
                  }}
                >
                  <p className="fs-4 font-bold mb-2">
                    Mentorship Application Status :
                    <span className="fs-5 ">
                      {' '}
                      {getStatusMessage(mentorStatus)}
                    </span>
                  </p>
                  <Link to={'/become_mentor'}>
                    <div className="text-center">
                      <Button
                        color="primary"
                        style={{ height: '35px', fontSize: '14px' }}
                        disabled={
                          mentorStatus === 'BLOCKED' ||
                          mentorStatus === 'REJECTED'
                        }
                      >
                        Edit Mentor Profile
                      </Button>
                    </div>
                  </Link>
                  <hr />

                  <div className="text-center">
                    <Button
                      color="primary"
                      style={{ height: '35px', fontSize: '14px' }}
                      //onClick={() => setIsConfirmModalOpen(true)}
                      onClick={handleMentorStatusToggle}
                      disabled={mentorStatus === 'REJECTED'}
                    >
                      {mentorStatus === 'APPROVED'
                        ? 'Deactivate My Mentor Profile'
                        : 'Activate My Mentor Profile'}
                    </Button>
                  </div>
                  <p className="fs-5 mb-2 mt-2">
                    If you have any questions please contact{' '}
                    <a href="mailto:admin@mettamate.ai">admin@mettamate.ai </a>
                  </p>
                </div>
              </Col>
            )}
          </Row>
          {/* Profile preview sections - only show if mentor */}
          <Row>
            {mentorStatus && existingMentorDetails && (
              <div
                style={{
                  // border: '1px solid #ccc',
                  // borderRadius: '8px',
                  padding: '16px',
                  // boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                  margin: '16px 0',
                  // backgroundColor: '#0D6EFD',
                }}
              >
                <Row
                  className="justify-content-center"
                  style={{
                    border: '0px solid blue',
                    borderRadius: '8px',
                    padding: '16px',
                  }}
                >
                  <Col className="mt-2 pt-2 text-center col-xl-4 col-lg-5 col-md-6 col-sm-8 col-xs-12">
                    {/* <img
                      src={publicViewText}
                      alt="Public View"
                      className="mb-2"
                      style={{ width: '400px', height: 'auto' }}
                    /> */}

                    <Card
                      style={{
                        // border: '2px solid blue',
                        // borderRadius: '8px',
                        padding: '16px',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                        backgroundColor: '#ffffff',
                        height: 'auto', // Ensure same height
                      }}
                    >
                      <CardBody>
                        <CardTitle tag="h5" className="text-primary">
                          Public View
                        </CardTitle>
                        <MentorCard
                          mentorDetails={existingMentorDetails}
                          mentorshipData={existingMentorData}
                          isAuthenticated={false}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                  <Col className="mt-2 pt-2 text-center col-xl-4 col-lg-5 col-md-6 col-sm-8 col-xs-12">
                    {/* <img
                      src={privateViewText}
                      alt="Private View"
                      className="mb-2"
                      style={{ width: '400px', height: 'auto' }}
                    /> */}

                    <Card
                      style={{
                        border: '2px solid blue',
                        borderRadius: '8px',
                        padding: '16px',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                        backgroundColor: '#fff8e1',
                        height: 'auto', // Ensure same height
                      }}
                    >
                      <CardBody>
                        <CardTitle tag="h5" className="text-primary">
                          Private View
                        </CardTitle>
                        <MentorCard
                          mentorDetails={existingMentorDetails}
                          mentorshipData={existingMentorData}
                          isAuthenticated={true}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            )}
          </Row>
        </Container>
      </section>
      <Modal
        isOpen={isConfirmModalOpen}
        toggle={() => setIsConfirmModalOpen(false)}
      >
        <ModalHeader toggle={() => setIsConfirmModalOpen(false)}>
          Confirm Action
        </ModalHeader>
        <ModalBody>{modalMessage}</ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => setIsConfirmModalOpen(false)}
          >
            Cancel
          </Button>
          <Button color="primary" onClick={confirmMentorStatusToggle}>
            Confirm
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );

  {
    /*<AlertDialog open={isConfirmModalOpen} onOpenChange={setIsConfirmModalOpen}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            {mentorStatus === 'APPROVED' ? 'Deactivate Profile' : 'Activate Profile'}
          </AlertDialogTitle>
          <AlertDialogDescription>
            Are you sure you want to {mentorStatus === 'APPROVED' ? 'deactivate' : 'activate'} your mentor profile? 
            {mentorStatus === 'APPROVED' 
              ? ' Your profile will not be visible to mentees until you activate it again.'
              : ' Your profile will become visible to mentees looking for mentors.'}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel onClick={() => setIsConfirmModalOpen(false)}>
            Cancel
          </AlertDialogCancel>
          <AlertDialogAction onClick={() => {
            handleMentorStatusToggle();
            setIsConfirmModalOpen(false);
          }}>
            {mentorStatus === 'APPROVED' ? 'Deactivate' : 'Activate'}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
    */
  }
};

export default SelfMentorPage;
