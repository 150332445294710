import axios from 'axios';
import apiUrl from 'apppages/server';

const PREDEFINED_COLORS = [
  '#1a365d', '#2D3748', '#553C9A', '#2C5282', '#744210',
  '#285E61', '#702459', '#7B341E', '#1A4E82', '#3C366B',
  '#1C4532', '#742A2A', '#233876', '#322659'
];

export const getRandomColor = (index) => {
  return PREDEFINED_COLORS[index % PREDEFINED_COLORS.length];
};

export const fetchEventCategories = async () => {
  try {
    const response = await axios.get(`${apiUrl}eb/getebcategories`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    // Modify the categories to include colors
    return response.data.map((category, index) => ({
      ...category,
      color: getRandomColor(index)
    }));
  } catch (error) {
    console.error('Failed to fetch categories:', error);
    throw error;
  }
};

const getDateRangeForTimeFilter = (timeFilter) => {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  
  switch (timeFilter) {
    case 'today':
      return { startDate: today.toISOString().split('T')[0], endDate: today.toISOString().split('T')[0] };
    case 'tomorrow':
      return { startDate: tomorrow.toISOString().split('T')[0], endDate: tomorrow.toISOString().split('T')[0] };
    case 'thisWeek': {
      const endOfWeek = new Date(today);
      endOfWeek.setDate(today.getDate() + (6 - today.getDay()));
      return { 
        startDate: today.toISOString().split('T')[0], 
        endDate: endOfWeek.toISOString().split('T')[0] 
      };
    }
    case 'weekend': {
      const saturday = new Date(today);
      saturday.setDate(today.getDate() + (6 - today.getDay()));
      const sunday = new Date(saturday);
      sunday.setDate(saturday.getDate() + 1);
      return { 
        startDate: saturday.toISOString().split('T')[0], 
        endDate: sunday.toISOString().split('T')[0] 
      };
    }
    case 'nextWeek': {
      const nextWeekStart = new Date(today);
      nextWeekStart.setDate(today.getDate() + (7 - today.getDay()) + 1);
      const nextWeekEnd = new Date(nextWeekStart);
      nextWeekEnd.setDate(nextWeekStart.getDate() + 6);
      return { 
        startDate: nextWeekStart.toISOString().split('T')[0], 
        endDate: nextWeekEnd.toISOString().split('T')[0] 
      };
    }
    default:
      return null;
  }
};

export const fetchEvents = async (params) => {
  try {
    const {
      pageIndex = 1,
      pageSize = 12,
      searchText = '',
      categories = [],
      eventType = '',
      location = null,
      timeFilter = 'all',
      startDate = '',
      endDate = '',
      isBookmarked = false,
      isMyEvents = false,
    } = params;

    // Handle date filtering
    let dateRange = null;
    if (timeFilter !== 'all') {
      dateRange = getDateRangeForTimeFilter(timeFilter);
    }

    // Build request body
    const requestBody = {
      page: pageIndex,
      limit: pageSize,
      searchtext: searchText,
      categories: categories.length > 0 ? categories : undefined,
      eventtype: eventType || undefined,
      lat: location ? location.lat : undefined,
      lng: location ? location.lng : undefined,
      eventlocation_placeid: location ? location.locationAddress : undefined,
      startDate: dateRange ? dateRange.startDate : startDate || undefined,
      endDate: dateRange ? dateRange.endDate : endDate || undefined,
      bookmarked: isBookmarked || undefined,
      myEvents: isMyEvents || undefined,
      memberuuid: localStorage.getItem('memberID') && (isMyEvents || isBookmarked) ? localStorage.getItem('memberID') : undefined,
    };

    // Make the API call
    const response = await axios.post(`${apiUrl}eb/fetchevents`, requestBody, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    return {
      events: response.data.events,
      pagination: response.data.pagination,
    };
  } catch (error) {
    console.error('Failed to fetch events:', error);
    throw error;
  }
};
