import React, { useEffect, useState, Component } from 'react';

class BookmarkedEventsFunction extends Component {
    render() {
      return (
        <div>
          BookMarked Events
        </div>
      );
    }
  }
  
  export default BookmarkedEventsFunction;