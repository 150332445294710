import React, { useState, useEffect, useRef } from 'react';
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  CardImg,
  Modal,
  ModalBody,
  Input,
} from 'reactstrap';
import axios from 'axios';
import apiUrl from 'apppages/server';
import {
  Calendar,
  Clock,
  MapPin,
  Globe,
  ExternalLink,
  Calendar as CalendarIcon,
  Share2,
  ZoomIn,
  Copy,
  Check,
} from 'lucide-react';
import { FaBookmark } from 'react-icons/fa';
import * as ReactIcons from 'react-icons/fa';
import * as BiIcons from 'react-icons/bi';
import { message, Tooltip } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import defaultEventImage from '../../../assets/images/MettaMateImages/eventSamples/defaultEventImage.png';

const styles = {
  header: {
    position: 'relative',
    width: '100%',
    height: '40vh', // Reduced height
    minHeight: '300px',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '4rem', // Add margin to push the card below the top bar
  },
  headerOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: 'linear-gradient(to bottom, rgba(0,0,0,0.2), rgba(0,0,0,0.8))',
  },
  contentCard: {
    backgroundColor: '#ffffff',
    borderRadius: '12px',
    padding: '1.5rem', // Reduced padding
    marginBottom: '2rem',
    boxShadow:
      '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    border: '1px solid #E2E8F0',
  },
  categoryBadge: {
    display: 'inline-flex',
    alignItems: 'center',
    gap: '0.5rem',
    padding: '0.6rem 1rem',
    borderRadius: '8px',
    fontSize: '1rem',
    fontWeight: '500',
    transition: 'all 0.3s ease',
    whiteSpace: 'nowrap',
    border: '1px solid #E2E8F0',
  },
  infoItem: {
    backgroundColor: '#F8FAFC',
    borderRadius: '12px',
    padding: '1rem', // Reduced padding
    marginBottom: '1rem',
    border: '1px solid #E2E8F0',
  },
  actionButtons: {
    position: 'absolute',
    top: '1rem',
    right: '1rem',
    display: 'flex',
    gap: '0.5rem',
  },
};

const formatTime = (time, timezone) => {
  const [hours, minutes] = time.split(':');
  const date = new Date();
  date.setHours(hours, minutes, 0, 0);

  return new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: '2-digit',
    hour12: true,
  }).format(date);
};

const EventDetailsPage = () => {
  const { eventuuid, slug } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [urlPreview, setUrlPreview] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const [isShareModalVisible, setIsShareModalVisible] = useState(false);
  const [shareUrl, setShareUrl] = useState('');
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    fetchEventDetails();
  }, [eventuuid]);

  const fetchEventDetails = async () => {
    try {
      let endpoint;
      if (location.pathname.startsWith('/event/')) {
        // Slug-based URL
        endpoint = `${apiUrl}eb/share-event/${slug}`;
      } else {
        // UUID-based URL
        endpoint = `${apiUrl}eb/fetchEbPost/${eventuuid}`;
      }

      const response = await axios.get(endpoint, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      // alert('Event Details: '+JSON.stringify(response.data));
      setEvent(response.data);
      if (response.data.eventorgurl) {
        fetchUrlPreview(response.data.eventorgurl);
      }
    } catch (error) {
      message.error('Failed to fetch event details');
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchUrlPreview = async (url) => {
    try {
      // You'll need to implement a backend endpoint for this
      const response = await axios.get(
        `${apiUrl}eb/org-url-preview?url=${encodeURIComponent(url)}`,
      );
      setUrlPreview(response.data);
    } catch (error) {
      console.error('Failed to fetch URL preview');
    }
  };

  const handleShare = () => {
    const shareUrl = `${window.location.protocol}//${window.location.host}/event/${event.event_slug}`;
    setShareUrl(shareUrl);
    setIsShareModalVisible(true);
  };

  const handleCopy = () => {
    setCopied(true);
    message.success('Link copied to clipboard!');
    setTimeout(() => setCopied(false), 3000);
  };

  const handleNativeShare = async () => {
    try {
      await navigator.share({
        title: event.eventtitle,
        text: `Check out this event: ${event.eventtitle}`,
        url: shareUrl
      });
      message.success('Event shared successfully!');
      setIsShareModalVisible(false); // Close modal on successful share
    } catch (error) {
      if (error.name === 'AbortError') {
        // User canceled share - just keep the modal open
        return;
      }
      message.error('Failed to share event');
      console.error('Share failed:', error);
    }
  };

  const getIconComponent = (iconName) => {
    return iconName
      ? ReactIcons[iconName] || BiIcons[iconName] || ReactIcons.FaUsers
      : ReactIcons.FaUsers;
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!event) {
    return <div>Event not found</div>;
  }

  return (
    <div className="event-details">
      {/* Hero Header */}
      <div
        style={{
          ...styles.header,
          backgroundImage: `url(${event.eventimagefilepath || defaultEventImage})`,
        }}
      >
        <div style={styles.headerOverlay} />
        <Container className="position-relative text-white pb-1">
          <Card
            className="shadow-lg mt-1"
            style={{
              maxWidth: '600px',
              margin: '0 auto',
              borderRadius: '12px',
              overflow: 'hidden',
              height: 'calc(40vh - 4rem)', // Adjust height to fit within the header
              marginTop: '4rem', // Add margin to push the card below the top bar
              position: 'relative', // Add position relative for the icon
            }}
          >
            <CardImg
              top
              src={event.eventimagefilepath || defaultEventImage}
              alt={event.eventtitle}
              style={{
                height: '100%',
                objectFit: 'cover', // Ensure the entire card area is covered without white spaces
                borderTopLeftRadius: '12px',
                borderTopRightRadius: '12px',
              }}
              onClick={() => setIsModalOpen(true)}
            />
            <ZoomIn
              size={32}
              style={{
                position: 'absolute',
                bottom: '1rem',
                right: '1rem',
                color: 'white',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                borderRadius: '50%',
                padding: '0.5rem',
                cursor: 'pointer',
              }}
              onClick={() => setIsModalOpen(true)}
            />
          </Card>
          <div style={styles.actionButtons}>
            <Tooltip 
              title={copySuccess ? 'Copied!' : 'Click to copy link'} 
              placement="bottom"
            >
              <Button
                color="light"
                size="lg"
                className="rounded-pill px-4 d-flex align-items-center"
                onClick={handleShare}
              >
                <Share2 size={18} className="me-2" />
                {copySuccess ? 'Copied!' : 'Share'}
              </Button>
            </Tooltip>
          </div>
        </Container>
      </div>

      <Container className="py-5">
        <Row>
          <Col lg={8}>
            {/* Event Title and Categories */}
            <div className="mb-5">
              <h1
                className="display-4 fw-bold mb-4"
                style={{ fontSize: '2.5rem' }}
              >
                {event.eventtitle}
              </h1>
              <div className="d-flex flex-wrap gap-2 mb-4">
                {event.categories?.map((category, index) => {
                  const IconComponent = getIconComponent(category.categoryicon);
                  return (
                    <Button
                      key={index}
                      color="light"
                      className="d-flex align-items-center gap-2 px-3 py-2 rounded-pill"
                      style={{
                        ...styles.categoryBadge,
                        backgroundColor: `${category.color}33`, // Adjusted color for better visibility
                        color: category.color,
                        border: `1px solid ${category.color}66`,
                        backgroundColor: '#f0f0f0', // Added background color
                      }}
                    >
                      <IconComponent size={18} />
                      <span>{category.categoryname}</span>
                    </Button>
                  );
                })}
              </div>
            </div>

            {/* Event Description */}
            <div style={styles.contentCard}>
              <h2 className="h3 mb-4" style={{ fontSize: '1.75rem' }}>
                About this Event
              </h2>
              <div
                className="text-dark fs-5"
                style={{ whiteSpace: 'pre-line', lineHeight: '1.8' }}
              >
                {event.eventdetails}
              </div>
            </div>

            {/* Organization Details */}
            {urlPreview && (
              <div style={styles.contentCard}>
                <h3 className="h4 mb-4" style={{ fontSize: '1.5rem' }}>
                  Event Organizer
                </h3>
                <a
                  href={event.eventorgurl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-decoration-none"
                >
                  <div
                    className="d-flex align-items-center gap-4 p-4"
                    style={{
                      backgroundColor: '#F8FAFC',
                      borderRadius: '12px',
                      border: '1px solid #E2E8F0',
                    }}
                  >
                    {urlPreview.favicon && (
                      <div className="flex-shrink-0">
                        <img
                          src={urlPreview.favicon}
                          alt=""
                          width={64}
                          height={64}
                          className="rounded-circle shadow-sm"
                        />
                      </div>
                    )}
                    <div>
                      <h4 className="h5 mb-2 text-primary">
                        {urlPreview.title}
                      </h4>
                      <p className="mb-0">{urlPreview.description}</p>
                    </div>
                    <ExternalLink size={20} className="ms-auto" />
                  </div>
                </a>
              </div>
            )}
          </Col>

          <Col lg={4}>
            {/* Event Details Card */}
            <div style={styles.contentCard} className="sticky-top">
              {/* Date & Time */}
              <div style={styles.infoItem}>
                <div className="d-flex align-items-center gap-3 mb-3">
                  <div className="p-2 bg-primary bg-opacity-10 rounded-circle">
                    <CalendarIcon size={24} className="text-primary" />
                  </div>
                  <h4 className="h5 mb-0" style={{ fontSize: '1.25rem' }}>
                    Date and Time
                  </h4>
                </div>
                <div className="ms-2 ps-4 border-start border-2">
                  <div className="text-dark fw-medium fs-5">
                    {new Date(event.eventstartdate).toLocaleDateString(
                      'en-US',
                      {
                        weekday: 'long',
                        month: 'long',
                        day: 'numeric',
                        year: 'numeric',
                      },
                    )}
                  </div>
                  <div className="text-dark fw-medium fs-6">
                    {formatTime(event.eventstarttime, event.eventtimezone)} - {formatTime(event.eventendtime, event.eventtimezone)}
                    <span className="ms-1 badge bg-light text-primary">
                      {event.eventtimezone}
                    </span>
                  </div>
                </div>
              </div>

              {/* Location */}
              <div style={styles.infoItem}>
                <div className="d-flex align-items-center gap-3 mb-3">
                  <div className="p-2 bg-primary bg-opacity-10 rounded-circle">
                    <MapPin size={24} className="text-primary" />
                  </div>
                  <h4 className="h5 mb-0" style={{ fontSize: '1.25rem' }}>
                    Location
                  </h4>
                </div>
                <div className="ms-2 ps-4 border-start border-2">
                  {event.eventtype === 'virtual' ? (
                    <>
                      <div className="text-dark fw-medium fs-6">Virtual Event</div>
                      <div className="text-dark">
                        Join via the link provided
                      </div>
                      <div className="text-dark fw-medium fs-6">
                        <span className="text-primary">
                          {event.eventlocation_virtual_link}
                        </span>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="text-dark fw-medium fs-6">
                        {event.eventlocation_address}
                      </div>
                      <a
                        href={`https://maps.google.com/?q=${event.eventlocation_address}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-primary text-decoration-none"
                      >
                        View on Map <ExternalLink size={14} className="ms-1" />
                      </a>
                    </>
                  )}
                </div>
              </div>

              {/* Registration Button */}
              {event.eventticketurl && (
                <Button
                  color="primary"
                  size="lg"
                  className="w-100 d-flex align-items-center justify-content-center gap-2 rounded-pill"
                  href={event.eventticketurl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <ExternalLink size={20} />
                  Register Now
                </Button>
              )}
            </div>
          </Col>
        </Row>
        {/* Back Button */}
        <Row>
          <Col lg={12} className="text-center mt-4">
            <Button color="secondary" onClick={() => navigate(-1)}>
              Back
            </Button>
          </Col>
        </Row>
      </Container>

      {/* Image Preview Modal */}
      <Modal
        isOpen={isModalOpen}
        toggle={() => setIsModalOpen(!isModalOpen)}
        size="lg"
      >
        <ModalBody className="p-0">
          <img
            src={event.eventimagefilepath || defaultEventImage}
            alt={event.eventtitle}
            style={{ width: '100%', height: 'auto' }}
          />
        </ModalBody>
      </Modal>

      {/* Share Modal */}
      <Modal
        isOpen={isShareModalVisible}
        toggle={() => setIsShareModalVisible(false)}
      >
        <div className="p-4">
          <h5 className="mb-3">Share "{event.eventtitle}"</h5>
          
          <div className="d-flex gap-2 align-items-center mb-4">
            <Input
              value={shareUrl}
              readOnly
              className="flex-grow-1"
              style={{
                backgroundColor: '#f8f9fa',
                padding: '0.75rem',
                borderRadius: '8px'
              }}
            />
            <CopyToClipboard text={shareUrl} onCopy={handleCopy}>
              <Button
                color={copied ? "success" : "primary"}
                className="d-flex align-items-center gap-2"
              >
                {copied ? (
                  <>
                    <Check size={16} />
                    Copied!
                  </>
                ) : (
                  <>
                    <Copy size={16} />
                    Copy
                  </>
                )}
              </Button>
            </CopyToClipboard>
          </div>

          {navigator.share && (
            <div className="mt-4 border-top pt-4">
              <h6 className="mb-3">Or share via</h6>
              <Button
                color="primary"
                outline
                className="w-100"
                onClick={handleNativeShare}
              >
                <Share2 size={16} className="me-2" />
                Share on your device
              </Button>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default EventDetailsPage;
