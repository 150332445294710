import ReactQuill from 'react-quill-new';
import 'react-quill-new/dist/quill.snow.css';

import React, { useState } from 'react';
import { Calendar, MessageSquare, Video, ExternalLink } from 'lucide-react';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import {
  Row,
  Col,
  CardBody,
  Card,
  Label,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  PaginationItem,
  PaginationLink,
  Alert,
  Fade,
  Progress,
} from 'reactstrap';
import Slider from 'react-slick';
import manavatar from '../../../assets/images/MettaMateImages/male_avatar.jpg';
import '../../../assets/css/mentorship.css';

const MentorCard = ({ mentorDetails, mentorshipData, isAuthenticated }) => {

  // Quill editor configurations
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link'],
      ['clean'],
    ],
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'list',
    'bullet',
    'link',
  ];

  //alert("MentorDetails="+JSON.stringify(mentorshipData));
  const [modal, setModal] = useState(false);
  const [mentorBiodata, setMentorBiodata] = useState(false);
  const [mentorCategories, setMentorCategories] = useState(false);
  const toggleMentorCategoriesModal = () => setMentorCategories(!mentorCategories);

  const toggleModal = () => setModal(!modal);
  const toggleMentorBiodataModal = () => setMentorBiodata(!mentorBiodata);

  const handleMessage = () => {
    window.open('https://wa.me/919172896771', '_blank');
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };
  // alert(JSON.stringify(mentor));
  return (
    <div>
      <Card className="catagories rounded rounded-3 overflow-hidden border-1 border-primary job-box job-primary candidate-list border-0 shadow card">
        <CardBody className="rounded overflow-hidden p-0 ">
          <Slider className="imageSlider image-container" {...settings}>
            <img src={mentorDetails.photothumb1} className="img-fluid img_thumb" />
          </Slider>
        </CardBody>
      </Card>
      <Card className="catagories rounded rounded-3 mt-1 shadow">
        <CardBody className="rounded shadow p-2 fixHeight">
          <p className="text-dark h5 name mb-0">
            <span style={{ color: `#0D6EFD` }}>{mentorDetails.firstname}</span>
          </p>
          <p className="fs-5 mb-1 mt-1">{mentorshipData.mentor_title}</p>
          <p className="fs-6 mb-1 mt-1">{mentorDetails.jobtitle}</p>

          <p className="fs-7 mb-1 mt-1">
            <FeatherIcon icon="map-pin" className="fea icon-sm me-1" />
            {mentorDetails.currentlocationname || mentorDetails.nativelocationname || "Location Not Found"}
          </p>
          {/* Skillset */}
          <div className="d-flex justify-content-center py-2">
            {mentorshipData.categories.length > 0 && (
              <div>
                <Link
                  className="rounded bg-soft-primary py-1 px-2 fs-7"
                  title={mentorshipData.categories[0].categoryname}
                >
                  {mentorshipData.categories[0].categoryname}
                </Link>
                {mentorshipData.categories.length > 1 && (
                  <button
                    className="btn btn-link"
                    onClick={() =>
                      toggleMentorCategoriesModal()
                    }
                    style={{ fontSize: `12px` }}
                  >
                    +{mentorshipData.categories.length - 1} more
                  </button>
                )}
              </div>
            )}
            {mentorshipData.categories.length == 0 && (
              <Link className="rounded bg-soft-primary py-1 px-2 fs-7 mt-2">
                Expertise Not Mentioned
              </Link>
            )}
          </div>

          <span className="text-muted">
            <FeatherIcon icon="briefcase" className="fea icon-sm me-1" />
            Experience <span className="text-success">{mentorshipData.years_of_experience}+ years</span>
          </span>
          <div className=" d-flex justify-content-center">
            <div className="m-1">
              <Button
                color="primary"
                style={{ height: '35px', fontSize: '14px' }}
                onClick={toggleMentorBiodataModal}
              >
                Professional Summary
              </Button>
            </div>
          </div>
        </CardBody>
      </Card>
      {/** Mentor bioddata **/}
      {(
        <Modal isOpen={mentorBiodata} toggle={toggleMentorBiodataModal} size="lg">
          <ModalHeader toggle={toggleMentorBiodataModal}>
            {mentorDetails.firstname}'s Brief Profile
          </ModalHeader>
          <ModalBody>
            {/* Bio Section */}
            <div className="profile-section mb-4">
              <h5 className="d-flex align-items-center mb-3">
                <i className="fas fa-user-circle text-primary me-2"></i>
                Professional Bio
              </h5>
              <div className="bio-content px-3">
                <ReactQuill
                  theme="bubble"
                  value={mentorshipData.mentor_bio}
                  readOnly={true}
                  style={{ minHeight: '100px', fontSize: '16px' }}
                />
              </div>
            </div>

            {/* Mentorship Type Section */}
            {isAuthenticated && (
              <div className="mentorship-type-section mb-4">
                <h5 className="d-flex align-items-center mb-3">
                  <i className="fas fa-handshake text-success me-2"></i>
                  Mentorship Type
                </h5>
                <div className="px-3">
                  <div className="d-flex align-items-center">
                    <i className={`fas fa-${mentorshipData.is_paid_mentorship ? 'rupee-sign text-warning' : 'heart text-danger'} me-2`}></i>
                    <span>
                      {mentorshipData.is_paid_mentorship ?
                        `Paid Mentorship - ₹ ${mentorshipData.hourly_rate}/hour` :
                        'Volunteer Mentorship'}
                    </span>
                  </div>
                </div>
              </div>
            )}

            {/* Availability Schedule */}
            {isAuthenticated && (
              <div className="availability-section">
                <h5 className="d-flex align-items-center mb-3">
                  <i className="fas fa-calendar-alt text-info me-2"></i>
                  Weekly Availability
                </h5>
                <div className="schedule-grid px-3">
                  <Row>
                    {mentorshipData.availability?.map((schedule) => (
                      <Col md={12} key={schedule.day_of_week}>
                        <div className="schedule-item d-flex align-items-center mb-2 p-2 bg-light rounded">
                          <div className="d-flex w-100 justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                              <i className="fas fa-clock text-primary me-3"></i>
                              <div>
                                <strong className="me-2" style={{ minWidth: '100px', display: 'inline-block' }}>
                                  {schedule.day_of_week}
                                </strong>
                                <span className="text-muted">
                                  {schedule.startTime} - {schedule.endTime}
                                </span>
                              </div>
                            </div>
                            <span className="badge bg-success">Available</span>
                          </div>
                        </div>
                      </Col>
                    ))}
                    {(!mentorshipData.availability || mentorshipData.availability.length === 0) && (
                      <Col md={12}>
                        <div className="text-center text-muted p-3">
                          <i className="fas fa-calendar-times me-2"></i>
                          Schedule not specified
                        </div>
                      </Col>
                    )}
                  </Row>
                </div>
              </div>
            )}
          </ModalBody>
          <ModalFooter>
          <Button color="primary" onClick={handleMessage}>
              Message
            </Button>
            <Button color="secondary" onClick={toggleMentorBiodataModal}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      )}

      {(
        <Modal isOpen={mentorCategories} toggle={toggleMentorCategoriesModal}>
          <ModalHeader toggle={toggleMentorCategoriesModal}>
            {mentorDetails.firstname}'s Expertise
          </ModalHeader>
          <ModalBody>
            {/* Areas of Expertise Section */}
            <div className="expertise-section mb-4">
              <h5 className="d-flex align-items-center mb-3">
                <i className="fas fa-star text-warning me-2"></i>
                Areas of Expertise
              </h5>
              <div className="categories-list ms-3">
                {mentorshipData.categories?.map((category) => (
                  <div
                    key={category.categoryid}
                    className="category-item d-flex align-items-center py-2"
                  >
                    <i className="fas fa-check-circle text-success me-2"></i>
                    <span>{category.categoryname}</span>
                  </div>
                ))}
                {(!mentorshipData.categories || mentorshipData.categories.length === 0) && (
                  <div className="text-muted">No areas of expertise specified</div>
                )}
              </div>
            </div>

            {/* Specializations Section */}
            <div className="specializations-section">
              <h5 className="d-flex align-items-center mb-3">
                <i className="fas fa-certificate text-primary me-2"></i>
                Specializations
              </h5>
              <div className="subcategories-list ms-3">
                {mentorshipData.subcategories?.map((subcategory) => (
                  <div
                    key={subcategory.subcategoryid}
                    className="subcategory-item d-flex align-items-center py-2"
                  >
                    <i className="fas fa-arrow-right text-secondary me-2"></i>
                    <span>{subcategory.subcategoryname}</span>
                  </div>
                ))}
                {(!mentorshipData.subcategories || mentorshipData.subcategories.length === 0) && (
                  <div className="text-muted">No specializations specified</div>
                )}
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleMentorCategoriesModal}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </div>
  );
};

export default MentorCard;
