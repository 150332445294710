import React, { useState, useEffect } from 'react';
import {
    Row,
    Col,
    Table,
    PaginationItem,
    PaginationLink,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from 'reactstrap';
import ContentLoader from 'react-content-loader';
import axios from 'axios';
import apiUrl from '../../server';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import ModalBodySkeleton from '../skeleton';
import ProfileModal from '../Profile_modal';
import MentorProfileDetails from './MentorProfileDetails';

const MentorsManagement = () => {
    const [activeTab, setActiveTab] = useState('PENDING');
    const [mentors, setMentors] = useState([]);  // Initialize with empty array instead of undefined
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageSize] = useState(10);
    const [totalCount, setTotalCount] = useState(0);
    const [totalPages, setTotalPages] = useState(0);
    const [searchTerm, setSearchTerm] = useState('');
    const [confirmModalOpen, setConfirmModalOpen] = useState(false);
    const [selectedMentor, setSelectedMentor] = useState(null);
    const [pendingAction, setPendingAction] = useState(null);
    const [memberModal, setModal] = useState(false);
    const [memberFullData, setMemberFullData] = useState([]);
    const [dob, setDOB] = useState({});
    const [currentLocation, setCurrentLocation] = useState({});
    const [nativeLocation, setNativeLocation] = useState({});
    const [companyLocation, setCompanyLocation] = useState({});
    const [isModalLoading, setModalLoading] = useState(true);
    const [existingMentorData, setExistingMentorData] = useState(null);
    const [mentorProfileModal, setMentorProfileModal] = useState(false);
    const [mentorProfileData, setMentorProfileData] = useState(null);

    const menuTabs = [
        { label: 'PENDING', value: 'PENDING' },
        { label: 'APPROVED', value: 'APPROVED' },
        { label: 'BLOCKED', value: 'BLOCKED' },
        { label: 'REJECTED', value: 'REJECTED' },
    ];

    useEffect(() => {
        loadMentors(currentPage, activeTab, searchTerm);
    }, [currentPage, activeTab]);

    const loadMentors = async (page, status, search = '') => {
        // alert("hellp we are coming here");
        setLoading(true);
        try {
            const response = await axios.post(
                `${apiUrl}mentorship/listMentors`,
                {
                    pageNo: page,
                    pageSize: pageSize,
                    status: status,
                    searchText: search
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                }
            );
            //alert(JSON.stringify(response.data));
            setMentors(response.data.mentors);
            setTotalCount(response.data.totalCount);
            setTotalPages(Math.ceil(response.data.totalCount / pageSize));
        } catch (error) {
            console.error('Error loading mentors:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleTabClick = (tab) => {
        setActiveTab(tab);
        setCurrentPage(1);
        setSearchTerm('');
        loadMentors(1, tab, '');
    };

    const handleSearch = (e) => {
        if (e.key === 'Enter') {
            setSearchTerm(e.target.value);
            setCurrentPage(1);
            loadMentors(1, activeTab, e.target.value);
        }
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleStatusChange = async (mentorId, newStatus) => {
        setSelectedMentor(mentorId);
        setPendingAction(newStatus);
        setConfirmModalOpen(true);
    };

    const handleDeleteMentor = async (mentorId) => {
        setSelectedMentor(mentorId);
        setPendingAction('DELETE');
        setConfirmModalOpen(true);
    };

    const confirmStatusChange = async () => {
        //alert("MentorUuid: " + selectedMentor + " Status: " + pendingAction);
        try {
            if (pendingAction === 'DELETE') {
                await axios.delete(
                    `${apiUrl}mentorship/delete-mentor/${selectedMentor}`,
                    {
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('token')}`,
                        },
                    }
                );
            } else {
                await axios.post(
                    apiUrl + 'mentorship/update-mentor-status',
                    {
                        mentorUuid: selectedMentor,
                        status: pendingAction,
                    },
                    {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: 'Bearer ' + localStorage.getItem('token'),
                        },
                    }
                );
            }
            loadMentors(currentPage, activeTab, searchTerm);
            setConfirmModalOpen(false);
        } catch (error) {
            console.error('Error updating mentor status:', error);
        }
    };

    const closeModal = () => {
        setConfirmModalOpen(false);
        setSelectedMentor(null);
        setPendingAction(null);
    };

    const renderPaginationItems = () => {
        const items = [];
        for (let i = 1; i <= totalPages; i++) {
            items.push(
                <PaginationItem key={i} active={currentPage === i}>
                    <PaginationLink onClick={() => handlePageChange(i)}>
                        {i}
                    </PaginationLink>
                </PaginationItem>
            );
        }
        return items;
    };

    const getOrdinalSuffix = (day) => {
        const suffixes = ['th', 'st', 'nd', 'rd'];
        const v = day % 100;
        return day + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
    };

    const getMonthName = (month) => {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        return months[month];
    };

    const fetchMentorProfileData = async (mentorUuid) => {
        try {
            setIsLoading(true);
            const response = 
                await axios.get(`${apiUrl}mentorship/fetch-mentor-status/${mentorUuid}`, {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.getItem('token'),
                },
            });

            if (response.data.isMentor) {
                setExistingMentorData(response.data.mentorDetails);
            }
        } catch (error) {
            console.error('Error checking mentor status:', error);
            setError({
                title: 'Error',
                message: 'Failed to fetch mentor status',
            });
            if (error.response?.status === 401) {
                navigate('/user-login-page');
            }
        } finally {
            setIsLoading(false);
        }
    };

    const openProfileModal = async (mentorUuid) => {
        setModalLoading(true);
        setModal(true);
        setMemberFullData(null); // Change from [] to null for cleaner checks

        try {
            console.log("Fetching mentor details for:", mentorUuid); // Debug log
            const result = await axios.get(
                `${apiUrl}members/fulldetails/${mentorUuid}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                }
            );

            console.log("Received data:", result.data); // Debug log

            if (result.data && result.data.length > 0) {
                const mentor = result.data[0];

                // Set DOB if available
                if (mentor.dob) {
                    const date = new Date(mentor.dob);
                    setDOB(`${getOrdinalSuffix(date.getDate())} ${getMonthName(date.getMonth())}, ${date.getFullYear()}`);
                }

                // Set locations with fallbacks
                setNativeLocation(mentor.nativelocationname || 'Location Not Updated');
                setCurrentLocation(mentor.currentlocationname || 'Location Not Updated');
                setCompanyLocation(mentor.companylocationname || 'Location Not Updated');

                // Set the full mentor data
                setMemberFullData(mentor);
            }
        } catch (error) {
            console.error('Error fetching mentor details:', error);
            // Optionally show error message to user
        } finally {
            setModalLoading(false);
        }
    };

    const closeProfileModal = () => {
        setModal(false);
        // Clean up states on close
        setMemberFullData(null);
        setDOB('');
        setCurrentLocation('');
        setNativeLocation('');
        setCompanyLocation('');
    };

    const openMentorProfileModal = async (mentorUuid) => {
        try {
            setModalLoading(true);
            setMentorProfileModal(true);
            const response = await axios.get(
                `${apiUrl}mentorship/fetch-mentor-status/${mentorUuid}`,
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                    },
                }
            );
            if (response.data.isMentor) {
                setMentorProfileData(response.data.mentorDetails);
            }
        } catch (error) {
            console.error('Error fetching mentor profile:', error);
        } finally {
            setModalLoading(false);
        }
    };

    const closeMentorProfileModal = () => {
        setMentorProfileModal(false);
        setMentorProfileData(null);
    };

    return (
        <div className="mentors-management">
            <div className="tabs-container mb-3">
                <div className="row">
                    <div className="col-md-6">
                        <div className="tabs">
                            {menuTabs.map((tab) => (
                                <React.Fragment key={tab.value}>
                                    <input
                                        type="radio"
                                        id={`radio-${tab.value}`}
                                        name="mentor-tabs"
                                        checked={activeTab === tab.value}
                                        onChange={() => handleTabClick(tab.value)}
                                    />
                                    <label className="tab" htmlFor={`radio-${tab.value}`}>
                                        {tab.label}
                                    </label>
                                </React.Fragment>
                            ))}
                            <span className="glider"></span>
                        </div>
                    </div>
                    <div className="col-md-6 d-flex justify-content-end">
                        <div className="col-md-6 d-flex my-2">
                            <i className="fa fa-refresh m-3" style={{ cursor: 'pointer' }}
                                onClick={() => loadMentors(1, activeTab, '')}></i>
                            <input
                                className="form-control mb-2"
                                placeholder="Enter to search by Name"
                                onKeyPress={handleSearch}
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {loading ? (
                <ContentLoader
                    speed={2}
                    height={200}
                    viewBox="0 0 1000 200"
                    backgroundColor="#f3f3f3"
                    foregroundColor="#ecebeb"
                >
                    <rect x="0" y="0" rx="3" ry="3" width="1000" height="20" />
                    <rect x="0" y="30" rx="3" ry="3" width="1000" height="20" />
                    <rect x="0" y="60" rx="3" ry="3" width="1000" height="20" />
                    <rect x="0" y="90" rx="3" ry="3" width="1000" height="20" />
                    <rect x="0" y="120" rx="3" ry="3" width="1000" height="20" />
                </ContentLoader>
            ) : (
                <>
                    <Table bordered hover>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Mentor Name</th>
                                <th>Email</th>
                                <th>Phone</th>
                                <th>Status</th>
                                <th>Action</th>
                                <th>Mentor Profile</th>
                            </tr>
                        </thead>
                        <tbody>
                            {mentors && mentors.length > 0 ? (
                                mentors.map((mentor, index) => (
                                    <tr key={mentor.mentor_uuid}>
                                        <td>{(currentPage - 1) * pageSize + index + 1}</td>
                                        <td className="text-primary">
                                            <u className="cursor-pointer" onClick={() => openProfileModal(mentor.mentor_uuid)}>
                                                {mentor.firstname} {mentor.lastname}
                                            </u>
                                        </td>
                                        <td>{mentor.emailid}</td>
                                        <td>
                                            +{mentor.phonecountrycode}-{mentor.phonenumber}
                                        </td>
                                        <td>
                                            <span className={`status-${mentor.status.toLowerCase()}`}>
                                                {mentor.status}
                                            </span>
                                        </td>
                                        <td>
                                            {activeTab === 'PENDING' && (
                                                <>
                                                    <Button
                                                        color="success"
                                                        size="sm"
                                                        className="me-2"
                                                        onClick={() => handleStatusChange(mentor.mentor_uuid, 'APPROVED')}
                                                    >
                                                        Approve
                                                    </Button>
                                                    <Button
                                                        color="danger"
                                                        size="sm"
                                                        onClick={() => handleStatusChange(mentor.mentor_uuid, 'REJECTED')}
                                                    >
                                                        Reject
                                                    </Button>
                                                </>
                                            )}
                                            {activeTab === 'APPROVED' && (
                                                <Button
                                                    color="warning"
                                                    size="sm"
                                                    onClick={() => handleStatusChange(mentor.mentor_uuid, 'BLOCKED')}
                                                >
                                                    Block
                                                </Button>
                                            )}
                                            {activeTab === 'BLOCKED' && (
                                                <Button
                                                    color="success"
                                                    size="sm"
                                                    onClick={() => handleStatusChange(mentor.mentor_uuid, 'APPROVED')}
                                                >
                                                    Unblock
                                                </Button>
                                            )}
                                            <Button
                                                color="danger"
                                                size="sm"
                                                onClick={() => handleDeleteMentor(mentor.mentor_uuid)}
                                            >
                                                Delete
                                            </Button>
                                        </td>
                                        <td>
                                            <Button
                                                color="info"
                                                size="sm"
                                                onClick={() => openMentorProfileModal(mentor.mentor_uuid)}
                                            >
                                                View Profile
                                            </Button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="7" className="text-center">
                                        No mentors found
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </Table>

                    <Row>
                        <Col md={6}>
                            <div className="">
                                Total Counts: <span className="totalCount">{totalCount}</span>
                            </div>
                        </Col>
                        <Col md={6} className="text-end">
                            {totalCount > pageSize && (
                                <ul className="pagination justify-content-end mb-0">
                                    <PaginationItem disabled={currentPage === 1}>
                                        <PaginationLink onClick={() => handlePageChange(currentPage - 1)}>
                                            {'<<'}
                                        </PaginationLink>
                                    </PaginationItem>
                                    {renderPaginationItems()}
                                    <PaginationItem disabled={currentPage === totalPages}>
                                        <PaginationLink onClick={() => handlePageChange(currentPage + 1)}>
                                            {'>>'}
                                        </PaginationLink>
                                    </PaginationItem>
                                </ul>
                            )}
                        </Col>
                    </Row>
                </>
            )}

            <Modal isOpen={confirmModalOpen} toggle={closeModal}>
                <ModalHeader toggle={closeModal}>
                    Confirmation
                    <button
                        type="button"
                        className="close"
                        aria-label="Close"
                        onClick={closeModal}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col lg="12">
                            <p className="mb-0">
                                Are you sure you want to{' '}
                                {pendingAction === 'APPROVED' ? 'approve' :
                                    pendingAction === 'REJECTED' ? 'reject' :
                                        pendingAction === 'BLOCKED' ? 'block' :
                                            pendingAction === 'DELETE' ? 'delete' : 'unblock'}{' '}
                                this mentor?
                            </p>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter className="p-1">
                    <Button color="secondary" onClick={closeModal} type="button">
                        Close
                    </Button>
                    <Button color="danger" type="button" onClick={confirmStatusChange}>
                        Confirm
                    </Button>
                </ModalFooter>
            </Modal>

            <Modal
                isOpen={memberModal}
                toggle={closeProfileModal}
                size="xl"
                fade={false}  // Disable fade animation to avoid findDOMNode
            >
                <ModalHeader toggle={closeProfileModal}>
                    Profile Details
                    <button
                        type="button"
                        className="close"
                        aria-label="Close"
                        onClick={closeProfileModal}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </ModalHeader>
                <ModalBody>
                    {isModalLoading ? (
                        <ModalBodySkeleton />
                    ) : memberFullData ? (
                        <ProfileModal
                            memberData={memberFullData}
                            location={[nativeLocation, currentLocation, companyLocation]}
                            dob={dob}
                        />
                    ) : (
                        <p>No mentor data available</p>
                    )}
                </ModalBody>
            </Modal>

            <Modal
                isOpen={mentorProfileModal}
                toggle={closeMentorProfileModal}
                size="lg"
                fade={false}
            >
                <ModalHeader toggle={closeMentorProfileModal}>
                    Mentor Profile Details
                </ModalHeader>
                <ModalBody>
                    {isModalLoading ? (
                        <ModalBodySkeleton />
                    ) : mentorProfileData ? (
                        <MentorProfileDetails mentorData={mentorProfileData} />
                    ) : (
                        <p className="text-center">No mentor profile data available</p>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={closeMentorProfileModal}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default MentorsManagement;


