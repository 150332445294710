import axios from 'axios';
import { fromUnixTime } from 'date-fns';
import FeatherIcon from 'feather-icons-react/build/FeatherIcon';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { Row, Col, Label, Input, Button } from 'reactstrap';
import apiUrl from '../../server';
import Physical from '../../../assets/images/MettaMateImages/women.png';
import Equality from '../../../assets/images/MettaMateImages/wedding-ring.png';
import CommonModal from '../../Home/modal';


const PersonalPreference = () => {
    // State to hold form data
    const [personalPreferenceFormData, setPersonalPrefenerceFormData] = useState({
        memberuuid: '',
        maritalstatus: [],
        startbirthyear: 0,
        endbirthyear: 0,
        startweight: 0,
        endweight: 0,
        complexions: [],
        gender: [],
        check_disability: 0,
        haircolors: [],
        eyecolors: [],
        minheight: 0,
        maxheight: 0,
    });


    const [startYearList, setStartYearList] = useState([]);
    const [maritalStatusList, setMaritalStatusList] = useState([]);
    const [genderList, setGenderList] = useState([]);
    const [complexionList, setcomplexionList] = useState([]);
    const [colorList, setcolorList] = useState([]);
    const [disabilityList, setDisabilityList] = useState([])
    const [minYearObject, setMinYearObject] = useState()
    const [maxYearObject, setMaxYearObject] = useState()

    const [error, setError] = useState();
    const [success, setSuccess] = useState();

    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        createYearList();
        const fetchData = async () => {
            setPersonalPrefenerceFormData({ ...personalPreferenceFormData, memberuuid: localStorage.getItem("memberID") })
            try {
                const [maritalstatusRes, genderListRes, complexionListRes, disabilityListRes, colorRes] = await Promise.all([
                    axios.get(`${apiUrl}codes/maritalstatus`),
                    axios.get(`${apiUrl}codes/gender`),
                    axios.get(`${apiUrl}codes/complexion`),
                    axios.get(`${apiUrl}codes/disability`),
                    axios.get(`${apiUrl}codes/color`),
                ]);
                setMaritalStatusList(maritalstatusRes.data.list);
                setGenderList(genderListRes.data.list);
                setcomplexionList(complexionListRes.data.list);
                disabilityListRes.data.list.push({ value: 100, label: "Dont Care" })
                setDisabilityList(disabilityListRes.data.list);
                setcolorList(colorRes.data.list);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
        getPersonalPreference();

    }, [])


    const handleReset = () => {

    }


    async function getPersonalPreference() {

        try {
            const response = await axios.get(
                apiUrl + 'preference/personal/' + localStorage.getItem("memberID"),
                {
                    headers: {
                        Authorization: 'Bearer' + ' ' + localStorage.getItem('token'),
                        'Content-Type': 'application/json',
                    },
                },
            );

            if (response.data.length > 0) {
                let personalPreferenceData = response.data[0]
                setPersonalPrefenerceFormData({
                    memberuuid: personalPreferenceData.memberuuid,
                    maritalstatus: personalPreferenceData.maritalstatus ? personalPreferenceData.maritalstatus : [],
                    startbirthyear: personalPreferenceData.startbirthyear ? personalPreferenceData.startbirthyear : 0,
                    endbirthyear: personalPreferenceData.endbirthyear ? personalPreferenceData.endbirthyear : 0,
                    startweight: personalPreferenceData.startweight ? personalPreferenceData.startweight : 0,
                    endweight: personalPreferenceData.endweight ? personalPreferenceData.endweight : 0,
                    complexions: personalPreferenceData.complexions ? personalPreferenceData.complexions : [],
                    gender: personalPreferenceData.gender ? personalPreferenceData.gender : [],
                    check_disability: personalPreferenceData.check_disability ? personalPreferenceData.check_disability : 0,
                    haircolors: personalPreferenceData.haircolors ? personalPreferenceData.haircolors : [],
                    eyecolors: personalPreferenceData.eyecolors ? personalPreferenceData.eyecolors : [],
                    maxheight: personalPreferenceData.maxheight ? personalPreferenceData.maxheight : 0,
                    minheight: personalPreferenceData.minheight ? personalPreferenceData.minheight : 0,
                })
                setMinYearObject(startYearList.find(x => x.value == personalPreferenceData.startbirthyear));
                setMinYearObject(startYearList.find(x => x.value == personalPreferenceData.endbirthyear));
            }
            else {
                setPersonalPrefenerceFormData({
                    memberuuid: localStorage.getItem("memberID"),
                    maritalstatus: [],
                    startbirthyear: 0,
                    endbirthyear: 0,
                    startweight: 0,
                    endweight: 0,
                    complexions: [],
                    gender: [],
                    check_disability: 0,
                    haircolors: [],
                    eyecolors: [],
                    maxheight: 0,
                    minheight: 0
                })
            }


        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    function createYearList() {
        let yearList = [];
        for (let i = 18; i <= 100; i++) {
            yearList.push(i)
        }

        setStartYearList(yearList);
    }
    const handleCheckboxChange = (checkedFlag, genderObject) => {
        let selectedGenders = personalPreferenceFormData.gender;
        if (checkedFlag) {
            if (!selectedGenders.includes(genderObject.value)) {
                selectedGenders.push(genderObject.value);
            }
        }
        if (!checkedFlag) {
            if (selectedGenders.includes(genderObject.value)) {
                selectedGenders.splice(selectedGenders.findIndex(x => genderObject.value === x), 1);
            }
        }
        setPersonalPrefenerceFormData({ ...personalPreferenceFormData, gender: selectedGenders });
    };

    const handleDisabilityCheckboxChange = (disabilityObject) => {
        setPersonalPrefenerceFormData({ ...personalPreferenceFormData, check_disability: disabilityObject.value });
    };


    const handleMaritalStatus = (checkedFlag, maritalStatusObj) => {
        let selectedMaritalStatus = personalPreferenceFormData.maritalstatus;
        if (checkedFlag) {
            if (!selectedMaritalStatus.includes(maritalStatusObj.value)) {
                selectedMaritalStatus.push(maritalStatusObj.value);
            }
        }
        if (!checkedFlag) {
            if (selectedMaritalStatus.includes(maritalStatusObj.value)) {
                selectedMaritalStatus.splice(selectedMaritalStatus.findIndex(x => maritalStatusObj.value === x), 1);
            }
        }
        setPersonalPrefenerceFormData({ ...personalPreferenceFormData, maritalstatus: selectedMaritalStatus });
    };

    const handleComplexionCheckboxChange = (checkedFlag, complextionObject) => {
        let selectedComplexions = personalPreferenceFormData.complexions;
        if (checkedFlag) {
            if (!selectedComplexions.includes(complextionObject.value)) {
                selectedComplexions.push(complextionObject.value);
            }
        }
        if (!checkedFlag) {
            if (selectedComplexions.includes(complextionObject.value)) {
                selectedComplexions.splice(selectedComplexions.findIndex(x => complextionObject.value === x), 1);
            }
        }
        setPersonalPrefenerceFormData({ ...personalPreferenceFormData, complexions: selectedComplexions });
    };

    const handleeyecolorCheckboxChange = (checkedFlag, eyecolorObject) => {
        let selectedeyeColor = personalPreferenceFormData.eyecolors;
        if (checkedFlag) {
            if (!selectedeyeColor.includes(eyecolorObject.value)) {
                selectedeyeColor.push(eyecolorObject.value);
            }
        }
        if (!checkedFlag) {
            if (selectedeyeColor.includes(eyecolorObject.value)) {
                selectedeyeColor.splice(selectedeyeColor.findIndex(x => eyecolorObject.value === x), 1);
            }
        }
        setPersonalPrefenerceFormData({ ...personalPreferenceFormData, eyecolors: selectedeyeColor });
    };
    const handlehaircolorCheckboxChange = (checkedFlag, haircolorObject) => {
        let selectedhairColor = personalPreferenceFormData.haircolors;
        if (checkedFlag) {
            if (!selectedhairColor.includes(haircolorObject.value)) {
                selectedhairColor.push(haircolorObject.value);
            }
        }
        if (!checkedFlag) {
            if (selectedhairColor.includes(haircolorObject.value)) {
                selectedhairColor.splice(selectedhairColor.findIndex(x => haircolorObject.value === x), 1);
            }
        }
        setPersonalPrefenerceFormData({ ...personalPreferenceFormData, haircolors: selectedhairColor });
    };

    // Handle form input changes
    const handleInputChange = (e, fieldName) => {
        // const { label, value } = e.target;
        setPersonalPrefenerceFormData({ ...personalPreferenceFormData, [fieldName]: e.value });
    };

    const handleMinYearChange = (event) => {
        setMinYearObject(event)
    };




    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        // Perform actions with form data, e.g., send to a server

        let result = await axios.post(`${apiUrl}preference/personal`, personalPreferenceFormData, {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
                "Content-Type": "application/json"
            },
        }).then((res) => {
            getPersonalPreference();
            setIsLoading(false);
            setSuccess({
                title: 'SUCCESS!',
                message: res.data.message,
            });
        }).catch((err) => {
            setError({
                title: 'An Error Occured!',
                message: err.response.data.message,
            });
            setIsLoading(false);
        });

    };

    const errorHandler = () => {
        setError(null);
    };
    const successHandler = () => {
        setSuccess(null);
    };

    const LoaderComponent = () => (
        <div id="preloader">
            <div id="status">
                <div className="spinner">
                    <div className="double-bounce1"></div>
                    <div className="double-bounce2"></div>
                </div>
            </div>
        </div>
    );

    return (
        <React.Fragment>
            {error && (
                <CommonModal
                    title={error.title}
                    message={error.message}
                    onClose={errorHandler}
                    status="error"
                />
            )}

            {success && (
                <CommonModal
                    title={success.title}
                    message={success.message}
                    onClose={successHandler}
                    status="success"
                />
            )}
            {
                isLoading ? <LoaderComponent /> : (
                    <Row >
                      
                        <form onSubmit={handleSubmit}>
                            <Col md="12">
                                <img src={Physical} className='legendIcon' style={{ width: `40px` }} />
                                <span className="m-2 legendName" >Aesthetics </span>
                                <Row>

                                    <Col md="12">

                                        <div className="mb-3">

                                            <Label className="form-label label_text">Complexion </Label>
                                            <div className="form-icon position-relative">
                                                <ul className="ks-cboxtags p-0">
                                                    {complexionList.map((response, index) => (
                                                        <div className="form-check form-check-inline" key={index}>
                                                            <li>
                                                                <div className="mb-0">
                                                                    <div className="form-check p-0">
                                                                        <Input type="checkbox"
                                                                            id={response.label + "complexion"}
                                                                            checked={personalPreferenceFormData.complexions.includes(response.value)}
                                                                            onChange={event => handleComplexionCheckboxChange(event.target.checked, response)}
                                                                        />
                                                                        <Label className="form-check-label" htmlFor={response.label + "complexion"}>{response.label}  </Label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </div>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col md="12">
                                        <div className="mb-3">
                                            <Label className="form-label label_text">Hair Color </Label>
                                            <div className="form-icon position-relative">
                                                <ul className="ks-cboxtags p-0">
                                                    {colorList.map((response, index) => (
                                                        <div className="form-check form-check-inline" key={index}>
                                                            <li>
                                                                <div className="mb-0">
                                                                    <div className="form-check p-0">
                                                                        <Input type="checkbox"
                                                                            id={response.label + "haircolor"}
                                                                            checked={personalPreferenceFormData.haircolors.includes(response.value)}
                                                                            onChange={event => handlehaircolorCheckboxChange(event.target.checked, response)}
                                                                        />
                                                                        <Label className="form-check-label" htmlFor={response.label + "haircolor"}>{response.label}  </Label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </div>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col md="12">
                                        <div className="mb-3">
                                            <Label className="form-label label_text">Eye Color </Label>
                                            <div className="form-icon position-relative">
                                                <ul className="ks-cboxtags p-0">
                                                    {colorList.map((response, index) => (
                                                        <div className="form-check form-check-inline" key={index}>
                                                            <li>
                                                                <div className="mb-0">
                                                                    <div className="form-check p-0">
                                                                        <Input type="checkbox"
                                                                            id={response.label + "eyecolor"}
                                                                            checked={personalPreferenceFormData.eyecolors.includes(response.value)}
                                                                            onChange={event => handleeyecolorCheckboxChange(event.target.checked, response)}
                                                                        />
                                                                        <Label className="form-check-label" htmlFor={response.label + "eyecolor"}>{response.label}  </Label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </div>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </Col>



                                    <Col md="3" sm="6" xs="6">
                                        <div className="mb-3">
                                            <Label className="form-label label_text ">Min Age</Label>
                                            <div className="form-icon position-relative">

                                                {/* <select name="name" id="name" type="text" className="form-control ps-5" placeholder="First Name :" /> */}

                                                {/* <Select
                                            value={minYearObject}
                                            name="startYear"
                                            options={startYearList}
                                            className="basic-multi-select"
                                            classNamePrefix="select"
                                            getOptionLabel={(option) => `${option.label}`}
                                            onChange={event => handleMinYearChange(event)}

                                        /> */}
                                                <select
                                                    className="form-select form-control form_Input" name="startyear"
                                                    id="startYear"
                                                    value={personalPreferenceFormData.startbirthyear}
                                                    onChange={(e) => setPersonalPrefenerceFormData({ ...personalPreferenceFormData, startbirthyear: e.target.value })}
                                                >
                                                    {(startYearList || []).map((item, key) => (
                                                        <option key={key} value={item}>
                                                            {item}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md="3" sm="6" xs="6">
                                        <div className="mb-3">
                                            <Label className="form-label label_text">Max Age</Label>
                                            <div className="form-icon position-relative">
                                                <select
                                                    className="form-select form-control form_Input" name="endyear"
                                                    id="endYear"
                                                    value={personalPreferenceFormData.endbirthyear}
                                                    onChange={(e) => setPersonalPrefenerceFormData({ ...personalPreferenceFormData, endbirthyear: e.target.value })}
                                                >
                                                    {(startYearList || []).map((item, key) => (
                                                        <option key={key} value={item}>
                                                            {item}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>
                                        </div>
                                    </Col>


                                    <Col md="3">
                                        <div className="mb-3">
                                            <Label className="form-label label_text">Min Weight</Label>
                                            <div className="form-icon position-relative">
                                                <FeatherIcon icon="book" className="fea icon-sm icons"></FeatherIcon>
                                                <Input name="minweight" type='number'
                                                    id="minWeight" value={personalPreferenceFormData.startweight}
                                                    className="form-control ps-5" placeholder="Enter Minimum Weight:"
                                                    onChange={event => setPersonalPrefenerceFormData({ ...personalPreferenceFormData, startweight: event.target.value })}

                                                />
                                            </div>
                                        </div>
                                    </Col>

                                    <Col md="3">
                                        <div className="mb-3">
                                            <Label className="form-label label_text">Max Weight</Label>
                                            <div className="form-icon position-relative">
                                                <FeatherIcon icon="book" className="fea icon-sm icons"></FeatherIcon>
                                                <Input type='number' name="maxweight" id="maxWeight"
                                                    value={personalPreferenceFormData.endweight}
                                                    className="form-control ps-5" placeholder="Enter Maximum Weight:"
                                                    onChange={event => setPersonalPrefenerceFormData({ ...personalPreferenceFormData, endweight: event.target.value })}

                                                />
                                            </div>
                                        </div>
                                    </Col>

                                    <Col md="3">
                                        <div className="mb-3">
                                            <Label className="form-label label_text">Min Height</Label>
                                            <div className="form-icon position-relative">
                                                <FeatherIcon icon="book" className="fea icon-sm icons"></FeatherIcon>
                                                <Input name="minHeight" type='number'
                                                    id="minHeight" value={personalPreferenceFormData.minheight}
                                                    className="form-control ps-5" placeholder="Enter Minimum Height:"
                                                    onChange={event => setPersonalPrefenerceFormData({ ...personalPreferenceFormData, minheight: event.target.value })}

                                                />
                                            </div>
                                        </div>
                                    </Col>

                                    <Col md="3">
                                        <div className="mb-3">
                                            <Label className="form-label label_text">Max Height</Label>
                                            <div className="form-icon position-relative">
                                                <FeatherIcon icon="book" className="fea icon-sm icons"></FeatherIcon>
                                                <Input type='number' name="maxHeight" id="maxHeight"
                                                    value={personalPreferenceFormData.maxheight}
                                                    className="form-control ps-5" placeholder="Enter Maximum Height:"
                                                    onChange={event => setPersonalPrefenerceFormData({ ...personalPreferenceFormData, maxheight: event.target.value })}

                                                />
                                            </div>
                                        </div>
                                    </Col>


                                </Row>

                                <hr style={{ boxShadow: `0px 1px 1px` }} />

                            </Col>
                            <Col md="12">
                                <img src={Equality} className='legendIcon' style={{ width: `40px` }} />
                                <span className="m-2 legendName" >Present State </span>
                                <Row>
                                    <Col md="12">
                                        <div className="mb-3">
                                            <Label className="form-label label_text">Gender </Label>
                                            <div className="form-icon position-relative">
                                                <ul className="ks-cboxtags p-0">
                                                    {genderList.map((response, index) => (
                                                        <div className="form-check form-check-inline" key={index}>
                                                            <li>
                                                                <div className="mb-0">
                                                                    <div className="form-check p-0">
                                                                        <Input type="checkbox"
                                                                            id={response.label + "gender"}
                                                                            checked={personalPreferenceFormData.gender.includes(response.value)}
                                                                            onChange={event => handleCheckboxChange(event.target.checked, response)}
                                                                        />
                                                                        <Label className="form-check-label" htmlFor={response.label + "gender"}>{response.label}  </Label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </div>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col md="12">
                                        <div className="mb-3">
                                            <Label className="form-label label_text">Preferred Marital Status </Label>
                                            <div className="form-icon position-relative">
                                                <ul className="ks-cboxtags p-0">
                                                    {maritalStatusList.map((response, index) => (
                                                        <div className="form-check form-check-inline" key={index}>
                                                            <li>
                                                                <div className="mb-0">
                                                                    <div className="form-check p-0">
                                                                        <Input type="checkbox"
                                                                            id={response.label + "maritalstatus"}
                                                                            checked={personalPreferenceFormData.maritalstatus.includes(response.value)}
                                                                            onChange={event => handleMaritalStatus(event.target.checked, response)}
                                                                        />
                                                                        <Label className="form-check-label" htmlFor={response.label + "maritalstatus"}>{response.label}  </Label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </div>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </Col>

                                    <Col md="12" xs={12}>
                                        <div className=" features feature-primary py-0">
                                            <div className="mb-3">
                                                <Label className="form-label label_text">Disability</Label>

                                                <section className="radio-section">
                                                    {disabilityList.map((response, index) => (
                                                        <div key={index}>
                                                            <div className="radio-list">
                                                                <div className="radio-item ">
                                                                    <Input name="radio" id={response.label + "disability"} type="radio"

                                                                        checked={personalPreferenceFormData.check_disability == response.value}
                                                                        onChange={event => handleDisabilityCheckboxChange(response)}
                                                                    />
                                                                    <label htmlFor={response.label + "disability"}>{response.label}</label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </section>
                                            </div>
                                        </div>
                                    </Col>




                                </Row>

                                <hr style={{ boxShadow: `0px 1px 1px` }} />

                            </Col>

                            <div className="mt-md-4 mt-3 mb-2 mt-sm-0 text-md-end">
                                <button
                                    type="submit"
                                    className="btn btn-outline-primary mt-2 "
                                >
                                    {' '}
                                    Save Changes{' '}
                                </button>{' '}
                                <button
                                    onClick={handleReset}
                                    className="btn btn-outline-secondary  mt-2 ms-2"
                                >
                                    Reset
                                </button>
                            </div>
                        </form>
                    </Row>
                )
            }


        </React.Fragment>
    );
};

export default PersonalPreference;