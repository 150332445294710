import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Profilecard from '../../Home/Profile_card.jsx';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
//partner preference
import PartnerPersonalDetails from '../../Home/Preference/Personal.jsx';
import PartnerRegionalDetails from '../../Home/Preference/Regional.jsx';
import PartnerEducationDetails from '../../Home/Preference/Education.jsx';
import PartnerFamilyDetails from '../../Home/Preference/FamilyB.jsx';
import PartnerSpiritualDetails from '../../Home/Preference/Spiritual.jsx';
import PartnerLifestyleDetails from '../../Home/Preference/Lifestyle.jsx';
import PertnerCareerDetails from '../../Home/Preference/Career.jsx';
import SettingsDetails from '../../Home/Profile/Settings.js';
import personalInfoIcon from '../../../assets/images/MettaMateImages/personalInfo.jpg';
import careerInfo from '../../../assets/images/MettaMateImages/career.png';
import educationIcon from '../../../assets/images/MettaMateImages/education.png';
import locationIcon from '../../../assets/images/MettaMateImages/location.png';
import lifestyleIcon from '../../../assets/images/MettaMateImages/lifestyle.png';
import spiritualIcon from '../../../assets/images/MettaMateImages/spiritual.png';
import familyIcon from '../../../assets/images/MettaMateImages/familyb.png';

const UserProfileEditFunction = () => {
    const [isPartnerPrefVisible, setPartnerPrefVisibility] = useState('personal');
    const [visibleSection, setVisibleSection] = useState(null);


    useEffect(() => {

    }, []);
    const toggleVisibility = (section) => {
        setVisibleSection(visibleSection === section ? null : section);
    };



    const scrollNavigation = () => {
        var doc = document.documentElement;
        var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
        if (top > 80) {
            document.querySelector('.shoppingbtn')?.classList.remove('btn-light');
            document.querySelector('.settingbtn')?.classList.remove('btn-light');
            document.querySelector('.shoppingbtn')?.classList.add('btn-primary');
            document.querySelector('.settingbtn')?.classList.add('btn-soft-primary');
            document.getElementById('topnav')?.classList.add('nav-sticky');
        } else {
            document.querySelector('.shoppingbtn')?.classList.remove('btn-primary');
            document
                .querySelector('.settingbtn')
                ?.classList.remove('btn-soft-primary');
            document.querySelector('.shoppingbtn')?.classList.add('btn-light');
            document.querySelector('.settingbtn')?.classList.add('btn-light');
            document.getElementById('topnav')?.classList.remove('nav-sticky');
        }


    };


    return (

        <React.Fragment>
            <Profilecard />
            <Container>
                <section className="section_user_home_page">
                    <Container>
                        <Row className="inner_row mt-4 mb-3">
                            <Col lg="12" className='Col_12'>
                                <Row className='mx-2'>
                                    {/* Personal Details Section */}
                                    <Row className="inner_row mt-3">
                                        <Col md={12} xs={12} className="d-flex justify-content-between align-items-center">
                                            <div className='d-flex align-items-center'>
                                                <img
                                                    src={personalInfoIcon}
                                                    alt="Photo Icon"
                                                    className="custom-photo-icon me-2"
                                                />
                                                <h5 className="title title_bar me-2">Fill Preferred Personal Details</h5>
                                            </div>
                                            <div
                                                size="sm"
                                                className="edit-button"
                                                onClick={() => toggleVisibility("personal")}>
                                                {visibleSection === "personal" ? "Close" : "Edit"}
                                            </div>

                                        </Col>
                                        {visibleSection === "personal" && (
                                            <Col md={12} xs={12} className="p-0 mt-2">
                                                <PartnerPersonalDetails />
                                            </Col>
                                        )}
                                    </Row>

                                    {/* Career Preference Section */}
                                    <Row className="inner_row mt-4">
                                        <Col md={12} xs={12} className="d-flex justify-content-between align-items-center">
                                        <div className='d-flex align-items-center'>
                                                <img
                                                    src={careerInfo}
                                                    alt="Photo Icon"
                                                    className="custom-photo-icon me-2"
                                                />
                                            <h5 className="title title_bar me-2">Career Preference</h5>
                                            </div>
                                            <div
                                                color="primary"
                                                size="sm"
                                                className="edit-button"
                                                onClick={() => toggleVisibility("career")}
                                            >
                                                {visibleSection === "career" ? "Close" : "Edit"}
                                            </div>
                                        </Col>
                                        {visibleSection === "career" && (
                                            <Col md={12} xs={12} className="p-0 mt-2">
                                                <PertnerCareerDetails />
                                            </Col>
                                        )}
                                    </Row>

                                    {/* Educational Preference Section */}
                                    <Row className="inner_row mt-4">
                                        <Col md={12} xs={12} className="d-flex justify-content-between align-items-center">
                                        <div className='d-flex align-items-center'>
                                                <img
                                                    src={educationIcon}
                                                    alt="Photo Icon"
                                                    className="custom-photo-icon me-2"
                                                />
                                            <h5 className="title title_bar me-2">Educational Preferences</h5>
                                            </div>
                                            <div
                                                color="primary"
                                                size="sm"
                                                className="edit-button"
                                                onClick={() => toggleVisibility("education")}
                                            >
                                                {visibleSection === "education" ? "Close" : "Edit"}
                                            </div>
                                        </Col>
                                        {visibleSection == 'education' && (
                                            <Col md={12} xs={12} className=" p-0">
                                                <PartnerEducationDetails />
                                            </Col>
                                        )}
                                    </Row>


                                    {/* Regional Preference Section */}
                                    <Row className="inner_row mt-4">
                                        <Col md={12} xs={12} className="d-flex justify-content-between align-items-center">
                                        <div className='d-flex align-items-center'>
                                                <img
                                                    src={locationIcon}
                                                    alt="Photo Icon"
                                                    className="custom-photo-icon me-2"
                                                />
                                            <h5 className="title title_bar me-2">Region
                                                / Location</h5>
                                            </div>
                                            <div
                                                color="primary"
                                                size="sm"
                                                className="edit-button"
                                                onClick={() => toggleVisibility("regional")}
                                            >
                                                {visibleSection === "regional" ? "Close" : "Edit"}
                                            </div>
                                        </Col>
                                        {visibleSection == 'regional' && (
                                            <Col md={12} xs={12} className=" p-0">
                                                <PartnerRegionalDetails />
                                            </Col>
                                        )}
                                    </Row>

                                    {/*  Spiritual Preference Section */}
                                    <Row className="inner_row mt-4">
                                        <Col md={12} xs={12} className="d-flex justify-content-between align-items-center">
                                        <div className='d-flex align-items-center'>
                                                <img
                                                    src={spiritualIcon}
                                                    alt="Photo Icon"
                                                    className="custom-photo-icon me-2"
                                                />
                                            <h5 className="title title_bar me-2"> Spiritual Preference</h5>
                                            </div>
                                            <div
                                                color="primary"
                                                size="sm"
                                                className="edit-button"
                                                onClick={() => toggleVisibility("spiritual")}
                                            >
                                                {visibleSection === "spiritual" ? "Close" : "Edit"}
                                            </div>
                                        </Col>
                                        {visibleSection == 'spiritual' && (
                                            <Col md={12} xs={12} className=" p-0">
                                                <PartnerSpiritualDetails />
                                            </Col>
                                        )}
                                    </Row>
                                    {/*LifeStyle Section */}
                                    <Row className="inner_row mt-4">
                                        <Col md={12} xs={12} className="d-flex justify-content-between align-items-center">
                                        <div className='d-flex align-items-center'>
                                                <img
                                                    src={lifestyleIcon}
                                                    alt="Photo Icon"
                                                    className="custom-photo-icon me-2"
                                                />
                                            <h5 className="title title_bar me-2">Lifestyle Preference</h5>
                                            </div>
                                            <div
                                                color="primary"
                                                size="sm"
                                                className="edit-button"
                                                onClick={() => toggleVisibility("lifestyle")}
                                            >
                                                {visibleSection === "lifestyle" ? "Close" : "Edit"}
                                            </div>
                                        </Col>
                                        {visibleSection == 'lifestyle' && (
                                            <Col md={12} xs={12} className=" p-0">
                                                <PartnerLifestyleDetails />
                                            </Col>
                                        )}
                                    </Row>


                                    {/*   Family Background Preference Section */}
                                    <Row className="inner_row mt-4 mb-3">
                                        <Col md={12} xs={12} className="d-flex justify-content-between align-items-center">
                                        <div className='d-flex align-items-center'>
                                                <img
                                                    src={familyIcon}
                                                    alt="Photo Icon"
                                                    className="custom-photo-icon me-2"
                                                />
                                            <h5 className="title title_bar me-2"> Family Background Preference</h5>
                                            </div>
                                            <div
                                                color="primary"
                                                size="sm"
                                                className="edit-button"
                                                onClick={() => toggleVisibility("family")}
                                            >
                                                {visibleSection === "family" ? "Close" : "Edit"}
                                            </div>
                                        </Col>
                                        {visibleSection == 'family' && (
                                            <Col md={12} xs={12} className=" p-0">
                                                <PartnerFamilyDetails />
                                            </Col>
                                        )}
                                    </Row>
                                </Row>



                                {/* {isPartnerPrefVisible == 'settings' && (
                                    <Col md={12} xs={12} className='p-0'>

                                        <SettingsDetails />
                                    </Col>
                                )} */}

                            </Col>
                        </Row>
                    </Container>
                </section>
            </Container>

        </React.Fragment>
    );
};

export default UserProfileEditFunction;
