import React from 'react';
import { Card, CardBody, Col, Container, Row, CardImg } from 'reactstrap';
import { Link } from 'react-router-dom';
import mentorshipImage from '../../assets/images/educationPage/mentorship-image.jpeg';
import fundGenerationImage from '../../assets/images/educationPage/fund-generation-image.jpeg';
import educationalResourcesImage from '../../assets/images/educationPage/Educational-Resources-image.jpeg';
import admissionsPreparationImage from '../../assets/images/educationPage/Admissions-Preparation-image.jpeg';

import flyer1 from '../../assets/images/flyersEducationPage/mentorship-program-flyer.jpeg';
import flyer2 from '../../assets/images/flyersEducationPage/scholarship-program-flyer.jpeg';
import flyer3 from '../../assets/images/flyersEducationPage/admission-program-flyer.jpeg';
import flyer4 from '../../assets/images/flyersEducationPage/educational-resources-flyer.jpeg';

const EducationPage = () => {
  const EducationCard = [
    {
      id: 1,
      image: mentorshipImage,
      title: 'Mentorship Program',
      link: '#',
      text: `A platform that solves the complexities of Education locally and globally. Get mentorship and
guidance from qualified Individuals on a range of topics like scholarships, academic
development, entrance exams, and beyond. Register now as a student or share your expertise
as a mentor!`,
    },
    {
      id: 2,
      image: fundGenerationImage,
      title: 'Educational Fund Generation',
      link: '#',
      text: `A platform designed to simplify your education journey in India & Abroad. We help you navigate
government and private scholarships, select the perfect major, and make higher learning
accessible for India's future trailblazers!
Sign up as a Student or guide as a Mentor today.`,
    },

    {
      id: 3,
      image: educationalResourcesImage,
      title: 'Platform for Educational Resources',
      link: '#',
      text: `Our extensive educational resources include:
      Expertly curated study materials and notes for various subjects and exams
Comprehensive guides for IIT JEE, NEET, CAT, GRE, GMAT, SAT, and other entrance exams.`,
    },
    {
      id: 4,
      image: admissionsPreparationImage,
      title: 'Admissions Preparation',
      link: '#',
      text: `Our expert admission guidance services are tailored to help you secure a spot at top universities in India and abroad. From profile building and university selection to application assistance, test preparation, and interview coaching, we'll guide you every step of the way. Our mission is to help you unlock your full potential and achieve academic success. `,
    },
  ];
  const flyersCard = [
    {
      id: 1,
      image: flyer1,
      link: '#',
    },
    {
      id: 2,
      image: flyer2,
      link: '#',
    },

    {
      id: 3,
      image: flyer3,
      link: '#',
    },
    {
      id: 4,
      image: flyer4,
      link: '#',
    },
  ];

  return (
    <React.Fragment>
      <section className="section">
        <Container>
          <Row>
            <h3 className="text-center">
              Empowering Marginalized Communities through Education
            </h3>
          </Row>

          <Row>
            {EducationCard.map((card, key) => (
              <Col xs="12" lg="12" className="mt-4 pt-2" key={key}>
                <Card className="shadow rounded border-0 overflow-hidden">
                  <Row className="g-0">
                    <Col
                      md={7}
                      className="d-flex flex-column align-items-start p-0 m-0"
                    >
                      <CardBody className="h-100 d-flex flex-column justify-content-start p-3">
                        {/* Title */}
                        <h5 className="card-title font-weight-bold mb-3 text-center">
                          <Link to="#" className="text-primary">
                            {card.title}
                          </Link>
                        </h5>
                        {/* Image */}
                        <img
                          src={card.image}
                          className="img-fluid"
                          alt="..."
                          style={{
                            width: '100%',
                            height: 'auto',
                            borderRadius: '10px',
                            objectFit: 'cover',
                          }}
                        />
                      </CardBody>
                    </Col>

                    <Col
                      md={5}
                      className="d-flex flex-column justify-content-center p-3"
                    >
                      <p className="fs-6 m-0">{card.text}</p>
                    </Col>
                  </Row>
                </Card>
              </Col>
            ))}
          </Row>

          <Row className="py-4">
            <h3 className="text-center">Currently running programs</h3>
          </Row>

          <Row className="shadshadow-md bg-body rounded">
            {flyersCard.map((flyersCard, key) => (
              <Col lg="4" md="6" className="" key={key} name="servicesCard">
                <Link to="" className="card-title title text-dark">
                  <Card className="blog rounded border-0 shadow overflow-hidden mb-4">
                    <div className="position-relative">
                      <CardImg
                        src={flyersCard.image}
                        className="img-fluid"
                        alt=""
                        style={{
                          width: '100%',
                          height: 'auto',
                          borderRadius: '10px',
                          objectFit: 'cover',
                        }}
                      />
                      <div className="overlay rounded-top bg-dark"></div>
                    </div>
                  </Card>
                </Link>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default EducationPage;
