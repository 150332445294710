import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Col, Card, CardBody, CardImg } from 'reactstrap';

//Import Icons
import FeatherIcon from 'feather-icons-react';

class CandidateBox extends Component {
  render() {
    return (
      <React.Fragment>
        {this.props.candidates.map((candidate, key) => (
          <Col key={key} lg="3" md="6" className="mt-4 pt-2">
	    <Card className="customer-testi border-0 text-center">
              <CardBody>
                <div className="position-relative">
                  <CardImg
                    top
                    src={candidate.image}
		    className="img-fluid avatar avatar-small rounded-circle mx-auto shadow"
                    alt=""
		    style={{ height: 165, width: 165 }}
                  />
                </div>
                <div className="pt-3 pb-2">
                  <h5 className="mb-0 pb-1 ">
                      {candidate.name}
                  </h5>
                  <p className="text-muted mt-4" style={{fontSize:'16px' }}>{candidate.designation}</p>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
      </React.Fragment>
    );
  }
}

export default CandidateBox;
