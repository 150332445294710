import React from 'react';
import '../Home/css/modal.css';
const Modal = (props) => {
  return (
    <section>
      <div className="backdrop" />
      {props.status == 'error' && (
        <div className="modal-container text-center">
          {/* background: linear-gradient(to bottom left, #EF8D9C 40%, #FFC39E 100%); */}
          <div className="icon-box">
            <i
              className="fa fa-close"
              aria-hidden="true"
              style={{
                position: `relative`,
                left: `0px`,
                fontSize: `30px`,
                top: `0px`,
              }}
            ></i>
          </div>
          <div className="header text-center mt-2" style={{ color: `red` }}>
            {props.title}
          </div>
          <div className="error-msg">
            <p className="message_p">{props.message}</p>
          </div>
          <footer className="modal-close p-1">
            {' '}
            <button
              type="button"
              className="closeBtn  btn"
              onClick={props.onClose}
            >
              Close
            </button>
          </footer>
        </div>
      )}

      {props.status == 'success' && (
        <div className="modal-container text-center">
          {/* style={{ background: `linear-gradient(to left bottom, rgb(61 236 63) 40%, rgb(108 156 69) 100%)` }} */}
          <div className="icon-box">
            <i
              className="fa fa-check"
              aria-hidden="true"
              style={{
                position: `relative`,
                left: `0px`,
                fontSize: `30px`,
                top: `0px`,
              }}
            ></i>
          </div>
          <div className="header text-center mt-2" style={{ color: `green` }}>
            {props.title}
          </div>
          <div className="success-msg">
            <p className="message_p" style={{ fontSize: `1em` }}>
              {props.message}
            </p>
          </div>
          <footer className="modal-close p-1">
            {' '}
            <button
              type="button"
              className="closeBtn btn"
              style={{ color: `green` }}
              onClick={props.onClose}
            >
              Close
            </button>
          </footer>
        </div>
      )}
      {props.status == 'warning' && (
        <div className="modal-container text-center">
          {/* style={{ background: `linear-gradient(to left bottom, rgb(61 236 63) 40%, rgb(108 156 69) 100%)` }} */}
          <div className="icon-box">
            <i
              className="fa fa-exclamation-circle text-success"
              aria-hidden="true"
              style={{
                position: `relative`,
                left: `0px`,
                fontSize: `30px`,
                top: `0px`,
              }}
            ></i>
          </div>
          <div className="header text-center mt-2" style={{ color: `#DAA520` }}>
            {props.title}
          </div>
          <div className="success-msg">
            <p className="message_p" style={{ fontSize: `1em` }}>
              {props.message}
            </p>
          </div>
          <footer className="modal-close p-1">
            {' '}
            <button
              type="button"
              className="closeBtn btn"
              style={{ color: `#DAA520` }}
              onClick={props.onClose}
            >
              Close
            </button>
          </footer>
        </div>
      )}
    </section>
  );
};

export default Modal;
