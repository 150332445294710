import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Profilecard from '../Home/Profile_card.jsx';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import PendingReferralFunction from './connection/PendingReferral.jsx';
import PendingConnections from './connection/PendingConnections.jsx';
import MyReferralsFunction from './connection/myReferral.jsx';
import MyConnectionsFunction from './connection/myConnection.jsx';
import FavouriteListFunction from './FavouriteListing.jsx';
import pendingreferralIcon from '../../assets/images/MettaMateImages/pendingreferral.png';
import myreferralIcon from '../../assets/images/MettaMateImages/myreferrals.png';
import pendingConnectionIcon from '../../assets/images/MettaMateImages/pendingconnection.png';
import myConnectionIcon from '../../assets/images/MettaMateImages/myconnection.png';

import { Container, Row, Col } from 'reactstrap';
const ConnectionsFunction = () => {
  const [isVisible, setPartnerPrefVisibility] = useState('pendingReferral');
  const [activeLink, setActiveLink] = useState('pendingReferral');
  const menudata = [
    {
      icon: pendingreferralIcon,
      menuName: 'Pending Referrals',
      value: 'pendingReferral',
    },
    { icon: myreferralIcon, menuName: 'My Referral', value: 'myReferral' },
    {
      icon: pendingConnectionIcon,
      menuName: 'Pending Connections',
      value: 'pendingConnects',
    },
    {
      icon: myConnectionIcon,
      menuName: 'My Connections',
      value: 'myConnections',
    },
    {
      icon: myConnectionIcon,
      menuName: 'My Favorites',
      value: 'myFavourites',
    },
  ];

  useEffect(() => {}, []);

  const handleLinkClick = (link) => {
    setActiveLink(link);
    setPartnerPrefVisibility(link);
  };

  const scrollNavigation = () => {
    var doc = document.documentElement;
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (top > 80) {
      document.querySelector('.shoppingbtn')?.classList.remove('btn-light');
      document.querySelector('.settingbtn')?.classList.remove('btn-light');
      document.querySelector('.shoppingbtn')?.classList.add('btn-primary');
      document.querySelector('.settingbtn')?.classList.add('btn-soft-primary');
      document.getElementById('topnav')?.classList.add('nav-sticky');
    } else {
      document.querySelector('.shoppingbtn')?.classList.remove('btn-primary');
      document
        .querySelector('.settingbtn')
        ?.classList.remove('btn-soft-primary');
      document.querySelector('.shoppingbtn')?.classList.add('btn-light');
      document.querySelector('.settingbtn')?.classList.add('btn-light');
      document.getElementById('topnav')?.classList.remove('nav-sticky');
    }
  };

  const menuslider = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 6, // Show 5 items at a time
    slidesToScroll: 1,
    initialSlide: 0,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <React.Fragment>
      <div className="container-fluid half-screen-background">
        <Container
          className="pt-2 mb-2"
          style={{ minHeight: '80vh' }}
        >
          <section className="section_user_home_page">
            <Row>
              <Col md={12} xs={12} className="inner_row mb-3">
                <Row>
                  <div className="w-5/6 m-auto">
                    <div className="mt-10 pb-2">
                      <Slider
                        {...menuslider}
                        className="overflow-hidden menu_slider "
                      >
                        {(menudata || []).map((item, key) => (
                          <div
                            className=" list-unstyled profession_ul text-center"
                            key={key}
                          >
                            <div
                              style={{ padding: `5px 10px 5px 5px` }}
                              className={`text-center profession_li mt-1 ${
                                activeLink === item.value ? 'active' : ''
                              }`}
                              onClick={() => handleLinkClick(item.value)}
                            >
                              <img
                                src={item.icon}
                                className="center"
                                style={{ width: `80px`, height: `60px` }}
                              />
                            </div>
                            <p
                              className="filter_title fw-normal m-auto"
                              style={{ fontSize: `16px` }}
                            >
                              <span
                                className={` ${activeLink === item.value ? 'active_text' : ''}`}
                              >
                                {item.menuName}
                              </span>
                            </p>
                          </div>
                        ))}
                      </Slider>
                    </div>
                  </div>
                </Row>
              </Col>

              {isVisible == 'pendingReferral' && (
                <Col
                  md={12}
                  xs={12}
                  style={{
                    transition: 'right 1s ease-in-out',
                    padding: '0',
                  }}
                >
                  {' '}
                  <PendingReferralFunction />
                </Col>
              )}
              {isVisible == 'myReferral' && (
                <Col
                  md={12}
                  xs={12}
                  style={{
                    transition: 'right 1s ease-in-out',
                    padding: '0',
                  }}
                >
                  <MyReferralsFunction />
                </Col>
              )}
              {isVisible == 'pendingConnects' && (
                <Col
                  md={12}
                  xs={12}
                  style={{
                    transition: 'right 1s ease-in-out',
                    padding: '0',
                  }}
                >
                  <PendingConnections />
                </Col>
              )}
              {isVisible == 'myConnections' && (
                <Col md={12} xs={12}>
                  <MyConnectionsFunction />
                </Col>
              )}
              {isVisible == 'myFavourites' && (
                <Col md={12} xs={12}>
                  <FavouriteListFunction />
                </Col>
              )}
            </Row>
          </section>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ConnectionsFunction;
