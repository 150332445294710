import React from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col, Card } from 'reactstrap';
import '../Home/css/style.css';

//Import Components
import Community from './Community.js';
// import Services from './Services.js';
// import ConnectSafe from './connectSafe';

// Import images
import Banner from '../../assets/images/mainBG-image.jpg';

const Section = () => {
  return (
    <React.Fragment>
      {/* Hero Start */}
      <section
        className="d-table w-100  index-section"
        style={{
          background: `url(${Banner}) center center no-repeat`,
          backgroundSize: 'cover',
        }}
        id="home"
      >
        <Container className="section-three">
          <Row className="position-relative align-items-center section-main">
            <Col lg={6} className="offset-lg-6">
              <Card className="title-heading studio-home rounded shadow mb-3 ">
                <p className="heading mb-3">
                  Connecting Minds & Uniting Hearts at{' '}
                  <span className="text-primary">MettaMate</span>
                </p>
                <p className="para-desc ">
                  Explore the platform crafted by the community for the
                  community. Join this unique community-built network and transform Yourself !
                </p>
                <div className="mt-4">
                  <Link
                    to="/user-registration-page"
                    className="btn btn-primary mt-2 me-2"
                  >
                    <i className="uil uil-edit"></i> Register
                  </Link>
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
      <Community />
      {/* <Services /> */}
      {/* <ConnectSafe /> */}
    </React.Fragment>
  );
};
export default Section;
