import React from 'react'
//Import Images
import candidate1 from '../../../assets/images/mettamate/snehalataKamble1.jpeg';
import candidate3 from '../../../assets/images/mettamate/raghuKiran.jpeg';
import candidate4 from '../../../assets/images/testimonies/datasahebTandale2.png';
import photodump from '../../../assets/images/Newemoji/male_avatar.jpg'
import photodumpfemale from '../../../assets/images/Newemoji/female_avatar.c085b7ebc7600153a498.jpeg'
import mentor1 from '../../../assets/images/Newemoji/mentor1.jpg'
import mentor5 from '../../../assets/images/Newemoji/mentor5n.jpg'
import mentor3 from '../../../assets/images/Newemoji/mentor3.jpg'
import mentor4 from '../../../assets/images/Newemoji/mentor4.jpg'
import mentor6 from '../../../assets/images/Newemoji/Alka.jpg'
import linkedInIcon from '../../../assets/images/Newemoji/linkedin2.png';
import '../../Home/css/userHomePage.css'
import {
    Container,
    Col,

} from 'reactstrap';
import { Row } from 'reactstrap';

const Mentor = () => {
    const mentors = [
        {
            id: 1,
            image: mentor1,
            name: 'Sankalp Soaji',
            designation:
                'Founder & CEO',
            link: '',
            socialIcon: {
                src: linkedInIcon,  // LinkedIn icon image
                url: '#'  // LinkedIn URL
            }
        },
        {
            id: 2,
            image: candidate1,
            name: 'Prof. Snehalata Kamble',
            designation:
                'Co-Founder & COO',
            link: '',
            socialIcon: {
                src: linkedInIcon,  // LinkedIn icon image
                url: '#'  // LinkedIn URL
            },
        },
        {
            id: 3,
            image: mentor5,
            name: 'Rajneesh',
            designation:
                'Chief Growth Officer',
            socialIcon: {
                src: linkedInIcon,  // LinkedIn icon image
                url: '#'  // LinkedIn URL
            },
        },
        {
            id: 4,
            image: candidate4,
            name: 'Dadasaheb Tandale',
            designation:
                'Chief Community Officer',
            link: '',
            socialIcon: {
                src: linkedInIcon,  // LinkedIn icon image
                url: '#'  // LinkedIn URL
            },
        },

        {
            id: 5,
            image: mentor3,
            name: 'Chinmayand Ingle',
            designation:
                'Chief Community Officer',
            link: '',
            socialIcon: {
                src: linkedInIcon,  // LinkedIn icon image
                url: '#'  // LinkedIn URL
            },
        },
        {
            id: 6,
            image: mentor4,
            name: 'Abhishek Bohare',
            designation:
                'Chief Community Officer',
            link: '',
            socialIcon: {
                src: linkedInIcon,  // LinkedIn icon image
                url: '#'  // LinkedIn URL
            },
        },
        {
            id: 7,
            image: mentor6,
            name: 'Dr. Alka Dhoke',
            designation:
                'Chief Community Officer',
            link: '',
            socialIcon: {
                src: linkedInIcon,  // LinkedIn icon image
                url: '#'  // LinkedIn URL
            },
        },
        {
            id: 8,
            image: photodumpfemale,
            name: 'XYZ',
            designation:
                'Chief Community Officer',
            link: '',
            socialIcon: {
                src: linkedInIcon,  // LinkedIn icon image
                url: '#'  // LinkedIn URL
            },
        },
        {
            id: 9,
            image: photodump,
            name: 'XYZ',
            designation:
                'Chief Community Officer',
            link: '',
            socialIcon: {
                src: linkedInIcon,  // LinkedIn icon image
                url: '#'  // LinkedIn URL
            },
        },
        {
            id: 10,
            image: photodump,
            name: 'XYZ',
            designation:
                'Chief Community Officer',
            link: '',
            socialIcon: {
                src: linkedInIcon,  // LinkedIn icon image
                url: '#'  // LinkedIn URL
            },
        },
    ];
    return (
        <React.Fragment>
            <section className="py-5 mt-5 bg-white">
                <Container className="mt-70 ">
                    {/* section title */}
                    <Row className="justify-content-center">
                        <Col className={'col-12 text-center'}>
                            <div className="pb-3">
                                <h1 className="title mb-2">Top 10 Best Mentors</h1>
                            </div>
                            <p className="fontSize18">
                                Welcome to{' '}
                                <span className="text-primary fw-bold">Metta Mate</span> a platform
                                dedicated to providing mentorship and guidance to Ambedkarites. Our
                                mission is to empower individuals by offering support on matters of
                                equality, relationships, and personal growth. Through experienced mentors,
                                we help nurture meaningful connections, promoting the values of Dr. B.R. Ambedkar
                                and fostering strong, equitable communities.
                            </p>
                        </Col>
                    </Row>

                    <Row className="justify-content-center pt-3 g-4">
                        {mentors.map((mentor) => (
                            <Col
                                key={mentor.id}
                                xs={12}  
                                sm={6}   
                                md={4}   
                                lg={3} 
                                className="d-flex justify-content-center"
                            >
                                <div className="mentor-card text-center  rounded-3 shadow-sm"
                                style={{border: '4px solid blue'}}>
                                    <div className="image-container mb-3">
                                        <img
                                            src={mentor.image}
                                            alt={mentor.name}
                                            className="img-fluid"
                                            style={{ width: '300px', height: '300px', objectFit: 'cover' }}
                                        />
                                    </div>
                                    <h4 className="mentors-name mb-2 fs-5 fw-semibold">{mentor.name}</h4>
                                    <p className="text-muted">{mentor.title}</p> {/* Optional title for each mentor */}
                                </div>
                            </Col>
                        ))}
                    </Row>


                </Container>
            </section>
        </React.Fragment>
    )
}

export default Mentor
