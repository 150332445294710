// src/services/mentorService.js
import axios from 'axios';
import apiUrl from '../../server';
const API_BASE_URL = apiUrl;

export const fetchMentors = async (params) => {
    try {
        // Get auth token if exists
        const token = localStorage.getItem('token');
        const headers = token ? { Authorization: `Bearer ${token}` } : {};

        // Convert params to query string format
        const queryParams = {
            page: params.page || 1,
            pageSize: params.pageSize || 20,
            categories: params.categories?.length ? params.categories.join(',') : undefined,
            // subCategories: params.subCategories?.length ? params.subCategories.join(',') : undefined,
            // searchText: params.searchText || undefined,
            // minExperience: params.minExperience,
            // maxExperience: params.maxExperience,
            // isPaidMentorship: params.isPaidMentorship
        };

        const response = await axios.get(`${API_BASE_URL}/mentorship/mentors`, {
            headers,
            params: queryParams
        });

        // Match the response format from our backend
        return {
            data: response.data.results,
            total: response.data.totalCount,
            currentPage: response.data.currentPage,
            totalPages: response.data.totalPages
        };
    } catch (error) {
        console.error('Error fetching mentors:', error);
        throw new Error(error.response?.data?.message || 'Failed to fetch mentors');
    }
};

export const fetchCategoriesAndSubcategories = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}mentorship/categories-with-subcategories`);
        return response.data;
    } catch (error) {
        console.error('Error fetching categories:', error);
        throw new Error(error.response?.data?.message || 'Failed to fetch categories');
    }
};

// Optional: Function to fetch categories
export const fetchCategories = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}mentorship/categories`);
        return response.data;
    } catch (error) {
        console.error('Error fetching categories:', error);
        throw new Error(error.response?.data?.message || 'Failed to fetch categories');
    }
};

// Optional: Function to fetch subcategories
export const fetchSubCategories = async (categoryIds) => {
    try {
        const response = await axios.get(`${API_BASE_URL}mentorship/subcategories`, {
            params: { categories: categoryIds.join(',') }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching subcategories:', error);
        throw new Error(error.response?.data?.message || 'Failed to fetch subcategories');
    }
};

export const fetchMentorsByCategory = async (categoryId, pageIndex) => {
    try {
        const response = await axios.get(`${API_BASE_URL}mentorship/fetch-mentors-categories`, {
            params: { categoryid: categoryId, pageindex: pageIndex }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching mentors by categories:', error);
        throw new Error(error.response?.data?.message || 'Failed to fetch categories');
    }
};

export const fetchMenteesByCategory = async (categoryId, pageIndex) => {
    try {
        // Get auth token if exists
        const token = localStorage.getItem('token');
        const headers = token ? { Authorization: `Bearer ${token}` } : {};
        const response = await axios.get(`${API_BASE_URL}mentee/fetch-mentees-categories`, {
            headers,
            params: { categoryid: categoryId, pageindex: pageIndex }
        });
        //alert("FetchMenteesByCategory Response = " + JSON.stringify(response));
        return response.data;
    } catch (error) {
        console.error('Error fetching mentors by categories:', error);
        throw new Error(error.response?.data?.message || 'Failed to fetch categories');
    }
};

export const fetchCategoryStats = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}mentorship/category-stats`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        });

        // alert("FetchCategory Response = "+JSON.stringify(response));
        // Convert array to object for easier access
        const statsObject = response.data.reduce((acc, stat) => {
            acc[stat.categoryid] = stat.mentor_count;
            return acc;
        }, {});

        return statsObject;
    } catch (error) {
        console.error('Error fetching category stats:', error);
        throw new Error(error.response?.data?.message || 'Failed to fetch category stats');
    }
};

export const fetchMenteeCategoryStats = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}mentee/mentee-category-stats`, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        });

        const statsObject = response.data.reduce((acc, stat) => {
            acc[stat.categoryid] = stat.mentee_count;
            return acc;
        }, {});

        return statsObject;
    } catch (error) {
        console.error('Error fetching mentee category stats:', error);
        throw new Error(error.response?.data?.message || 'Failed to fetch mentee category stats');
    }
};

