import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  Card,
  CardBody,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { Upload, message } from 'antd';
import ImgCrop from 'antd-img-crop';
import { 
  FaCalendarAlt,
  FaClock,
  FaMapMarkerAlt,
  FaGlobe,
  FaLink,
  FaImage,
  FaTags,
  FaHeading,
  FaAlignLeft,
  FaUsersCog,
  FaMobileAlt,
  FaTicketAlt,
  FaUsers,
  FaTheaterMasks,
  FaVolleyballBall,
  FaUtensils,
  FaGraduationCap,
  FaHandshake,
  FaHeart
} from 'react-icons/fa';
import { BiWorld } from 'react-icons/bi';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import TimezoneSelect from 'react-timezone-select';
import axios from 'axios';
import apiUrl from 'apppages/server';
import * as ReactIcons from 'react-icons/fa';
import * as BiIcons from 'react-icons/bi';
import { fetchEventCategories } from './eventServices';
import { Globe, Users, Eye, EyeOff } from 'lucide-react'; // Add these imports
import { initiatePayment } from 'utils/paymentUtils'; // Import initiatePayment from paymentUtils
import { useFormik } from 'formik';

const iconStyle = { color: '#0D6EFD' };

const styles = {
  fieldLabel: {
    fontSize: '1.1rem',
    fontWeight: '500'
  },
  input: {
    fontSize: '1.1rem'
  },
  categoryTag: {
    display: 'inline-block',
    padding: '8px 16px',
    margin: '4px',
    borderRadius: '20px',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
  },
  selectedTag: {
    backgroundColor: '#1a365d', // Darker blue
    color: 'white',
  },
  unselectedTag: {
    backgroundColor: '#2d3748', // Dark gray
    color: '#fff',
    '&:hover': {
      backgroundColor: '#4a5568', // Slightly lighter on hover
    }
  }
};

const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB in bytes
const ACCEPTED_FILE_TYPES = ['image/jpeg', 'image/png'];
const MIN_DIMENSIONS = { width: 600, height: 338 };
const RECOMMENDED_DIMENSIONS = { width: 1200, height: 675 };

const formatDateForInput = (dateString) => {
  if (!dateString) return '';
  const date = new Date(dateString);
  return date.toISOString().split('T')[0];
};

const visibilityOptions = [
  {
    value: 'Public',
    icon: Globe,
    title: 'Public',
    description: 'This will make your event visible to entire world',
    color: '#4CAF50' // Green
  },
  {
    value: 'MettaMate',
    icon: Users,
    title: 'MettaMate Community',
    description: 'This will make your event visible only to registered members of MettaMate Community',
    color: '#2196F3' // Blue
  },
  {
    value: 'Unlisted',
    icon: EyeOff,
    title: 'Unlisted',
    description: 'This will not list your event anywhere',
    color: '#757575' // Gray
  }
];

const NewEventForm = () => {
  const { eventuuid } = useParams();
  const navigate = useNavigate();
  
  // Update getCurrentDate to ensure proper SQL date format
  const getCurrentDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const day = String(today.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`; // Returns YYYY-MM-DD format
  };

  // Add date validation function
  const validateDateFormat = (dateString) => {
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    if (!regex.test(dateString)) return false;
    
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return false;
    
    return true;
  };

  // Format time to HH:mm
  const formatTime = (hours, minutes) => {
    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  };

  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [eventCategories, setEventCategories] = useState([]);
  const [error, setError] = useState(null); // Add error state
  
  const [eventAddress, setEventAddress] = useState('');
  const [addressComponents, setAddressComponents] = useState({
    city: '',
    state: '',
    country: '',
  });

  // Add this near other state declarations
  const [previewVisible, setPreviewVisible] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');

  // Payment-related state
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [isPaymentPending, setIsPaymentPending] = useState(false);
  const [skipPayment, setSkipPayment] = useState(false);
  // const [paymentResponse, setPaymentResponse] = useState(null); // Add state for payment response

  const togglePaymentModal = () => setShowPaymentModal(!showPaymentModal);

  useEffect(() => {
    fetchCategories();
    if (eventuuid) {
      fetchEventDetails();
    }
  }, [eventuuid]);

  const fetchCategories = async () => {
    try {
      const categories = await fetchEventCategories();
      setEventCategories(categories);
    } catch (error) {
      message.error('Failed to fetch categories');
    }
  };

  const fetchEventDetails = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`${apiUrl}eb/fetchEbPost/${eventuuid}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      // Format the dates before setting in state
      const eventData = {
        ...response.data,
        eventstartdate: formatDateForInput(response.data.eventstartdate),
        eventenddate: formatDateForInput(response.data.eventenddate),
      };

      formik.setValues(eventData);

      // Handle existing image
      if (response.data.eventimagefilepath) {
        // Create a file list with the existing image
        setFileList([{
          uid: '-1',
          name: 'existing-image.jpg',
          status: 'done',
          url: response.data.eventimagefilepath,
          preview: response.data.eventimagefilepath
        }]);
      }
    } catch (error) {
      message.error('Failed to fetch event details');
    } finally {
      setLoading(false);
    }
  };

  const handleAddressSelect = async (address) => {
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      const placeId = results[0].place_id;
      
      setEventAddress(address);
      formik.setFieldValue('eventlocation_placeid', placeId);
      formik.setFieldValue('eventlocation_address', address);
    } catch (error) {
      message.error('Error selecting address');
    }
  };

  // Update the handleImageChange function
  const handleImageChange = ({ fileList: newFileList }) => {
    // Check if there's a file to validate
    if (newFileList.length > 0) {
      const file = newFileList[0].originFileObj;
      
      // Validate file size
      if (file.size > MAX_FILE_SIZE) {
        message.error('Image must be smaller than 2MB');
        return;
      }
  
      // Validate file type
      if (!ACCEPTED_FILE_TYPES.includes(file.type)) {
        message.error('Only JPEG and PNG files are allowed');
        return;
      }
  
      // Validate dimensions
      const img = new Image();
      img.src = URL.createObjectURL(file);
      img.onload = () => {
        URL.revokeObjectURL(img.src);
        if (img.width < MIN_DIMENSIONS.width || img.height < MIN_DIMENSIONS.height) {
          message.error(`Image must be at least ${MIN_DIMENSIONS.width}x${MIN_DIMENSIONS.height}px`);
          return;
        }
        // Update preview URL for the current file
        newFileList[0].preview = URL.createObjectURL(file);
        setFileList(newFileList);
      };
    } else {
      setFileList(newFileList);
    }
  };

  // Add these new handler functions
  const handlePreview = async (file) => {
    if (!file.preview) {
      file.preview = URL.createObjectURL(file.originFileObj);
    }
    setPreviewImage(file.preview);
    setPreviewVisible(true);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  const handleCancel = () => setPreviewVisible(false);

  const handleRemoveImage = () => {
    setFileList([]);
  };

  const handlePaymentSuccess = async (paymentResponse) => {
    try {
      // alert('Payment response1:', paymentResponse);  
      // Handle payment success logic here
      // You can call your backend API to verify the payment and update the event status
      message.success('Payment successful! Proceeding to create event.');
      setIsPaymentPending(false);
      setShowPaymentModal(false);
      // alert('Payment response2:', paymentResponse);  
      // setPaymentResponse(paymentResponse); // Store payment response
      await submitFormData(formik.values, paymentResponse); // Call the common function to submit the form data
    } catch (error) {
      message.error('Payment verification failed');
      console.error('Error:', error);
    }
  };

  const submitFormData = async (values, paymentResponse) => {
    try {
      setLoading(true);
      
      const formDataToSend = new FormData();
      
      // Append all form fields
      Object.keys(values).forEach(key => {
        if (key === 'listeventcategories') {
          formDataToSend.append(key, JSON.stringify(values[key]));
        } else if (key !== 'eventimagefilepath' && key !== 'organizer' && key !== 'categories') { // Skip the image path and unwanted fields
          formDataToSend.append(key, values[key]);
        }
      });

      // Handle image upload
      if (fileList.length > 0) {
        // If it's a new file upload
        if (fileList[0].originFileObj) {
          formDataToSend.append('ebimage', fileList[0].originFileObj);
        } else {
          // If using existing image, pass the URL
          formDataToSend.append('eventimagefilepath', fileList[0].url);
        }
      }

      // Append payment response if available
      if (paymentResponse) {
        formDataToSend.append('paymentResponse', JSON.stringify(paymentResponse));
      }

      const response = await axios({
        method: eventuuid ? 'put' : 'post',
        url: eventuuid ? `${apiUrl}eb/updateebpost/${eventuuid}` : `${apiUrl}eb/addebpost`,
        data: formDataToSend,
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });

      message.success(`Event successfully ${eventuuid ? 'updated' : 'created'}!`);
      navigate('/event-listing-page');
    } catch (error) {
      message.error('Failed to save event');
      console.error('Error:', error);
    } finally {
      setLoading(false);
    }
  };

  // Update submitEventPosting to properly handle skip payment
  const submitEventPosting = async () => {
    try {
      if (skipPayment) {
        // If skipping payment, directly submit form data without payment
        await submitFormData(formik.values, null);
      } else {
        // Only initiate payment if not skipping
        await initiatePayment(100, handlePaymentSuccess, apiUrl, null);
      }
    } catch (error) {
      setError({
        title: 'Error',
        message: error.message,
      });
      setIsPaymentPending(false);
    }
  };

  const formik = useFormik({
    initialValues: {
      eventuuid: eventuuid || '',
      eventtitle: '',
      eventdetails: '',
      eventstartdate: getCurrentDate(),
      eventstarttime: formatTime(9, 0),
      eventenddate: getCurrentDate(),
      eventendtime: formatTime(17, 0),
      eventtimezone: 'Asia/Kolkata',
      eventlocation_placeid: '',
      eventlocation_address: '',
      eventlocation_virtual_link: '',
      eventorgurl: '',
      eventticketurl: '',
      listeventcategories: [],
      eventimagepath: '',
      eventtype: '',
      memberuuid: localStorage.getItem('memberID'),
      eventstatus: 'Pending', // Default status for new events
      eventvisibility: 'Public', // Default to Public
    },
    validate: (values) => {
      const errors = {};

      if (!values.eventtitle) {
        errors.eventtitle = 'Event title is required';
      }

      if (!values.eventdetails) {
        errors.eventdetails = 'Event description is required';
      }

      if (!validateDateFormat(values.eventstartdate)) {
        errors.eventstartdate = 'Invalid start date format';
      }

      if (!validateDateFormat(values.eventenddate)) {
        errors.eventenddate = 'Invalid end date format';
      }

      const startDate = new Date(values.eventstartdate);
      const endDate = new Date(values.eventenddate);

      if (endDate < startDate) {
        errors.eventenddate = 'End date cannot be before start date';
      }

      if (values.eventtype === 'in-person' && (!values.eventlocation_placeid || !values.eventlocation_address)) {
        errors.eventlocation_address = 'Physical location is required for in-person events';
      }

      if (values.eventtype === 'virtual' && !values.eventlocation_virtual_link) {
        errors.eventlocation_virtual_link = 'Virtual meeting link is required for virtual events';
      }

      if (values.eventtype === 'both' && (!values.eventlocation_placeid || !values.eventlocation_address || !values.eventlocation_virtual_link)) {
        errors.eventlocation_address = 'Both physical location and virtual link are required for hybrid events';
      }

      return errors;
    },
    onSubmit: async (values) => {
      // Only show payment modal if creating a new event
      if (!eventuuid && !skipPayment) {
        setShowPaymentModal(true);
        return;
      }

      await submitFormData(values, null /*paymentResponse*/); 
      // Call the common function to submit the form data
    }
  });

  const toggleCategory = (categoryUuid) => {
    if (categoryUuid === 'all') {
      formik.setFieldValue('listeventcategories', formik.values.listeventcategories.length === eventCategories.length - 1 
        ? [] 
        : eventCategories.map(cat => cat.categoryuuid));
    } else {
      formik.setFieldValue('listeventcategories', formik.values.listeventcategories.includes(categoryUuid)
        ? formik.values.listeventcategories.filter(uuid => uuid !== categoryUuid)
        : [...formik.values.listeventcategories, categoryUuid]);
    }
  };

  const getIconComponent = (iconName) => {
    // Check both FA and Bi icon libraries
    return iconName ? (ReactIcons[iconName] || BiIcons[iconName] || ReactIcons.FaUsers) : ReactIcons.FaUsers;
  };

  // Update handleModalAction to call submitEventPosting directly
  const handleModalAction = async (shouldSkipPayment) => {
    setSkipPayment(shouldSkipPayment);
    setShowPaymentModal(false);
    
    if (shouldSkipPayment) {
      // Direct form submission without payment
      await submitFormData(formik.values, null);
    } else {
      // Proceed with payment flow
      await initiatePayment(100, handlePaymentSuccess, apiUrl, null);
    }
  };

  return (
    <section className="section">
      <Container>
        <Row className="justify-content-center">
          <Col lg={12} xs={12} md={12}>
            <Card className="shadow">
              <CardBody>
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <CardTitle className="fs-4">
                    <FaCalendarAlt className="me-2" style={iconStyle} />
                    {eventuuid ? 'Edit Event' : 'Create New Event'}
                  </CardTitle>
                </div>

                <Form onSubmit={formik.handleSubmit}>
                  <Row>
                    <Col md={8}>
                      {/* Basic Event Information */}
                      <div className="mb-4">
                        <Label className="form-label fs-5">
                          <FaHeading className="me-2" style={iconStyle} />
                          Event Details
                        </Label>
                        <div className="p-3 border rounded">
                          <FormGroup className="mb-3">
                            <Label className="form-label" style={styles.fieldLabel}>Event Title <span className="text-danger">*</span></Label>
                            <div className="form-icon position-relative">
                              <Input
                                type="text"
                                className="form-control ps-5"
                                name="eventtitle"
                                placeholder="Give your event a clear, engaging title"
                                value={formik.values.eventtitle}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                required
                                style={styles.input}
                                invalid={formik.touched.eventtitle && !!formik.errors.eventtitle}
                              />
                              <FaHeading className="fea icon-sm icons" />
                              {formik.touched.eventtitle && formik.errors.eventtitle && (
                                <div className="invalid-feedback">{formik.errors.eventtitle}</div>
                              )}
                            </div>
                          </FormGroup>

                          <FormGroup className="mb-3">
                            <Label className="form-label" style={styles.fieldLabel}>Event Description <span className="text-danger">*</span></Label>
                            <div className="form-icon position-relative">
                              <Input
                                type="textarea"
                                className="form-control ps-5"
                                name="eventdetails"
                                placeholder="Describe what attendees can expect"
                                rows="4"
                                value={formik.values.eventdetails}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                required
                                style={styles.input}
                                invalid={formik.touched.eventdetails && !!formik.errors.eventdetails}
                              />
                              <FaAlignLeft className="fea icon-sm icons" />
                              {formik.touched.eventdetails && formik.errors.eventdetails && (
                                <div className="invalid-feedback">{formik.errors.eventdetails}</div>
                              )}
                            </div>
                          </FormGroup>
                        </div>
                      </div>

                      {/* Date and Time Section */}
                      <div className="mb-4">
                        <Label className="form-label fs-5">
                          <FaClock className="me-2" style={iconStyle} />
                          Date & Time
                        </Label>
                        <div className="p-3 border rounded">
                          <Row>
                            <Col md={6}>
                              <FormGroup className="mb-3">
                                <Label className="form-label" style={styles.fieldLabel}>Start Date <span className="text-danger">*</span></Label>
                                <Input
                                  type="date"
                                  className="form-control"
                                  name="eventstartdate"
                                  value={formik.values.eventstartdate}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  required
                                  style={styles.input}
                                  min={getCurrentDate()} // Prevent past dates
                                  invalid={formik.touched.eventstartdate && !!formik.errors.eventstartdate}
                                />
                                {formik.touched.eventstartdate && formik.errors.eventstartdate && (
                                  <div className="invalid-feedback">{formik.errors.eventstartdate}</div>
                                )}
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup className="mb-3">
                                <Label className="form-label" style={styles.fieldLabel}>Start Time <span className="text-danger">*</span></Label>
                                <Input
                                  type="time"
                                  className="form-control"
                                  name="eventstarttime"
                                  value={formik.values.eventstarttime}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  required
                                  style={styles.input}
                                  invalid={formik.touched.eventstarttime && !!formik.errors.eventstarttime}
                                />
                                {formik.touched.eventstarttime && formik.errors.eventstarttime && (
                                  <div className="invalid-feedback">{formik.errors.eventstarttime}</div>
                                )}
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup className="mb-3">
                                <Label className="form-label" style={styles.fieldLabel}>End Date <span className="text-danger">*</span></Label>
                                <Input
                                  type="date"
                                  className="form-control"
                                  name="eventenddate"
                                  value={formik.values.eventenddate}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  required
                                  style={styles.input}
                                  min={formik.values.eventstartdate} // Can't end before start date
                                  invalid={formik.touched.eventenddate && !!formik.errors.eventenddate}
                                />
                                {formik.touched.eventenddate && formik.errors.eventenddate && (
                                  <div className="invalid-feedback">{formik.errors.eventenddate}</div>
                                )}
                              </FormGroup>
                            </Col>
                            <Col md={6}>
                              <FormGroup className="mb-3">
                                <Label className="form-label" style={styles.fieldLabel}>End Time <span className="text-danger">*</span></Label>
                                <Input
                                  type="time"
                                  className="form-control"
                                  name="eventendtime"
                                  value={formik.values.eventendtime}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  required
                                  style={styles.input}
                                  invalid={formik.touched.eventendtime && !!formik.errors.eventendtime}
                                />
                                {formik.touched.eventendtime && formik.errors.eventendtime && (
                                  <div className="invalid-feedback">{formik.errors.eventendtime}</div>
                                )}
                              </FormGroup>
                            </Col>
                            <Col md={12}>
                              <FormGroup className="mb-3">
                                <Label className="form-label" style={styles.fieldLabel}>Time Zone <span className="text-danger">*</span></Label>
                                <TimezoneSelect
                                  value={formik.values.eventtimezone}
                                  onChange={(tz) => formik.setFieldValue('eventtimezone', tz.value)}
                                  style={styles.input}
                                  defaultValue="Asia/Kolkata"
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                      </div>

                      {/* Location Section */}
                      <div className="mb-4">
                        <Label className="form-label fs-5">
                          <FaMapMarkerAlt className="me-2" style={iconStyle} />
                          Location Details
                        </Label>
                        <div className="p-3 border rounded">
                          <FormGroup className="mb-3">
                            <Label className="form-label" style={styles.fieldLabel}>Event Type <span className="text-danger">*</span></Label>
                            <div className="d-flex gap-3">
                              {[
                                { value: 'virtual', label: 'Virtual' },
                                { value: 'in-person', label: 'In-person' },
                                { value: 'both', label: 'Hybrid' }
                              ].map((type) => (
                                <div key={type.value} className="form-check">
                                  <Input
                                    type="radio"
                                    name="eventtype"
                                    value={type.value}
                                    checked={formik.values.eventtype === type.value}
                                    onChange={(e) => {
                                      formik.setFieldValue('eventtype', e.target.value);
                                      // Reset location fields when type changes
                                      formik.setFieldValue('eventlocation_placeid', '');
                                      formik.setFieldValue('eventlocation_address', '');
                                      formik.setFieldValue('eventlocation_virtual_link', '');
                                    }}
                                    id={`eventtype-${type.value}`}
                                    required
                                    style={styles.input}
                                  />
                                  <Label className="form-check-label" htmlFor={`eventtype-${type.value}`} style={styles.fieldLabel}>
                                    {type.label}
                                  </Label>
                                </div>
                              ))}
                            </div>
                          </FormGroup>

                          {/* Virtual Link Input */}
                          {(formik.values.eventtype === 'virtual' || formik.values.eventtype === 'both') && (
                            <FormGroup className="mb-3">
                              <Label className="form-label" style={styles.fieldLabel}>
                                Virtual Meeting Link <span className="text-danger">*</span>
                              </Label>
                              <Input
                                type="url"
                                className="form-control"
                                name="eventlocation_virtual_link"
                                placeholder="Enter virtual meeting link (e.g., Zoom, Teams, Google Meet)"
                                value={formik.values.eventlocation_virtual_link}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                required={formik.values.eventtype === 'virtual' || formik.values.eventtype === 'both'}
                                style={styles.input}
                                invalid={formik.touched.eventlocation_virtual_link && !!formik.errors.eventlocation_virtual_link}
                              />
                              {formik.touched.eventlocation_virtual_link && formik.errors.eventlocation_virtual_link && (
                                <div className="invalid-feedback">{formik.errors.eventlocation_virtual_link}</div>
                              )}
                            </FormGroup>
                          )}

                          {/* Physical Location Input */}
                          {(formik.values.eventtype === 'in-person' || formik.values.eventtype === 'both') && (
                            <FormGroup className="mb-3">
                              <Label className="form-label" style={styles.fieldLabel}>
                                Venue Location <span className="text-danger">*</span>
                              </Label>
                              <PlacesAutocomplete
                                value={eventAddress}
                                onChange={setEventAddress}
                                onSelect={handleAddressSelect}
                              >
                                {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                  <div>
                                    <Input
                                      {...getInputProps({
                                        placeholder: 'Enter venue address',
                                        className: 'form-control'
                                      })}
                                      required={formik.values.eventtype === 'in-person' || formik.values.eventtype === 'both'}
                                      style={styles.input}
                                      invalid={formik.touched.eventlocation_address && !!formik.errors.eventlocation_address}
                                    />
                                    {formik.touched.eventlocation_address && formik.errors.eventlocation_address && (
                                      <div className="invalid-feedback">{formik.errors.eventlocation_address}</div>
                                    )}
                                    <div className="autocomplete-dropdown-container">
                                      {loading && <div>Loading...</div>}
                                      {suggestions.map((suggestion, index) => (
                                        <div
                                          {...getSuggestionItemProps(suggestion)}
                                          key={index}
                                          className="suggestion-item"
                                        >
                                          {suggestion.description}
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                )}
                              </PlacesAutocomplete>
                            </FormGroup>
                          )}
                        </div>
                      </div>

                      {/* Event Visibility Section */}
                      <div className="mb-4">
                        <Label className="form-label fs-5">
                          <Eye className="me-2" style={iconStyle} />
                          Event Visibility
                        </Label>
                        <div className="p-3 border rounded">
                          <div className="d-flex flex-column gap-3">
                            {visibilityOptions.map((option) => (
                              <div
                                key={option.value}
                                className={`cursor-pointer p-3 rounded transition-all ${
                                  formik.values.eventvisibility === option.value
                                    ? 'border-primary bg-light'
                                    : 'border'
                                }`}
                                style={{
                                  borderWidth: '2px',
                                  borderStyle: 'solid',
                                  borderColor: formik.values.eventvisibility === option.value ? option.color : '#E2E8F0',
                                  cursor: 'pointer'
                                }}
                                onClick={() => formik.setFieldValue('eventvisibility', option.value)}
                              >
                                <div className="d-flex align-items-center gap-3">
                                  <div
                                    className="p-2 rounded-circle"
                                    style={{
                                      backgroundColor: `${option.color}15`,
                                      color: option.color
                                    }}
                                  >
                                    <option.icon size={24} />
                                  </div>
                                  <div className="flex-grow-1">
                                    <h6 className="mb-1 fw-semibold" style={{ color: option.color }}>
                                      {option.title}
                                    </h6>
                                    <p className="mb-0 text-muted small">
                                      {option.description}
                                    </p>
                                  </div>
                                  <div
                                    className={`rounded-circle border d-flex align-items-center justify-content-center ${
                                      formik.values.eventvisibility === option.value ? 'border-primary' : ''
                                    }`}
                                    style={{
                                      width: '20px',
                                      height: '20px',
                                      borderWidth: '2px'
                                    }}
                                  >
                                    {formik.values.eventvisibility === option.value && (
                                      <div
                                        className="rounded-circle"
                                        style={{
                                          width: '12px',
                                          height: '12px',
                                          backgroundColor: option.color
                                        }}
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </Col>

                    <Col md={4}>
                      {/* Image Upload Section */}
                      <div className="mb-4">
                        <Label className="form-label fs-5">
                          <FaImage className="me-2" style={iconStyle} />
                          Event Banner
                        </Label>
                        <div className="p-3 border rounded text-center">
                          <ImgCrop 
                            aspect={16/9}
                            quality={1}
                            modalTitle="Crop Event Image"
                            modalWidth={800}
                          >
                            <Upload
                              listType="picture-card"
                              fileList={fileList}
                              onChange={handleImageChange}
                              onPreview={handlePreview}
                              maxCount={1}
                              accept=".jpg,.jpeg,.png"
                              onRemove={handleRemoveImage}
                              beforeUpload={() => false}
                            >
                              {fileList.length === 0 && (
                                <div>
                                  <FaImage style={{ fontSize: '2rem', color: '#8c8c8c' }} />
                                  <div style={{ marginTop: 8 }}>Upload</div>
                                </div>
                              )}
                            </Upload>
                          </ImgCrop>
                          {fileList.length > 0 && (
                            <Button
                              color="danger"
                              size="sm"
                              className="mt-2"
                              onClick={handleRemoveImage}
                            >
                              Delete Image
                            </Button>
                          )}
                          <Modal
                            visible={previewVisible}
                            title={previewTitle}
                            footer={null}
                            onCancel={handleCancel}
                          >
                            <img alt="Preview" style={{ width: '100%' }} src={previewImage} />
                          </Modal>
                          <div className="text-muted mt-2" style={{ fontSize: '0.85rem' }}>
                            <div>Recommended: 1200x675px (16:9)</div>
                            <div>Minimum: 600x338px</div>
                            <div>Maximum size: 2MB</div>
                            <div>Formats: JPEG, PNG</div>
                          </div>
                        </div>
                      </div>

                      {/* Links Section */}
                      <div className="mb-4">
                        <Label className="form-label fs-5">
                          <FaLink className="me-2" style={iconStyle} />
                          Event Links
                        </Label>
                        <div className="p-3 border rounded">
                          <FormGroup className="mb-3">
                            <Label className="form-label" style={styles.fieldLabel}>Registration URL</Label>
                            <div className="form-icon position-relative">
                              <Input
                                type="url"
                                className="form-control ps-5"
                                name="eventticketurl"
                                placeholder="Registration/Ticket URL"
                                value={formik.values.eventticketurl}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                style={styles.input}
                                invalid={formik.touched.eventticketurl && !!formik.errors.eventticketurl}
                              />
                              <FaTicketAlt className="fea icon-sm icons" />
                              {formik.touched.eventticketurl && formik.errors.eventticketurl && (
                                <div className="invalid-feedback">{formik.errors.eventticketurl}</div>
                              )}
                            </div>
                          </FormGroup>

                          <FormGroup className="mb-3">
                            <Label className="form-label" style={styles.fieldLabel}>Organization URL</Label>
                            <div className="form-icon position-relative">
                              <Input
                                type="url"
                                className="form-control ps-5"
                                name="eventorgurl"
                                placeholder="Organization website"
                                value={formik.values.eventorgurl}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                style={styles.input}
                                invalid={formik.touched.eventorgurl && !!formik.errors.eventorgurl}
                              />
                              <FaGlobe className="fea icon-sm icons" />
                              {formik.touched.eventorgurl && formik.errors.eventorgurl && (
                                <div className="invalid-feedback">{formik.errors.eventorgurl}</div>
                              )}
                            </div>
                          </FormGroup>
                        </div>
                      </div>

                      {/* Event Categories Section */}
                      <div className="mb-4">
                        <Label className="form-label fs-5">
                          <FaTags className="me-2" style={iconStyle} />
                          Event Categories
                        </Label>
                        <div className="p-3 border rounded">
                          <div className="d-flex flex-wrap gap-2">
                            {eventCategories.map((category) => {
                              const IconComponent = getIconComponent(category.categoryicon);
                              return (
                                <Button
                                  key={category.categoryuuid}
                                  color={formik.values.listeventcategories.includes(category.categoryuuid) ? 'primary' : 'light'}
                                  className="d-flex align-items-center gap-2 px-3 py-2 rounded-pill"
                                  onClick={() => toggleCategory(category.categoryuuid)}
                                  style={{
                                    whiteSpace: 'nowrap',
                                    transition: 'all 0.3s ease',
                                    borderColor: formik.values.listeventcategories.includes(category.categoryuuid) ? 'transparent' : '#dee2e6',
                                  }}
                                >
                                  <IconComponent style={{ color: formik.values.listeventcategories.includes(category.categoryuuid) ? 'white' : category.color }} />
                                  <span>{category.categoryname}</span>
                                </Button>
                              );
                            })}
                          </div>
                          <small className="text-muted mt-2 d-block">
                            Select all categories that apply to your event
                          </small>
                        </div>
                      </div>
                    </Col>

                    {/* Submit Buttons */}
                    <Col md={12} className="text-end mt-4">
                      <Button
                        color="secondary"
                        type="button"
                        className="me-2"
                        onClick={() => navigate('/event-listing-page')}
                      >
                        Cancel
                      </Button>
                      <Button
                        color="primary"
                        type="submit"
                        disabled={loading}
                      >
                        {loading ? 'Saving...' : (eventuuid ? 'Update Event' : 'Create Event')}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>

      {/* Payment Modal */}
      <Modal isOpen={showPaymentModal} toggle={togglePaymentModal}>
        <ModalHeader toggle={togglePaymentModal}>
          <FaHeart className="me-2" style={iconStyle} />
          Support Us with a Small Donation
        </ModalHeader>
        <ModalBody>
          <p>
            We appreciate your effort in creating an event. To help us maintain and improve our platform, we kindly ask for a small donation.
          </p>
          <p>
            Would you like to make a donation before submitting your event?
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => handleModalAction(true)}>
            Skip Donation
          </Button>
          <Button color="primary" onClick={() => handleModalAction(false)} disabled={isPaymentPending}>
            {isPaymentPending ? 'Processing...' : 'Donate & Submit'}
          </Button>
        </ModalFooter>
      </Modal>
    </section>
  );
};

export default NewEventForm;
