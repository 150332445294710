import React, { useEffect, useState, Component } from 'react';
import {
  Row,
  Col,
  CardBody,
  Card,
  Label,
  Input,
  Form,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  PaginationItem,
  PaginationLink,
  FormFeedback,
  Alert,
  Fade,
  Progress,
} from 'reactstrap';
// import moment from 'moment-timezone';
// import { DateTime } from 'luxon';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import ContentLoader, { List } from 'react-content-loader';
import Slider from 'react-slick';
import blankEventImage from '../../../assets/images/MettaMateImages/eventSamples/defaultEventImage.png';
import postAnEventImagePath from '../../../assets/images/MettaMateImages/eventSamples/postAnEventPoster.png';
import '../..//Home/css/userHomePage.css';
import CommonModal from '../../Home/modal';
import ImgCrop from 'antd-img-crop';
import { Upload } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import DefaultPicture from '../DefaultPicture.js';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import EventForm from './EventForm';
import axios from 'axios';
import apiUrl from 'apppages/server';

const ImageSliderSettings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: true,
};

class MyEventsFunction extends Component {
  // Step 1: Initialize state in the constructor
  constructor(props) {
    super(props);
    this.state = {
      successMessage: '',
      eventcategoryList: [],
      showDeleteModal: false,
      showEventDetailsModal: false,
      selectedEvent: null,
      currentContents: 'displayMyEvents',
      isLoading: true, // state variable "count"
      eventList: [],
      currentItems: [],
      pageNumbers: [],
      totalPages: 5,
      currentPage: 1,
      totalCount: 5,
      itemsPerPage: 5,
      configurationData: JSON.parse(localStorage.getItem('configurationData')),
      // Elements for Event Posting
      inputEventImagePath: '',
      file1: [],
      modalEventDetails: '',
      modalEventTitle: '',
    };
    this.formNavigate = this.formNavigate.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.handleDeleteEvent = this.handleDeleteEvent.bind(this);
  }

  translateDateToReadableFormat = (givenDate, givenTimezone) => {
    const formattedDate = new Date(givenDate).toLocaleDateString('en-US', {
      weekday: 'long', // Sunday
      year: 'numeric', // 2024
      month: 'short', // Oct
      day: 'numeric', // 13
    });
    return formattedDate;
  };

  formattedTimeFormat = (givenTime, givenTimezone) => {
    // Get the current date and merge with the provided time
    const [hour, minute] = givenTime.split(':');
    const currentDate = new Date();
    currentDate.setHours(hour);
    currentDate.setMinutes(minute);

    // Format the time for the specified time zone with abbreviation
    const formattedTime = new Intl.DateTimeFormat('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      timeZone: givenTimezone,
      timeZoneName: 'short', // This adds the time zone abbreviation (e.g., PDT, EST)
      hour12: false, // Use 24-hour format
    }).format(currentDate);

    return formattedTime;
  };

  fetchMyEvents = async () => {
    this.setState({ isLoading: true });
    let memberuuid = localStorage.getItem('memberID');
    const res = await axios.get(`${apiUrl}eb/getmyebposts/${memberuuid}`, {
      headers: {
        'Content-Type': 'application/json',
        authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    });

    if (res.data?.length) {
      const allMyEbPosts = res.data; 
      if (allMyEbPosts?.length) {
        this.setState({ currentItems: allMyEbPosts });
      }
    } else {
      this.setState({ currentItems: [] });
    }

    this.setState({ isLoading: false });
  };

  fetchAllCategories = () => {
    axios
      .get(`${apiUrl}eb/getebcategories`, {
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        if (res.data?.length) this.setState({ eventcategoryList: res.data });
      })
      .catch((err) => console.error(err));
  };

  componentDidMount() {
    // Example: Fetching data or performing side effects
    console.log('MyEvents did mount!');
    // this.setState({ isLoading: true });
    // Simulate an API call
    setTimeout(() => {
      this.fetchMyEvents();
      this.fetchAllCategories();
    }, 1 * 1000); // 40 seconds
  }

  formNavigate() {
    this.setState({ currentContents: 'displayMyEvents' });
    this.fetchMyEvents();
  }
  closeModal() {
    this.setState({ showDeleteModal: false, showEventDetailsModal: false });
  }

  handleUpdateEvent(event) {
    this.setState({ selectedEvent: event });
    this.setState({ currentContents: 'postAnEvent' });
    console.log(event);
  }

  handleEventDetailsModal(eventTitle, eventDetails) {
    this.setState({
      showEventDetailsModal: true,
      modalEventTitle: eventTitle,
      modalEventDetails: eventDetails,
    });
  }

  handlePostAnEvent() {
    this.props.enablePostAnEventFunction();
    this.setState({ currentContents: 'postAnEvent', selectedEvent: null });
  }

  addEventCategories(eventCategories) {
    const filledEventCategories = [];
    for (let i = 0; i < eventCategories.length; i++) {
      filledEventCategories.push(
        <li className="list-inline-item float-start m-2">
          <span className="badge rounded-pill text-dark border  bg-light border-1 border-dark font_size14">
            <span className="font_size14">
              {' '}
              {eventCategories[i].categoryname}
            </span>
          </span>
        </li>,
      );
    }
    return filledEventCategories;
  }

  handleDeleteEvent(eventuuid) {
    axios
      .delete(
        `${apiUrl}eb/deleteebpost/${eventuuid}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            'Content-Type': 'application/json',
          },
        },
      )
      .then((res) => {
        if (res.data?.length) {
          this.closeModal();
          this.setState({
            successMessage: {
              title: 'Success!',
              message: 'Event Deleted Successfully !',
            },
          });
          this.fetchMyEvents();
        }
      })
      .catch((err) => console.error(err));
  }

  handleDeleteEvent1(event) {
    this.setState({ selectedEvent: event });
    console.log(event);
  }

  render() {
    return (
      <div className="contianer-fluid">
        {this.state.successMessage && (
          <CommonModal
            title={this.state.successMessage.title}
            message={this.state.successMessage.message}
            onClose={() => this.setState({ successMessage: false })}
            status="success"
          />
        )}
        <Row>
          {this.state.currentContents == 'displayMyEvents' && (
            <Col lg="12" className="Col_12">
              <Row>
                <Col md={12} xs={12} className="d-flex justify-content-center">
                  <h3>Your Posted Events</h3>
                </Col>
              </Row>
              <Row>
                <Col md={12} xs={12} className=" ">
                  {this.state.isLoading ? (
                    <Row>
                      {[1, 2, 3, 4].map((key) => (
                        <Col
                          lg="3"
                          md="6"
                          xs="12"
                          key={key}
                          className="mt-4 pt-2 text-center"
                        >
                          <Card className="catagories overflow-hidden rounded border-0">
                            <ContentLoader
                              speed={2}
                              width={280}
                              height={400}
                              viewBox="0 0 280 400"
                              backgroundColor="#f3f3f3"
                              foregroundColor="#ecebeb"
                            >
                              {/* Image skeleton */}
                              <rect
                                x="0"
                                y="0"
                                rx="5"
                                ry="5"
                                width="280"
                                height="200"
                              />

                              {/* Other content placeholders */}
                              <rect
                                x="0"
                                y="220"
                                rx="5"
                                ry="5"
                                width="280"
                                height="20"
                              />
                              <rect
                                x="0"
                                y="250"
                                rx="5"
                                ry="5"
                                width="280"
                                height="20"
                              />
                              <rect
                                x="0"
                                y="280"
                                rx="5"
                                ry="5"
                                width="140"
                                height="20"
                              />
                              <rect
                                x="150"
                                y="280"
                                rx="5"
                                ry="5"
                                width="130"
                                height="20"
                              />
                              <circle cx="30" cy="330" r="15" />
                              <circle cx="70" cy="330" r="15" />
                              <circle cx="110" cy="330" r="15" />
                            </ContentLoader>
                          </Card>
                        </Col>
                      ))}
                    </Row>
                  ) : (
                    <>
                      {this.state.currentItems ? (
                        <Row>
                          <Col
                            key="defaultPostAnEvent"
                            className="mt-4 pt-2 text-start col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12  "
                          >
                            <Card className="catagories  rounded rounded-3 overflow-hidden border-1 border-primary shadow ">
                              {/* Top Display on the card and where is the IMAGE ? */}
                              <CardBody className="rounded overflow-hidden p-0 ">
                                <ul
                                  className="list-unstyled align-items-center width100 position-absolute  "
                                  style={{
                                    zIndex: `9`,
                                    marginBottom: `0px`,
                                  }}
                                ></ul>
                                <Slider className="imageSlider image-container">
                                  <img
                                    src={postAnEventImagePath}
                                    className="img-fluid img_thumb"
                                  />
                                </Slider>
                              </CardBody>
                            </Card>
                            <Card className="catagories rounded rounded-3 mt-1 overflow-hidden shadow">
                              <CardBody className="rounded shadow p-2 text-align-center">
                                <Link
                                  onClick={() => this.handlePostAnEvent()}
                                  className={`rounded btn btn-outline-primary py-1 d-flex`}
                                >
                                  <i>
                                    <FeatherIcon
                                      icon="globe"
                                      className="fea icon-sm "
                                    />
                                  </i>
                                  <span
                                    style={{ fontSize: `16px` }}
                                    className=" d-none d-md-block mx-1"
                                  >
                                    &nbsp;&nbsp;Post an Event !
                                  </span>
                                </Link>
                              </CardBody>
                            </Card>
                          </Col>
                          {(this.state.currentItems || []).map(
                            (eventObject, key) => (
                              <Col
                                key={key}
                                className="mt-4 pt-2 text-start col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12  "
                              >
                                <Card className="catagories  rounded rounded-3 overflow-hidden border-1 border-primary shadow ">
                                  {/* Top Display on the card and where is the IMAGE ? */}
                                  <CardBody className="rounded overflow-hidden p-0 ">
                                    <ul
                                      className="list-unstyled align-items-center width100 position-absolute  "
                                      style={{
                                        zIndex: `9`,
                                        marginBottom: `0px`,
                                      }}
                                    >
                                      <li className="list-inline-item float-start m-2">
                                        <span className="badge rounded-pill text-dark border  bg-light border-1 border-dark font_size14">
                                          {eventObject.eventstatus != '' && (
                                            <span className="font_size14">
                                              {' '}
                                              {eventObject.eventstatus}
                                            </span>
                                          )}
                                        </span>
                                      </li>
                                    </ul>
                                    <Slider
                                      className="imageSlider image-container"
                                      {...ImageSliderSettings}
                                    >
                                      <img
                                        src={
                                          eventObject.eventimagefilepath
                                            ? eventObject.eventimagefilepath
                                            : blankEventImage
                                        }
                                        // src={defaultEventImage}
                                        className="img-fluid img_thumb"
                                      />
                                    </Slider>
                                  </CardBody>
                                </Card>
                                <Card className="catagories rounded rounded-3 mt-1 overflow-hidden shadow">
                                  <CardBody className="rounded shadow p-2">
                                    <div className="justify-content-left">
                                      <p className="text-dark h5 name mb-0">
                                        <span style={{ color: `#0D6EFD` }}>
                                          {eventObject.eventtitle},{' '}
                                        </span>{' '}
                                      </p>
                                      <p className="m-0 font_size14">
                                        <FeatherIcon
                                          icon="calendar"
                                          className="fea icon-sm me-1"
                                        />
                                        {this.translateDateToReadableFormat(
                                          eventObject.eventstartdate,
                                        )}{' '}
                                        -{' '}
                                        {this.translateDateToReadableFormat(
                                          eventObject.eventenddate,
                                        )}
                                      </p>
                                      <p className="m-0 font_size14">
                                        <FeatherIcon
                                          icon="calendar"
                                          className="fea icon-sm me-1"
                                        />
                                        {this.formattedTimeFormat(
                                          eventObject.eventstarttime,
                                          eventObject.eventtimezone,
                                        )}{' '}
                                        -{' '}
                                        {this.formattedTimeFormat(
                                          eventObject.eventendtime,
                                          eventObject.eventtimezone,
                                        )}
                                      </p>
                                      <p className="m-0 font_size14">
                                        <FeatherIcon
                                          icon="map-pin"
                                          className="fea icon-sm me-1"
                                        />
                                        {eventObject.eventlocationname || 'Location not provided'}
                                      </p>
                                      <p className="m-0 font_size14">
                                        <ul>
                                          {/* {this.addEventCategories(
                                            this.state.eventcategoryList,
                                          )} */}
                                        </ul>
                                      </p>
                                    </div>
                                    <div className="d-flex justify-content-center">
                                      <div className="m-0">
                                        <button
                                          onClick={() =>
                                            this.handleEventDetailsModal(
                                              eventObject.eventtitle,
                                              eventObject.eventdetails,
                                            )
                                          }
                                          className="btn designation btn-primary viewBtn"
                                        >
                                          <FeatherIcon
                                            icon="eye"
                                            className="fea icon-sm icons"
                                          />{' '}
                                          &nbsp; Details
                                        </button>
                                        <button
                                          className="btn btn-success ms-2"
                                          onClick={() =>
                                            this.handleUpdateEvent(eventObject)
                                          }
                                        >
                                          <i className="fa fa-pencil position-static"></i>
                                        </button>
                                        <button
                                          className="btn btn-danger ms-2"
                                          onClick={() => {
                                            this.setState({
                                              showDeleteModal: true,
                                            });
                                            this.handleDeleteEvent1(
                                              eventObject,
                                            );
                                          }}
                                        >
                                          <i className="fa fa-trash position-static"></i>
                                        </button>
                                      </div>
                                    </div>
                                  </CardBody>
                                </Card>
                              </Col>
                            ),
                          )}
                          <Col xs={12} className="mt-4 pt-2">
                            {this.state.totalPages > 1 && (
                              <>
                                {this.state.currentItems.length > 0 && (
                                  <ul className="pagination justify-content-center mb-0">
                                    <PaginationItem
                                      disabled={this.state.currentPage === 1}
                                    >
                                      <PaginationLink
                                        to="#"
                                        aria-label="Previous"
                                        onClick={() =>
                                          handlePageClick(
                                            this.state.currentPage - 1,
                                          )
                                        }
                                      >
                                        {'<<'}
                                      </PaginationLink>
                                    </PaginationItem>
                                    {this.state.pageNumbers.map((number) => (
                                      <PaginationItem
                                        active={
                                          number === this.state.currentPage
                                        }
                                        key={number}
                                        disabled={number === '...'}
                                      >
                                        <PaginationLink
                                        // onClick={() =>
                                        //   handlePageClick(number)
                                        // }
                                        >
                                          {number}
                                        </PaginationLink>
                                      </PaginationItem>
                                    ))}
                                    <PaginationItem
                                      disabled={
                                        this.state.currentPage ===
                                        this.state.totalPages
                                      }
                                    >
                                      <PaginationLink
                                        to="#"
                                        aria-label="Next"
                                        // onClick={() =>
                                        //   handlePageClick(
                                        //     this.state.currentPage + 1,
                                        //   )
                                        // }
                                      >
                                        {'>>'}
                                      </PaginationLink>
                                    </PaginationItem>
                                  </ul>
                                )}
                              </>
                            )}
                          </Col>
                        </Row>
                      ) : (
                        <div className="text-center mt-3 label_text">
                          Currently No Events !
                        </div>
                      )}
                    </>
                  )}
                </Col>
              </Row>
            </Col>
          )}
          {/* {this.state.currentContents == 'postAnEvent' && (
            <Row className=" m-0  inner_row_personal">
              <h5 className="title title_bar">Upload Your Photos</h5>
              <Col md={12} xs={12} className=" p-0">
                {' '}
                Create a beautiful form for Accepting Events here
              </Col>
            </Row>
          )} */}

          {this.state.currentContents == 'postAnEvent' && (
            <Row className=" m-0  inner_row_personal">
              {/* <h5 className="title title_bar">Upload Your Photos</h5> */}
              <Col md={12} xs={12} className=" p-0">
                <EventForm
                  selectedEvent={this.state.selectedEvent}
                  formNavigate={this.formNavigate}
                />
              </Col>
            </Row>
          )}
        </Row>
        <Modal isOpen={this.state.showDeleteModal} toggle={this.closeModal}>
          <ModalHeader toggle={this.closeModal}>
            Confirmation
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={this.closeModal}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col lg="12">
                <p className="mb-0">
                  Are You Sure to delete {this.state.selectedEvent?.eventtitle}?
                </p>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="p-1">
            <Button color="secondary" onClick={this.closeModal} type="button">
              Close
            </Button>
            <Button
              color="danger"
              type="button"
              onClick={() =>
                this.handleDeleteEvent(this.state.selectedEvent?.eventuuid)
              }
            >
              Delete
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={this.state.showEventDetailsModal}
          toggle={this.closeModal}
          id="eventDetailsModal"
          tabIndex="-1"
          centered
        >
          <ModalBody className="py-5">
            <div className="text-center">
              <button
                type="button"
                onClick={() => {
                  this.closeModal();
                }}
                className="btn btn-primary float-end m-4"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
              <div className="mt-4 d-flex align-items-center justify-content-center">
                <h3 className="mb-0">
                  <p className="">{this.state.modalEventTitle}</p>
                </h3>
              </div>
              <div className="mt-4">
                <h5>Event Details</h5>
                <p className="text-muted">{this.state.modalEventDetails}</p>
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default MyEventsFunction;
