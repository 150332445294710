// React Basic and Bootstrap
import React, { useEffect } from 'react';
import { Container, Row, Col } from 'reactstrap';
import Counter2 from '../../components/Shared/Counter2';
// import generic component
// import WorkProcess from "./WorkProcess";
import Section from './Section';
import Popup from '../../components/Layout/popup';
import NewConnectionSafe from './newConnectSafe';
import Testimonials from './Testimonials';

import Asset190 from '../../assets/images/Newemoji/trustedA.png';
import Asset189 from '../../assets/images/Newemoji/Countries.png';
import Asset186 from '../../assets/images/Newemoji/lifepartnerblue.png';
import Asset187 from '../../assets/images/Newemoji/Community (2).png';

const Index = () => {
  let counters = [
    {
      title: 'Trusted Ambedkarites',
      image: Asset190,
      start: 1800,
      value: 1899,
      iconName: 'users',
      postfix: '+',
    },
    {
      title: 'Countries',
      image: Asset189,
      start: 0,
      value: 8,
      iconName: 'globe',
      postfix: '+',
    },
    {
      title: 'Alliance Seekers',
      image: Asset186,
      start: 1400,
      value: 1416,
      iconName: 'user-check',
      postfix: '+',
    },
    {
      title: 'Community Members',
      image: Asset187,
      start: 400,
      value: 483,
      iconName: 'user-plus',
      postfix: '+',
    },
    {
      title: 'Mentors',
      image: Asset187,
      start: 15,
      value: 20,
      iconName: 'user-plus',
      postfix: '+',
    },
  ];

  useEffect(() => {
    document.body.classList = '';
    document.getElementById('topnav')?.classList.add('bg-white');
    document.getElementById('buyButton')?.classList.add('nav-light');
    document.querySelector('.shoppingbtn')?.classList.add('btn-primary');
    // window.addEventListener("scroll", scrollNavigation, true);
  });

  // const scrollNavigation = () => {
  //   var doc = document.documentElement;
  //   const navBar = document.getElementById("topnav");
  //   var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
  //   if (navBar != null) {
  //     if (top > 80) {
  //       navBar.classList.add("nav-sticky");
  //     } else {
  //       navBar.classList.remove("nav-sticky");
  //       document.querySelector(".shoppingbtn")?.classList.add("btn-primary");
  //       document.querySelector(".shoppingbtn")?.classList.remove("btn-light");
  //       document.querySelector(".settingbtn")?.classList.add("btn-soft-primary");
  //     }
  //   }
  // };

  return (
    <React.Fragment>
      {/* section */}
      <Section />
      <div className="container-fluid pt-4 mt-50 mt-60 mb-5" style={{border: '0px solid #FF7F00'}} >
        <Row className="justify-content-center">
          <Col xs="12" className="text-center">
            <div className="section-title mb-4 pb-2">
              <h4 className="title mb-4">
                See everything about your{' '}
                <span className="text-primary">MettaMate</span>
              </h4>
              <p className=" para-desc mx-auto mb-0 fontSize20">
                Lets build the strong Ambedkarites community through{' '}
                <span className="text-primary fw-bold">MettaMate</span> that can
                provide everything !
              </p>
            </div>
          </Col>
        </Row>
        <Row id="counter">
          {/* counter */}
          <Counter2 counters={counters} />
        </Row>
      </div>
      <NewConnectionSafe />
      <Testimonials />
      {/* <Popup /> */}
    </React.Fragment>
  );
};

export default Index;
