import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import '../../../src/apppages/Home/css/style.css';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import { Col } from 'reactstrap';
import Search from '../../../src/assets/images/Newemoji/Alliance Seeker Icon.png'
import Community from '../../../src/assets/images/Newemoji/Community Members Icon.png'
import MatchMaker from '../../../src/assets/images/Newemoji/Match Makers.png'
import networknavicon from '../../../src/assets/images/event/network_icon.png';
import mentorMentee from '../../../src/assets/images/mentorship/mentorMentee.jpeg';

const ChooseOption = ({ setRegistrationOption, registrationOption, setMembershipTypeValue }) => {
  // State for controlling the modal
  const [modalOpen, setModalOpen] = useState(false);

  // Function to toggle modal
  const toggleModal = () => setModalOpen(!modalOpen);
  return (
    // <>
    //   <div className="d-flex flex-wrap justify-content-center gap-3">
    //   <div className="card text-center" style={{ width: '18rem', backgroundColor: '#FF8A00', color: 'white', cursor: 'pointer' }}
    //           onClick={() => setRegistrationOption(!registrationOption)}
    //           >
    //       <div className="card-body">
    //       <img src={Search} className='mb-3' alt="" />
    //         <h5 className="card-title">Search Life-Partner</h5>
    //         <p className="card-text">Explore profiles and find your perfect match.</p>
    //       </div>
    //     </div>

    //     {/* Card 2: Community Members */}
    //     <div className="card text-center" style={{ width: '18rem', backgroundColor: '#0D6EFD', color: 'white', cursor: 'pointer' }}
    //      onClick={() => { setRegistrationOption(!registrationOption); setMembershipTypeValue(4); }}>
    //       <div className="card-body">
    //       <img src={Community} className='mb-3' alt="" />
    //         <h5 className="card-title">Community Members</h5>
    //         <p className="card-text">Connect with members of your community.</p>

    //       </div>
    //     </div>

    //     {/* Card 3: Match Makers */}
    //     <div className="card text-center" style={{ width: '18rem', backgroundColor: '#21B252', color: 'white', cursor: 'pointer' }}
    //       //  onClick={() => { setMembershipTypeValue(5); }}
    //       onClick={() => {

    //         toggleModal(); // Show modal on click
    //       }}
    //     >


    //       <div className="card-body">
    //         <img src={MatchMaker} className='mb-3' alt="" />
    //         <h5 className="card-title">Community Service Provider</h5>
    //         <p className="card-text">They will provide you the best Services.</p>

    //       </div>
    //     </div>
    //   </div >

    //   {/* Sign In Section */}
    //   < div className="text-center mt-4" >
    //     <p className="mb-0">
    //       <small className="text-dark me-2 label_text">Already have an account?</small>{' '}
    //       <Link
    //         to="/user-login-page"
    //         className="text-dark fw-bold badge text-white fs-6"
    //         style={{ background: 'rgb(255, 127, 0)' }}
    //       >
    //         Sign In
    //       </Link>
    //     </p>
    //   </div>
    //   {/* Modal */}
    //   <Modal isOpen={modalOpen} toggle={toggleModal} centered>
    //     {/* Modal Header */}
    //     <ModalHeader className="d-flex justify-content-between align-items-center">
    //       <div className="w-100 text-center">
    //         <h5 className="mb-0 text-center">Work in Progress</h5>
    //       </div>
    //       <button
    //         type="button"
    //         className="close-btn"
    //         aria-label="Close"
    //         onClick={toggleModal}
    //         style={{ position: 'absolute', right: '1rem', top: '1rem' , border: 'none'}}
    //       >
    //         <span aria-hidden="true">&times;</span>
    //       </button>
    //     </ModalHeader>

    //     {/* Modal Body */}
    //     <ModalBody className="text-center">
    //       <p className="mb-4">This feature is currently under development. Please check back later!</p>
    //     </ModalBody>
    //   </Modal>

    // </>
    <>
      <div className="row justify-content-center">
        {/* Card 1: Search Life-Partner */}
        <div className="col-md-6 mb-4">
          <div
            className="card card-registration text-center"
            style={{ backgroundColor: '#FF8A00', color: 'white', cursor: 'pointer' }}
            onClick={() => setRegistrationOption(!registrationOption)}
          >
            <div className="card-body card-body-registration">
              <img src={Search} alt="Search Life-Partner" />
              <h5 className="card-title-registration">Search Life-Partner</h5>
              <p className="card-text-registration">Explore profiles and find your perfect match.</p>
            </div>
          </div>
        </div>

      <div className="row justify-content-center">
        {/* Card 3: Community Members */}
        <div className="col-md-6 mb-4">
          <div
            className="card card-registration text-center"
            style={{ backgroundColor: '#0D6EFD', color: 'white', cursor: 'pointer' }}
            onClick={() => {
              setRegistrationOption(!registrationOption);
              setMembershipTypeValue(4);
            }}
          >
            <div className="card-body card-body-registration ">
              <img src={Community} alt="Community Members" />
              <h5 className="card-title-registration">Community Members</h5>
              <p className="card-text-registration">Connect with members of your community.</p>
            </div>
          </div>
        </div>

        {/* Card 2: New Feature */}
        <div className="col-md-6  mb-4">
          <div
            className="card card-registration text-center"
            style={{ backgroundColor: '#0F766E', color: 'white', cursor: 'pointer' }}
            onClick={() => {
              setRegistrationOption(!registrationOption);
              setMembershipTypeValue(4);
            }}
          >
            <div className="card-body card-body-registration">
              <img src={mentorMentee} alt="New Feature" />
              <h5 className="card-title-registration">Mentor & Mentee</h5>
              <p className="card-text-registration">Transform your personal and professional life!</p>
            </div>
          </div>
        </div>
      </div>

        {/* Card 4: Service Provider */}
        <div className="col-md-6 mb-4">
          <div
            className="card  text-center"
            style={{ backgroundColor: '#0EA5E9', color: 'white', cursor: 'pointer' }}
            onClick={toggleModal}
          >
            <div className="card-body ">
              <img src={MatchMaker} alt="Community Service Provider" />
              <h5 className="card-title-registration">Community Service Provider</h5>
              <p className="card-text-registration">They will provide you the best Services.</p>
            </div>
          </div>
        </div>
      </div>

      {/* Sign In Section */}
      <div className="text-center mt-1">
        <p className="mb-0">
          <small className="text-dark me-2 label_text">Already have an account?</small>{' '}
          <Link
            to="/user-login-page"
            className="text-dark fw-bold badge text-white fs-6"
            style={{ background: 'rgb(255, 127, 0)' }}
          >
            Sign In
          </Link>
        </p>
      </div>

      {/* Modal */}
      <Modal isOpen={modalOpen} toggle={toggleModal} centered>
        <ModalHeader className="d-flex justify-content-between align-items-center">
          <div className="w-100 text-center">
            <h5 className="mb-0 text-center">Work in Progress</h5>
          </div>
          <button
            type="button"
            className="close-btn"
            aria-label="Close"
            onClick={toggleModal}
            style={{ position: 'absolute', right: '1rem', top: '1rem', border: 'none' }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </ModalHeader>
        <ModalBody className="text-center">
          <p className="mb-4">This feature is currently under development. Register as commnity member for now and please check back later for this feature !</p>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ChooseOption;
