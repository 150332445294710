import React, { useEffect, useState } from 'react';
import CommonModal from '../Home/modal.js';
import { useFormik } from 'formik';
import marriagePhoto2 from '../../assets/images/MettaMateImages/MIG1.avif';
import { Link } from 'react-router-dom';
import '../Home/css/register.css';
import apiUrl from '../server';
import axios from 'axios';
import waveIcon from '../../assets/images/MettaMateImages/Group189.svg';
import ourLogo from '../../assets/images/MettaMateImages/sblogo2.svg';
import googleIcon from '../../assets/images/MettaMateImages/Google.svg';
import linkedInIcon from '../../assets/images/Newemoji/linkedin2.png';
import loginBanner from '../../assets/images/MettaMateImages/loginbanner2.gif';
import {
  Container,
  Col,
  Input,
  Label,
  Button,
  Form,
  FormFeedback,
  Card,
  CardTitle,
  CardText,
  CardBody,
  CardImg,
} from 'reactstrap';
import { Row } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';

import loginBackgroundBanner from '../../assets/images/mainBG-image.jpg';
import mm_vision from '../../assets/images/mettamate/mm_vision.jpg';
import AboutUsSection from './AboutUsSection.js';
import LeadershipTeam from './leadership_team/LearshipTeam';

//Import Images
import candidate1 from '../../assets/images/mettamate/snehalataKamble1.jpeg';
import candidate2 from '../../assets/images/testimonies/pallaviGK2.png';
import coo from '../../assets/images/Newemoji/COO.jpg';
import candidate3 from '../../assets/images/mettamate/raghuKiran.jpeg';
import candidate4 from '../../assets/images/testimonies/datasahebTandale2.png';
import photodump from '../../assets/images/Newemoji/male_avatar.jpg';
import photodumpfemale from '../../assets/images/Newemoji/female_avatar.c085b7ebc7600153a498.jpeg';
import antra from '../../assets/images/Newemoji/Antra.jpg';

const AboutUsPage = () => {
  const navigate = useNavigate();

  const [isLoading, setisLoading] = useState(false);

  // Testimonials Component Logic (Integrated)
  // const testimonialsData = [
  //   {
  //     id: 1,
  //     image: candidate2,
  //     name: 'Dr. Pallavi G. K.',
  //     designation:
  //       'Founder & CEO',
  //     link: '',
  //     socialIcon: {
  //       src: linkedInIcon,  // LinkedIn icon image
  //       url: '#'  // LinkedIn URL
  //     }
  //   },
  //   {
  //     id: 2,
  //     image: coo,
  //     name: 'Dharamveer Singh',
  //     designation:
  //       'Co-Founder & COO',
  //     link: '',
  //     socialIcon: {
  //       src: linkedInIcon,  // LinkedIn icon image
  //       url: '#'  // LinkedIn URL
  //     },
  //   },
  //   {
  //     id: 3,
  //     image: photodumpfemale,
  //     name: 'Xyz',
  //     designation:
  //       'Chief Growth Officer',
  //       socialIcon: {
  //         src: linkedInIcon,  // LinkedIn icon image
  //         url: '#'  // LinkedIn URL
  //       },
  //   },
  //   {
  //     id: 4,
  //     image: photodump,
  //     name: 'Xyz',
  //     designation:
  //       'Chief Community Officer',
  //     link: '',
  //     socialIcon: {
  //       src: linkedInIcon,  // LinkedIn icon image
  //       url: '#'  // LinkedIn URL
  //     },
  //   },
  // ];
  // const testimonialsData2 = [
  //   {
  //     id: 5,
  //     image: candidate1,
  //     name: 'Prof. Snehalata Kamble',
  //     link: '',
  //     socialIcon: {
  //       src: linkedInIcon,  // LinkedIn icon image
  //       url: '#'  // LinkedIn URL
  //     },
  //   },

  //   {
  //     id: 7,
  //     image: candidate3,
  //     name: 'Raghu Kiran Bolle',
  //     socialIcon: {
  //       src: linkedInIcon,  // LinkedIn icon image
  //       url: '#'  // LinkedIn URL
  //     },
  //   },
  //   {
  //     id: 8,
  //     image: candidate4,
  //     name: 'Dadasaheb Tandale',
  //     link: '',
  //     socialIcon: {
  //       src: linkedInIcon,  // LinkedIn icon image
  //       url: '#'  // LinkedIn URL
  //     },
  //   },
  //   {
  //     id: 6,
  //     image: antra,
  //     name: 'Antra Gajbhiye',
  //     link: '',
  //     socialIcon: {
  //       src: linkedInIcon,  // LinkedIn icon image
  //       url: '#'  // LinkedIn URL
  //     },
  //   },
  // ];

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.classList = '';
  }, []);

  return (
    <React.Fragment>
      {/* Section So far DONE */}
      <AboutUsSection />
      <section className="bg-white">
        <Container className="mb-3">
          {/* section title */}
          <Row className="justify-content-center">
            <Col className={'col-12 text-center'}>
              <div className="pb-3">
                <h2 className="title mb-2"> Our Story </h2>
              </div>
              <p className="fontSize18">
                Welcome to{' '}
                <span className="text-primary fw-bold">Metta Mate</span>, a
                revolutionary app designed with the vision of empowering
                marginalized communities through educational transformation (Mentorship), health transformation, professional & livelihood and community networking.
              </p>
            </Col>
          </Row>

          <Row>
            <Col md={6} key="1" className="col-12 mt-4 pt-2">
              <Card className="border-0">
                <CardBody className="p-0 ">
                  <Link to="/portfolio-detail-one">
                    <img
                      src={mm_vision}
                      className="img-fluid rounded work-image"
                      alt="vision"
                    />
                  </Link>
                  <div className=" pt-3">
                    <h4 className="title mb-1 text-primary">Our Mission</h4>
                    <p className="fontSize18">
MettaMate empowers marginalized communities by bridging gaps in education, health, and livelihoods. Through mentorship, financial aid, skill development, and accessible resources, we enable individuals to achieve academic success, improve well-being, and create sustainable opportunities for economic growth.
                    </p>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md={6} key="1" className="col-12 mt-4 pt-2">
              <Card className="border-0">
                <CardBody className="p-0 ">
                  <Link to="/portfolio-detail-one">
                    <img
                      src={mm_vision}
                      className="img-fluid rounded work-image"
                      alt="vision"
                    />
                  </Link>
                  <div className=" pt-3">
                    <h4 className="title mb-2 text-primary">Our Vision</h4>
                    <p className="fontSize18">

MettaMate envisions a world where marginalized communities thrive with access to education, health, and economic opportunities. We aim to transform over 1 million lives in the next two years, fostering equity, resilience, and lasting social impact.
                    </p>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* <hr />
          <Row className="justify-content-center">
            <Col>
              <h2 className="text-center">Executive Team</h2>
            </Col>
          </Row>
          <Row className="justify-content-center">
            {testimonialsData.map((testimonial) => (
              <Col
                key={testimonial.id}
                xs={12}
                sm={6}
                md={4}
                lg={3}
                className="d-flex justify-content-center mb-4"
              >
                <div className="testimonial-card text-center">
                  <img
                    src={testimonial.image}
                    alt={testimonial.name}
                    className="img-fluid "
                  style={{ width: '300px', height: '300px', borderRadius: '8px' }}
                  />
                  <h4 className="mt-3 testimonial-name">{testimonial.name}</h4>
                  <div className='d-flex text-center'>
                    <h5 className="designation">{testimonial.designation}</h5>
                    {testimonial.socialIcon && testimonial.socialIcon.src && (
                      <img
                        src={testimonial.socialIcon.src}
                        className='mx-2 mt-1'
                        alt="LinkedIn Icon"  // It's good to include alt text for accessibility
                        style={{width: '25px', height:'25px'}}
                      />
                    )}
                  </div>

                </div>
              </Col>
            ))}
          </Row>
          <hr />
          <Row className="justify-content-center">
            <Col>
              <h2 className="text-center">Board Team</h2>
            </Col>
          </Row>
          <Row className="justify-content-center">
            {testimonialsData2.map((testimonial) => (
              <Col
                key={testimonial.id}
                xs={12}
                sm={6}
                md={4}
                lg={3}
                className="d-flex justify-content-center mb-4"
              >
                <div className="testimonial-card text-center">
                  <img
                    src={testimonial.image}
                    alt={testimonial.name}
                    className="img-fluid "
                    style={{ width: '300px', height: '300px', borderRadius: '8px' }}

                  />
                  <div className='d-flex text-center'>
                  <h4 className="testimonial-name">{testimonial.name}</h4>
                  {testimonial.socialIcon && testimonial.socialIcon.src && (
                      <img
                        src={testimonial.socialIcon.src}
                        className=' mt-3 social-linkedin'
                        alt="LinkedIn Icon"  // It's good to include alt text for accessibility
                        style={{width: '25px', height:'25px'}}
                      />
                    )}
                    </div>

                </div>
              </Col>
            ))}
          </Row> */}
          <hr />
          <Row className="justify-content-center">
            <Col className={'col-12 text-center'}>
              <LeadershipTeam />
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col className={'col-12 text-center'}>
              <div className="pb-3 pt-3">
                <h1 className="title mb-2"> Join Us </h1>
              </div>
              <p className="fontSize18">
                Whether you are looking for mentor or wants to offer
                mentorship,life partner,seeking support and resources,or simply
                want to connect with lime minded individuals,{' '}
                <span className="text-primary fw-bold">Metta Mate</span> is here
                for you. Join us in transformation the marginalized
                community.Together,we can create a brighter and more inclusive
                future for all.
              </p>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col className={'col-12 text-center'}>
              <div className="pt-3 ">
                <Link
                  to="/user-registration-page"
                  className="btn btn-primary mt-70"
                >
                  {' '}
                  <span className="fontSize20">Join Now</span>{' '}
                </Link>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default AboutUsPage;
