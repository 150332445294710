import React, { useState, useEffect } from 'react';
import UnifiedMentorCard from './UnifiedMentorCard';
import SearchFilters from './SearchFiltersNew';
import Pagination from './Pagination';
import { Link } from 'react-router-dom';

import {
  fetchMentors,
	fetchMentorsByCategory,
  fetchCategoriesAndSubcategories,
  fetchCategoryStats,
} from './mentorService';
import {
  Col,
  Card,
  CardBody,
  Badge,
  Container,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
  Alert,
} from 'reactstrap';

import automationIcon from '../../../assets/images/mentorship/categoryIcons/automation.png';
import bussinessIcon from '../../../assets/images/mentorship/categoryIcons/bussiness.png';
import bussinessDevelopmentIcon from '../../../assets/images/mentorship/categoryIcons/business-development.png';
import chemicalIcon from '../../../assets/images/mentorship/categoryIcons/chemical.png';
import artsIcon from '../../../assets/images/mentorship/categoryIcons/arts.png';
import academicsIcon from '../../../assets/images/mentorship/categoryIcons/academics.png';
import financeIcon from '../../../assets/images/mentorship/categoryIcons/finance.png';
import healthIcon from '../../../assets/images/mentorship/categoryIcons/health.png';
import leadershipIcon from '../../../assets/images/mentorship/categoryIcons/leadership-and-managment.png';
import materialIcon from '../../../assets/images/mentorship/categoryIcons/material-science.png';
import medicalIcon from '../../../assets/images/mentorship/categoryIcons/medical-and-healthcare.png';
import personalDevelopmentIcon from '../../../assets/images/mentorship/categoryIcons/personal-development.png';
import pharmaIcon from '../../../assets/images/mentorship/categoryIcons/pharmaneutical.png';
import spritualIcon from '../../../assets/images/mentorship/categoryIcons/spiritual-growth.png';
import technologyIcon from '../../../assets/images/mentorship/categoryIcons/technology.png';
const caterogyIcons = {
  1: automationIcon,
  2: bussinessIcon,
  3: bussinessDevelopmentIcon,
  4: chemicalIcon,
  5: artsIcon,
  6: academicsIcon,
  7: financeIcon,
  8: healthIcon,
  9: leadershipIcon,
  10: materialIcon,
  11: medicalIcon,
  12: personalDevelopmentIcon,
  13: pharmaIcon,
  14: spritualIcon,
  15: technologyIcon,
};

const generateTestData = (originalMentors, count) => {
  return Array(count).fill().map((_, index) => ({
    ...originalMentors[0],
    mentorUuid: `${originalMentors[0].mentorUuid}-${index}` // Create unique IDs
  }));
};

const CategoryCard = ({ category, categoryStats, isSelected, onClick, index }) => {
  return (
    <Card
      className={`category-card shadow-hover h-100 transition-all duration-300 border-2 ${
        isSelected ? 'border-primary shadow-lg' : 'border-primary border-opacity-25'
      } ${index % 2 === 0 ? 'bg-soft-warning' : 'bg-soft-primary'}`}
      onClick={onClick}
      style={{ cursor: 'pointer' }}
    >
      <CardBody className="p-4">
        {/* Updated Header Section with consistent font styling */}
        <div className="d-flex align-items-center justify-content-between mb-4">
          <div className="category-icon-wrapper rounded-circle p-3 bg-light-primary">
            <img
              src={caterogyIcons[parseInt(category.categoryid)]}
              style={{ height: '40px', width: '40px' }}
              alt={category.categoryname}
              className="transition-transform hover:scale-110"
            />
          </div>
          <div className="category-info flex-grow-1 px-3">
            <h5 className="mb-1 category-title">{category.categoryname}</h5>
            <small className="text-muted">
              {category.subcategories.length} specializations
            </small>
          </div>
          {categoryStats[category.categoryid] && (
            <div className="mentor-stats bg-white rounded-pill border border-primary border-opacity-25 px-3 py-2">
              <div className="text-center">
                <div className="fs-5 fw-bold text-primary mb-0">
                  {categoryStats[category.categoryid]}
                </div>
                <div className="text-xs text-primary-dark">Mentors</div>
              </div>
            </div>
          )}
        </div>

        {/* Simplified Subcategories Section */}
        <div className="subcategories-section mt-4">
          <div className="d-flex align-items-center mb-2">
            <i className="fas fa-certificate text-warning me-2"></i>
            <span className="text-dark fw-medium fs-6">Specializations</span>
          </div>
          <div className="d-flex flex-wrap gap-2">
            {category.subcategories?.map((sub) => (
              <Link
                key={`sub-${sub.subcategoryid}`}
                className="rounded bg-soft-primary py-1 px-2 fs-7 text-dark font-weight-medium subcategory-item"
                title={sub.subcategoryname}
              >
                {sub.subcategoryname}
              </Link>
            ))}
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

// Update the CSS styles
const categoryCardStyles = `
.category-card {
  transition: all 0.3s ease;
  border-width: 2px !important;
}

.category-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0,0,0,0.1) !important;
  border-color: var(--bs-primary) !important;
  border-width: 2px !important;
}

.bg-soft-primary {
  background-color: rgba(13, 110, 253, 0.05);
}

.bg-soft-secondary {
  background-color: rgba(255, 255, 255, 1);
}

.border-opacity-25 {
  border-opacity: 0.25;
}

.bg-light-primary {
  background-color: rgba(13, 110, 253, 0.1);
}

.bg-light-hover:hover {
  background-color: rgba(13, 110, 253, 0.05);
}

.stats-badge {
  border: 2px solid rgba(13, 110, 253, 0.2);
}

.shadow-hover {
  transition: box-shadow 0.3s ease;
}

.shadow-hover:hover {
  box-shadow: 0 5px 15px rgba(0,0,0,0.1);
}

.subcategory-item {
  transition: all 0.2s ease;
  cursor: default;
}

.subcategory-item:hover {
  background-color: rgba(13, 110, 253, 0.1);
}

.subcategories-section {
  border-top: 1px solid rgba(13, 110, 253, 0.1);
  padding-top: 1rem;
}

.specializations-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 0.35rem;
  padding: 0.25rem;
}

.specialization-badge {
  transition: all 0.2s ease;
  background-color: rgba(255, 255, 255, 0.9) !important;
  border-color: rgba(13, 110, 253, 0.2) !important;
}

.specialization-badge:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  background-color: rgba(13, 110, 253, 0.05) !important;
  border-color: rgba(13, 110, 253, 0.4) !important;
}

.fs-6 {
  font-size: 0.9rem !important;
}

.rounded-3 {
  border-radius: 0.5rem !important;
}

.fs-7 {
  font-size: 0.813rem !important;
  font-weight: 400;
}

.fw-medium {
  font-weight: 500 !important;
}

.mentor-count-badge {
  position: absolute;
  top: 1rem;
  right: 1rem;
  background-color: rgba(255, 255, 255, 0.95);
  border: 2px solid rgba(13, 110, 253, 0.2);
  border-radius: 50%;
  padding: 0.5rem;
  min-width: 70px;
  min-height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.mentor-count-badge:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  border-color: rgba(13, 110, 253, 0.4);
}

.mentor-count-badge .fs-5 {
  font-size: 1.25rem !important;
  line-height: 1.2;
}

.mentor-count-badge .text-xs {
  font-size: 0.75rem;
  opacity: 0.8;
}

.category-info {
  min-width: 0; // Prevents text overflow
}

.category-info h5 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mentor-stats {
  min-width: 80px;
  transition: all 0.2s ease;
}

.mentor-stats:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  border-color: var(--bs-primary) !important;
}

.category-title {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-weight: 500 !important;
  color: var(--bs-primary);
  font-size: 1.15rem;
  letter-spacing: -0.02em;
}

.category-info {
  min-width: 0;
}

.category-info .category-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.compact-badge {
  display: inline-block;
  padding: 0.25rem 0.75rem;
  background-color: rgba(13, 110, 253, 0.05);
  border: 1px solid rgba(13, 110, 253, 0.15);
  border-radius: 1rem;
  color: var(--bs-primary);
  transition: all 0.2s ease;
  margin: 0.15rem;
}

.compact-badge:hover {
  background-color: rgba(13, 110, 253, 0.1);
  border-color: rgba(13, 110, 253, 0.25);
  transform: translateY(-1px);
}

.specializations-wrapper {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  padding: 4px;
}

.specialization-tag {
  display: inline-block;
  padding: 4px 12px;
  background-color: rgba(13, 110, 253, 0.05);
  border: 1px solid rgba(13, 110, 253, 0.15);
  border-radius: 4px;
  color: var(--bs-primary);
  font-size: 0.813rem;
  transition: all 0.2s ease;
  text-decoration: none;
  margin: 2px;
}

.specialization-tag:hover {
  background-color: rgba(13, 110, 253, 0.1);
  border-color: rgba(13, 110, 253, 0.25);
  transform: translateY(-1px);
  text-decoration: none;
  color: var(--bs-primary);
}
`;

const MentorshipPage = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [mentors, setMentors] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoryStats, setCategoryStats] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const [filters, setFilters] = useState({
    categories: [],
    subCategories: [],
    searchText: '',
    minExperience: '',
    maxExperience: '',
    isPaidMentorship: null,
  });

  const [pagination, setPagination] = useState({
    currentPage: 1,
    totalPages: 1,
    totalMentors: 0,
    mentorsPerPage: 20,
  });

  // Load initial data
  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsAuthenticated(!!token);
    loadInitialData();
  }, []);

  const loadInitialData = async () => {
    try {
      setLoading(true);

      // Fetch categories and their stats in parallel
      const [categoriesData, statsData] = await Promise.all([
        fetchCategoriesAndSubcategories(),
        fetchCategoryStats(),
      ]);

      //alert("Fetched subcategories = "+JSON.stringify(categoriesData));
      setCategories(categoriesData.data);
      setCategoryStats(statsData);
    } catch (error) {
      console.error('Error loading initial data:', error);
      setError('Failed to load categories');
    } finally {
      setLoading(false);
    }
  };

	const handlePageChange = (newPage) => {
			//alert("Going for PageIndex = "+newPage);
      setPagination({
        currentPage: newPage,
      });
	};

  // Load mentors only when filters change or pagination
  useEffect(() => {
    if (searchPerformed) {
      loadMentors();
    }
  }, [filters, pagination.currentPage]);

  const loadMentors = async () => {
    try {
      setLoading(true);
      setError(null);
      const response = await fetchMentorsByCategory(selectedCategory, pagination.currentPage, pagination.mentorsPerPage);
			// alert("Mentors Data = "+ JSON.stringify(response));
			// Assuming mentors is your original data array
			// const testMentors = generateTestData(response.data, 20);
      setMentors(response.data);
      setPagination((prev) => ({
        ...prev,
				currentPage : response.pagination.currentPage,
        totalPages: response.pagination.totalPages,
        totalMentors: response.pagination.totalCount,
      }));
    } catch (err) {
      setError('Failed to load mentors');
    } finally {
      setLoading(false);
    }
  };

  const handleFilterChange = (newFilters) => {
    setFilters(newFilters);
    setPagination((prev) => ({ ...prev, currentPage: 1 }));
    setSearchPerformed(true);
  };

  return (
    <React.Fragment>
      <section className="section">
        <Container>
          {/* Header Section */}
          <Row className="mb-4">
            <Col>
              <h4 className="text-center mb-3">
                Find Top Professional Mentors
              </h4>
              <p className="text-center fs-5">
                Connect with experienced professionals who can guide you
              </p>
            </Col>
          </Row>
          {/* {alert(caterogyIcons[1])} */}
          {/* Categories Grid */}
          {!searchPerformed && (
            <Row className="mb-4">
              <Col>
                <h5 className="mb-3">Browse by Category</h5>
                <Row className="g-4">
                  {categories
                    .sort((a, b) => {
                      const mentorsInA = categoryStats[a.categoryid] || 0;
                      const mentorsInB = categoryStats[b.categoryid] || 0;
                      return mentorsInB - mentorsInA;
                    })
                    .map((category, index) => (
                    <Col 
                      key={`category-${category.categoryid}`} 
                      xs={12} 
                      md={6} 
                      lg={4}
                    >
                      <CategoryCard
                        category={category}
                        categoryStats={categoryStats}
                        isSelected={selectedCategory === category.categoryid}
                        index={index}
                        onClick={() => {
                          setSelectedCategory(category.categoryid);
                          handleFilterChange({
                            ...filters,
                            categories: [category.categoryid],
                          });
                        }}
                      />
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          )}

          {/* Active Filters */}
          {(selectedCategory) && (
            <Row className="mb-3">
              <Col>
                <div className="d-flex flex-wrap gap-2 align-items-center fs-6">
                  <strong>Active Filters:</strong>
                  {filters.categories.map((catId) => {
                    const category = categories.find(
                      (c) => c.categoryid === catId,
                    );
                    return (
                      <Badge
                        key={catId}
                        color="primary"
                        className="d-flex align-items-center gap-2 fs-6"
                      >
                        {category?.categoryname}
                        <Button
                          close
                          onClick={() => {
															setSearchPerformed(false);
															setSelectedCategory(null);
															setFilters({
																categories: [],
																subCategories: [],
																searchText: '',
															});
                          }}
                        />
                      </Badge>
                    );
                  })}
                  {/* Add other active filters here */}
                  {(selectedCategory) && (
                    <Button
                      color="link"
                      size="sm"
                      onClick={() => {
                        setSearchPerformed(false);
                        setSelectedCategory(null);
                        setFilters({
                          categories: [],
                          subCategories: [],
                          searchText: '',
                        });
                      }}
                    >
                      Clear All
                    </Button>
                  )}
                </div>
              </Col>
            </Row>
          )}

          {/* Results Section */}
          { selectedCategory && loading ? (
            <div className="text-center py-5">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <>
						{selectedCategory && (

              <Row>
                {mentors.map((mentor) => (
                  <Col 
                    key={`mentor-${mentor.mentor_uuid}`} // Changed from mentorUuid to mentor_uuid
                    className="pt-2 text-center col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12"
                  >
                    <UnifiedMentorCard
                      mentorDetails={mentor}
                      isAuthenticated={isAuthenticated}
                    />
                  </Col>
                ))}
              </Row>
						)}

              {/* Pagination and No Results messaging remains same */}

						<hr />
						<div className="w-full flex justify-center my-8">
							<div className="flex space-x-2">
									<Pagination
											currentPage={pagination.currentPage}
											totalPages={pagination.totalPages}
											onPageChange={handlePageChange}
									/>
							</div>
						</div>
            </>
          )}
        </Container>
      </section>
    </React.Fragment>
  );
};

export default MentorshipPage;
