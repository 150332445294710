import React from 'react';
import { Card, CardBody, Col, Container, Row, CardImg } from 'reactstrap';
import { Link } from 'react-router-dom';
import jobPostingImage from '../../assets/images/professionalDevelopmentPage/job-posting.jpeg';
import referalsImage from '../../assets/images/professionalDevelopmentPage/referals.jpeg';
import SkillDevelopmentImage from '../../assets/images/professionalDevelopmentPage/development-workshop.jpeg';

import flyer1 from '../../assets/images/flyersDevelopmentPage/job-posting-flyer.jpeg';
import flyer2 from '../../assets/images/flyersDevelopmentPage/referral-program-flyer.jpeg';
import flyer3 from '../../assets/images/flyersDevelopmentPage/development-program-flyer.jpeg';

const ProfessionalDevelopmentPage = () => {
  const professionalDevelopmentCard = [
    {
      id: 1,
      image: jobPostingImage,
      title: 'Job Posting Platform',
      link: '#',
      text: `Mettamate, we're passionate about connecting talented
       individuals with exciting job opportunities.Our platform offers
        a huge job database, personalized job matching, an easy application
         process, and valuable career resources. Employers can also post
         their jobs to reach a wide audience, manage candidate applications
          efficiently, and enjoy a user-friendly job posting experience.`,
    },
    {
      id: 2,
      image: referalsImage,
      title: 'Referrals',
      link: '#',
      text: `As a Mettamate member, you'll gain access to exclusive job
       and internship listings, personalized career coaching and mentoring,
        professional resume and cover letter review, and networking opportunities
         with industry leaders. Our referral programme also offers exciting
         incentives for successful referrals.
`,
    },

    {
      id: 3,
      image: SkillDevelopmentImage,
      title: 'Skill Development Workshops',
      link: '#',
      text: `Supercharge your skills and transform your career with 
      Mettamate's cutting-edge skill development programmes! From emerging
      technologies to essential soft skills, our expert-led training sessions
      will equip you with the knowledge and expertise to stay ahead of the curve.
       Join our community of lifelong learners and discover a world of possibilities!`,
    },
  ];
  const flyersCard = [
    {
      id: 1,
      image: flyer1,
      link: '#',
    },
    {
      id: 2,
      image: flyer2,
      link: '#',
    },
    {
      id: 3,
      image: flyer3,
      link: '#',
    },
  ];

  return (
    <React.Fragment>
      <section className="section">
        <Container>
          <Row>
            <h3 className="text-center">
              Livelihood and Professional Development
            </h3>
          </Row>

          <Row>
            {professionalDevelopmentCard.map((card, key) => (
              <Col xs="12" lg="12" className="mt-4 pt-2" key={key}>
                <Card className="shadow rounded border-0 overflow-hidden">
                  <Row className="g-0">
                    <Col
                      md={7}
                      className="d-flex flex-column align-items-start p-0 m-0"
                    >
                      <CardBody className="h-100 d-flex flex-column justify-content-start p-3">
                        {/* Title */}
                        <h5 className="card-title font-weight-bold mb-3 text-center">
                          <Link to="#" className="text-primary">
                            {card.title}
                          </Link>
                        </h5>
                        {/* Image */}
                        <img
                          src={card.image}
                          className="img-fluid"
                          alt="..."
                          style={{
                            width: '100%',
                            height: 'auto',
                            borderRadius: '10px',
                            objectFit: 'cover',
                          }}
                        />
                      </CardBody>
                    </Col>

                    <Col
                      md={5}
                      className="d-flex flex-column justify-content-center p-3"
                    >
                      <p className="fs-6 m-0">{card.text}</p>
                    </Col>
                  </Row>
                </Card>
              </Col>
            ))}
          </Row>

          <Row className="py-4">
            <h3 className="text-center">Currently running programs</h3>
          </Row>

          <Row className="shadshadow-md bg-body rounded">
            {flyersCard.map((flyersCard, key) => (
              <Col lg="4" md="6" className="" key={key} name="servicesCard">
                <Link to="" className="card-title title text-dark">
                  <Card className="blog rounded border-0 shadow overflow-hidden mb-4">
                    <div className="position-relative">
                      <CardImg
                        src={flyersCard.image}
                        className="img-fluid"
                        alt=""
                        style={{
                          width: '100%',
                          height: 'auto',
                          borderRadius: '10px',
                          objectFit: 'cover',
                        }}
                      />
                      <div className="overlay rounded-top bg-dark"></div>
                    </div>
                  </Card>
                </Link>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default ProfessionalDevelopmentPage;
