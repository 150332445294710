import corporate from '../../assets/images/demo/corporate.png';
import crypto from '../../assets/images/demo/crypto.png';
import shop from '../../assets/images/demo/shop.png';
import portfolio from '../../assets/images/demo/portfolio.png';
import helpCenter from '../../assets/images/demo/help-center.png';
import hosting from '../../assets/images/demo/hosting.png';
import job from '../../assets/images/demo/job.png';
import forums from '../../assets/images/demo/forums.png';
import blog from '../../assets/images/demo/blog.png';
import nft from '../../assets/images/demo/nft.png';
import mentorIcon from '../../assets/images/mentorship/mentor-icon-1.png';
import homeIcon from '../../assets/images/Newemoji/homeblue.png';
import communityIcon from '../../assets/images/Newemoji/Community.png';
import messageIcon from '../../assets/images/Newemoji/messageblue.png';
import worldIcon from '../../assets/images/Newemoji/worldblue.png';
import serviceIcon from '../../assets/images/Newemoji/service1.png';
import blogIcon from '../../assets/images/Newemoji/Blogsblue.png';
import notificationIcon from '../../assets/images/Newemoji/notification (2).png';
import partnerIcon from '../../assets/images/Newemoji/lifepartnerblue.png';
import eventIcon from '../../assets/images/event/eventIcon.png';  
import eventnavicon from '../../assets/images/event/event_icon.png';
import { Calendar } from 'lucide-react'; // Add this import

const keyConstantMenus = [
  {
    id: 1,
    title: 'Login',
    link: '/user-login-page',
    icon: 'log-in',
    linkClassName: 'rounded btn btn-warning py-1 d-flex',
    styles: {
      backgroundColor: '#ff7f00',
      color: 'white',
    },
  },
  {
    id: 2,
    title: 'Register',
    link: '/user-registration-page',
    icon: 'edit',
    linkClassName: 'rounded btn btn-primary py-1 d-flex',
  },
];

const keyConstantMenusAfterLogin = [
  {
    id: 1,
    title: 'Messaging',
    link: '/chats',
    icon: 'message-circle',
    linkClassName: 'rounded btn btn-warning py-1 ',
    liClassName: '',
  },
  {
    id: 2,
    title: 'Profile',
    link: '/my-profile-edit-page',
    icon: 'edit',
    linkClassName: 'me="nav-link',
    liClassName: 'nav-item list-inline-item mb-0 d-f',
  },
  {
    id: 3,
    title: 'Logout',
    link: '/user-login-page',
    icon: 'log-out',
    linkClassName: 'me="nav-link',
    liClassName: 'nav-item list-inline-item mb-0 d-f',
  },
];

const navLinks = [
  //Note : each child and nested child must have unique id
  {
    id: 91,
    title: 'Home',
    link: '/',
    linkClassName: ' py-1 desktop-icon',
    imgSrc: homeIcon,
    imgSrcClassName: 'desktop-icon-size',
  },
  {
    id: 92,
    title: 'Services',
    link: '/ServicePage',
    linkClassName: 'py-1 desktop-icon ',
    imgSrc: serviceIcon,
    imgSrcClassName: 'desktop-icon-size',
  },
  {
    id: 93,
    title: 'Mentors',
    link: '/mentorship-page',
    linkClassName: ' py-1 desktop-icon',
    imgSrc: mentorIcon,
    imgSrcClassName: 'desktop-icon-size',
  },
  {
    id: 94,
    title: 'Events',
    link: '/event-listing-page',
    linkClassName: ' py-1 desktop-icon',
    imgSrc: eventIcon,
    imgSrcClassName: 'desktop-icon-size',
  },
  {
    id: 95,
    title: 'Blogs',
    link: '/blogs',
    linkClassName: ' py-1 desktop-icon',
    imgSrc: blogIcon,
    imgSrcClassName: 'desktop-icon-size',
  },
];

// Alliance Seeker
const sessionnavLinks = [
  //Note : each child and nested child must have unique id
  {
    id: 1,
    title: 'Home',
    link: '/allainceseeker-home-page',
    logowidth: '18px',
    img: 'home',
    icon: 'fa-home',
    linkClassName: 'py-1  blacktxt desktop-icon',
    imgSrc: homeIcon,
    imgSrcClassName: 'desktop-icon-size',
  },
  {
    id: 2,
    title: 'Community',
    link: '/communitymember-home-page ',
    logowidth: '18px',
    isMegaMenu: true,
    icon: 'fa-sitemap',
    linkClassName: 'py-1 desktop-icon purpletxt',
    imgSrc: communityIcon,
    imgSrcClassName: 'desktop-icon-size',
  },

  {
    id: 5,
    title: 'My World',
    link: '',
    img: 'home',
    icon: 'edit',
    linkClassName:
      'py-1  for-dropdown  darktxt justify-content-start desktop-icon',
    imgSrc: worldIcon,
    imgSrcClassName: 'desktop-icon-size',
  },
  {
    id: 4,
    title: 'Events',
    link: '/event-listing-page',
    logowidth: '18px',
    img: 'messages',
    icon: 'fa-home',
    linkClassName: 'py-1  lighttxt desktop-icon',
    imgSrc: eventnavicon,
    imgSrcClassName: 'desktop-icon-size',
  },
  {
    id: 7,
    title: 'Mentorship',
    link: '#',
    isMentorship: true,
    imgSrc: worldIcon,
    imgSrcClassName: 'desktop-icon-size',
    linkClassName: 'py-1  for-dropdown  darktxt justify-content-start desktop-icon',
    hasDropdown: true
  },
];

// Community Members
const communityMsessionnavLinks = [
  //Note : each child and nested child must have unique id
  {
    id: 1,
    title: 'Home',
    link: '/communitymember-home-page',
    logowidth: '18px',
    img: 'home',
    icon: 'edit',
    linkClassName: ' py-1 blacktxt desktop-icon',
    imgSrc: homeIcon,
    imgSrcClassName: 'desktop-icon-size',
  },
  {
    id: 2,
    title: 'Life-Partner',
    link: '/allainceseeker-home-page',
    isMegaMenu: true,
    logowidth: '18px',
    img: 'home',
    icon: 'edit',
    linkClassName: ' py-1 desktop-icon  purpletxt',
    imgSrc: partnerIcon,
    imgSrcClassName: 'desktop-icon-size',
  },

  {
    id: 5,
    title: 'My World',
    link: '',
    img: 'home',
    icon: 'edit',
    linkClassName:
      '  for-dropdown py-1 text-dark darktxt justify-content-start desktop-icon',
    imgSrc: worldIcon,
    imgSrcClassName: 'desktop-icon-size',
  },
  {
    id: 4,
    title: 'Events',
    link: '/event-listing-page',
    logowidth: '18px',
    img: 'messages',
    icon: 'fa-home',
    linkClassName: ' py-1 desktop-icon lighttxt',
    imgSrc: eventnavicon,
    imgSrcClassName: 'desktop-icon-size',
  },
  {
    id: 7,
    title: 'Mentorship',
    link: '#',
    isMentorship: true,
    imgSrc: worldIcon,
    imgSrcClassName: 'desktop-icon-size',
    linkClassName: 'py-1  for-dropdown  darktxt justify-content-start desktop-icon',
    hasDropdown: true
  },
];

export {
  navLinks,
  sessionnavLinks,
  communityMsessionnavLinks,
  keyConstantMenus,
  keyConstantMenusAfterLogin,
};
