import ReactQuill from 'react-quill-new';
import 'react-quill-new/dist/quill.snow.css';

import React, { useState } from 'react';
import { Calendar, MessageSquare, Video, ExternalLink } from 'lucide-react';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import {
  Row,
  Col,
  CardBody,
  Card,
  Label,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  PaginationItem,
  PaginationLink,
  Alert,
  Fade,
  Progress,
} from 'reactstrap';
import Slider from 'react-slick';
import manavatar from '../../../assets/images/MettaMateImages/male_avatar.jpg';
import '../../../assets/css/mentorship.css';
import axios from 'axios';
import apiUrl from '../../server.js';

const UnifiedMentorCard = ({ mentorDetails, isAuthenticated }) => {
  // Quill editor configurations
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link'],
      ['clean'],
    ],
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'list',
    'bullet',
    'link',
  ];

  // alert('MentorDetails=' + JSON.stringify(mentorDetails));
  const [modal, setModal] = useState(false);
  const [mentorBiodata, setMentorBiodata] = useState(false);
  const [mentorCategories, setMentorCategories] = useState(false);
  const toggleMentorCategoriesModal = () =>
    setMentorCategories(!mentorCategories);

  const toggleModal = () => setModal(!modal);

  const isUserAuthenticated = () => {
    return isAuthenticated && 
      localStorage.getItem('token') && 
      localStorage.getItem('memberID');  
  };

  const toggleMentorBiodataModal = () => {
    setMentorBiodata(!mentorBiodata);
    if (!mentorBiodata && isUserAuthenticated()) {
      fetchConnectionStatus();
    }
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };

  // Add these new states
  const [confirmConnectionModalOpen, setConfirmConnectionModalOpen] =
    useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);
  const [connectionStatus, setConnectionStatus] = useState(
    mentorDetails.connectionstatus || 'Register & Connect',
  );

  // Add these new handler functions
  const toggleConnectionModal = () => {
    if (connectionStatus === 'Register & Connect') {
      window.location.href = 'https://MettaMate.ai/user-registration-page';
    } else {
      setConfirmConnectionModalOpen(!confirmConnectionModalOpen);
    }
  };

  const fetchConnectionStatus = async () => {
    try {
      let request = {
        connectedMemberUUID: mentorDetails.mentor_uuid,
        memberUUID: localStorage.getItem('memberID'),
      };

      const result = await axios.post(
        `${apiUrl}connection/checkconnectionstatus`,
        request,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );

      const status = result.data.status;
      let statusLabel = '';

      switch (status) {
        case 2:
          statusLabel = 'Connected';
          break;
        case 1:
          statusLabel = 'Pending Connection';
          break;
        case 3:
        case 4:
          statusLabel = 'Not available for Connection';
          break;
        default:
          statusLabel = 'Initiate Connection';
      }

      setConnectionStatus(statusLabel);
    } catch (error) {
      console.error('Fetch connection status error:', error);
    }
  };

  // Call fetchConnectionStatus when component mounts
  React.useEffect(() => {
    // fetchConnectionStatus();
  }, []);

  const updateConnection = async () => {
    try {
      let request = {
        connectedmemberuuid: mentorDetails.mentor_uuid, // Using mentor_uuid
        memberuuid: localStorage.getItem('memberID'), // Using memberuuid from localStorage
      };

      const result = await axios.post(
        `${apiUrl}connection/requestforconnection`,
        request,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      );

      setConfirmConnectionModalOpen(false);
      setConnectionStatus('Connecting');
      setSuccess({
        title: 'SUCCESS!',
        message: result.data.message,
      });
    } catch (error) {
      console.error('Connection error:', error);
      setConfirmConnectionModalOpen(false);
      setError({
        title: 'Failed!',
        message:
          error.response?.data?.message || 'Unable to send connection request',
      });
    }
  };

  const renderFooterButtons = () => {
    return (
      <>
        {connectionStatus !== 'Connected' && (
          <Button color="success" onClick={toggleConnectionModal} 
          disabled={connectionStatus === 'Not available for Connection'}> 
            <span>
              <i className="fa fa-handshake-o" aria-hidden="true"></i> 
              <span>{' '}</span>{connectionStatus || 'Connect'}
            </span>
          </Button>
        )}

        <Button
          color="primary"
          onClick={() =>
            window.open(
              'https://wa.me/' +
                mentorDetails.phonecountrycode +
                mentorDetails.phonenumber,
            )
          }
          disabled={connectionStatus !== 'Connected'}
        >
          <span>
            <i className="fa fa-whatsapp" aria-hidden="true"></i> 
            <span>{' '}</span>Message
          </span>
        </Button>

        <Button color="secondary" onClick={toggleMentorBiodataModal}>
          Close
        </Button>
      </>
    );
  };

  // alert(JSON.stringify(mentor));
  return (
    <div className="mb-5">
      <Card className="catagories rounded rounded-3 overflow-hidden border-1 border-primary job-box job-primary candidate-list border-0 shadow card">
        <CardBody className="rounded overflow-hidden p-0 ">
          <Slider className="imageSlider image-container" {...settings}>
            <img
              src={mentorDetails.photothumb1}
              className="img-fluid img_thumb"
            />
          </Slider>
        </CardBody>
      </Card>
      <Card className="catagories rounded rounded-3 mt-1 shadow">
        <CardBody className="rounded shadow p-2 fixHeight">
          <p className="text-dark h5 name mb-0">
            <span style={{ color: `#0D6EFD` }}>{mentorDetails.firstname}</span>
          </p>
          <p className="fs-5 mb-1 mt-1">{mentorDetails.mentor_title}</p>
          <p className="fs-6 mb-1 mt-1">
            {mentorDetails.jobtitle || 'Empty Job Title'}
          </p>

          <p className="fs-7 mb-1 mt-1">
            <FeatherIcon icon="map-pin" className="fea icon-sm me-1" />
            {mentorDetails.currentlocationname ||
              mentorDetails.nativelocationname ||
              'Location Not Found'}
          </p>
          {/* Skillset */}

          <div className="d-flex justify-content-center py-2">
            {mentorDetails.subcategories?.length > 0 && (
              <div>
                <Link
                  className="rounded bg-soft-primary py-1 px-2 fs-7"
                  title={mentorDetails.subcategories[0].subcategoryname}
                >
                  {mentorDetails.subcategories[0].subcategoryname}
                </Link>
                {mentorDetails.subcategories.length > 1 && (
                  <button
                    className="btn btn-link"
                    onClick={() => toggleMentorCategoriesModal()}
                    style={{ fontSize: `12px` }}
                  >
                    +{mentorDetails.subcategories.length - 1} more
                  </button>
                )}
              </div>
            )}
            {(!mentorDetails.subcategories ||
              mentorDetails.subcategories.length === 0) && (
              <Link className="rounded bg-soft-primary py-1 px-2 fs-7 mt-2">
                Specializations Not Mentioned
              </Link>
            )}
          </div>

          <span className="text-muted">
            <FeatherIcon icon="briefcase" className="fea icon-sm me-1" />
            Experience{' '}
            <span className="text-success">
              {mentorDetails.years_of_experience}+ years
            </span>
          </span>
          <div className=" d-flex justify-content-center">
            <div className="m-1">
              <Button
                color="primary"
                style={{ height: '35px', fontSize: '14px' }}
                onClick={toggleMentorBiodataModal}
              >
                Explore Mentor
              </Button>
            </div>
          </div>
        </CardBody>
      </Card>
      {/** Mentor bioddata **/}
      {
        <Modal
          isOpen={mentorBiodata}
          toggle={toggleMentorBiodataModal}
          size="lg"
        >
          <ModalHeader toggle={toggleMentorBiodataModal}>
            {mentorDetails.firstname}'s Brief Profile
          </ModalHeader>
          <ModalBody>
            {/* Bio Section */}
            <div className="profile-section mb-4">
              <h5 className="d-flex align-items-center mb-3">
                <i className="fas fa-user-circle text-primary me-2"></i>
                Professional Bio
              </h5>
              <div className="bio-content px-3">
                <ReactQuill
                  theme="bubble"
                  value={mentorDetails.mentor_bio}
                  readOnly={true}
                  style={{ minHeight: '100px', fontSize: '16px' }}
                />
              </div>
            </div>

            {/* Mentorship Type Section */}
            {isAuthenticated && (
              <div className="mentorship-type-section mb-4">
                <h5 className="d-flex align-items-center mb-3">
                  <i className="fas fa-handshake text-success me-2"></i>
                  Mentorship Type
                </h5>
                <div className="px-3">
                  <div className="d-flex align-items-center">
                    <i
                      className={`fas fa-${mentorDetails.is_paid_mentorship ? 'rupee-sign text-warning' : 'heart text-danger'} me-2`}
                    ></i>
                    <span>
                      {mentorDetails.is_paid_mentorship
                        ? `Paid Mentorship - ₹ ${mentorDetails.hourly_rate}/hour`
                        : 'Volunteer Mentorship'}
                    </span>
                  </div>
                </div>
              </div>
            )}

            {/* Availability Schedule */}
            {isAuthenticated && (
              <div className="availability-section">
                <h5 className="d-flex align-items-center mb-3">
                  <i className="fas fa-calendar-alt text-info me-2"></i>
                  Weekly Availability
                </h5>
                <div className="schedule-grid px-3">
                  <Row>
                    {mentorDetails.availability?.map((schedule) => (
                      <Col md={12} key={schedule.day_of_week}>
                        <div className="schedule-item d-flex align-items-center mb-2 p-2 bg-light rounded">
                          <div className="d-flex w-100 justify-content-between align-items-center">
                            <div className="d-flex align-items-center">
                              <i className="fas fa-clock text-primary me-3"></i>
                              <div>
                                <strong
                                  className="me-2"
                                  style={{
                                    minWidth: '100px',
                                    display: 'inline-block',
                                  }}
                                >
                                  {schedule.day_of_week}
                                </strong>
                                <span className="text-muted">
                                  {schedule.start_time} - {schedule.end_time}
                                </span>
                              </div>
                            </div>
                            <span className="badge bg-success">Available</span>
                          </div>
                        </div>
                      </Col>
                    ))}
                    {(!mentorDetails.availability ||
                      mentorDetails.availability.length === 0) && (
                      <Col md={12}>
                        <div className="text-center text-muted p-3">
                          <i className="fas fa-calendar-times me-2"></i>
                          Schedule not specified
                        </div>
                      </Col>
                    )}
                  </Row>
                </div>
              </div>
            )}
          </ModalBody>
          <ModalFooter>{renderFooterButtons()}</ModalFooter>
        </Modal>
      }

      {
        <Modal isOpen={mentorCategories} toggle={toggleMentorCategoriesModal}>
          <ModalHeader toggle={toggleMentorCategoriesModal}>
            {mentorDetails.firstname}'s Expertise
          </ModalHeader>
          <ModalBody>
            {/* Areas of Expertise Section */}
            <div className="expertise-section mb-4">
              <h5 className="d-flex align-items-center mb-3">
                <i className="fas fa-star text-warning me-2"></i>
                Areas of Expertise
              </h5>
              <div className="categories-list ms-3">
                {mentorDetails.categories?.map((category) => (
                  <div
                    key={category.categoryid}
                    className="category-item d-flex align-items-center py-2"
                  >
                    <i className="fas fa-check-circle text-success me-2"></i>
                    <span>{category.categoryname}</span>
                  </div>
                ))}
                {(!mentorDetails.categories ||
                  mentorDetails.categories.length === 0) && (
                  <div className="text-muted">
                    No areas of expertise specified
                  </div>
                )}
              </div>
            </div>

            {/* Specializations Section */}
            <div className="specializations-section">
              <h5 className="d-flex align-items-center mb-3">
                <i className="fas fa-certificate text-primary me-2"></i>
                Specializations
              </h5>
              <div className="subcategories-list ms-3">
                {mentorDetails.subcategories?.map((subcategory) => (
                  <div
                    key={subcategory.subcategoryid}
                    className="subcategory-item d-flex align-items-center py-2"
                  >
                    <i className="fas fa-arrow-right text-secondary me-2"></i>
                    <span>{subcategory.subcategoryname}</span>
                  </div>
                ))}
                {(!mentorDetails.subcategories ||
                  mentorDetails.subcategories.length === 0) && (
                  <div className="text-muted">No specializations specified</div>
                )}
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleMentorCategoriesModal}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      }

      {/* Add this modal for connection confirmation */}
      <Modal isOpen={confirmConnectionModalOpen} toggle={toggleConnectionModal}>
        <ModalHeader toggle={toggleConnectionModal}>
          Confirmation
          <button
            type="button"
            className="close-button"
            aria-label="Close"
            onClick={toggleConnectionModal}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col lg="12">
              <p className="mb-0">Are You Sure to Connect?</p>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="p-1">
          <Button color="secondary" onClick={toggleConnectionModal}>
            Close
          </Button>
          <Button color="success" onClick={updateConnection}>
            Connect
          </Button>
        </ModalFooter>
      </Modal>

      {/* Add success/error alerts */}
      {success && (
        <Modal isOpen={!!success} toggle={() => setSuccess(null)}>
          <ModalHeader toggle={() => setSuccess(null)}>Success</ModalHeader>
          <ModalBody>{success.message}</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => setSuccess(null)}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      )}

      {error && (
        <Modal isOpen={!!error} toggle={() => setError(null)}>
          <ModalHeader toggle={() => setError(null)}>Error</ModalHeader>
          <ModalBody>{error.message}</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => setError(null)}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      )}
    </div>
  );
};

export default UnifiedMentorCard;
