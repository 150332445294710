import React from 'react';
//Import Images
import candidate1 from '../../../assets/images/mettamate/snehalataKamble1.jpeg';
import candidate2 from '../../../assets/images/Newemoji/pallavi5.jpg';
import drpallavi from '../../../assets/images/Newemoji/DrPallaviCutHead.jpg';
import coo from '../../../assets/images/Newemoji/COO.jpg';
import candidate3 from '../../../assets/images/mettamate/raghuKiran.jpeg';
import candidate4 from '../../../assets/images/testimonies/datasahebTandale2.png';
import photodump from '../../../assets/images/Newemoji/male_avatar.jpg';
import photodumpfemale from '../../../assets/images/Newemoji/female_avatar.c085b7ebc7600153a498.jpeg';
import antra from '../../../assets/images/Newemoji/Antra2.jpg';
import etmember from '../../../assets/images/Newemoji/rahul2.jpg';
import bod3 from '../../../assets/images/Newemoji/hitesh.jpg';
import linkedInIcon from '../../../assets/images/Newemoji/linkedin2.png';
import instaIcon from '../../../assets/images/Newemoji/instagram.png';

import {
  Container,
  Col,
  Input,
  Label,
  Button,
  Form,
  FormFeedback,
  Card,
  CardTitle,
  CardText,
  CardBody,
  CardImg,
} from 'reactstrap';
import { Row } from 'reactstrap';
import '../../Home/css/style.css';

const LeadershipTeam = () => {
  // Testimonials Component Logic (Integrated)
  const executiveteam = [
    {
      id: 1,
      image: drpallavi,
      name: 'Dr. Pallavi G. K.',
      designation: 'Founder & Chief Executive Officer ',
      link: '',
      socialIcon: {
        src: linkedInIcon, // LinkedIn icon image
        url: 'https://www.linkedin.com/in/dr-pallavi-g-k-06825310a/', // LinkedIn URL
      },
    },
    {
      id: 2,
      image: etmember,
      name: 'Rahul Deepak Dongre',
      designation: 'Chief Growth Officer',
      link: '',
      socialIcon: {
        src: linkedInIcon, // LinkedIn icon image
        url: 'https://www.linkedin.com/in/rahul-dongre-32125a4a/', // LinkedIn URL
      },
    },
    {
      id: 3,
      image: antra,
      name: 'Antra Gajbhiye',
      designation: 'Chief Relationship Officer',
      socialIcon: {
        src: linkedInIcon, // LinkedIn icon image
        url: 'https://www.linkedin.com/in/antragajbhiye/', // LinkedIn URL
      },
    },
  ];
  const boardmembers = [
    {
      id: 6,
      image: coo,
      name: 'Dharam Vir Singh (Ret. IFS)',
      designation: 'Board Member',
      link: '',
      socialIcon: {
        src: linkedInIcon, // LinkedIn icon image
        url: 'https://www.linkedin.com/in/dharam-vir-singh-671b75123/', // LinkedIn URL
      },
    },
    {
      id: 5,
      image: candidate1,
      name: 'Prof. Snehalata Kamble',
      designation: 'Board Member',
      link: '',
      socialIcon: {
        src: linkedInIcon, // LinkedIn icon image
        url: 'https://www.linkedin.com/in/sneha-k-b9abb1263/', // LinkedIn URL
      },
    },

    {
      id: 7,
      image: bod3,
      name: 'Hitesh Kumar',
      designation: 'Board Member',
      socialIcon: {
        src: instaIcon,
        url: 'https://www.instagram.com/theoutcastindia/?igsh=emxkOHQ1YnpyczNr#',
      },
    },
  ];
  const digitalpartners = [
    {
      id: 5,
      image: candidate1,
      name: 'Prof. Snehalata Kamble',
      designation: 'xyz',
      link: '',
      socialIcon: {
        src: linkedInIcon, // LinkedIn icon image
        url: '#', // LinkedIn URL
      },
    },

    {
      id: 6,
      image: antra,
      name: 'Antra Gajbhiye',
      designation: 'xyz',
      link: '',
      socialIcon: {
        src: linkedInIcon, // LinkedIn icon image
        url: '#', // LinkedIn URL
      },
    },
    {
      id: 7,
      image: candidate3,
      name: 'Raghu Kiran Bolle',
      designation: 'xyz',
      socialIcon: {
        src: linkedInIcon, // LinkedIn icon image
        url: '#', // LinkedIn URL
      },
    },
  ];
  return (
    <>
      <section className=" bg-white">
        <Container className="leadership-main">
          <Row className="justify-content-center">
            <Col>
              <h3 className="text-center">
                Meet our Executive Leadership Team
              </h3>
            </Col>
          </Row>
          <Row className="justify-content-center">
            {executiveteam.map((executiveteam) => (
              <Col
                key={executiveteam.id}
                xs={12}
                sm={6}
                md={4}
                lg={3}
                className="d-flex justify-content-center mb-4"
              >
                <div
                  className="testimonial-card text-center border border-primary"
                  style={{
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    borderRadius: '10px',
                    overflow: 'hidden',
                  }}
                >
                  <div
                    className="image-container mt-3"
                    style={{ height: '200px', overflow: 'hidden' }}
                  >
                    <img
                      src={executiveteam.image}
                      alt={executiveteam.name}
                      className="img-fluid"
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                      }}
                    />
                  </div>
                  <div className="details-container p-3">
                    <p className="text-dark fs-5 style-font name mb-0 pt-2">
                      <span style={{ color: `#0D6EFD` }}>
                        {executiveteam.name}{' '}
                      </span>{' '}
                    </p>
                    <p className="text-dark fs-6 style-font name mb-0 pt-2">
                      <span style={{ color: `#000000` }}>
                        {executiveteam.designation}
                      </span>{' '}
                    </p>
                    <div className="justify-content-center align-items-center mb-1">
                      {executiveteam.socialIcon &&
                        executiveteam.socialIcon.src && (
                          <a
                            href={executiveteam.socialIcon.url}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ marginLeft: '10px' }}
                          >
                            <img
                              src={executiveteam.socialIcon.src}
                              className="mt-2 social-linkedin"
                              alt="LinkedIn Icon"
                              style={{ width: '25px', height: '25px' }}
                            />
                          </a>
                        )}
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>

          <hr />
          <Row className="justify-content-center">
            <Col>
              <h3 className="text-center">Board Members</h3>
            </Col>
          </Row>
          <Row className="justify-content-center">
            {boardmembers.map((boardmembers) => (
              <Col
                key={boardmembers.id}
                xs={12}
                sm={6}
                md={4}
                lg={3}
                className="d-flex justify-content-center mb-4"
              >
                <div
                  className="testimonial-card text-center border border-primary"
                  style={{
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    borderRadius: '10px',
                    overflow: 'hidden',
                  }}
                >
                  <div
                    className="image-container mt-3"
                    style={{ height: '200px', overflow: 'hidden' }}
                  >
                    <img
                      src={boardmembers.image}
                      alt={boardmembers.name}
                      className="img-fluid"
                      style={{
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                      }}
                    />
                  </div>
                  <div className="details-container p-3">
                    <p className="text-dark fs-5 style-font name mb-0 pt-2">
                      <span style={{ color: `#0D6EFD` }}>
                        {boardmembers.name}{' '}
                      </span>{' '}
                    </p>
                    <p className="text-dark fs-6 style-font name mb-0 pt-2">
                      <span style={{ color: `#000000` }}>
                        {boardmembers.designation}
                      </span>{' '}
                    </p>
                    {boardmembers.socialIcon && boardmembers.socialIcon.src && (
                      <a
                        href={boardmembers.socialIcon.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ marginLeft: '10px' }}
                      >
                        <img
                          src={boardmembers.socialIcon.src}
                          className="mt-1 social-linkedin"
                          alt="LinkedIn Icon"
                          style={{ width: '25px', height: '25px' }}
                        />
                      </a>
                    )}
                  </div>
                </div>
              </Col>
            ))}
          </Row>
          {/* <hr />
          <Row className="justify-content-center">
            <Col>
              <h2 className="text-center">Digital Media Partners</h2>
            </Col>
          </Row>
          <Row className="justify-content-center">
            {digitalpartners.map((digitalpartner) => (
              <Col
                key={digitalpartner.id}
                xs={12}
                sm={6}
                md={4}
                lg={3}
                className="d-flex justify-content-center mb-4"
              >
                <div className="testimonial-card text-center" style={{ boxShadow: '4px 4px 10px rgba(0, 0, 0, 0.2)' }}>
                  <div className="image-container">
                    <img
                      src={digitalpartner.image}
                      alt={digitalpartner.name}
                      className="img-fluid"
                      style={{
                        width: '280px',
                        height: '280px',
                        objectFit: 'cover',
                        border: '1px solid blue'
                      }}
                    />
                  </div>

                  <div className="details-container" >
                    <h4 className="testimonial-name">{digitalpartner.name}</h4>
                    <div className='d-flex justify-content-center align-items-center mb-2'>
                      <h5 className="designation">{digitalpartner.designation}</h5>
                      {digitalpartner.socialIcon && digitalpartner.socialIcon.src && (
                        <a
                          href={digitalpartner.socialIcon.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{ marginLeft: '10px' }}
                        >
                          <img
                            src={digitalpartner.socialIcon.src}
                            className="mt-1 social-linkedin"
                            alt="LinkedIn Icon"
                            style={{ width: '25px', height: '25px', marginLeft: '10px' }}
                          />
                        </a>
                      )}
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </Row> */}
        </Container>
      </section>
    </>
  );
};

export default LeadershipTeam;
