import ReactQuill, { Quill } from 'react-quill-new';
import 'react-quill-new/dist/quill.snow.css';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaRupeeSign, FaHeart } from 'react-icons/fa'; // Change the import
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  CardBody,
  Label,
  Button,
  Input,
  FormFeedback,
  CardTitle,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { useFormik } from 'formik';
import Select from 'react-select';
import axios from 'axios';
import '../css/userHomePage.css';
import apiUrl from '../../server';
import appUrl from '../../config';
import razorKeyID from '../../config';

import CommonModal from '../modal';
import { Link } from 'react-router-dom';
import {
  Table,
  ControlledCarousel,
  UncontrolledCollapse,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from 'reactstrap';

import { User, Info, DollarSign, Clock, Eye } from 'feather-icons-react';
import {
  FaUserEdit,
  FaUserTie,
  FaUserCheck,
  FaBriefcase,
  FaListAlt,
  FaInfoCircle,
  FaDollarSign,
  FaClock,
  FaEye,
} from 'react-icons/fa';

import { initiatePayment } from '../../../utils/paymentUtils';

const iconStyle = { color: '#0D6EFD' };

// Register the "bullet" format
const Block = Quill.import('blots/block');
Block.tagName = 'DIV';
Quill.register(Block, true);

// Register the "list" format which includes "bullet"
const List = Quill.import('formats/list');
Quill.register(List, true);

const BecomeMentor = () => {
  // Function to dynamically load the Razorpay script
  const loadRazorpayScript = () => {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = 'https://checkout.razorpay.com/v1/checkout.js';
      script.onload = () => resolve(true);
      script.onerror = () => reject(false);
      document.body.appendChild(script);
    });
  };

  const navigate = useNavigate();

  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);

  const [mentorStatus, setMentorStatus] = useState(null); // 'pending', 'approved', 'rejected', or null
  const [isEditing, setIsEditing] = useState(false);
  const [existingMentorData, setExistingMentorData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isPaymentPending, setIsPaymentPending] = useState(false);

  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [skipPayment, setSkipPayment] = useState(false);

  const togglePaymentModal = () => setShowPaymentModal(!showPaymentModal);

  // Inside your form component
  const [editorContent, setEditorContent] = useState('');

  // Quill editor configurations
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link'],
      ['clean'],
    ],
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'list',  // Remove 'bullet' from formats as it's included in 'list'
    'link',
  ];

  useEffect(() => {
    // Load the Razorpay script when the component is mounted
    loadRazorpayScript().then((success) => {
      if (success) {
        console.log('Razorpay script loaded successfully.');
        // You can initialize Razorpay here if needed
      } else {
        console.error('Failed to load Razorpay script.');
      }
    });
    fetchCategories();
    checkMentorStatus();
  }, []);

  const checkMentorStatus = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(apiUrl + 'mentorship/mentor-status', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      });

      if (response.data.isMentor) {
        setMentorStatus(response.data.status);
        // alert(JSON.stringify(response.data.mentorDetails));
        // alert("existingMentorData.availability : "+JSON.stringify(response.data.mentorDetails.availability));
        setExistingMentorData(response.data.mentorDetails);
        // Pre-fill the form with existing data
        setSelectedCategories(
          response.data.mentorDetails.categories.map((cat) => ({
            value: cat.categoryid,
            label: cat.categoryname,
          })),
        );
        // Fetch and set subcategories based on existing categories
        await fetchSubCategories(
          response.data.mentorDetails.categories.map((cat) => cat.categoryid),
        );
        setSelectedSubCategories(
          response.data.mentorDetails.subcategories.map((subCat) => ({
            value: subCat.subcategoryid,
            label: subCat.subcategoryname,
          })),
        );
      }
    } catch (error) {
      console.error('Error checking mentor status:', error);
      setError({
        title: 'Error',
        message: 'Failed to fetch mentor status',
      });
      if (error.response?.status === 401) {
        navigate('/user-login-page');
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await axios.get(apiUrl + 'mentorship/categories', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      });
      // alert(JSON.stringify(response.data));
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const fetchSubCategories = async (categoryIds) => {
    try {
      const response = await axios.get(
        apiUrl + `mentorship/subcategories?categories=${categoryIds.join(',')}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        },
      );
      setSubCategories(response.data.subCategories);
    } catch (error) {
      console.error('Error fetching subcategories:', error);
    }
  };

  const handleCategoryChange = (selected) => {
    setSelectedCategories(selected);
    if (selected.length > 0) {
      fetchSubCategories(selected.map((cat) => cat.value));
    } else {
      setSubCategories([]);
      setSelectedSubCategories([]);
    }
  };

  // Helper function to create a full week structure
  const createEmptyWeekStructure = () => ({
    Monday: { isAvailable: false, startTime: '', endTime: '' },
    Tuesday: { isAvailable: false, startTime: '', endTime: '' },
    Wednesday: { isAvailable: false, startTime: '', endTime: '' },
    Thursday: { isAvailable: false, startTime: '', endTime: '' },
    Friday: { isAvailable: false, startTime: '', endTime: '' },
    Saturday: { isAvailable: false, startTime: '', endTime: '' },
    Sunday: { isAvailable: false, startTime: '', endTime: '' },
  });

  const formatAvailabilityData = (apiAvailability) => {
    // If we get the null entry array, return empty week structure
    if (
      apiAvailability?.length === 1 &&
      apiAvailability[0].day_of_week === null
    ) {
      return createEmptyWeekStructure();
    }

    // Otherwise, process the actual availability data
    return {
      ...createEmptyWeekStructure(),
      ...Object.fromEntries(
        apiAvailability.map((schedule) => [
          schedule.day_of_week.charAt(0).toUpperCase() +
            schedule.day_of_week.slice(1),
          {
            isAvailable: true,
            startTime: schedule.startTime || '',
            endTime: schedule.endTime || '',
          },
        ]),
      ),
    };
  };

  const handlePaymentSuccess = async (paymentResponse) => {
    try {
      const mentorData = {
        ...validation.values,
        categories: selectedCategories.map((cat) => cat.value),
        subCategories: selectedSubCategories.map((subCat) => subCat.value),
        paymentResponse, // Include payment details
      };

      // alert('Mentor Data ' + JSON.stringify(mentorData));
      const response = await axios.post(
        apiUrl + 'mentorship/become-mentor',
        mentorData,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        },
      );

      if (response.status === 200) {
        setSuccess({
          title: 'Success!',
          message: 'Your mentor profile has been created successfully',
        });
      }
    } catch (error) {
      setError({
        title: 'Error!',
        message: error.response?.data?.message || 'Something went wrong',
      });
    } finally {
      setIsPaymentPending(false);
    }
  };

  const handleSubmit = async (values) => {
    if (isEditing) {
      // Handle edit submission
      try {
        const mentorData = {
          ...values,
          categories: selectedCategories.map((cat) => cat.value),
          subCategories: selectedSubCategories.map((subCat) => subCat.value),
        };

        const response = await axios.post(
          apiUrl + `mentorship/update-mentor/${existingMentorData.mentor_uuid}`,
          mentorData,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + localStorage.getItem('token'),
            },
          },
        );

        if (response.status === 200) {
          setSuccess({
            title: 'Success!',
            message: 'Your mentor profile has been updated successfully',
          });
          setIsEditing(false);
          checkMentorStatus(); // Refresh the data
        }
      } catch (error) {
        setError({
          title: 'Error!',
          message: error.response?.data?.message || 'Something went wrong',
        });
      }
    } else {
      if (values.isPaidMentorship && values.hourlyRate > 0) {
        setShowPaymentModal(true);
      } else {
        submitMentorData(values);
      }
    }
  };

  const submitMentorData = async (values) => {
    setIsPaymentPending(true);
    try {
      if (skipPayment) {
        await handlePaymentSuccess(null);
      } else {
        await initiatePayment(249, handlePaymentSuccess, apiUrl, appUrl);
      }
    } catch (error) {
      setError({
        title: 'Payment Error',
        message: error.message,
      });
      setIsPaymentPending(false);
    }
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: existingMentorData
      ? {
          mentorTitle: existingMentorData.mentor_title,
          mentorBio: existingMentorData.mentor_bio,
          yearsOfExperience: existingMentorData.years_of_experience,
          isPaidMentorship: existingMentorData?.is_paid_mentorship || false,
          hourlyRate: existingMentorData.hourly_rate,
          isPubliclyVisible: existingMentorData.is_publicly_visible,

          // Merge the empty week structure with existing availability data
          availability: formatAvailabilityData(existingMentorData.availability),
        }
      : {
          mentorTitle: '',
          mentorBio: '',
          yearsOfExperience: '',
          isPaidMentorship: false,
          hourlyRate: 0.0,
          isPubliclyVisible: false, // Add this field
          availability: createEmptyWeekStructure(),
        },

    validate: (values) => {
      const errors = {};

      Object.entries(values.availability).forEach(([day, schedule]) => {
        if (schedule.isAvailable) {
          if (!schedule.startTime || !schedule.endTime) {
            if (!errors.availability) errors.availability = {};
            errors.availability[day] = 'Please specify both start and end time';
          } else if (schedule.startTime >= schedule.endTime) {
            if (!errors.availability) errors.availability = {};
            errors.availability[day] = 'End time must be after start time';
          }
        }
      });

      if (!values.mentorTitle) {
        errors.mentorTitle = 'Title is required';
      }

      if (!values.mentorBio) {
        errors.mentorBio = 'Bio is required';
      } else if (values.mentorBio.length > 512) {
        errors.mentorBio = 'Bio must be less than 512 characters';
      }

      if (values.isPaidMentorship && !values.hourlyRate) {
        errors.hourlyRate = 'Hourly rate is required for paid mentorship';
      }

      return errors;
    },
    onSubmit: handleSubmit,
  });

  const handleCancelEdit = () => {
    navigate('/self-mentor-page');
  };

  if (isLoading) {
    return <div className="text-center p-5">Loading...</div>;
  }

  const renderStatusBadge = () => {
    const statusColors = {
      pending: 'warning',
      approved: 'success',
      rejected: 'danger',
    };

    return (
      mentorStatus && (
        <div className={`badge bg-${statusColors[mentorStatus]} mb-3`}>
          Status: {mentorStatus.charAt(0).toUpperCase() + mentorStatus.slice(1)}
        </div>
      )
    );
  };

  return (
    <React.Fragment>
      {error && (
        <CommonModal
          title={error.title}
          message={error.message}
          onClose={() => setError(null)}
          status="error"
        />
      )}

      {success && (
        <CommonModal
          title={success.title}
          message={success.message}
          onClose={() => {
            setSuccess(null);
            navigate('/self-mentor-page');
          }}
          status="success"
        />
      )}

      <Modal isOpen={showPaymentModal} toggle={togglePaymentModal}>
        <ModalHeader toggle={togglePaymentModal}>
          <FaHeart className="me-2" style={{ color: 'red' }} />
          Support MettaMate
        </ModalHeader>
        <ModalBody>
          <p>
            <strong>Support MettaMate</strong> by paying a small registration fee to list your paid mentorship profile available promptly to all the MettaMate users.
          </p>
          <p>
            Your small donation for doing this registration would help us to sustain the MettaMate application and continue providing valuable services to our community.
          </p>
          <p>
            If you really feel not to pay, then select the option "Unable to pay registration fees" and we will forgive the payment.
          </p>
          <div className="form-check">
            <Input
              type="checkbox"
              className="form-check-input"
              id="skipPayment"
              onChange={() => setSkipPayment(!skipPayment)}
              checked={skipPayment}
            />
            <Label className="form-check-label" htmlFor="skipPayment">
              Unable to pay registration fees
            </Label>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => {
            togglePaymentModal();
            submitMentorData(validation.values);
          }}>
            {skipPayment ? 'Submit Without Payment' : 'Proceed to Payment'}
          </Button>
          <Button color="secondary" onClick={togglePaymentModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>

      <section className="section">
        <Container>
          <Row className="justify-content-center">
            <Col lg={12} xs={12} md={12}>
              <Card className="shadow">
                <CardBody>
                  <div className="d-flex justify-content-between align-items-center mb-4">
                    <CardTitle className="fs-4">
                      {isEditing ? (
                        <>
                          <FaUserEdit className="me-2" style={iconStyle} />
                          Edit Mentor Profile
                        </>
                      ) : existingMentorData ? (
                        <>
                          <FaUserTie className="me-2" style={iconStyle} />
                          Mentor Profile
                        </>
                      ) : (
                        <>
                          <FaUserTie className="me-2" style={iconStyle} />
                          Become a Mentor
                        </>
                      )}
                    </CardTitle>
                    {renderStatusBadge()}
                    {existingMentorData && !isEditing && (
                      <Button
                        color="primary"
                        size="sm"
                        onClick={() => setIsEditing(true)}
                      >
                        Edit Profile
                      </Button>
                    )}
                  </div>
                  <Form onSubmit={validation.handleSubmit}>
                    <Row>
                      <Col md={12}>
                        <div className="mb-3">
                          <Label className="form-label fs-6 ">
                            <FaBriefcase className="me-2" style={iconStyle} />
                            Professional Title{' '}
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            name="mentorTitle"
                            placeholder="e.g., Senior Software Engineer"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.mentorTitle}
                            invalid={
                              validation.touched.mentorTitle &&
                              validation.errors.mentorTitle
                            }
                          />
                          {validation.touched.mentorTitle &&
                            validation.errors.mentorTitle && (
                              <FormFeedback type="invalid">
                                {validation.errors.mentorTitle}
                              </FormFeedback>
                            )}
                        </div>
                      </Col>

                      <Col md={12}>
                        <div className="mb-3">
                          <Label className="form-label fs-6 ">
                            <FaListAlt className="me-2" style={iconStyle} />
                            Categories <span className="text-danger">*</span>
                          </Label>
                          <Select
                            isMulti
                            options={categories.map((cat) => ({
                              value: cat.categoryid,
                              label: cat.categoryname,
                            }))}
                            value={selectedCategories}
                            onChange={handleCategoryChange}
                            className="basic-multi-select fs-6"
                            classNamePrefix="select"
                            styles={{
                              control: (base, state) => ({
                                ...base,
                                minHeight: '60px',
                                maxHeight:
                                  state.selectProps.value?.length > 3
                                    ? '150px'
                                    : 'fit-content',
                                overflow: 'auto',
                              }),
                              valueContainer: (base) => ({
                                ...base,
                                maxHeight: '150px',
                                overflow: 'auto',
                                padding: '2px 8px',
                              }),
                              multiValue: (base) => ({
                                ...base,
                                margin: '4px',
                              }),
                            }}
                          />
                          {/* After the Categories Select */}
                          {validation.touched.categories &&
                            selectedCategories.length === 0 && (
                              <FormFeedback className="d-block">
                                At least one category is required
                              </FormFeedback>
                            )}
                        </div>
                      </Col>

                      {subCategories.length > 0 && (
                        <Col md={12}>
                          <div className="mb-3">
                            <Label className="form-label fs-6">
                              <FaListAlt className="me-2" style={iconStyle} />
                              Specializations{' '}
                              <span className="text-danger">*</span>
                            </Label>
                            <Select
                              isMulti
                              options={subCategories.map((subCat) => ({
                                value: subCat.subcategoryid,
                                label: subCat.subcategoryname,
                              }))}
                              value={selectedSubCategories}
                              onChange={setSelectedSubCategories}
                              className="basic-multi-select fs-6"
                              classNamePrefix="select"
                              styles={{
                                control: (base, state) => ({
                                  ...base,
                                  minHeight: '60px',
                                  maxHeight:
                                    state.selectProps.value?.length > 3
                                      ? '150px'
                                      : 'fit-content',
                                  overflow: 'auto',
                                }),
                                valueContainer: (base) => ({
                                  ...base,
                                  maxHeight: '150px',
                                  overflow: 'auto',
                                  padding: '2px 8px',
                                }),
                                multiValue: (base) => ({
                                  ...base,
                                  margin: '4px',
                                }),
                              }}
                            />
                            {/* After the Subcategories Select */}
                            {validation.touched.subCategories &&
                              selectedCategories.length > 0 &&
                              selectedSubCategories.length === 0 && (
                                <FormFeedback className="d-block">
                                  At least one specialization is required
                                </FormFeedback>
                              )}
                          </div>
                        </Col>
                      )}

                      <Col md={12}>
                        <div className="mb-3">
                          <Label className="form-label fs-5">
                            <FaInfoCircle className="me-2" style={iconStyle} />
                            Professional Bio{' '}
                            <span className="text-danger">*</span>
                          </Label>
                          <ReactQuill
                            theme="snow"
                            value={validation.values.mentorBio}
                            onChange={(content) => {
                              validation.setFieldValue('mentorBio', content);
                            }}
                            modules={modules}
                            formats={formats}
                            style={{
                              height: '200px',
                              marginBottom: '50px',
                              fontSize: '1rem',
                            }}
                          />
                          <small className="text-muted fs-6">
                            {/* Note: You'll need to handle character count differently for HTML content */}
                            Use formatting tools to make your bio more engaging
                          </small>
                        </div>
                      </Col>

                      <Col md={6}>
                        <div className="mb-3">
                          <Label className="form-label fs-6 ">
                            <FaUserCheck className="me-2" style={iconStyle} />
                            Years of Experience{' '}
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="number"
                            className="form-control"
                            name="yearsOfExperience"
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.yearsOfExperience}
                          />
                        </div>
                      </Col>
                      <Col md={6}>
                        <div className="mb-3">
                          <Label className="form-label fs-6 ">
                            <FaRupeeSign className="me-2" style={iconStyle} />
                            Mentorship Type
                          </Label>
                          <div className="d-flex flex-column gap-2">
                            <div className="form-check">
                              <Input
                                type="radio"
                                className="form-check-input"
                                name="isPaidMentorship"
                                id="volunteerMentorship"
                                onChange={() =>
                                  validation.setFieldValue(
                                    'isPaidMentorship',
                                    false,
                                  )
                                }
                                checked={!validation.values.isPaidMentorship}
                              />
                              <Label
                                className="form-check-label fs-6"
                                htmlFor="volunteerMentorship"
                              >
                                Volunteer Mentorship
                              </Label>
                            </div>
                            <div className="form-check">
                              <Input
                                type="radio"
                                className="form-check-input"
                                name="isPaidMentorship"
                                id="paidMentorship"
                                onChange={() =>
                                  validation.setFieldValue(
                                    'isPaidMentorship',
                                    true,
                                  )
                                }
                                checked={validation.values.isPaidMentorship}
                              />
                              <Label
                                className="form-check-label fs-6"
                                htmlFor="paidMentorship"
                              >
                                Paid Mentorship
                              </Label>
                            </div>
                          </div>
                        </div>
                      </Col>

                      {validation.values.isPaidMentorship && (
                        <Col md={6}>
                          <div className="mb-3">
                            <Label className="form-label fs-6 ">
                              <FaRupeeSign className="me-2" style={iconStyle} />
                              Hourly Rate <span className="text-danger">*</span>
                            </Label>
                            <Input
                              type="number"
                              className="form-control"
                              name="hourlyRate"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.hourlyRate}
                              invalid={
                                validation.touched.hourlyRate &&
                                validation.errors.hourlyRate
                              }
                            />
                          </div>
                        </Col>
                      )}

                      <Col md={12}>
                        <div className="mb-4">
                          <Label className="form-label fs-6 ">
                            <FaClock className="me-2" style={iconStyle} />
                            Availability Schedule{' '}
                            <span className="text-danger">*</span>
                          </Label>
                          <div className="p-3 border rounded">
                            {Object.entries(validation.values.availability).map(
                              ([day, schedule]) => (
                                <div key={day} className="mb-3">
                                  <div className="d-flex align-items-center mb-2">
                                    <Input
                                      type="checkbox"
                                      className="form-check-input me-2"
                                      name={`availability.${day}.isAvailable`}
                                      onChange={validation.handleChange}
                                      checked={
                                        validation.values.availability[day]
                                          .isAvailable
                                      }
                                    />
                                    <Label className="form-check-label mb-0 fs-6">
                                      {day}
                                    </Label>
                                  </div>
                                  {validation.values.availability[day]
                                    .isAvailable && (
                                    <Row className="ms-4">
                                      <Col md={5}>
                                        <Input
                                          type="time"
                                          className="form-control fs-6"
                                          name={`availability.${day}.startTime`}
                                          onChange={validation.handleChange}
                                          value={
                                            validation.values.availability[day]
                                              .startTime
                                          }
                                        />
                                        {validation.errors.availability?.[day]
                                          ?.startTime && (
                                          <div className="text-danger">
                                            {
                                              validation.errors.availability[
                                                day
                                              ].startTime
                                            }
                                          </div>
                                        )}
                                      </Col>
                                      <Col md={1} className="text-center pt-2">
                                        to
                                      </Col>
                                      <Col md={5}>
                                        <Input
                                          type="time"
                                          className="form-control fs-6"
                                          name={`availability.${day}.endTime`}
                                          onChange={validation.handleChange}
                                          value={
                                            validation.values.availability[day]
                                              .endTime
                                          }
                                        />
                                        {validation.errors.availability?.[day]
                                          ?.endTime && (
                                          <div className="text-danger">
                                            {
                                              validation.errors.availability[
                                                day
                                              ].endTime
                                            }
                                          </div>
                                        )}
                                      </Col>
                                    </Row>
                                  )}
                                </div>
                              ),
                            )}
                          </div>
                        </div>
                      </Col>

                      <Col md={12}>
                        <div className="mb-4">
                          <Label className="form-label fs-6">
                            <FaEye className="me-2" style={iconStyle} />
                            Profile Visibility
                          </Label>
                          <div className="p-3 border rounded">
                            <div className="d-flex flex-column gap-2">
                              <div className="form-check">
                                <Input
                                  type="radio"
                                  className="form-check-input"
                                  id="privateVisibility"
                                  name="isPubliclyVisible"
                                  onChange={() =>
                                    validation.setFieldValue(
                                      'isPubliclyVisible',
                                      false,
                                    )
                                  }
                                  checked={!validation.values.isPubliclyVisible}
                                />
                                <Label
                                  className="form-check-label fs-6"
                                  htmlFor="privateVisibility"
                                >
                                  Make it visible ONLY to MettaMate users
                                </Label>
                                <small className="d-block mt-1 fs-6">
                                  Your mentor profile will only be visible to
                                  registered MettaMate users. All your
                                  information will remain private within the
                                  MettaMate community.
                                </small>
                              </div>

                              <div className="form-check mt-2">
                                <Input
                                  type="radio"
                                  className="form-check-input"
                                  id="publicVisibility"
                                  name="isPubliclyVisible"
                                  onChange={() =>
                                    validation.setFieldValue(
                                      'isPubliclyVisible',
                                      true,
                                    )
                                  }
                                  checked={validation.values.isPubliclyVisible}
                                />
                                <Label
                                  className="form-check-label fs-6"
                                  htmlFor="publicVisibility"
                                >
                                  Make my mentor profile visible to
                                  non-MettaMate members
                                </Label>
                                <small className="d-block mt-1 fs-6">
                                  If enabled, a summary of your mentor profile
                                  will be visible on the MettaMate public
                                  website. This includes your professional
                                  title, areas of expertise, and years of
                                  experience. Personal contact information will
                                  remain private.
                                </small>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>
                      <Col md={12} className="text-center mt-4">
                        {(!existingMentorData || isEditing) && (
                          <Button
                            color="primary"
                            type="submit"
                            disabled={
                              !validation.isValid ||
                              selectedCategories.length === 0 ||
                              (selectedCategories.length > 0 &&
                                selectedSubCategories.length === 0) ||
                              isPaymentPending
                            }
                          >
                            {isEditing ? 'Save Changes' : 'Submit Application'}
                          </Button>
                        )}
                        {isEditing && (
                          <Button
                            color="secondary"
                            className="ms-2"
                            onClick={handleCancelEdit}
                          >
                            Cancel
                          </Button>
                        )}
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default BecomeMentor;
