import React, { Component } from 'react';
import {
  Container,
  Row,
  Col,
  PaginationItem,
  PaginationLink,
  Pagination,
} from 'reactstrap';

//Import Components
// import ImageResize from './ImageResizer'
import SectionTitle from '../../components/Shared/SectionTitle';
import CandidateBox from './CandidateBox';

//Import Images
import candidate1 from '../../assets/images/mettamate/snehalataKamble1.jpeg';
//import candidate2 from '../../assets/images/mettamate/pallaviGK.jpeg';
import candidate2 from '../../assets/images/testimonies/pallaviGK2.png';
import candidate8 from '../../assets/images/testimonies/pallaviGK8.jpeg';
import candidate9 from '../../assets/images/testimonies/rajneesh.jpeg';
import candidate3 from '../../assets/images/mettamate/raghuKiran.jpeg';
//import candidate4 from '../../assets/images/mettamate/dadasahebTandale.jpeg';
import candidate4 from '../../assets/images/testimonies/datasahebTandale2.png';

class Testimonials extends Component {
  constructor(props) {
    super(props);
    this.state = {
      candidates: [
        {
          id: 1,
          image: candidate2,
          name: 'Dr. Pallavi G. K.',
          designation:
            '"Dive into a vibrant community of like-minded individuals who value authenticity, passion, and deep connections. ' +
            'Discover a world where your desires are embraced, your boundaries respected, and your fantasies explored in a safe and welcoming space."',
          link: '',
          socialIds: [
            { icon: 'facebook', link: '#' },
            { icon: 'instagram', link: '#' },
            { icon: 'twitter', link: '#' },
            { icon: 'linkedin', link: '#' },
          ],
        },
        {
          id: 9,
          image: candidate9,
          name: 'Mr. Rajneesh',
          designation:
            '"I wholeheartedly recommend MettaMate to students seeking guidance, mentorship, and community. Joining MettaMate has been one of the most rewarding experiences of my life – I am excited to see the impact it will have on yours!"',
          link: '',
          socialIds: [
            { icon: 'facebook', link: '#' },
            { icon: 'instagram', link: '#' },
            { icon: 'twitter', link: '#' },
            { icon: 'linkedin', link: '#' },
          ],
        },
        {
          id: 2,
          image: candidate1,
          name: 'Prof. Snehalata Kamble',
          designation:
            '"Getting a perfect life partner with whom you can spend your entire life is really tough task. ' +
            'Finding a good matchmaker is substantial before finding a perfect life partner and your ' +
            'perfect matchmaker is Mettamate."',
          link: '',
          socialIds: [
            { icon: 'facebook', link: '#' },
            { icon: 'instagram', link: '#' },
            { icon: 'twitter', link: '#' },
            { icon: 'linkedin', link: '#' },
          ],
        },
        {
          id: 4,
          image: candidate4,
          name: 'Dadasaheb Tandale',
          designation:
            '"At MettaMate, we believe in the power of human connection. We create a space where desires are explored, boundaries are pushed, and fantasies come."',
          link: '',
          socialIds: [
            { icon: 'facebook', link: '#' },
            { icon: 'instagram', link: '#' },
            { icon: 'twitter', link: '#' },
            { icon: 'linkedin', link: '#' },
          ],
        },
        {
          id: 3,
          image: candidate3,
          name: 'Raghu Kiran Bolle',
          designation:
            '"MettaMate stands out as a beacon of inclusivity and empowerment, providing a space where experts and Ambedkarites from diverse backgrounds can come together to share knowledge, support each other, and foster growth in inaccessible communities."',
          socialIds: [
            { icon: 'facebook', link: '#' },
            { icon: 'instagram', link: '#' },
            { icon: 'twitter', link: '#' },
            { icon: 'linkedin', link: '#' },
          ],
        },
        {
          id: 5,
          image: candidate1,
          name: 'Prof. Snehalata Kamble',
          designation:
            'Getting a perfect life partner with whom you can spend your entire life is really tough task. ' +
            'Finding a good matchmaker is substantial before finding a perfect life partner and your ' +
            'perfect matchmaker is Mettamate.',
          link: '',
          socialIds: [
            { icon: 'facebook', link: '#' },
            { icon: 'instagram', link: '#' },
            { icon: 'twitter', link: '#' },
            { icon: 'linkedin', link: '#' },
          ],
        },
        {
          id: 6,
          image: candidate2,
          name: 'Dr. Pallavi G. K.',
          designation:
            'Mettamate is a unique platform for Ambedkarites to connect,collaborate and celebrate. ' +
            'Let us all grow together to get success for each other.',
          link: '',
          socialIds: [
            { icon: 'facebook', link: '#' },
            { icon: 'instagram', link: '#' },
            { icon: 'twitter', link: '#' },
            { icon: 'linkedin', link: '#' },
          ],
        },
        {
          id: 9,
          image: candidate9,
          name: 'Mr. Rajaneesh',
          designation:
            '"I wholeheartedly recommend MettaMate to students seeking guidance, mentorship, and community. Joining MettaMate has been one of the most rewarding experiences of my life – I am excited to see the impact it will have on yours!"',
          link: '',
          socialIds: [
            { icon: 'facebook', link: '#' },
            { icon: 'instagram', link: '#' },
            { icon: 'twitter', link: '#' },
            { icon: 'linkedin', link: '#' },
          ],
        },
        {
          id: 8,
          image: candidate4,
          name: 'Dadasaheb Tandale',
          designation:
            'MettaMate is a true platform of Ambedkarite Community Members',
          link: '',
          socialIds: [
            { icon: 'facebook', link: '#' },
            { icon: 'instagram', link: '#' },
            { icon: 'twitter', link: '#' },
            { icon: 'linkedin', link: '#' },
          ],
        },
      ],
      rendercandidates: [],
      cols: 4,
      currentIndex: 0,
      greater: false,
      currentdiv: 1,
      maxdiv: 0,
    };

    // this.updateWindowSize.bind(this);
  }

  // updateWindowSize = () => {
  //   if (window.outerWidth >= 1230) {
  //     this.setState({ itemCount: 3, cols: 4 });
  //   } else if (window.outerWidth >= 970 && window.outerWidth < 1230) {
  //     this.setState({ itemCount: 2, cols: 6 });
  //   } else if (window.outerWidth <= 970) {
  //     this.setState({ itemCount: 1, cols: 12 });
  //   }
  // };

  componentDidMount() {
    //   window.addEventListener('resize', this.updateWindowSize);
    //   this.updateWindowSize();
    if (this.state.candidates.length > 4) {
      let maxdiv = Math.floor(this.state.candidates.length / 4);
      if (this.state.candidates.length % 4 != 0) {
        maxdiv = maxdiv + 1;
      }
      var rendercandidates = [];
      for (var i = 0; i < 4; i++) {
        rendercandidates.push(this.state.candidates[i]);
      }
      this.setState({
        maxdiv: maxdiv,
        rendercandidates: rendercandidates,
      });
    } else {
      var rendercandidates = [];
      for (var i = 0; i < this.state.candidates; i++) {
        rendercandidates.push(this.state.candidates[i]);
      }
      this.setState({
        maxdiv: 1,
        rendercandidates: rendercandidates,
      });
    }
  }

  // // Make sure to remove the DOM listener when the component is unmounted.
  // componentWillUnmount() {
  //   window.removeEventListener('resize', this.updateWindowSize);
  // }
  handlePrevious = (e) => {
    e.preventDefault();
    if (this.state.currentdiv == 1) return;

    var currentdiv = this.state.currentdiv - 1;
    var candidates = this.state.candidates;
    var rendercandidates = [];
    var calcivaribale = currentdiv - 1;
    calcivaribale = calcivaribale * 4;
    for (var i = 0; i < 4; i++) {
      if (candidates[calcivaribale] != null) {
        rendercandidates.push(candidates[calcivaribale++]);
      } else break;
    }
    this.setState({
      currentdiv: this.state.currentdiv + 1,
      rendercandidates: rendercandidates,
    });

    this.setState({
      currentdiv: this.state.currentdiv - 1,
    });
  };
  handleNext = (e) => {
    e.preventDefault();
    if (this.state.currentdiv == this.state.maxdiv) return;

    var currentdiv = this.state.currentdiv + 1;
    var candidates = this.state.candidates;
    var rendercandidates = [];
    var calcivaribale = currentdiv - 1;
    calcivaribale = calcivaribale * 4;
    for (var i = 0; i < 4; i++) {
      if (candidates[calcivaribale] != null) {
        rendercandidates.push(candidates[calcivaribale++]);
      } else break;
    }
    this.setState({
      currentdiv: this.state.currentdiv + 1,
      rendercandidates: rendercandidates,
    });
  };
  render() {
    return (
      <React.Fragment>
        <section className="section pb-0 ">
          <Container>
            <Row className="justify-content-center">
              <Col className={'col-12 text-center'}>
                <div className="">
                  <h3 className="title mb-0">
                    {' '}
                    MettaMate works with Ambedkarite Community Members &
                    Organizations
                  </h3>
                </div>
              </Col>
            </Row>

            <Row>
              {/* candidate box */}
              <CandidateBox candidates={this.state.rendercandidates} />
              <div className="tns-nav  ">
                <Pagination className="mb-0 d-flex justify-content-center">
                  <PaginationItem>
                    <PaginationLink
                      href="#"
                      onClick={this.handlePrevious}
                      previous
                    >
                      Prev
                    </PaginationLink>
                  </PaginationItem>

                  <PaginationItem active>
                    <PaginationLink href="">
                      {this.state.currentdiv}
                    </PaginationLink>
                  </PaginationItem>

                  <PaginationItem>
                    <PaginationLink href="#" onClick={this.handleNext} next>
                      Next
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              </div>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Testimonials;
