import React from 'react';
import ReactQuill from 'react-quill-new';
import 'react-quill-new/dist/quill.bubble.css';
import { Row, Col } from 'reactstrap';

const MentorProfileDetails = ({ mentorData }) => {
  return (
    <div className="mentor-profile-details">
      {/* Title Section */}
      <div className="profile-section mb-4">
        <h4 className="text-primary mb-3">{mentorData.mentor_title || 'No Title Specified'}</h4>
      </div>

      {/* Bio Section */}
      <div className="profile-section mb-4">
        <h5 className="d-flex align-items-center mb-3">
          <i className="fas fa-user-circle text-primary me-2"></i>
          Professional Bio
        </h5>
        <div className="bio-content px-3">
          <ReactQuill
            theme="bubble"
            value={mentorData.mentor_bio || 'No bio provided'}
            readOnly={true}
            style={{ minHeight: '100px', fontSize: '16px' }}
          />
        </div>
      </div>

      {/* Experience and Rates Section - Add keys to Col components */}
      <Row className="mb-4">
        <Col md={6} key="experience-col">
          <h5 className="d-flex align-items-center mb-3">
            <i className="fas fa-briefcase text-success me-2"></i>
            Experience
          </h5>
          <p className="ms-3">{mentorData.years_of_experience}+ years</p>
        </Col>
        <Col md={6} key="mentorship-type-col">
          <h5 className="d-flex align-items-center mb-3">
            <i className="fas fa-money-bill text-warning me-2"></i>
            Mentorship Type
          </h5>
          <p className="ms-3">
            {mentorData.is_paid_mentorship 
              ? `Paid - ₹${mentorData.hourly_rate}/hour`
              : 'Volunteer Mentorship'}
          </p>
        </Col>
      </Row>

      {/* Updated Categories Section with Subcategories */}
      <div className="expertise-section mb-4">
        <h5 className="d-flex align-items-center mb-3">
          <i className="fas fa-tags text-info me-2"></i>
          Areas of Expertise
        </h5>
        <div className="px-3">
          {mentorData.categories?.map((category, index) => (
            <span key={index} className="badge bg-light text-dark me-2 mb-2 p-2">
              {category.categoryname}
            </span>
          ))}
        </div>

        {/* New Specializations/Subcategories Section */}
        <h6 className="d-flex align-items-center mb-2 mt-3">
          <i className="fas fa-certificate text-warning me-2"></i>
          Specializations
        </h6>
        <div className="px-3">
          {mentorData.subcategories?.map((subcategory, index) => (
            <span key={index} className="badge bg-soft-warning text-dark me-2 mb-2 p-2">
              <i className="fas fa-arrow-right me-1"></i>
              {subcategory.subcategoryname}
            </span>
          ))}
          {(!mentorData.subcategories || mentorData.subcategories.length === 0) && (
            <p className="text-muted mb-0">No specializations specified</p>
          )}
        </div>
      </div>

      {/* Updated Availability Schedule */}
      <div className="availability-section">
        <h5 className="d-flex align-items-center mb-3">
          <i className="fas fa-calendar-alt text-primary me-2"></i>
          Weekly Availability
        </h5>
        <div className="schedule-grid px-3">
          {mentorData.availability?.map((schedule, index) => (
            <div key={index} className="schedule-item d-flex align-items-center mb-2 p-2 bg-light rounded">
              <div className="d-flex w-100 justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <i className="fas fa-clock text-primary me-3"></i>
                  <div>
                    <strong className="me-2" style={{ minWidth: '100px', display: 'inline-block' }}>
                      {schedule.day_of_week}
                    </strong>
                    <span className="text-muted">
                      <i className="fas fa-watch text-warning me-2"></i>
                      {schedule.startTime} - {schedule.endTime}
                    </span>
                  </div>
                </div>
                <span className="badge bg-success">Available</span>
              </div>
            </div>
          ))}
          {(!mentorData.availability || mentorData.availability.length === 0) && (
            <div className="text-center text-muted p-3">
              <i className="fas fa-calendar-times me-2"></i>
              Schedule not specified
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MentorProfileDetails;
