import React, { useEffect, useState, Component } from 'react';

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardImg,
  ModalBody,
  UncontrolledCarousel,
  UncontrolledCollapse,
  Modal,
  ModalHeader,
  ModalFooter,
  Carousel, CarouselItem, CarouselControl,
  Button,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../Home/css/userHomePage.css';
import FeatherIcon from 'feather-icons-react';
import { useLocation } from 'react-router-dom';
import { Navigate } from 'react-router-dom';

import axios from 'axios';
import apiUrl from '../server';
import appUrl from '../config';

import manavatar from '../../assets/images/MettaMateImages/male_avatar.jpg';
import candidate1 from '../../assets/images/MettaMateImages/female_avatar.jpeg';

import transgenderavatar from '../../assets/images/MettaMateImages/transgenderprofile.png';
import birthcake from '../../assets/images/MettaMateImages/birthday-cake.svg';
import locationImg from '../../assets/images/MettaMateImages/carbon_location-filled.svg';
import heightimg from '../../assets/images/MettaMateImages/game-icons_body-height.svg';
import weightimg from '../../assets/images/MettaMateImages/fa6-solid_weight-scale.svg';
import cityimg from '../../assets/images/MettaMateImages/healthicons_city.svg';
import callimg from '../../assets/images/MettaMateImages/mingcute_phone-fill.svg';
import mailimg from '../../assets/images/MettaMateImages/material-symbols_mail-outline.svg';
import complexionimg from '../../assets/images/MettaMateImages/carbon_face-add.svg';
import bloodgroupimg from '../../assets/images/MettaMateImages/maki_blood-bank.svg';
import hairimg from '../../assets/images/MettaMateImages/mingcute_hair-fill.svg';
import eyeimg from '../../assets/images/MettaMateImages/icomoon-free_eye.svg';
import degreenameimg from '../../assets/images/MettaMateImages/icon-park-outline_degree-hat.svg';
import collegeimg from '../../assets/images/MettaMateImages/college.svg';
import professionimg from '../../assets/images/MettaMateImages/profession.svg';
import companyimg from '../../assets/images/MettaMateImages/company.svg';
import moneyimg from '../../assets/images/MettaMateImages/game-icons_money-stack.svg';
import nonvegimg from '../../assets/images/MettaMateImages/circum_wheat.svg';
import smokeimg from '../../assets/images/MettaMateImages/ic_outline-smoke-free.svg';
import drinkimg from '../../assets/images/MettaMateImages/carbon_drink-01.svg';
import excerciseimg from '../../assets/images/MettaMateImages/flat-color-icons_sports-mode.svg';
import gamesimg from '../../assets/images/MettaMateImages/uil_game.svg';
import hobbiesimg from '../../assets/images/MettaMateImages/hobbies.svg';
import petimg from '../../assets/images/MettaMateImages/streamline_pet-paw-solid.svg';
import ngoimg from '../../assets/images/MettaMateImages/ngo.svg';
import casteimg from '../../assets/images/MettaMateImages/caste.svg';
import meditationimg from '../../assets/images/MettaMateImages/healthicons_exercise-yoga.svg';
import familyimg from '../../assets/images/MettaMateImages/emojione-monotone_family.svg';
import fatherimg from '../../assets/images/MettaMateImages/father.svg';
import motherimg from '../../assets/images/MettaMateImages/mother.svg';
import siblingsimg from '../../assets/images/MettaMateImages/noofsibling.svg';
import positionsiblingimg from '../../assets/images/MettaMateImages/family.svg';
import religionimg from '../../assets/images/MettaMateImages/religion.svg';
import dnaimg from '../../assets/images/MettaMateImages/ph_dna.svg';
import { logoutFunction } from './logout';
import CommonModal from './modal';
import ChatBoxFunction from './chat/chatbox';
import femaleavatar from '../../assets/images/MettaMateImages/female_avatar.jpeg';
const ViewProfilePageFunction = () => {
  const [userdata, setUserData] = useState('');

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const toggleModal = () => setIsModalOpen(!isModalOpen);
  const closeModal = () => setIsModalOpen(false);

  const getImage = (imagepath, gender) => {
    if (imagepath) {
      return imagepath;
    } 
    else if (gender === 'Male') {
      return manavatar;
    } else if (gender === 'Female') {
      return femaleavatar;
    } 
    else {
      return null;
    }
  };

  const items = [
    { key: 1, src: getImage(userdata.photothumb1, userdata.gender) },
    { key: 2, src: getImage(userdata.photothumb2, userdata.gender) },
    { key: 3, src: getImage(userdata.photothumb3, userdata.gender) },
  ];

  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const slides = items.map((item, index) => (
    <CarouselItem
      onExiting={() => setAnimating(true)}
      onExited={() => setAnimating(false)}
      key={item.key}
    >
     <div className="image-wrapper" onClick={() => { setSelectedImage(item.src); toggleModal(); }}>
      <img
        src={item.src}
        alt={`Slide ${index + 1}`}
        style={{ height: '200px' }}
        className="img-fluid memberImage"
      />
      <div className="preview-layer">
        <i className="fea icon-eye">
          <FeatherIcon icon="eye" />
        </i>
      </div>
    </div>
    </CarouselItem>
  ));

  const location = useLocation();
  const { state } = location || {};
  const [age, setAge] = useState(0);
  const { data, connectionStatus, userage } = state || {};
  const [userConnectionStatus, setUserConnectionStatus] = useState('');
  const [recommendationUsers, setRecommendationUsers] = useState([]);

  const [partnerDetails, setPartnerDetailsData] = useState('');
  const [errorAlert, setErrorAlert] = useState('');
  const [confirmConnectionmodalOpen, setconfirmConnectionmodalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState('Confirmation');
  const [modalDescription, setModalDescription] = useState(
    'Are You Sure to Connect?',
  );
  const [modalConnectButton, setModalConnectButton] = useState(true);
  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const [activeLink, setActiveLink] = useState(1);
  let currentLocation;
  let companyLocation;
  const [linkChecked, setLinkChecked] = useState(true);
  var menudata = [
    { menuName: 'Details', value: 1, checkedValue: linkChecked },
    { menuName: 'Expectation', value: 4, checkedValue: !linkChecked },
  ];
  var [copyModalOpen, changeCopyModal] = useState(false);
  var [copyButton, setCopyButton] = useState('Copy Url');

  const fetchMemberDetails = async (memberUUID, connectionStatus, userage) => {
    try {
      setAge(userage)
      const result = await axios.get(
        `${apiUrl}members/fulldetails/${memberUUID}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      );
      setUserConnectionStatus(connectionStatus);
      setUserData(result.data[0]);
      partnerExpectationsDetails(memberUUID);

      if (result.data[0].gendercode == 1) {
        getAllMembers(4, memberUUID);
      } else {
        getAllMembers(1, memberUUID);
      }
    } catch (error) {
      console.error('Error fetching location details:', error);
    }
  };

  const getAllMembers = async (membertypevalue, memberuuid) => {
    try {
      const response = await axios.post(
        `${apiUrl}members/new_getAllianceSeekers`,
        {
          pageNo: 1,
          pageSize: 10,
          membertypeid: membertypevalue,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      );
      const allmembersfromapi = response.data.details;
      var count = 0;
      var i = 0;
      var array = [];
      while (count < 6) {
        if (allmembersfromapi[i].memberuuid != memberuuid) {
          array.push(allmembersfromapi[i]);
          count++;
        }
        i++;
      }
      setRecommendationUsers(array);
    } catch (error) {
      console.error('Error fetching location details:', error);
    }
  };

  const partnerExpectationsDetails = async (memberUUID) => {
    try {
      const response = await axios.get(
        apiUrl + 'preference/fullpreference/' + memberUUID,
        {
          headers: {
            Authorization: 'Bearer' + ' ' + localStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
        },
      );
      if (response.data.length > 0) {
        setPartnerDetailsData(response.data[0]);
      } else {
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    if (data) {
      fetchMemberDetails(data, connectionStatus, userage);
      partnerExpectationsDetails(data);
    }
  }, []);

  const calculate_age = (dob1) => {
    if (dob1 != null) {
      var today = new Date();
      var birthDate = new Date(dob1); // create a date object directly from `dob1` argument
      var age_now = today.getFullYear() - birthDate.getFullYear();
      var m = today.getMonth() - birthDate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age_now--;
      }
      return age_now ? age_now : '00';
    } else {
      return '00';
    }
  };
  const openConnectionModal = async (connStatus) => {
    console.log(connStatus);
    setconfirmConnectionmodalOpen(true);
  };

  const requestForConnection = async () => {

    try {
      let request = {
        memberuuid: localStorage.getItem('memberID'),
        connectedmemberuuid: userdata.memberuuid,
      };

      let result = await axios.post(`${apiUrl}connection/requestforconnection`, request, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
        .then(async (res) => {

          setModalTitle('SUCCESS');
          setModalDescription('Request sent successfully');
          setModalConnectButton(false)
          setUserConnectionStatus('Connecting')
        });
    } catch (error) {
      setModalTitle('ERROR');
      setModalDescription('Request Failed');
    }
  };

  const openShortChat = () => {
    let configurationData = localStorage.getItem('configurationData');
    if (!configurationData) {
      window.location.href = '/index';
    }
    let loggedInUserData = JSON.parse(configurationData);

    if (loggedInUserData.validsubscriber == 0) {
      window.location.href = '/subscriptionPlan';
    }

    if (document.getElementById('chatbox').style.display == 'none') {
      document.getElementById('chatbox').style.display = '';
    } else {
    }
  };

  const closeShortChat = () => {
    document.getElementById('chatbox').style.display = 'none';
  };

  const handleLinkClick = (link) => {
    setLinkChecked(!linkChecked);
    setActiveLink(link);
  };

  const getPronoun = (gender) => {
    if (gender == 1) {
      return 'His';
    } else if (gender == 2) {
      return 'Her';
    } else {
      return '';
    }
  };

  const returnStringDate = (dob) => {
    if (dob && dob != 'Secured_Info') {
      try {
        const dt = new Date(dob);
        const month = dt.toLocaleString('default', { month: 'long' });
        const day = dt.getDate();
        const year = dt.getFullYear();

        return day + ' ' + month + ' ' + year;
      } catch (error) {
        return '';
      }
    } else {
      return ''
    }
  };

  const widgets = [
    {
      id: 1,
      icon: 'uil uil-dashboard',
      className: 'navbar-item account-menu px-0',
      title: 'Profile',
      link: '/page-profile',
    },
    {
      id: 2,
      icon: 'uil uil-users-alt',
      className: 'navbar-item account-menu px-0 mt-2',
      title: 'Members',
      link: '/page-members',
    },
    {
      id: 3,
      icon: 'uil uil-file',
      className: 'navbar-item account-menu px-0 mt-2',
      title: 'Portfolio',
      link: '/page-works',
    },
    {
      id: 4,
      icon: 'uil uil-comment',
      className: 'navbar-item account-menu px-0 mt-2',
      title: 'Chat',
      link: '/page-chat',
    },
    {
      id: 5,
      icon: 'uil uil-envelope-star',
      className: 'navbar-item account-menu px-0 mt-2',
      title: 'Messages',
      link: '/page-messages',
    },
    {
      id: 6,
      icon: 'uil uil-transaction',
      className: 'navbar-item account-menu px-0 mt-2',
      title: 'Payments',
      link: '/page-payments',
    },
    {
      id: 7,
      icon: 'uil uil-setting',
      className: 'navbar-item account-menu px-0 mt-2 active',
      title: 'Settings',
      link: '/page-profile-edit',
    },
    {
      id: 8,
      icon: 'uil uil-dashboard',
      className: 'navbar-item account-menu px-0 mt-2',
      title: 'Logout',
      link: '/auth-login-three',
    },
  ];


  const openCopyModal = (data) => {
    changeCopyModal(!copyModalOpen);
  };
  const toggleCopyModal = () => {
    changeCopyModal(!copyModalOpen);
  };
  const handleCopyText = () => {
    const urlToCopy = `${appUrl}/${userdata.memberuuid}`;
    const textArea = document.createElement('textarea');
    textArea.value = urlToCopy;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');

    document.body.removeChild(textArea);
    setCopyButton('Copied');
    setTimeout(() => {
      setCopyButton('Copy url');
    }, 3000);
  };

  return (
    <React.Fragment>
      {/* breadcrumb */}

      <section className="section ">
        <Container className="mt-lg-3">
          <Row className="justify-content-center">
            <Col lg="8" xs="12">
              <Card className="border-1 rounded shadow">
                <CardBody>
                  <Row className="">
                    <Col lg="6" md="6" className="text-md-center text-center" >
                      <div
                        className="position-relative mb-4"
                        style={{ display: 'flex', justifyContent: 'center' }}
                      >
                        <Carousel activeIndex={activeIndex} next={next} previous={previous}>
                          {slides}
                          <CarouselControl direction="prev" directionText="Previous" onClickHandler={previous} />
                          <CarouselControl direction="next" directionText="Next" onClickHandler={next} />
                        </Carousel>

                        <Modal isOpen={isModalOpen} toggle={toggleModal} size="md">
                          <button
                            type="button"
                            aria-label="Close"
                            className='close-button'
                            onClick={() => setIsModalOpen(false)} // Explicitly close the modal
                            style={{
                              position: 'absolute',
                              top: '0px',
                              right: '0px',
                             
                              backgroundColor:'black',
                              border: 'none',
                              fontSize: '1.5rem',
                              cursor: 'pointer',
                              zIndex: '1000', // Ensure it appears above other content
                            }}
                          >
                            &times; {/* You can use a cross icon or text here */}
                          </button>
                          <ModalBody>
                            {selectedImage && (
                              <img
                                src={selectedImage}
                                alt="Modal"
                                className='view-modal-img'
                                style={{ width: '100%' }}
                              />
                            )}
                          </ModalBody>

                        </Modal>
                      </div>
                    </Col>

                    <Col lg="6" md="6">
                      <Row className="pb-4">
                        <h3 className=" mb-0 text-md-start text-start">
                          {userdata.firstname}, {age}{' '}
                        </h3>
                      </Row>

                      <Row className="fontSize18 ">
                        <div>
                          <FeatherIcon
                            icon="briefcase"
                            className="fea icon-sm icons"
                          />
                          <small className="px-3 fontSize18 fw-bold ">
                            {userdata.jobprofession || 'Unknown Job Profession'}
                          </small>
                        </div>

                        <div>
                          <FeatherIcon
                            icon="map-pin"
                            className="fea icon-sm icons"
                          />
                          <small className="px-3 fontSize18 fw-bold  ">
                            {userdata.currentlocationname || 'Unknown Location'}
                          </small>
                        </div>

                        <div>
                          <FeatherIcon
                            icon="gift"
                            className="fea icon-sm icons"
                          />
                          {userdata.dob != 'Secured_Info' ?
                            (<small className="px-3 fontSize18 fw-bold ">
                              {returnStringDate(userdata.dob) ||
                                'Unknown Birthdate'}
                            </small>)
                            : (
                              <>
                                <Link
                                  className="btn-link ms-3 subscribe_btn font_size14"
                                  to={'/subscriptionplan'}
                                >
                                  Subscribe
                                </Link></>
                            )}
                        </div>
                      </Row>
                      <Row className="">
                        <small className="px-3 fontSize18 fw-bold ">
                          {' '}
                          {/* {returnStringDate(userdata.dob) || 'Unknown Birthdate'} */}
                        </small>
                      </Row>
                      <Row className="">
                        <small className="px-3 fontSize18 fw-bold ">
                          {' '}
                          {/* {returnStringDate(userdata.dob) || 'Unknown Birthdate'} */}
                        </small>
                      </Row>
                      <Row className="">
                        <small className="px-3 fontSize18 fw-bold ">
                          {' '}
                          {/* {returnStringDate(userdata.dob) || 'Unknown Birthdate'} */}
                        </small>
                      </Row>

                      <Row className="text-end">
                        <div className="m-3">
                          {/* <button
                            type="button"
                            className="btn btn-outline-primary me-2"
                            onClick={() => openCopyModal(userdata)}
                          >
                            <span className="d-flex">
                              <span className="d-none d-md-block">Share </span>
                              <i>
                                <FeatherIcon
                                  icon="share-2"
                                  className="fea icon-sm icons px-1"
                                  style={{ width: `30px` }}
                                />
                              </i>
                            </span>
                          </button> */}
                          {!userConnectionStatus && (
                            <button
                              title="Send Connection Request"
                              className="btn btn-outline-success me-2"
                              onClick={() =>
                                openConnectionModal(userConnectionStatus)
                              }
                            >
                              <span>
                                <i
                                  className="fa fa-handshake-o "
                                  aria-hidden="true"
                                ></i>{' '}
                                Connect
                              </span>
                            </button>
                          )}
                          {userConnectionStatus == 'Connecting' && (
                            <button
                              title="Waiting To Connect"
                              className="btn btn-outline-danger"
                            >
                              <span>
                                <i
                                  className="fa fa-exchange"
                                  aria-hidden="true"
                                ></i>
                                Connecting
                              </span>
                            </button>
                          )}
                          {userConnectionStatus == 'Connected' && (
                            <button
                              className="btn btn-outline-success"
                              title="Message"
                              onClick={() => openShortChat()}
                            >
                              <FeatherIcon
                                icon="message-circle"
                                className="fea icon-sm icons "
                              />
                              &nbsp; Message
                            </button>
                          )}
                        </div>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card className="border-1 rounded shadow mt-2">
                <div className="row mt-4 mb-1">
                  <div className="col-md-12 px-0 ">
                    <div className="tabs mx-4">
                      {(menudata || []).map((item, key) => (
                        <>
                          <input
                            type="radio"
                            id={`radio-${key + 1}`}
                            name="tabs"
                            checked={item.checkedValue}
                            onClick={() => handleLinkClick(item.value)}
                          />
                          <label
                            className=" fontSize18 tab "
                            htmlFor={`radio-${key + 1}`}
                          >
                            {getPronoun(userdata.gendercode)} {item.menuName}
                          </label>
                        </>
                      ))}
                    </div>
                  </div>
                </div>
                {activeLink == 1 ? (
                  <div className="mx-4">
                    <Row>
                      <Col md={6}>
                        <ul className="list-unstyled m-2 ">
                          <h4>
                            <span>
                              {getPronoun(userdata.gendercode)} Personal Details
                            </span>
                          </h4>
                          {userdata.membertypeid === 1 && (
                            <>
                              <li className=" mx-2 my-1 ">
                                <div className="d-flex">
                                  <img
                                    src={complexionimg}
                                    className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3 mt-1`}
                                  />
                                  <p className="mb-0 fontSize18 mt-1">
                                    Complexion
                                  </p>
                                </div>
                                <p className="fw-bold value_text fontSize18 mb-1">
                                  {' '}
                                  {userdata.complexion
                                    ? userdata.complexion
                                    : 'Unknown Complexion'}
                                </p>
                              </li>
                              <li className=" mx-2 my-1 ">
                                <div className="d-flex">
                                  <img
                                    src={heightimg}
                                    className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3 mt-1`}
                                  />
                                  <p className="mb-0 fontSize18 mt-1">Height</p>
                                </div>
                                <p className="fw-bold value_text fontSize18 mb-1">
                                  {' '}
                                  {userdata.height
                                    ? userdata.height
                                    : 'Unknown Height'}{' '}
                                  ft
                                </p>
                              </li>
                              <li className=" mx-2 my-1 ">
                                <div className="d-flex">
                                  <img
                                    src={weightimg}
                                    className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3 mt-1`}
                                  />
                                  <p className="mb-0 fontSize18 mt-1">Weight</p>
                                </div>
                                <p className="fw-bold value_text fontSize18 mb-1">
                                  {' '}
                                  {userdata.weight
                                    ? userdata.weight
                                    : 'Unknown Weight'}{' '}
                                  kg
                                </p>
                              </li>
                              <li className=" mx-2 my-1 ">
                                <div className="d-flex mt-1">
                                  <img
                                    src={hairimg}
                                    className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3 mt-1`}
                                  />
                                  <p className="mb-0 fontSize18">Hair Color</p>
                                </div>
                                <p className="fw-bold value_text fontSize18 mb-1">
                                  {' '}
                                  {userdata.haircolor
                                    ? userdata.haircolor
                                    : 'Unknown Hair Color'}
                                </p>
                              </li>
                              <li className=" mx-2 my-1 ">
                                <div className="d-flex mt-1">
                                  <img
                                    src={eyeimg}
                                    className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3 mt-1`}
                                  />
                                  <p className="mb-0 fontSize18">Eye Color</p>
                                </div>
                                <p className="fw-bold value_text fontSize18 mb-1">
                                  {' '}
                                  {userdata.eyecolor
                                    ? userdata.eyecolor
                                    : 'Unknown Eye Color'}
                                </p>
                              </li>
                              <li className=" mx-2 my-1 ">
                                <div className="d-flex mt-1">
                                  <img
                                    src={bloodgroupimg}
                                    className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3 mt-1`}
                                  />
                                  <p className="mb-0 fontSize18">Blood Group</p>
                                </div>
                                <p className="fw-bold value_text fontSize18 mb-1">
                                  {' '}
                                  {userdata.bloodgroup
                                    ? userdata.bloodgroup
                                    : 'Unknown Blood Group'}
                                </p>
                              </li>
                            </>
                          )}
                          <li className=" mx-2 my-1 ">
                            <div className="d-flex mt-1">
                              <img
                                src={cityimg}
                                className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3 mt-1`}
                              />
                              <p className="mb-0 fontSize18">City</p>
                            </div>
                            <p className="fw-bold value_text fontSize18 mb-1">
                              {' '}
                            </p>
                          </li>

                          <li
                            className="mx-2 my-1"
                            style={{ cursor: 'not-allowed' }}
                          >
                            <div>
                              <img
                                src={mailimg}
                                className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3`}
                              />
                              <span className="fontSize18 me-2">
                                Email&nbsp;
                              </span>
                              {(userdata.emailid == 'Subscription_Required' ||
                                userdata.emailid == 'Secured_Info') && (
                                  <Link
                                    className="btn-link ms-3 subscribe_btn"
                                    to={'/subscriptionplan'}
                                  >
                                    Subscribe
                                  </Link>
                                )}
                              {userdata.emailid == '' && (
                                <span className="">Unknown </span>
                              )}
                              {(userdata.emailid != 'Secured_Info') && (
                                <>
                                  <p className="fw-bold value_text fontSize18 mb-1">
                                    {userdata.emailid}
                                  </p>
                                </>
                              )}
                              {/* {userdata.emailid == 'Subscription_Required' && (
                                <>
                                  <span className="badge rounded bg-soft-success border-1 border-primary font_size18">
                                    <i>
                                      <FeatherIcon
                                        icon="lock"
                                        className="fea icon-sm text-accent me-1"
                                      />{' '}
                                      Subscription Required
                                    </i>
                                  </span>
                                </>
                              )} */}
                            </div>
                          </li>

                          <li className="mx-2 my-1">
                            <div>
                              <img
                                src={callimg}
                                className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3`}
                              />
                              <span className="fontSize18">Mobile</span>

                              {(userdata.phonenumber ==
                                'Subscription_Required' ||
                                userdata.phonenumber == 'Secured_Info') && (
                                  <Link
                                    className="btn-link ms-3 subscribe_btn"
                                    to={'/subscriptionplan'}
                                  >
                                    Subscribe
                                  </Link>
                                )}
                              {userdata.phonenumber == '' && (
                                <span className="">Unknown </span>
                              )}
                              {(userdata.phonenumber !=
                                'Secured_Info') && (
                                  <>
                                    <p className="fw-bold value_text fontSize18 mb-1">
                                      {' '}
                                      (+{userdata.phonecountrycode})-
                                      {userdata.phonenumber}
                                    </p>
                                  </>
                                )}
                              {userdata.phonenumber == 'Subscription_Required' && (
                                <>
                                  <span className="badge rounded bg-soft-success border-1 border-primary font_size18">
                                    <i>
                                      <FeatherIcon
                                        icon="lock"
                                        className="fea icon-sm text-accent me-1"
                                      />{' '}
                                      Subscription Required
                                    </i>
                                  </span>
                                </>
                              )}
                            </div>
                          </li>

                          <li>
                            <ul className="list-unstyled social-icon social mb-0 fontSize18 mt-4">
                              <li className="list-inline-item">
                                <Link
                                  disabled={
                                    (userdata.facebook ==
                                      'Subscription_Required' ||
                                      userdata.facebook == 'Secured_Info')
                                  }
                                  to={
                                    userdata.facebook ==
                                      'Subscription_Required' ||
                                      userdata.facebook == 'Secured_Info'
                                      ? null
                                      : userdata.facebook
                                  }
                                  className="rounded"
                                >
                                  <FeatherIcon
                                    icon="facebook"
                                    className="fea icon-sm fea-social"
                                  />
                                </Link>
                              </li>{' '}
                              <li className="list-inline-item">
                                <Link
                                  to={
                                    userdata.instagram ==
                                      'Subscription_Required' ||
                                      userdata.instagram == 'Secured_Info'
                                      ? null
                                      : userdata.instagram
                                  }
                                  className="rounded"
                                  disabled={
                                    userdata.instagram ==
                                    'Subscription_Required' ||
                                    userdata.instagram == 'Secured_Info'
                                  }
                                >
                                  <FeatherIcon
                                    icon="instagram"
                                    className="fea icon-sm fea-social"
                                  />
                                </Link>
                              </li>{' '}
                              <li className="list-inline-item">
                                <Link
                                  to={
                                    userdata.twitter ==
                                      'Subscription_Required' ||
                                      userdata.twitter == 'Secured_Info'
                                      ? null
                                      : userdata.twitter
                                  }
                                  disabled={
                                    userdata.twitter ==
                                    'Subscription_Required' ||
                                    userdata.twitter == 'Secured_Info'
                                  }
                                  className="rounded"
                                >
                                  <FeatherIcon
                                    icon="twitter"
                                    className="fea icon-sm fea-social"
                                  />
                                </Link>
                              </li>{' '}
                              <li className="list-inline-item">
                                <Link
                                  disabled={
                                    userdata.linkedin ==
                                    'Subscription_Required' ||
                                    userdata.linkedin == 'Secured_Info'
                                  }
                                  to={
                                    userdata.linkedin ==
                                      'Subscription_Required' ||
                                      userdata.linkedin == 'Secured_Info'
                                      ? null
                                      : userdata.linkedin
                                  }
                                  className="rounded"
                                >
                                  <FeatherIcon
                                    icon="linkedin"
                                    className="fea icon-sm fea-social"
                                  />
                                </Link>
                              </li>{' '}
                              <li className="list-inline-item">
                                <Link
                                  to={
                                    userdata.youtube ==
                                      'Subscription_Required' ||
                                      userdata.youtube == 'Secured_Info'
                                      ? null
                                      : userdata.youtube
                                  }
                                  disabled={
                                    userdata.youtube ==
                                    'Subscription_Required' ||
                                    userdata.youtube == 'Secured_Info'
                                  }
                                  className="rounded"
                                >
                                  <FeatherIcon
                                    icon="youtube"
                                    className="fea icon-sm fea-social"
                                  />
                                </Link>
                              </li>{' '}
                            </ul>
                          </li>
                        </ul>
                        <hr />
                        <div>
                          <h4>
                            <span>
                              {getPronoun(userdata.gendercode)} Lifestyle
                              Details
                            </span>
                          </h4>
                          <div className="mb-1 ">
                            <div className="d-flex mt-1">
                              <img
                                src={nonvegimg}
                                className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3`}
                              />
                              <p className="mb-0 fontSize18">
                                Diet Preferences
                              </p>
                            </div>
                            <p className="fw-bold value_text fontSize18 mb-1">
                              {' '}
                              {userdata.diet ? userdata.diet : 'Unknown'}
                            </p>
                          </div>

                          <Row>
                            <Col md={6}>
                              <div className="mb-1 ">
                                <div className="d-flex mt-1">
                                  <img
                                    src={smokeimg}
                                    className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3`}
                                  />
                                  <p className="mb-0 fontSize18">Smoking</p>
                                </div>
                                <p className="fw-bold value_text fontSize18 mb-1">
                                  {' '}
                                  {userdata.smokinghabit
                                    ? userdata.smokinghabit
                                    : 'Unknown'}
                                </p>
                              </div>

                              <div className="mb-1 ">
                                <div className="d-flex mt-1">
                                  <img
                                    src={drinkimg}
                                    className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3`}
                                  />
                                  <p className="mb-0 fontSize18">Drinking</p>
                                </div>
                                <p className="fw-bold value_text fontSize18 mb-1">
                                  {' '}
                                  {userdata.drinkinghabit
                                    ? userdata.drinkinghabit
                                    : 'Unknown'}
                                </p>
                              </div>
                            </Col>
                          </Row>

                          <div className="mb-1 ">
                            <div className="d-flex mt-1">
                              <img
                                src={excerciseimg}
                                className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3`}
                              />
                              <p className="mb-0 fontSize18">
                                Exercise Routines
                              </p>
                            </div>
                            <p className="fw-bold value_text fontSize18 mb-1">
                              {' '}
                              {userdata.exerciseroutine
                                ? userdata.exerciseroutine
                                : 'Unknown'}
                            </p>
                          </div>

                          <div className="mb-1 ">
                            <div className="d-flex mt-1">
                              <img
                                src={gamesimg}
                                className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3`}
                              />
                              <p className="mb-0 fontSize18">Games</p>
                            </div>
                            <p className="fw-bold value_text fontSize18 mb-1">
                              {' '}
                              {userdata.gameList
                                ? userdata.gameList
                                : 'Unknown'}
                            </p>
                          </div>

                          <div className="mb-1">
                            <div className="d-flex mt-1">
                              <img
                                src={hobbiesimg}
                                className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3`}
                              />
                              <p className="mb-0 fontSize18">
                                Hobbies_Interest
                              </p>
                            </div>
                            <p className="fw-bold value_text fontSize18 mb-1">
                              {' '}
                              {userdata.hobbies ? userdata.hobbies : 'Unknown'}
                            </p>
                          </div>

                          <div className="mb-1">
                            <div className="d-flex mt-1">
                              <img
                                src={petimg}
                                className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3`}
                              />
                              <p className="mb-0 fontSize18">Pet Preference</p>
                            </div>
                            <p className="fw-bold value_text fontSize18 mb-1">
                              {' '}
                              {userdata.petpreference
                                ? userdata.petpreference
                                : 'Unknown'}
                            </p>
                          </div>
                          <hr />

                          <div className="mb-1">
                            <h4>
                              <span>
                                {getPronoun(userdata.gendercode)} Social
                                Contribution
                              </span>
                            </h4>
                            <div className="d-flex mt-1">
                              <img
                                src={ngoimg}
                                className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3`}
                              />
                              <p className="mb-0 fontSize18">Part Of NGO</p>
                            </div>
                            <p className="fw-bold value_text fontSize18 mb-1">
                              {' '}
                              {userdata.partofngo
                                ? userdata.partofngo
                                : 'Unknown'}
                            </p>
                          </div>
                          <hr />
                        </div>
                      </Col>
                      <Col md={6}>
                        {userdata.membertypeid === 1 && (
                          <>
                            <div className="pt-2">
                              <h4>
                                <span>
                                  {getPronoun(userdata.gendercode)} Educational
                                  Details
                                </span>
                              </h4>
                              <div className="d-flex mt-1">
                                <img
                                  src={degreenameimg}
                                  className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3 mt-1`}
                                />
                                <p className="mb-0 fontSize18">Degree</p>
                              </div>
                              <p className="fw-bold value_text fontSize18 mb-1">
                                {' '}
                                {userdata.degreelevel
                                  ? userdata.degreelevel
                                  : 'Unknown Degree'}{' '}
                                &nbsp; ,
                                {userdata.degreename
                                  ? userdata.degreename
                                  : 'Unknown Degree Name'}
                              </p>

                              <p className="mb-1 ">
                                <div className="d-flex mt-1">
                                  <img
                                    src={collegeimg}
                                    className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3`}
                                  />
                                  <p className="mb-0 fontSize18">
                                    Degree College
                                  </p>
                                </div>
                                <p className="fw-bold value_text fontSize18 mb-1">
                                  {' '}
                                  {userdata.degreecollege
                                    ? userdata.degreecollege
                                    : 'Unknown Degree College'}
                                </p>
                              </p>
                            </div>
                            <hr />
                            <div>
                              <h4>
                                <span>
                                  {getPronoun(userdata.gendercode)} Professional
                                  Details
                                </span>
                              </h4>
                              <div className="mb-1">
                                <div className="d-flex mt-1">
                                  <img
                                    src={professionimg}
                                    className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3`}
                                  />
                                  <p className="mb-0 fontSize18">Job Title</p>
                                </div>
                                <p className="fw-bold value_text fontSize18 mb-1">
                                  {' '}
                                  {userdata.jobtitle
                                    ? userdata.jobtitle
                                    : 'Unknown Job title'}
                                </p>
                              </div>

                              <div className="mb-1 ">
                                <div className="d-flex mt-1">
                                  <img
                                    src={companyimg}
                                    className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3`}
                                  />
                                  <p className="mb-0 fontSize18">
                                    Company Name
                                  </p>
                                </div>
                                <p className="fw-bold value_text fontSize18 mb-1">
                                  {' '}
                                  {userdata.companyname
                                    ? userdata.companyname
                                    : 'Unknown Company'}
                                </p>
                              </div>

                              <div className="mb-1 ">
                                <div className="d-flex mt-1">
                                  <img
                                    src={location}
                                    className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3`}
                                  />
                                  <p className="mb-0 fontSize18">
                                    Company Location
                                  </p>
                                </div>
                                <p className="fw-bold value_text fontSize18 mb-1">
                                  {' '}
                                  {companyLocation}
                                </p>
                              </div>

                              <div className="mb-1 ">
                                <div className="d-flex mt-1">
                                  <img
                                    src={moneyimg}
                                    className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3`}
                                  />
                                  <p className="mb-0 fontSize18">Salary</p>
                                </div>
                                <p className="fw-bold value_text fontSize18 mb-1">
                                  {' '}
                                  {userdata.salctc
                                    ? userdata.salctc
                                    : 'Unknown CTC'}
                                </p>
                              </div>

                              <div className="mb-1">
                                <div className="d-flex mt-1">
                                  <img
                                    src={professionimg}
                                    className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3`}
                                  />
                                  <p className="mb-0 fontSize18">Skill sets</p>
                                </div>
                                <p className="fw-bold value_text fontSize18 mb-1">
                                  {' '}
                                  {userdata.skillset
                                    ? userdata.skillset
                                    : 'Unknown skillset'}
                                </p>
                              </div>

                              <hr />
                            </div>

                            <div>
                              <p className="m-0 font_size16 ">
                                <h4>
                                  <span>
                                    {getPronoun(userdata.gendercode)} Religious
                                    Details
                                  </span>
                                </h4>
                                <div className="mb-1">
                                  <div className="d-flex mt-1">
                                    <img
                                      src={religionimg}
                                      className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3`}
                                    />
                                    <p className="mb-0 fontSize18">
                                      Current Religion
                                    </p>
                                  </div>
                                  <p className="fw-bold value_text fontSize18 mb-1">
                                    {' '}
                                    {userdata.currentreligion
                                      ? userdata.currentreligion
                                      : 'Unknown'}
                                  </p>
                                </div>

                                <div className="mb-1">
                                  <div className="d-flex mt-1">
                                    <img
                                      src={religionimg}
                                      className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3`}
                                    />
                                    <p className="mb-0 fontSize18">
                                      Previous Religion
                                    </p>
                                  </div>
                                  <p className="fw-bold value_text fontSize18 mb-1">
                                    {' '}
                                    {userdata.currentreligion
                                      ? userdata.previousreligion
                                      : 'Unknown'}
                                  </p>
                                </div>
                              </p>
                            </div>

                            <div>
                              <div className="mb-1">
                                <div className="d-flex mt-1">
                                  <img
                                    src={casteimg}
                                    className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3`}
                                  />
                                  <p className="mb-0 fontSize18">Caste</p>
                                </div>
                                <p className="fw-bold value_text fontSize18 mb-1">
                                  {' '}
                                  {userdata.caste ? userdata.caste : 'Unknown'}
                                </p>
                              </div>
                            </div>
                            <div>
                              <div className="mb-1">
                                <div className="d-flex mt-1">
                                  <img
                                    src={meditationimg}
                                    className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3`}
                                  />
                                  <p className="mb-0 fontSize18">
                                    Number Of Vipassana Courses Completed
                                  </p>
                                </div>
                                <p className="fw-bold value_text fontSize18 mb-1">
                                  {' '}
                                  {userdata.noofvipassana
                                    ? userdata.noofvipassana
                                    : 'Unknown'}
                                </p>
                              </div>

                              <div className="mb-1">
                                <div className="d-flex mt-1">
                                  <img
                                    src={meditationimg}
                                    className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3`}
                                  />
                                  <p className="mb-0 fontSize18">
                                    Other Meditation Done
                                  </p>
                                </div>
                                <p className="fw-bold value_text fontSize18 mb-1">
                                  {' '}
                                  {userdata.othermeditation
                                    ? userdata.othermeditation
                                    : 'Unknown'}
                                </p>
                              </div>
                              <hr />
                            </div>

                            <div>
                              <p className="m-0 font_size16 ">
                                <h4>
                                  <span>
                                    {getPronoun(userdata.gendercode)} Family
                                    Details
                                  </span>
                                </h4>

                                <div className="mb-1">
                                  <div className="d-flex mt-1">
                                    <img
                                      src={familyimg}
                                      className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3`}
                                    />
                                    <p className="mb-0 fontSize18">
                                      Family Type
                                    </p>
                                  </div>
                                  <p className="fw-bold value_text fontSize18 mb-1">
                                    {' '}
                                    {userdata.familytype
                                      ? userdata.familytype
                                      : 'Unknown'}
                                  </p>
                                </div>
                              </p>
                              <Row>
                                <Col md={6}>
                                  <div className="mb-1">
                                    <div className="d-flex mt-1">
                                      <img
                                        src={fatherimg}
                                        className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3`}
                                      />
                                      <p className="mb-0 fontSize18">Father</p>
                                    </div>
                                    <p className="fw-bold value_text fontSize18 mb-1">
                                      {' '}
                                      {userdata.father
                                        ? userdata.father
                                        : 'Unknown'}
                                    </p>
                                  </div>

                                  <div className="mb-1">
                                    <div className="d-flex mt-1">
                                      <img
                                        src={motherimg}
                                        className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3`}
                                      />
                                      <p className="mb-0 fontSize18">Mother</p>
                                    </div>
                                    <p className="fw-bold value_text fontSize18 mb-1">
                                      {' '}
                                      {userdata.mother
                                        ? userdata.mother
                                        : 'Unknown'}
                                    </p>
                                  </div>
                                </Col>

                                <div className="mb-1">
                                  <div className="d-flex mt-1">
                                    <img
                                      src={siblingsimg}
                                      className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3`}
                                    />
                                    <p className="mb-0 fontSize18">
                                      No Of Siblings
                                    </p>
                                  </div>
                                  <p className="fw-bold value_text fontSize18 mb-1">
                                    {' '}
                                    {userdata.noofsibling
                                      ? userdata.noofsibling
                                      : 'Unknown'}
                                  </p>
                                </div>

                                <div className="mb-1">
                                  <div className="d-flex mt-1">
                                    <img
                                      src={positionsiblingimg}
                                      className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3`}
                                    />
                                    <p className="mb-0 fontSize18">
                                      Your position in siblings
                                    </p>
                                  </div>
                                  <p className="fw-bold value_text fontSize18 mb-1">
                                    {' '}
                                    {userdata.yourposition
                                      ? userdata.yourposition
                                      : 'Unknown'}
                                  </p>
                                </div>
                              </Row>
                            </div>
                            <div>
                              <div className="mb-1">
                                <div className="d-flex mt-1">
                                  <img
                                    src={dnaimg}
                                    className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3`}
                                  />
                                  <p className="mb-0 fontSize18">
                                    Genetic Disease
                                  </p>
                                </div>
                                <p className="fw-bold value_text fontSize18 mb-1">
                                  {' '}
                                  {userdata.geneticdiseases
                                    ? userdata.geneticdiseases
                                    : 'Unknown'}
                                </p>
                              </div>
                            </div>
                          </>
                        )}
                      </Col>
                    </Row>
                  </div>
                ) : (
                  <div className="mx-4">
                    <Row>
                      <Col md={6}>
                        <ul className="list-unstyled m-2">
                          <h4>
                            <span>Personal Details</span>
                          </h4>
                          {userdata.membertypeid === 1 && (
                            <>
                              <li className=" mx-2 my-1 ">
                                <div className="d-flex">
                                  <img
                                    src={complexionimg}
                                    className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3 mt-1`}
                                  />
                                  <p className="mb-0 fontSize18 mt-1">
                                    Complexion
                                  </p>
                                </div>
                                <p className="fw-bold value_text fontSize18 mb-1">
                                  {' '}
                                  {partnerDetails.complexions
                                    ? partnerDetails.complexions
                                    : 'Unknown Complexion'}
                                </p>
                              </li>
                              <li className=" mx-2 my-1 ">
                                <div className="d-flex">
                                  <img
                                    src={heightimg}
                                    className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3 mt-1`}
                                  />
                                  <p className="mb-0 fontSize18 mt-1">
                                    Max Height
                                  </p>
                                </div>
                                <p className="fw-bold value_text fontSize18 mb-1">
                                  {' '}
                                  {partnerDetails.maxheight != 0
                                    ? partnerDetails.maxheight
                                    : 'Unknown Height'}{' '}
                                  ft
                                </p>
                              </li>
                              <li className=" mx-2 my-1 ">
                                <div className="d-flex">
                                  <img
                                    src={weightimg}
                                    className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3 mt-1`}
                                  />
                                  <p className="mb-0 fontSize18 mt-1">
                                    Max Weight
                                  </p>
                                </div>
                                <p className="fw-bold value_text fontSize18 mb-1">
                                  {' '}
                                  {partnerDetails.endweight != 0
                                    ? partnerDetails.endweight
                                    : 'Unknown Weight'}{' '}
                                  kg
                                </p>
                              </li>
                              <li className=" mx-2 my-1 ">
                                <div className="d-flex mt-1">
                                  <img
                                    src={hairimg}
                                    className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3 mt-1`}
                                  />
                                  <p className="mb-0 fontSize18">Hair Color</p>
                                </div>
                                <p className="fw-bold value_text fontSize18 mb-1">
                                  {' '}
                                  {partnerDetails.haircolors
                                    ? partnerDetails.haircolors
                                    : 'Unknown Hair Color'}
                                </p>
                              </li>
                              <li className=" mx-2 my-1 ">
                                <div className="d-flex mt-1">
                                  <img
                                    src={eyeimg}
                                    className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3 mt-1`}
                                  />
                                  <p className="mb-0 fontSize18">Eye Color</p>
                                </div>
                                <p className="fw-bold value_text fontSize18 mb-1">
                                  {' '}
                                  {partnerDetails.eyecolors
                                    ? partnerDetails.eyecolors
                                    : 'Unknown Eye Color'}
                                </p>
                              </li>
                              {/* <li className=" mx-2 my-1 ">
                                <div className="d-flex mt-1">
                                  <img
                                    src={bloodgroupimg}
                                    className={`${partnerDetails.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3 mt-1`}
                                  />
                                  <p className="mb-0 fontSize18">Blood Group</p>
                                </div>
                                <p className="fw-bold value_text fontSize18 mb-1">
                                  {' '}
                                  {partnerDetails.bloodgroup
                                    ? partnerDetails.bloodgroup
                                    : 'Unknown Blood Group'}
                                </p>
                              </li> */}
                            </>
                          )}
                          {/* <li className=" mx-2 my-1 ">
                            <div className="d-flex mt-1">
                              <img
                                src={cityimg}
                                className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3 mt-1`}
                              />
                              <p className="mb-0 fontSize18">City</p>
                            </div>
                            <p className="fw-bold value_text fontSize18 mb-1">
                              {' '}
                            </p>
                          </li> */}

                          {/* <li
                            className="mx-2 my-1"
                            style={{ cursor: 'not-allowed' }}
                          >
                            <div>
                              <img
                                src={mailimg}
                                className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3`}
                              />
                              <span className="fontSize18 me-2">
                                Email&nbsp;
                              </span>
                              {userdata.emailid == '' && (
                                <span className="">Unknown </span>
                              )}
                              {userdata.emailid != 'Subscription_Required' && (
                                <>
                                  <p className="fw-bold value_text fontSize18 mb-1">
                                    {userdata.emailid}
                                  </p>
                                </>
                              )}
                              {userdata.emailid == 'Subscription_Required' && (
                                <>
                                  <span className="badge rounded bg-soft-success border-1 border-primary font_size18">
                                    <i>
                                      <FeatherIcon
                                        icon="lock"
                                        className="fea icon-sm text-accent me-1"
                                      />{' '}
                                      Subscription Required
                                    </i>
                                  </span>
                                </>
                              )}
                            </div>
                          </li> */}

                          {/* <li className="mx-2 my-1">
                            <div>
                              <img
                                src={callimg}
                                className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3`}
                              />
                              <span className="fontSize18">Mobile </span>
                              {userdata.phonenumber == '' && (
                                <span className="">Unknown </span>
                              )}
                              {userdata.phonenumber !=
                                'Subscription_Required' && (
                                <>
                                  <p className="fw-bold value_text fontSize18 mb-1">
                                    {' '}
                                    (+{userdata.phonecountrycode})-
                                    {userdata.phonenumber}
                                  </p>
                                </>
                              )}
                              {userdata.phonenumber ==
                                'Subscription_Required' && (
                                <>
                                  <span className="badge rounded bg-soft-success border-1 border-primary font_size18">
                                    <i>
                                      <FeatherIcon
                                        icon="lock"
                                        className="fea icon-sm text-accent me-1"
                                      />{' '}
                                      Subscription Required
                                    </i>
                                  </span>
                                </>
                              )}
                            </div>
                          </li> */}

                          <li>
                            <ul className="list-unstyled social-icon social mb-0 fontSize18 mt-4">
                              <li className="list-inline-item">
                                <Link
                                  disabled={
                                    userdata.facebook == 'Subscription_Required'
                                  }
                                  to={userdata.facebook}
                                  className="rounded"
                                >
                                  <FeatherIcon
                                    icon="facebook"
                                    className="fea icon-sm fea-social"
                                  />
                                </Link>
                              </li>{' '}
                              <li className="list-inline-item">
                                <Link
                                  to={userdata.instagram}
                                  className="rounded"
                                >
                                  <FeatherIcon
                                    icon="instagram"
                                    className="fea icon-sm fea-social"
                                  />
                                </Link>
                              </li>{' '}
                              <li className="list-inline-item">
                                <Link to={userdata.twitter} className="rounded">
                                  <FeatherIcon
                                    icon="twitter"
                                    className="fea icon-sm fea-social"
                                  />
                                </Link>
                              </li>{' '}
                              <li className="list-inline-item">
                                <Link
                                  to={userdata.linkedin}
                                  className="rounded"
                                >
                                  <FeatherIcon
                                    icon="linkedin"
                                    className="fea icon-sm fea-social"
                                  />
                                </Link>
                              </li>{' '}
                              <li className="list-inline-item">
                                <Link to={userdata.youtube} className="rounded">
                                  <FeatherIcon
                                    icon="youtube"
                                    className="fea icon-sm fea-social"
                                  />
                                </Link>
                              </li>{' '}
                            </ul>
                          </li>
                        </ul>
                        <hr />
                        <div>
                          <h4>
                            <span>Educational Details</span>
                          </h4>
                          <div className="d-flex mt-1">
                            <img
                              src={degreenameimg}
                              className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3 mt-1`}
                            />
                            <p className="mb-0 fontSize18">Degree</p>
                          </div>
                          <p className="fw-bold value_text fontSize18 mb-1">
                            {' '}
                            {partnerDetails.degreelevels
                              ? partnerDetails.degreelevels
                              : 'Unknown Degree'}{' '}
                            &nbsp; ,
                            {partnerDetails.degreenames
                              ? partnerDetails.degreenames
                              : 'Unknown Degree Name'}
                          </p>

                          <p className="mb-1 ">
                            <div className="d-flex mt-1">
                              <img
                                src={collegeimg}
                                className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3`}
                              />
                              <p className="mb-0 fontSize18">Degree College</p>
                            </div>
                            <p className="fw-bold value_text fontSize18 mb-1">
                              {' '}
                              {partnerDetails.degreeuniversities
                                ? partnerDetails.degreeuniversities
                                : 'Unknown Degree College'}
                            </p>
                          </p>
                        </div>
                        <hr />
                        <div>
                          <h4>
                            <span>Professional Details</span>
                          </h4>
                          <div className="mb-1">
                            <div className="d-flex mt-1">
                              <img
                                src={professionimg}
                                className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3`}
                              />
                              <p className="mb-0 fontSize18">Job Title</p>
                            </div>
                            <p className="fw-bold value_text fontSize18 mb-1">
                              {' '}
                              {partnerDetails.jobtitles
                                ? partnerDetails.jobtitles
                                : 'Unknown Job title'}
                            </p>
                          </div>

                          <div className="mb-1 ">
                            <div className="d-flex mt-1">
                              <img
                                src={companyimg}
                                className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3`}
                              />
                              <p className="mb-0 fontSize18">
                                Company Category
                              </p>
                            </div>
                            <p className="fw-bold value_text fontSize18 mb-1">
                              {' '}
                              {partnerDetails.companycategory
                                ? partnerDetails.companycategory
                                : 'Unknown Company Category'}
                            </p>
                          </div>

                          <div className="mb-1 ">
                            <div className="d-flex mt-1">
                              <img
                                src={location}
                                className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3`}
                              />
                              <p className="mb-0 fontSize18">
                                Company Location
                              </p>
                            </div>
                            <p className="fw-bold value_text fontSize18 mb-1">
                              {' '}
                              {partnerDetails.companylocationid
                                ? partnerDetails.companylocationid
                                : 'Unknown Company Location'}
                            </p>
                          </div>

                          <div className="mb-1 ">
                            <div className="d-flex mt-1">
                              <img
                                src={moneyimg}
                                className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3`}
                              />
                              <p className="mb-0 fontSize18">Salary</p>
                            </div>
                            <p className="fw-bold value_text fontSize18 mb-1">
                              {' '}
                              {partnerDetails.startsalary
                                ? partnerDetails.startsalary
                                : 'Unknown CTC'}
                            </p>
                          </div>

                          {/* <div className="mb-1">
                            <div className="d-flex mt-1">
                              <img
                                src={professionimg}
                                className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3`}
                              />
                              <p className="mb-0 fontSize18">Skill sets</p>
                            </div>
                            <p className="fw-bold value_text fontSize18 mb-1">
                              {' '}
                              {userdata.skillset
                                ? userdata.skillset
                                : 'Unknown skillset'}
                            </p>
                          </div> */}

                          <hr />
                        </div>
                      </Col>
                      <Col md={6}>
                        {userdata.membertypeid === 1 && (
                          <>
                            <div>
                              <h4>
                                <span>Lifestyle Details</span>
                              </h4>
                              <div className="mb-1 ">
                                <div className="d-flex mt-1">
                                  <img
                                    src={nonvegimg}
                                    className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3`}
                                  />
                                  <p className="mb-0 fontSize18">
                                    Diet Preferences
                                  </p>
                                </div>
                                <p className="fw-bold value_text fontSize18 mb-1">
                                  {' '}
                                  {partnerDetails.diettypes
                                    ? partnerDetails.diettypes
                                    : 'Unknown'}
                                </p>
                              </div>

                              <Row>
                                <Col md={6}>
                                  <div className="mb-1 ">
                                    <div className="d-flex mt-1">
                                      <img
                                        src={smokeimg}
                                        className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3`}
                                      />
                                      <p className="mb-0 fontSize18">Smoking</p>
                                    </div>
                                    <p className="fw-bold value_text fontSize18 mb-1">
                                      {' '}
                                      {partnerDetails.smoking
                                        ? partnerDetails.smoking
                                        : 'Unknown'}
                                    </p>
                                  </div>

                                  <div className="mb-1 ">
                                    <div className="d-flex mt-1">
                                      <img
                                        src={drinkimg}
                                        className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3`}
                                      />
                                      <p className="mb-0 fontSize18">
                                        Drinking
                                      </p>
                                    </div>
                                    <p className="fw-bold value_text fontSize18 mb-1">
                                      {' '}
                                      {partnerDetails.drinking
                                        ? partnerDetails.drinking
                                        : 'Unknown'}
                                    </p>
                                  </div>
                                </Col>
                              </Row>

                              <div className="mb-1 ">
                                <div className="d-flex mt-1">
                                  <img
                                    src={excerciseimg}
                                    className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3`}
                                  />
                                  <p className="mb-0 fontSize18">
                                    Exercise Routines
                                  </p>
                                </div>
                                <p className="fw-bold value_text fontSize18 mb-1">
                                  {' '}
                                  {partnerDetails.exercise
                                    ? partnerDetails.exercise
                                    : 'Unknown'}
                                </p>
                              </div>

                              <div className="mb-1 ">
                                <div className="d-flex mt-1">
                                  <img
                                    src={gamesimg}
                                    className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3`}
                                  />
                                  <p className="mb-0 fontSize18">Games</p>
                                </div>
                                <p className="fw-bold value_text fontSize18 mb-1">
                                  {' '}
                                  {partnerDetails.playgames
                                    ? partnerDetails.playgames
                                    : 'Unknown'}
                                </p>
                              </div>

                              <div className="mb-1">
                                <div className="d-flex mt-1">
                                  <img
                                    src={hobbiesimg}
                                    className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3`}
                                  />
                                  <p className="mb-0 fontSize18">
                                    Hobbies_Interest
                                  </p>
                                </div>
                                <p className="fw-bold value_text fontSize18 mb-1">
                                  {' '}
                                  {partnerDetails.hobbies
                                    ? partnerDetails.hobbies
                                    : 'Unknown'}
                                </p>
                              </div>

                              {/* <div className="mb-1">
                                <div className="d-flex mt-1">
                                  <img
                                    src={petimg}
                                    className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3`}
                                  />
                                  <p className="mb-0 fontSize18">
                                    Pet Preference
                                  </p>
                                </div>
                                <p className="fw-bold value_text fontSize18 mb-1">
                                  {' '}
                                  {userdata.petpreference
                                    ? userdata.petpreference
                                    : 'Unknown'}
                                </p>
                              </div> */}
                              <hr />

                              {/* <div className="mb-1">
                                <h4>
                                  <span>
                                    {getPronoun(userdata.gendercode)} Social
                                    Details
                                  </span>
                                </h4>
                                <div className="d-flex mt-1">
                                  <img
                                    src={ngoimg}
                                    className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3`}
                                  />
                                  <p className="mb-0 fontSize18">Part Of NGO</p>
                                </div>
                                <p className="fw-bold value_text fontSize18 mb-1">
                                  {' '}
                                  {userdata.partofngo
                                    ? userdata.partofngo
                                    : 'Unknown'}
                                </p>
                              </div> */}
                              <hr />
                            </div>

                            <div>
                              <p className="m-0 font_size16 ">
                                <h4>
                                  <span>Religious Details</span>
                                </h4>
                                <div className="mb-1">
                                  <div className="d-flex mt-1">
                                    <img
                                      src={religionimg}
                                      className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3`}
                                    />
                                    <p className="mb-0 fontSize18">
                                      Current Religion
                                    </p>
                                  </div>
                                  <p className="fw-bold value_text fontSize18 mb-1">
                                    {' '}
                                    {partnerDetails.currentreligion
                                      ? partnerDetails.currentreligion
                                      : 'Unknown'}
                                  </p>
                                </div>

                                <div className="mb-1">
                                  <div className="d-flex mt-1">
                                    <img
                                      src={religionimg}
                                      className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3`}
                                    />
                                    <p className="mb-0 fontSize18">
                                      Previous Religion
                                    </p>
                                  </div>
                                  <p className="fw-bold value_text fontSize18 mb-1">
                                    {' '}
                                    {partnerDetails.previousreligion
                                      ? partnerDetails.previousreligion
                                      : 'Unknown'}
                                  </p>
                                </div>
                              </p>
                            </div>

                            <div>
                              <div className="mb-1">
                                <div className="d-flex mt-1">
                                  <img
                                    src={casteimg}
                                    className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3`}
                                  />
                                  <p className="mb-0 fontSize18">Caste</p>
                                </div>
                                <p className="fw-bold value_text fontSize18 mb-1">
                                  {' '}
                                  {partnerDetails.caste
                                    ? partnerDetails.caste
                                    : 'Unknown'}
                                </p>
                              </div>
                            </div>
                            <div>
                              <div className="mb-1">
                                <div className="d-flex mt-1">
                                  <img
                                    src={meditationimg}
                                    className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3`}
                                  />
                                  <p className="mb-0 fontSize18">
                                    Number Of Vipassana Courses Completed
                                  </p>
                                </div>
                                <p className="fw-bold value_text fontSize18 mb-1">
                                  {' '}
                                  {partnerDetails.vipassananumbers
                                    ? partnerDetails.vipassananumbers
                                    : 'Unknown'}
                                </p>
                              </div>

                              <div className="mb-1">
                                <div className="d-flex mt-1">
                                  <img
                                    src={meditationimg}
                                    className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3`}
                                  />
                                  <p className="mb-0 fontSize18">
                                    Other Meditation Done
                                  </p>
                                </div>
                                <p className="fw-bold value_text fontSize18 mb-1">
                                  {' '}
                                  {partnerDetails.othermeditationstatus
                                    ? partnerDetails.othermeditationstatus
                                    : 'Unknown'}
                                </p>
                              </div>
                              <hr />
                            </div>

                            <div>
                              <p className="m-0 font_size16 ">
                                <h4>
                                  <span>Family Details</span>
                                </h4>

                                <div className="mb-1">
                                  <div className="d-flex mt-1">
                                    <img
                                      src={familyimg}
                                      className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3`}
                                    />
                                    <p className="mb-0 fontSize18">
                                      Family Type
                                    </p>
                                  </div>
                                  <p className="fw-bold value_text fontSize18 mb-1">
                                    {' '}
                                    {partnerDetails.familytypes
                                      ? partnerDetails.familytypes
                                      : 'Unknown'}
                                  </p>
                                </div>
                              </p>
                              <Row>
                                <Col md={6}>
                                  <div className="mb-1">
                                    <div className="d-flex mt-1">
                                      <img
                                        src={fatherimg}
                                        className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3`}
                                      />
                                      <p className="mb-0 fontSize18">Father</p>
                                    </div>
                                    <p className="fw-bold value_text fontSize18 mb-1">
                                      {' '}
                                      {partnerDetails.fatherrequired
                                        ? partnerDetails.fatherrequired
                                        : 'Unknown'}
                                    </p>
                                  </div>

                                  <div className="mb-1">
                                    <div className="d-flex mt-1">
                                      <img
                                        src={motherimg}
                                        className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3`}
                                      />
                                      <p className="mb-0 fontSize18">Mother</p>
                                    </div>
                                    <p className="fw-bold value_text fontSize18 mb-1">
                                      {' '}
                                      {partnerDetails.motherrequired
                                        ? partnerDetails.motherrequired
                                        : 'Unknown'}
                                    </p>
                                  </div>
                                </Col>

                                <div className="mb-1">
                                  <div className="d-flex mt-1">
                                    <img
                                      src={siblingsimg}
                                      className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3`}
                                    />
                                    <p className="mb-0 fontSize18">
                                      No Of Siblings
                                    </p>
                                  </div>
                                  <p className="fw-bold value_text fontSize18 mb-1">
                                    {' '}
                                    {partnerDetails.numofsiblings
                                      ? partnerDetails.numofsiblings
                                      : 'Unknown'}
                                  </p>
                                </div>

                                {/* <div className="mb-1">
                                  <div className="d-flex mt-1">
                                    <img
                                      src={positionsiblingimg}
                                      className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3`}
                                    />
                                    <p className="mb-0 fontSize18">
                                      Your position in siblings
                                    </p>
                                  </div>
                                  <p className="fw-bold value_text fontSize18 mb-1">
                                    {' '}
                                    {userdata.yourposition
                                      ? userdata.yourposition
                                      : 'Unknown'}
                                  </p>
                                </div> */}
                              </Row>
                            </div>
                            <div>
                              <div className="mb-1">
                                <div className="d-flex mt-1">
                                  <img
                                    src={dnaimg}
                                    className={`${userdata.membertypeid == 1 ? 'modal-img-bg-green' : 'modal-img-bg-blue'} modal-img me-3`}
                                  />
                                  <p className="mb-0 fontSize18">
                                    Genetic Disease
                                  </p>
                                </div>
                                <p className="fw-bold value_text fontSize18 mb-1">
                                  {' '}
                                  {partnerDetails.geneticdisease
                                    ? partnerDetails.geneticdisease
                                    : 'Unknown'}
                                </p>
                              </div>
                            </div>
                          </>
                        )}
                      </Col>
                    </Row>
                  </div>
                )}
              </Card>
            </Col>
            <Col lg="4" md="6" xs="12" className="d-lg-block d-none">
              <h3>You may also like</h3>
              {recommendationUsers.map((user) => (
                <Card className="border-1 rounded shadow" key={user.memberuuid}>
                  <CardBody>
                    <Row className="">
                      <Col lg="6" md="6" className="text-md-center text-center">
                        <div
                          className="position-relative mb-4"
                          style={{ display: 'flex', justifyContent: 'center' }}
                        >
                          <div className="position-relative">
                            {user.photothumb1 ? (
                              <CardImg
                                top
                                src={user.photothumb1}
                                className="border-1 rounded shadow"
                                alt=""
                                style={{
                                  maxHeight: '100%',
                                  maxWidth: '100%',
                                  objectFit: 'contain',
                                }}
                              />
                            ) : (
                              <CardImg
                                top
                                src={candidate1}
                                className="border-1 rounded shadow"
                                alt=""
                                style={{
                                  maxHeight: '100%',
                                  maxWidth: '100%',
                                  objectFit: 'contain',
                                }}
                              />
                            )}
                            <div className="overlay rounded-top bg-dark"></div>
                          </div>
                        </div>
                      </Col>

                      <Col lg="6" md="6">
                        <Row className="pb-4">
                          <h4 className=" mb-0 text-md-start text-start">
                            {/* {user.firstname}, {calculate_age(user.dob)} */}
                            {user.firstname}, {calculate_age(user.dob)}

                          </h4>
                        </Row>

                        <Row className="fontSize14">
                          <div>
                            <FeatherIcon
                              icon="briefcase"
                              className="fea icon-sm icons"
                            />
                            <small className="px-3 fontSize16">
                              {user.jobprofession || 'Job Profession '}
                            </small>
                          </div>

                          <div>
                            <FeatherIcon
                              icon="map-pin"
                              className="fea icon-sm icons"
                            />
                            <small className="px-3 fontSize16">
                              {user.currentlocationname || 'Location no provided'}
                            </small>
                          </div>

                          {/* <div >
                            <FeatherIcon
                              icon="eye"
                              className="fea icon-sm icons"
                              onClick={() => fetchMemberDetails(user.memberuuid)}
                            />
                            <small className="px-3 fontSize16">
                              View profile
                            </small>
                          </div> */}
                        </Row>
                        <Row className="text-start pt-4">
                          <div className="">
                            <button
                              type="button"
                              className="btn btn-outline-primary me-2"
                              onClick={() =>
                                fetchMemberDetails(
                                  user.memberuuid,
                                  user.connectionstatus,
                                  calculate_age(user.dob)
                                )
                              }
                            >
                              <span className="d-flex">
                                <i>
                                  <FeatherIcon
                                    icon="eye"
                                    className="fea icon-sm icons px-1"
                                    style={{ width: `30px` }}
                                  />
                                </i>
                                <span className="d-none d-md-block">View </span>
                              </span>
                            </button>
                          </div>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              ))}
            </Col>
          </Row>
        </Container>
      </section>
      <div
        id="chatbox"
        style={{
          display: 'none',
          position: 'fixed',
          bottom: '20px',
          right: '20px',
          zIndex: 99999,
          width: '316px',
        }}
      >
        <div className="card">
          <div className="card-header">
            <h6 className="text-white my-2 mx-2">
              {' '}
              Messages
              <button
                className="btn btn-sm btn-link float-end p-0"
                onClick={() => closeShortChat()}
              >
                <i className="fa fa-times fa-2x position-static"></i>
              </button>
            </h6>
          </div>
          <div className="card-body p-0">
            <ChatBoxFunction memberdata={userdata} />
          </div>
        </div>
      </div>
      {/* connection MOdal */}

      <Modal
        isOpen={confirmConnectionmodalOpen}
        toggle={!confirmConnectionmodalOpen}
      >
        <ModalHeader toggle={!confirmConnectionmodalOpen}>
          {modalTitle}
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={() => {
              setconfirmConnectionmodalOpen(!confirmConnectionmodalOpen);
            }}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col lg="12">
              <p className="mb-0">{modalDescription}</p>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="p-1">
          <Button
            color="secondary"
            onClick={() => {
              setconfirmConnectionmodalOpen(!confirmConnectionmodalOpen);
            }}
            type="button"
          >
            Close
          </Button>
          {modalConnectButton && (
            <Button
              color="success"
              type="button"
              onClick={requestForConnection}
            >
              Connect
            </Button>
          )}
        </ModalFooter>
      </Modal>
      <Modal isOpen={copyModalOpen == true} toggle={toggleCopyModal}>
        <ModalHeader toggle={toggleCopyModal}>
          Share Url
          <button
            type="button"
            className="btn shareUrlbtn btn-dark"
            onClick={handleCopyText}
          >
            <span aria-hidden="true">
              {copyButton == 'Copy Url' ? (
                <i>
                  <FeatherIcon icon="copy" className="fea icon-sm icons" />
                </i>
              ) : (
                <i>
                  <FeatherIcon icon="check" className="fea icon-sm icons" />
                </i>
              )}{' '}
              {copyButton}
            </span>
          </button>
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={toggleCopyModal}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </ModalHeader>
        <ModalBody className="copy_Modal_body">
          <Row>
            <Col lg="12">
              <p className="mb-0">
                {appUrl}
                {'index/'}
                {userdata.sharedMemberUuid}
              </p>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
  //   }
};

export default ViewProfilePageFunction;
