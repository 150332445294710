import { useCallback } from 'react';
import Talk from 'talkjs';
import { Session, Chatbox } from '@talkjs/react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import Slider from 'react-slick';
import CryptoJS from 'crypto-js';
import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import apiUrl from '../../server';
import loginBackgroundBanner from '../../../assets/images/mainBG-image.jpg';

function Mychat() {
  const [me, setMe] = useState();
  const [other, setOther] = useState();
  const [session, setSession] = useState();
  const [advertiseData, setAdvertiseData] = useState([]);
  const [pageNo, setPageNo] = useState('1');
  const [itemPerPage, setItemPerPage] = useState('1');
  const [signature, setSignature] = useState({});
  const [configurationData, setConfigurationData] = useState(
    JSON.parse(localStorage.getItem('configurationData')),
  );

  if (!configurationData) {
    window.location.href = '/index';
  }

  const getAdvertisementDetails = async () => {
    let clearinterval;
    try {
      const response = await axios.post(
        `${apiUrl}advertisement/getalladvertisement`,
        {
          pageNo: pageNo,
          pageSize: itemPerPage,
          search: '',
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      );
      setAdvertiseData(response.data.details);
      let totalCount = response.data.details.length;
      if (pageNo == totalCount / itemPerPage) {
        pageNo = 1;
        // getAdvertisementDetails(pageNo, itemPerPage)
      } else {
        // setTimeout(() => {
        //     pageNo = pageNo + 1;
        //     getAdvertisementDetails(pageNo, itemPerPage)
        // }, 60000);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getAdvertisementDetails();
  }, []);

  useEffect(() => {
    async function fetchData() {
      const response = await axios.get(`${apiUrl}members/getsignature`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      let ownerData = JSON.parse(localStorage.getItem('configurationData'));
      const me = new Talk.User({
        id: ownerData.memberuuid,
        name: ownerData.firstname,
        role: 'default',
        email: ownerData.email,
        photoUrl: localStorage.getItem('profilePicture'),
      });

      setMe(me);
      const newSession = new Talk.Session({
        appId: 'Dlxn8mVe',
        me: me,
        signature: response.data.details,
      });
      if (newSession) {
        const conversation = newSession.getOrCreateConversation(
          Talk.oneOnOneId(me, other),
        );
        conversation.setParticipant(me);
        const newInbox = newSession.createInbox();
        newInbox.mount(document.getElementById('talkjs-container'));
      }
    }
    fetchData();
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };
  return (
    <React.Fragment>
      <>
        <Container className="pt-3 mt-5 mb-2">
          {/* <section
            className="section_user_home_page"
            style={{
              background: `url(${loginBackgroundBanner}) center center no-repeat`,
              backgroundSize: 'cover',
            }}
            id="userlogin"
          >
            <Container className="section-three pb-1">
              <Row className="position-relative align-items-center pt-5"></Row>
            </Container>
          </section> */}

          <Container className="pb-2">
            <Row className="position-relative align-items-center pt-1 chat-up-spacing">
              <Col md={8} key="1" className="col-12 mt-4 pt-2">
                <Card className="title-heading rounded shadow mb-1">
                  <CardBody>
                    <Row>
                      <Col className={'col-12 text-center'}>
                        <div className="">
                          <h3 className="title mb-0">
                            {' '}
                            Communicate With your Community Members !
                          </h3>
                        </div>
                      </Col>
                    </Row>
                    <div
                      className={`row ${configurationData.memberstatuscode === 3 || configurationData.memberstatuscode === 0 ? 'pointer_event_none' : ''}`}
                    >
                      <div
                        id="talkjs-container"
                        style={{ height: '700px' }}
                        className="pt-3"
                      >
                        <i>Loading chat...</i>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>

              <Col lg={4} className="col-12 mt-4 pt-1">
                <Card className="title-heading rounded shadow mb-5">
                  <CardBody
                    style={{ height: '700px', backgroundColor: '#f2ede9' }}
                  >
                    <p className="font_size16">
                      Community Advertisement ! Please reach out to MettaMate's
                      admin to use this space for showcase your ads to other
                      community Members
                    </p>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </Container>
      </>
    </React.Fragment>
  );
}

export default Mychat;
