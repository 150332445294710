import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import '../Home/css/userHomePage.css';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import manavatar from '../../assets/images/MettaMateImages/male_avatar.jpg';
import femaleavatar from '../../assets/images/MettaMateImages/female_avatar.jpeg';
import transgenderavatar from '../../assets/images/MettaMateImages/transgenderprofile.png';
import FeatherIcon from 'feather-icons-react';
import ProfileModal from '../Home/Profile_modal';
import apiUrl from '../server';
import DataContext from '../Home/context';
import ModalBodySkeleton from './skeleton';
import waveIcon from '../../assets/images/MettaMateImages/Group189.svg';
// Import images
import mainBanner from '../../assets/images/mainBG-image.jpg';
import Topbar from '../../components/Layout/Topbar';

const ProfilecardFunction = () => {
  const [currentLocation, setCurrentLocation] = useState({});
  const [nativeLocation, setNativeLocation] = useState({});
  const [companyLocation, setCompanyLocation] = useState({});
  const [memberFullData, setMemberFullData] = useState([]);
  const [memberModal, setModal] = useState(false);
  const [copyModalOpen, setcopyModalOpen] = useState(false);
  const [sharedMemberUuid, setsharedMemberUuid] = useState({});
  const [copyButton, setCopyButton] = useState('Copy Url');
  const [pageUrl, setpageUrl] = useState({});
  const [dob, setDOB] = useState({});
  const [isModalLoading, setModalLoading] = useState(true);
  const [profileVisitModal, setProfileVisitModal] = useState(false);
  // const [activeLink, setactiveLink] = useState('allainceseeker')
  const { activeLink, setActiveLink } = useContext(DataContext);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [profileVisits, setProfileVisits] = useState({
    details: [],
    totalCount: '',
  });

  const navigate = useNavigate();
  let configuredata = JSON.parse(localStorage.getItem('configurationData'));
  let ProfilePicture = localStorage.getItem('profilePicture');
  let location = window.location.href.split('/');
  let currentPage = location[3];

  useEffect(() => {
    configuredata = JSON.parse(localStorage.getItem('configurationData'));
    setpageUrl(apiUrl.replace('8080', '3000'));
    setActiveLink(1);
    const visitedResult = axios
      .get(
        `${apiUrl}members/viewedprofile/${localStorage.getItem('memberID')}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      )
      .then(async (res) => {
        setProfileVisits({
          details: res.data.details,
          totalCount: res.data.totalCount,
        });
      });
  }, []);

  const openModal = async (memberuuid) => {
    setModalLoading(true);
    setModal(!memberModal);
    let memberid = memberuuid ? memberuuid : localStorage.getItem('memberID');
    // if(memberuuid){
    //     memberid = memberuuid
    // }
    // else{
    //     memberid = localStorage.getItem('memberID')
    // }
    try {
      const result = await axios.get(
        `${apiUrl}members/fulldetails/${memberid}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        },
      );
      const { data } = result;
      if (data && data.length > 0) {
        const member = data[0];
        const date = new Date(member.dob);
        setDOB(
          `${getOrdinalSuffix(date.getDate())} ${getMonthName(date.getMonth())}, ${date.getFullYear()}`,
        );
        setNativeLocation( member.nativelocationname || 'Location not updated');
        setCurrentLocation( member.currentlocationname || 'Location not updated');
        setCompanyLocation( member.companylocationname || 'Location not updated');
        setMemberFullData(member);
        setModalLoading(false);
      }
    } catch (error) {
      console.error('Error fetching member details: ', error);
    }
  };

  const closeModal = () => {
    setModal(false);
  };
  const closeProfileVisitModal = () => {
    setProfileVisitModal(false);
  };
  const getOrdinalSuffix = (day) => {
    const suffixes = ['th', 'st', 'nd', 'rd'];
    const v = day % 100;
    return day + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
  };
  const getMonthName = (month) => {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    return months[month];
  };

  const redirectToSubscription = () => {
    navigate('/subscriptionPlan');
  };
  const openCopyModal = (memberdata) => {
    setcopyModalOpen(true);
    setsharedMemberUuid(memberdata.memberuuid);
  };
  const toggleCopyModal = () => {
    setcopyModalOpen(false);
  };

  const handleCopyText = () => {
    const urlToCopy = `${pageUrl}index/${sharedMemberUuid}`;
    const textArea = document.createElement('textarea');
    textArea.value = urlToCopy;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    setCopyButton('Copied!');
    setTimeout(() => {
      setCopyButton('Copy Url');
    }, 3000);
  };
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const handleSettingsClick = () => {
    navigate('/settings');
  };

  //   logout = async () => {
  //     let result = await axios.post(
  //       `${apiUrl}auth/logout`,
  //       { memberuuid: localStorage.getItem('memberID') },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem('token')}`,
  //           'Content-Type': 'application/json',
  //         },
  //       },
  //     );
  //     localStorage.clear();

  //     this.setState({ isLoggedIn: false }); // Reset sessionnavLinks
  //   };

  return (
    <>
      <section
        className="d-table w-100 pt-1 mt-1"
        style={{
          background: `url(${mainBanner}) center center no-repeat`,
          backgroundSize: 'cover',
        }}
        id="registration"
      >
        <Container className="pb-2">
          <Row className="position-relative align-items-center profile-main pt-2">
            <Col lg="12">
              <Card className="border-0 rounded shadow profile_card mt-2">
                <CardBody className="card-body profile_card_body profile-card p-3">
                  <Row className="">
                    <Col
                      lg="2"
                      md="3"
                      sm="12"
                      className="text-md-start text-center "
                    >
                      {ProfilePicture != 'undefined' && (
                        <>
                          <img
                            src={ProfilePicture}
                            className="avatar avatar-large rounded-circle  d-block mx-auto avatar_image"
                            alt=""
                          />
                        </>
                      )}
                      {ProfilePicture == 'undefined' && (
                        <div>
                          {configuredata.gendercode === 1 && (
                            <img
                              src={manavatar}
                              className="avatar avatar-large rounded-circle  d-block mx-auto avatar_image"
                              alt=""
                            />
                          )}
                          {configuredata.gendercode === 2 && (
                            <img
                              src={femaleavatar}
                              className="avatar avatar-large rounded-circle  d-block mx-auto avatar_image"
                              alt=""
                            />
                          )}
                          {configuredata.gendercode === 3 && (
                            <img
                              src={transgenderavatar}
                              className="avatar avatar-large rounded-circle  d-block mx-auto avatar_image"
                              alt=""
                            />
                          )}
                        </div>
                      )}
                    </Col>

                    <Col
                      lg="10"
                      md="9"
                      sm={12}
                      className="justify-content-center"
                    >
                      <div className="d-flex">
                        <p className="title mb-0 title-text username text-primary">
                          {configuredata.firstname} {configuredata.lastname}
                        </p>
                        <div className="d-flex"></div>

                        {/* </button> */}
                      </div>

                      <Row>
                        <Col md={5} className="">
                          <ul className=" mb-0 p-0  social-icon social">
                            <li className="list-inline-item me-2">
                              {configuredata.jobprofession != '' && (
                                <small className=" text-dark me-2 fontSize16">
                                  {configuredata.jobprofession}
                                </small>
                              )}
                              {configuredata.jobprofession == '' && (
                                <small className="text-muted h6 me-2">
                                  Unknown Profession
                                </small>
                              )}
                            </li>{' '}
                            <li className="list-inline-item me-2">
                              <button
                                className="btn btn-outline-primary py-1 p-1 my-2"
                                disabled={
                                  configuredata.memberstatuscode == 3 ||
                                  configuredata.memberstatuscode == 0
                                }
                                onClick={(e) => openModal('')}
                              >
                                View Profile
                              </button>
                            </li>
                          </ul>
                        </Col>

                        <Col md={7} sm={12} className="text-md-end text-center">
                          <ul className="mb-0 p-0 my-2 ">
                            <li
                              className=" list-inline-item configuration_btn"
                              title="About Me"
                            >
                              {configuredata.memberstatuscode == 1 ? (
                                <div>
                                  {configuredata.membertypeid == 1 && (
                                    <Link
                                      style={{
                                        width: `100%`,
                                        padding: `0px 10px`,
                                        pointerEvents: 'auto',
                                      }}
                                      to="/my-profile-edit-page"
                                      className={`rounded btn btn-outline-primary py-1 h2 d-flex ${currentPage === 'my-profile-edit-page' ? 'active' : ''}`}
                                    >
                                      {/* <i className="uil uil-setting align-middle" style={{ fontSize: `18px` }}></i>{' '} */}
                                      <i>
                                        <FeatherIcon
                                          icon="user"
                                          className="fea icon-sm mb-1"
                                        />
                                      </i>
                                      <span
                                        style={{ fontSize: `16px` }}
                                        className=" d-md-block mx-1"
                                      >
                                        About Me
                                      </span>
                                    </Link>
                                  )}
                                  {configuredata.membertypeid == 2 && (
                                    <Link
                                      style={{
                                        width: `100%`,
                                        padding: `0px 10px`,
                                        pointerEvents: 'auto',
                                      }}
                                      to="/matchmaker"
                                      className={`rounded btn btn-outline-primary py-1 d-flex ${currentPage === 'matchmaker' ? 'active' : ''}`}
                                    >
                                      <i>
                                        <FeatherIcon
                                          icon="user"
                                          className="fea icon-sm mb-1"
                                        />
                                      </i>
                                      <span
                                        style={{ fontSize: `16px` }}
                                        className="  d-md-block mx-1"
                                      >
                                        About Me
                                      </span>
                                    </Link>
                                  )}
                                  {configuredata.membertypeid == 3 && (
                                    <Link
                                      style={{
                                        width: `100%`,
                                        padding: `0px 10px`,
                                        pointerEvents: 'auto',
                                      }}
                                      to="/sbpartner"
                                      className={`rounded btn btn-outline-primary py-1 text-white d-flex ${currentPage === 'sbpartner' ? 'active' : ''}`}
                                    >
                                      <i>
                                        <FeatherIcon
                                          icon="user"
                                          className="fea icon-sm mb-1"
                                        />
                                      </i>
                                      <span
                                        style={{ fontSize: `16px` }}
                                        className="  d-md-block mx-1"
                                      >
                                        About Me
                                      </span>
                                    </Link>
                                  )}
                                  {configuredata.membertypeid == 4 && (
                                    <Link
                                      style={{
                                        width: `100%`,
                                        padding: `0px 10px`,
                                        pointerEvents: 'auto',
                                      }}
                                      to="/configuregeneralmember"
                                      className={`rounded btn btn-outline-primary py-1 d-flex ${currentPage === 'configuregeneralmember' ? 'active' : ''}`}
                                    >
                                      <i>
                                        <FeatherIcon
                                          icon="user"
                                          className="fea icon-sm mb-1"
                                        />
                                      </i>
                                      <span
                                        style={{ fontSize: `16px` }}
                                        className="ne d-md-block mx-1"
                                      >
                                        About Me
                                      </span>
                                    </Link>
                                  )}
                                </div>
                              ) : (
                                <div
                                  style={{
                                    width: `100%`,
                                    padding: `0px 10px`,
                                    pointerEvents: 'none',
                                    opacity: 0.5,
                                  }}
                                  className={`rounded btn btn-outline-primary py-1 d-flex`}
                                >
                                  <i>
                                    <FeatherIcon
                                      icon="user"
                                      className="fea icon-sm mb-1"
                                    />
                                  </i>
                                  <span
                                    style={{ fontSize: `16px` }}
                                    className=" d-none d-md-block mx-1"
                                  >
                                    About Me
                                  </span>
                                </div>
                              )}
                            </li>{' '}
                            {configuredata.membertypeid == 1 && (
                              <li
                                className="list-inline-item"
                                title="Expectations"
                              >
                                {configuredata.memberstatuscode !== 3 ? (
                                  <Link
                                    style={{
                                      width: `100%`,
                                      padding: `0px 10px`,
                                      pointerEvents: 'auto',
                                    }}
                                    to="/partner-preference-page"
                                    className={`rounded btn btn-outline-primary py-1  d-flex ${currentPage === 'partner-preference-page' ? 'active' : ''}`}
                                  >
                                    <i>
                                      <FeatherIcon
                                        icon="slack"
                                        className="fea icon-sm mb-1"
                                      />
                                    </i>
                                    <span
                                      style={{ fontSize: `16px` }}
                                      className=" d-md-block mx-1"
                                    >
                                      Partner Expectations
                                    </span>
                                  </Link>
                                ) : (
                                  <div
                                    style={{
                                      width: `100%`,
                                      padding: `0px 10px`,
                                      pointerEvents: 'none',
                                      opacity: 0.5,
                                    }}
                                    className={`rounded btn btn-outline-primary py-1  d-flex`}
                                  >
                                    <i>
                                      <FeatherIcon
                                        icon="slack"
                                        className="fea icon-sm mb-1"
                                      />
                                    </i>
                                    <span
                                      style={{ fontSize: `16px` }}
                                      className=" d-md-block mx-1"
                                    >
                                      Expectations
                                    </span>
                                  </div>
                                )}
                              </li>
                            )}
                           
                            <li
                              className="list-inline-item configuration_btn"
                              title="Admin"
                            >
                              {configuredata.role == 'admin' && (
                                <Link
                                  style={{
                                    padding: `0px 10px`,
                                    pointerEvents: 'auto',
                                  }}
                                  to="/adminportal"
                                  className={`rounded btn btn-outline-primary py-1 h2 d-flex ${currentPage === 'adminportal' ? 'active' : ''}`}
                                >
                                  <i>
                                    <FeatherIcon
                                      icon="users"
                                      className="fea icon-sm "
                                    />
                                  </i>
                                  <span
                                    style={{ fontSize: `16px` }}
                                    className=" d-none d-md-block mx-1"
                                  >
                                    Admin
                                  </span>
                                </Link>
                              )}
                            </li>
                            {/* <li
                              className=" list-inline-item configuration_btn"
                              title="About Me"
                            >
                              <Link
                                style={{
                                  padding: `0px 10px`,
                                  pointerEvents: 'auto',
                                }}
                                to="/user-login-page"
                                onClick={() => Topbar.logout()}
                                className={`mx-2 rounded btn btn-outline-primary py-1 h2 d-flex ${currentPage === 'settings' ? 'active' : ''}`}
                              >
                                <i>
                                  <FeatherIcon
                                    icon="log-out"
                                    className="fea icon-sm "
                                  />
                                </i>
                                <span
                                  style={{ fontSize: `16px` }}
                                  className=" d-none d-md-block mx-1"
                                >
                                  Logout
                                </span>
                              </Link>
                            </li> */}
                          </ul>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <Modal isOpen={memberModal} size="xl">
          <ModalHeader toggle={() => openModal('')}>
            Profile Details
            <button
              type="button"
              className="close-button"
              aria-label="Close"
              onClick={closeModal}
            >
              <span aria-hidden="true">&times;</span>
            </button>
            <button
              type="button"
              className="btn shareBtn"
              onClick={() => openCopyModal(memberFullData)}
            >
              <span aria-hidden="true" className="d-flex">
                {' '}
                <span className="d-none d-md-block">Share This Profile </span>
                <i>
                  <FeatherIcon
                    icon="share-2"
                    className="fea icon-sm icons px-1"
                    style={{ width: `30px` }}
                  />
                </i>
              </span>
            </button>
          </ModalHeader>
          {isModalLoading ? (
            <ModalBody>
              <ModalBodySkeleton />
            </ModalBody>
          ) : (
            <div>
              {memberFullData && Object.keys(memberFullData).length > 0 && (
                <ProfileModal
                  memberData={memberFullData}
                  location={[nativeLocation, currentLocation, companyLocation]}
                  dob={dob}
                />
              )}
            </div>
          )}
        </Modal>

        <Modal isOpen={copyModalOpen} toggle={toggleCopyModal}>
          <ModalHeader toggle={toggleCopyModal}>
            Share Url
            <button
              type="button"
              className="btn shareBtn shareUrlbtn btn-dark text-white btn-outline-secondary"
              onClick={handleCopyText}
            >
              <span aria-hidden="true">
                {copyButton == 'Copy Url' ? (
                  <i>
                    <FeatherIcon icon="copy" className="fea icon-sm icons" />
                  </i>
                ) : (
                  <i>
                    <FeatherIcon icon="check" className="fea icon-sm icons" />
                  </i>
                )}{' '}
                {copyButton}
              </span>
            </button>
            <button
              type="button"
              className="close-button"
              aria-label="Close"
              onClick={toggleCopyModal}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </ModalHeader>
          <ModalBody className="copy_Modal_body">
            <Row>
              <Col lg="12">
                <p className="mb-0">
                  {pageUrl}
                  {'index/'}
                  {sharedMemberUuid}
                </p>
              </Col>
            </Row>
          </ModalBody>
        </Modal>

        <Modal isOpen={profileVisitModal} toggle={closeProfileVisitModal}>
          <ModalHeader toggle={closeProfileVisitModal}>
            Profile Visits
            <button
              type="button"
              className="close-button"
              aria-label="Close"
              onClick={closeProfileVisitModal}
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col lg="12">
                {(profileVisits.details || []).map((item, index) => (
                  <div key={index} className="d-flex">
                    <i
                      className="fa fa-angle-double-right mt-1"
                      aria-hidden="true"
                    ></i>
                    &nbsp; &nbsp;
                    <div
                      className="cursor-pointer"
                      onClick={() => openModal(item.memberuuid)}
                    >
                      {' '}
                      {item.firstname} {item.lastname}
                    </div>
                  </div>
                ))}
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </section>
    </>
  );
};
export default ProfilecardFunction;
