import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import '../Home/css/register.css';
import CommonModal from '../Home/modal.js';
import 'react-phone-input-2/lib/style.css'; // Import the styles
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css'; // Import the material theme (optional)
import apiUrl from '../server';
import axios from 'axios';
import manavatar from '../../assets/images/MettaMateImages/male_avatar.jpg';
import femaleavatar from '../../assets/images/MettaMateImages/female_avatar.jpeg';
import transgenderavatar from '../../assets/images/MettaMateImages/transgenderprofile.png';

import {
  Container,
  Row,
  Col,
  Card,
  Form,
  CardBody,
  Label,
  Button,
  Input,
  FormFeedback,
  CardTitle,
  CardText,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Modal,
} from 'reactstrap';
import FeatherIcon from 'feather-icons-react';
import { useFormik } from 'formik';
//import images
import services from '../../assets/images/construction/serveices.png';
import welcomePicture from '../../assets/images/MettaMateImages/WelcomePic.jpeg';
import { useNavigate } from 'react-router-dom';
// Import images
import mainBanner from '../../assets/images/mainBG-image.jpg';
import mmlogo from '../../assets/images/mettamate/FinalLogoMM.svg';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import ChooseOption from './ChooseOption';

// const [phoneCountryCode, phoneCountryCodeSetter] = useState();
const RegistrationPhase1 = (props) => {
  const [error, setError] = useState();
  const [value, setValue] = useState('');
  const [value1, setValue1] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const [phoneNumber1, setPhoneNumber1] = useState();
  const [countryCode, setCountryCode] = useState('');
  const [countryCode1, setCountryCode1] = useState('');
  const [genderList, setGenderList] = useState([]);
  const [memberTypeList, setMemberTypeList] = useState([]);
  const [success, setSuccess] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [membershipTypeValue, setMembershipTypeValue] = useState(1);

  const [dateOfBirth, setDateOfBirth] = useState({ month: "", day: "", year: "" });
  const [dobError, setDobError] = useState('');
  const [selectedMem, setselectedMem] = useState('');
  const [occupationalList, setOccupationalList] = useState([]);
  const [jobProfession, setJobProfession] = useState('');

  const [currentaddress, setCurrentAddress] = useState('');
  const [currentmapvalue, setcurrentmapValue] = useState(null);
  const [currentcity, setcurrentCity] = useState('');
  const [currentstate, setcurrentState] = useState('');
  const [currentcountry, setcurrentCountry] = useState('');


  const [typeOfMember, setTypeOfMember] = useState('');
  const [profilePic, setProfilePic] = useState('');
  const [profilePicError, setProfilePicError] = useState('');
  const [imageProfile, setImageProfile] = useState(null);

  const [ggender, setGgender] = useState('');
  const [registrationOption, setRegistrationOption] = useState(true);


  const isMobile = window.innerWidth < 768;




  const [step, setStep] = useState(1);



  const [memberCategoriList, setMemberCategoriList] = useState([
    {
      value: 1,
      label: 'Alliance Seeker',
    },
    {
      value: 4,
      label: 'Community Member',
    },

  ]);

  const imageRef = useRef(null);

  const [emptyCartModal, setEmptyCartModal] = useState(false);
  function emptyCart_modal() {
    setEmptyCartModal(!emptyCartModal);
  }

  function countryselect() {
    return (
      <PhoneInput
        placeholder="Enter phone number"
        value={value}
        onChange={setValue}
        country={'in'}
        error={
          value
            ? isValidPhoneNumber(value)
              ? undefined
              : 'Invalid phone number'
            : 'Phone number required'
        }
      />
    );
  }
  useEffect(() => {
    // selectedallinaceseeker();
    window.scrollTo(0, 0);
    fetchGenderList();
    //fetchMemberTypeList();
    fetchOccupationList();
    fetchTypesOfMember();
  }, []);

  const fetchTypesOfMember = async () => {
    try {
      const response = await axios.get(apiUrl + 'codes/joiningpurpose/', {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setMemberTypeList(response.data.list);
      // console.log(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchOccupationList = async () => {
    try {
      const response = await axios.get(apiUrl + 'codes/occupationalcategory/', {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setOccupationalList(response.data.list);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchGenderList = async () => {
    try {
      const response = await axios.get(apiUrl + 'codes/gender/', {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setGenderList(response.data.list);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchMemberTypeList = async () => {
    try {
      const response = await axios.get(apiUrl + 'codes/membertype/', {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      // setMemberTypeList(response.data.list);
    } catch (error) {
      console.error('Error fetching data:', error);

    }
  };
  const handleOnChange = (value, country) => {
    console.log(value, country);

    setCountryCode(country.dialCode);
    setPhoneNumber(value.replace(countryCode, ''));
  };

  const handlerecommenderCountrycodechange = (value, country) => {
    setCountryCode1(country.dialCode);
    setPhoneNumber1(value.replace(countryCode1, ''));
  };
  function countrySelect1() {
    return (
      <PhoneInput
        placeholder="Enter phone number"
        value={value1}
        onChange={setValue1}
      />
    );
  }
  const navigate = useNavigate();
  const [isVisible, setIsVisible] = useState(false);
  const changeHandler = (e) => {
    if (e.target.value === '1') {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      mobileno: '',
      gender: '',
      membershiptype: '',
      recommenders: '',
      recommenderemail: '',
      recommenderphone: '',
      lastname: '',
      firstname: '',
      countrycode: '',
      recommendercountrycode: '',
      membershipTypeValue: 1,
      //new params
      dob: '',
      jobprofession: '', //no.
      currentcity : '',
      currentstate: '',
      currentcountry: '',
      currentlocationname: '',
      currentlocationid: '',
      typeofmember: '',
      photopath1: '',
      confirmpassword: '',
    },

    onSubmit: async (values) => {
      // setisLoading(true);

      const { month, day, year } = dateOfBirth;
      if (month && day && year) {
        values.dob = `${year}-${month}-${day}`;
      }
      values.countrycode = countryCode;
      values.recommendercountrycode = countryCode1;
      values.mobileno = phoneNumber;
      values.recommenderphone = phoneNumber1 || '';

      //new params
      // values.dob = dateOfBirth;
      values.jobprofession = jobProfession;
      values.currentlocationid = currentmapvalue ? currentmapvalue : '';
      values.currentcity = currentcity;
      values.currentstate = currentstate;
      values.currentcountry = currentcountry;
      values.currentlocationname = currentaddress;
      values.typeofmember = typeOfMember;

      //var selectElement = document.getElementById('membershipSelect');
      //values.membershiptype = memberTypeList;
      values.membershiptype = membershipTypeValue;

      // var genderElement = document.getElementById('genderSelect');
      values.gender = parseInt(ggender);
      var recommenderElement = document.getElementById('recommendersSelect');
      values.recommenders = recommenderElement.value || '';
      delete values.confirmpassword;
      console.log(profilePic);

      if (!profilePic) {
        setProfilePicError(true);
        return;
      }

      try {
        const response = await axios.post(
          apiUrl + 'registration/init',
          values,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        );
        if (response.status == 200 || response.status == 'OK') {
          if (profilePic) {
            const formData = new FormData();
            formData.append('image', profilePic);
            formData.append('memberUUID', response.data.memberUUID);
            formData.append('imageindex', '0');

            let response2 = await axios.post(
              apiUrl + 'share/uploadImage',
              formData,
              {
                headers: {
                  'Content-Type': 'multipart/form-data',
                  Authorization: 'Bearer' + ' ' + localStorage.getItem('token'),
                },
              },
            );
          }

          localStorage.setItem('email', values.email);
          localStorage.setItem('memberID', response.data.memberUUID);
          setSuccess({
            title: 'SUCCESS!',
            message: 'OTP Send to Your Email',
          });

          setTimeout(() => {
            navigate('/confirm-user-registration');
          }, 2000);
        } else {
          setError({
            title: 'An error occurred!',
            message: response.data.message,
          });
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        // setisLoading(false);
      }
    },
  });

  const errorHandler = () => {
    setError(null);
  };
  const successHandler = () => {
    setSuccess(null);
  };
  const [modal, setModal] = useState(false);
  function tog_modal() {
    setModal(!modal);
  }


  const isValidDate = (month, day, year) => {
    if (!month || !day || !year) return false;
    const today = new Date();
    const dob = new Date(year, month - 1, day); // Months are 0-indexed
    const age = today.getFullYear() - dob.getFullYear();
    const m = today.getMonth() - dob.getMonth();

    console.log(
      age > 18,
      age === 18 && today.getMonth() > dob.getMonth(),
      age === 18 &&
      today.getMonth() === dob.getMonth() &&
      today.getDate() >= dob.getDate(),
    );

    return (
      age > 18 ||
      (age === 18 && today.getMonth() > dob.getMonth()) ||
      (age === 18 && today.getMonth() === dob.getMonth() && today.getDate() >= dob.getDate())
    );
  };

  const handleDateChange = (event, field) => {
    const value = event.target.value;
    setDateOfBirth((prevDate) => {
      const updatedDate = { ...prevDate, [field]: value };
      if (updatedDate.month && updatedDate.day && updatedDate.year) {
        const { month, day, year } = updatedDate;
        if (isValidDate(month, day, year)) {
          setDobError("");
        } else {
          setDobError("Age should be above 18");
        }
      }
      return updatedDate;
    });
  };

  const generateOptions = (start, end) => {
    const options = [];
    for (let i = start; i <= end; i++) {
      options.push(
        <option key={i} value={i}>
          {i < 10 ? `0${i}` : i}
        </option>
      );
    }
    return options;
  };

  // Function for Year (Descending Order)
  const generateYearOptions = (start, end) => {
    const options = [];
    for (let i = start; i >= end; i--) {
      options.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
    return options;
  };


  const handleCurrentAddressChange = (newAddress) => {
    setCurrentAddress(newAddress);
  };

  const handlecurrentAddressSelect = async (address, placeid) => {
    setcurrentmapValue(placeid);
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);

      setCurrentAddress(address);
      const addressComponents = results[0].address_components;
      const city =
        addressComponents.find((component) =>
          component.types.includes('locality'),
        )?.long_name || '';
      const state =
        addressComponents.find((component) =>
          component.types.includes('administrative_area_level_1'),
        )?.long_name || '';
      const country =
        addressComponents.find((component) =>
          component.types.includes('country'),
        )?.long_name || '';

      // Store the city, state, and country in your state or do something else with this information
      setcurrentCity(city);
      setcurrentState(state);
      setcurrentCountry(country);
      let locationDetails = [city, state, country].filter(Boolean).join(", ");
      setCurrentAddress(locationDetails);
    } catch (error) {
      console.error('Error selecting address:', error);
    }
  };

  const handleDocAttachement = async (e, docName) => {
    const file = e.target.files[0];
    console.log(file);

    const validImageTypes = [
      'image/jpeg',
      'image/png',
      'image/gif',
      'image/svg+xml',
    ];

    if (file) {
      if (!validImageTypes.includes(file.type)) {
        setProfilePicError(true);
        setImageProfile('');
        return;
      }

      const imageURL = URL.createObjectURL(file);
      setImageProfile(imageURL);
      setProfilePic(file);
      setProfilePicError(false);
    }
  };

  return (
    <React.Fragment>
      {error && (
        <CommonModal
          title={error.title}
          message={error.message}
          onClose={errorHandler}
          status="error"
        />
      )}

      {success && (
        <CommonModal
          title={success.title}
          message={success.message}
          onClose={successHandler}
          status="success"
        />
      )}
      {isLoading ? (
        <div id="preloader">
          <div id="status">
            <div className="spinner">
              <div className="double-bounce1"></div>
              <div className="double-bounce2"></div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <section
            className="d-table w-100 registration-section"
            style={{
              background: isMobile ? 'none' : `url(${mainBanner}) center center no-repeat`,
              backgroundSize: isMobile ? 'auto' : 'cover',
            }}
            id="registration"
          >
            <Container className="section-three py-2">
              <Row className="position-relative align-items-center registration-main">
                <Col lg={6} className="offset-lg-6">
                  <Card
                    className="title-heading studio-home rounded shadow registration-card "                  
                  >
                    <Row className="justify-content-center text-black">
                      <h3 className="text-center pt-3 text-black registration-wel">
                        Welcome to MettaMate for SignUp !
                      </h3>
                      {step === 2 && (
                        <h5 className="text-center fw-normal">
                          As{' '}
                          <h5 className="text-danger d-inline">
                            {
                              memberCategoriList.filter(
                                (member) =>
                                  member.value === membershipTypeValue,
                              )?.[0]?.label
                            }
                          </h5>
                        </h5>
                      )}
                    </Row>
                    <Form
                      className="my-2"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      {step === 1 && (
                        <div className="slide-right-animation">
                          <Row className="justify-content-center text-black">
                            <p className="text-center registration-wel-sub text-black ">
                              What you would like to join us as ?
                            </p>
                          </Row>
                         


                          {registrationOption ? <ChooseOption setRegistrationOption={setRegistrationOption} registrationOption={registrationOption} setMembershipTypeValue={setMembershipTypeValue} /> :
                            <Row className="justify-content-center text-black">
                              <Col lg={12} className="p-2">
                                <Row>
                                  <Col md={3} />

                                  <Col md={3} />
                                  <Col md={12} className="fieldset">
                                    <div className="legend">
                                      Unique Identifiers
                                    </div>
                                    <Row className="mt-4">
                                      <Col md={6} xs={12}>
                                        <div className=" features feature-primary py-0">
                                          <div className="mb-3">

                                            <PhoneInput
                                              className=" form-control form_Input"
                                              international
                                              minLength={10}
                                              maxLength={13}
                                              country={'in'}
                                              value={phoneNumber}
                                              error={
                                                value
                                                  ? isValidPhoneNumber(value)
                                                    ? undefined
                                                    : 'Invalid phone number'
                                                  : 'Phone number required'
                                              }
                                              onChange={(value, country) => {
                                                setPhoneNumber(value);
                                                setCountryCode(country.dialCode);
                                              }}

                                            />
                                          </div>
                                        </div>
                                      </Col>

                                      <Col md={6} xs={12}>
                                        <div className="features feature-primary  py-0">
                                          <div className="mb-3">

                                            <div className="form-icon position-relative">
                                              <i>
                                                <FeatherIcon
                                                  icon="mail"
                                                  className="fea icon-sm icons"
                                                />
                                              </i>
                                            </div>
                                            <Input
                                              type="email"
                                              className="form-control form_Input ps-5"
                                              placeholder="Email"
                                              name="email"
                                              required
                                              onChange={validation.handleChange}
                                              onBlur={validation.handleBlur}
                                              value={
                                                validation.values.email || ''
                                              }
                                              invalid={
                                                validation.touched.email &&
                                                  validation.errors.email
                                                  ? true
                                                  : false
                                              }
                                            />
                                            {validation.touched.email &&
                                              validation.errors.email ? (
                                              <FormFeedback type="invalid">
                                                {validation.errors.email}
                                              </FormFeedback>
                                            ) : null}
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Col>

                                  <Col md={6} xs={12}>
                                    <div className="features feature-primary py-0">
                                      <div className="mb-3">
                                        <Label className="form-label label_text">
                                          First Name{' '}
                                          <span className="text-danger">*</span>
                                        </Label>
                                        <div className="form-icon position-relative">
                                          <i>
                                            <FeatherIcon
                                              icon="user"
                                              className="fea icon-sm icons"
                                            />
                                          </i>
                                        </div>
                                        <Input
                                          type="text"
                                          className="form-control form_Input ps-5"
                                          placeholder="First Name"
                                          name="firstname"
                                          required
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={
                                            validation.values.firstname || ''
                                          }
                                          invalid={
                                            validation.touched.firstname &&
                                              validation.errors.firstname
                                              ? true
                                              : false
                                          }
                                        />
                                        {validation.touched.firstname &&
                                          validation.errors.firstname ? (
                                          <FormFeedback type="invalid">
                                            {validation.errors.firstname}
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                    </div>
                                  </Col>

                                  <Col md={6} xs={12}>
                                    <div className="features feature-primary  py-0">
                                      <div className="mb-3">
                                        <Label className="form-label label_text">
                                          Last Name{' '}
                                          <span className="text-danger">*</span>
                                        </Label>
                                        <div className="form-icon position-relative">
                                          <i>
                                            <FeatherIcon
                                              icon="user"
                                              className="fea icon-sm icons"
                                            />
                                          </i>
                                        </div>
                                        <Input
                                          type="text"
                                          className="form-control form_Input ps-5"
                                          placeholder="Last Name"
                                          name="lastname"
                                          required
                                          onChange={validation.handleChange}
                                          onBlur={validation.handleBlur}
                                          value={validation.values.lastname || ''}
                                          invalid={
                                            validation.touched.lastname &&
                                              validation.errors.lastname
                                              ? true
                                              : false
                                          }
                                        />
                                        {validation.touched.lastname &&
                                          validation.errors.lastname ? (
                                          <FormFeedback type="invalid">
                                            {validation.errors.lastname}
                                          </FormFeedback>
                                        ) : null}
                                      </div>
                                    </div>
                                  </Col>
                                  <Col md={6} xs={12}>
                                    <div className=" features feature-primary py-0">
                                      <div className="mb-3">
                                        <Label className="form-label label_text">
                                          Gender{' '}
                                          <span className="text-danger">*</span>
                                        </Label>
                                        <select
                                          id="genderSelect"
                                          className="form-select form-control form_Input"
                                          onChange={(e) => {
                                            setGgender(e.target.value);
                                          }}
                                          value={ggender}
                                          required
                                        >
                                          <option value={''}>
                                            Select Gender
                                          </option>
                                          {(genderList || []).map((item, key) => (
                                            <option key={key} value={item.value}>
                                              {item.label}
                                            </option>
                                          ))}
                                        </select>
                                      </div>
                                    </div>
                                  </Col>

                                  {membershipTypeValue === 4 && (
                                    <Col md={6} sm={12}>
                                      <div className="features feature-primary p-1">
                                        <div className="mb-3">
                                          <Label className="form-label label_text">
                                            Join MettaMate for
                                          </Label>
                                          <select
                                            className="form-select form-control form_Input"
                                            id="jobprofession"
                                            value={typeOfMember}
                                            onChange={(e) =>
                                              setTypeOfMember(e.target.value)
                                            }
                                          >
                                            <option value={''}>Select</option>
                                            {(memberTypeList || []).map(
                                              (item, key) => (
                                                <option
                                                  key={key}
                                                  value={item.value}
                                                >
                                                  {item.label}
                                                </option>
                                              ),
                                            )}
                                          </select>
                                        </div>
                                      </div>
                                    </Col>
                                  )}

                                  {membershipTypeValue === 1 && (
                                    <Col md={6} xs={12}>
                                      <div className="features feature-primary">
                                        <div className="">
                                          <Label className="form-label label_text">
                                            Date of Birth{' '}
                                            <span className="text-danger">*</span>
                                          </Label>
                                          <br />

                                          <div className="d-flex">
                                            {/* Month Dropdown */}
                                            <select
                                              className="form-control me-1"
                                              value={dateOfBirth.month}
                                              onChange={(e) => handleDateChange(e, "month")}
                                              required
                                            >
                                              <option value="">Month</option>
                                              {generateOptions(1, 12)}
                                            </select>

                                            {/* Day Dropdown */}
                                            <select
                                              className="form-control"
                                              value={dateOfBirth.day}
                                              onChange={(e) => handleDateChange(e, "day")}
                                              required
                                            >
                                              <option value="">Day</option>
                                              {generateOptions(1, 31)}
                                            </select>

                                            {/* Year Dropdown */}
                                            <select
                                              className="form-control ms-1"
                                              value={dateOfBirth.year}
                                              onChange={(e) => handleDateChange(e, "year")}
                                              required
                                            >
                                              <option value="">Year</option>
                                              {generateYearOptions(2014, 1924)} {/* Use descending order function */}
                                            </select>
                                          </div>
                                          <span className="text-danger ms-1">
                                            {dobError}
                                          </span>
                                        </div>
                                      </div>
                                    </Col>
                                  )}


                                  <Col xs={12} className="text-center">
                                    <p className="mb-0 mt-3">
                                      <small className="text-dark me-2 label_text">
                                        Already have an account ?
                                      </small>{' '}
                                      <Link
                                        to="/user-login-page"
                                        className="text-dark fw-bold badge text-white fs-6"
                                        style={{
                                          background: 'rgb(255, 127, 0)',
                                        }}
                                      >
                                        Sign In
                                      </Link>
                                    </p>
                                  </Col>

                                </Row>
                              </Col>
                              <Col
                                md={12}
                                xs={12}
                                className="d-flex justify-content-center"
                              >
                                <Button
                                  disabled={
                                    !(
                                      phoneNumber > 10000000000 &&
                                      validation.values.email &&
                                      validation.values.firstname &&
                                      validation.values.lastname &&
                                      ggender
                                    ) ||
                                    (membershipTypeValue == 1 && !dateOfBirth) ||
                                    (membershipTypeValue == 4 && !typeOfMember)
                                  }
                                  onClick={() => {
                                    setStep(2);
                                  }}
                                  color="primary"
                                >
                                  Step 2
                                </Button>

                                {/* <div className="text-center features feature-primary ">
                                        <Button
                                          color="primary"
                                          className="register_text"
                                          onClick={() => setStep(2)}
                                        >
                                          Next
                                        </Button>
                                      </div> */}
                              </Col>

                              <Row>
                                <div className="text-center mt-2">{step} of 2 Page</div>
                              </Row>

                            </Row>

                          }
                        </div>
                      )}
                      {step === 2 && (
                        <Row className="mt-3 slide-left-animation">
                          <Col md={6} sm={12}>
                            <div className=" features feature-primary p-1">
                              <div className="mb-3">
                                <Label className="form-label label_text">
                                  Current Location
                                </Label>
                                <PlacesAutocomplete
                                  value={currentaddress}
                                  onChange={handleCurrentAddressChange}
                                  onSelect={handlecurrentAddressSelect}
                                  className="form-control form_input"
                                >
                                  {({
                                    getInputProps,
                                    suggestions,
                                    getSuggestionItemProps,
                                    loading,
                                  }) => {
                                    const uniqueSuggestions = new Set();

                                    return (
                                      <div>
                                        <input
                                          {...getInputProps({
                                            placeholder: 'Search Places ...',
                                            className: 'location-search-input',
                                          })}
                                        />
                                        <div className="autocomplete-dropdown-container">
                                          {loading && <div>Loading...</div>}
                                          {suggestions.map(
                                            (suggestion, index) => {
                                              const className =
                                                suggestion.active
                                                  ? 'suggestion-item--active'
                                                  : 'suggestion-item';

                                              const words =
                                                suggestion.description.split(
                                                  ',',
                                                );
                                              const trimmedArray = words.map(
                                                (str) => str.trim(),
                                              );
                                              const lastThreeWords =
                                                trimmedArray
                                                  .slice(-3)
                                                  .join(',');
                                              // Check if the suggestion is unique based on place_id
                                              if (
                                                !uniqueSuggestions.has(
                                                  lastThreeWords,
                                                )
                                              ) {
                                                uniqueSuggestions.add(
                                                  lastThreeWords,
                                                );

                                                // inline style for demonstration purpose
                                                const style = suggestion.active
                                                  ? {
                                                    backgroundColor:
                                                      '#fafafa',
                                                    cursor: 'pointer',
                                                  }
                                                  : {
                                                    backgroundColor:
                                                      '#ffffff',
                                                    cursor: 'pointer',
                                                  };

                                                return (
                                                  <div
                                                    key={index}
                                                    {...getSuggestionItemProps(
                                                      suggestion,
                                                      {
                                                        className,
                                                        style,
                                                      },
                                                    )}
                                                  >
                                                    <span>
                                                      {lastThreeWords}
                                                    </span>
                                                  </div>
                                                );
                                              } else {
                                                return null; // Skip rendering duplicate suggestions
                                              }
                                            },
                                          )}
                                        </div>
                                      </div>
                                    );
                                  }}
                                </PlacesAutocomplete>
                              </div>
                            </div>
                          </Col>

                          <Col md={6} xs={12}>
                            <div className="features feature-primary p-1">
                              <div className="mb-3">
                                <Label className="form-label label_text">
                                  Job Profession{' '}
                                  <span className="text-danger">*</span>
                                </Label>
                                <select
                                  className="form-select form-control form_Input"
                                  id="jobprofession"
                                  value={jobProfession}
                                  onChange={(e) =>
                                    setJobProfession(e.target.value)
                                  }
                                >
                                  <option value="">Select Profession</option>
                                  {(occupationalList || []).map((item, key) => (
                                    <option key={key} value={item.value}>
                                      {item.label}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                          </Col>

                          {
                            <>
                              <Col md={6} xs={12} className="p-0">
                                {membershipTypeValue === 4 && (
                                  <Col md={12} xs={12}>
                                    <div className="features feature-primary">
                                      <div className="">
                                        <Label className="form-label label_text">
                                          Date Of Birth{' '}
                                          <span className="text-danger">*</span>
                                        </Label>
                                        <br />

                                        <div className="d-flex">
                                          {/* Month Dropdown */}
                                          <select
                                            className="form-control me-1"
                                            value={dateOfBirth.month}
                                            onChange={(e) => handleDateChange(e, "month")}
                                            required
                                          >
                                            <option value="">Month</option>
                                            {generateOptions(1, 12)}
                                          </select>

                                          {/* Day Dropdown */}
                                          <select
                                            className="form-control"
                                            value={dateOfBirth.day}
                                            onChange={(e) => handleDateChange(e, "day")}
                                            required
                                          >
                                            <option value="">Day</option>
                                            {generateOptions(1, 31)}
                                          </select>

                                          {/* Year Dropdown */}
                                          <select
                                            className="form-control ms-1"
                                            value={dateOfBirth.year}
                                            onChange={(e) => handleDateChange(e, "year")}
                                            required
                                          >
                                            <option value="">Year</option>
                                            {generateYearOptions(2014, 1924)} {/* Use descending order function */}
                                          </select>
                                        </div>
                                        <span className="text-danger ms-1">
                                          {dobError}
                                        </span>
                                      </div>
                                    </div>
                                  </Col>
                                )}

                                <Col md={12} xs={12}>
                                  <div className="features feature-primary py-0">
                                    <div className="mb-3">
                                      <Label className="form-label label_text">
                                        Create Password{' '}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      <div className="form-icon position-relative">
                                        <i>
                                          <FeatherIcon
                                            icon="key"
                                            className="fea icon-sm icons"
                                          />
                                        </i>
                                      </div>
                                      <Input
                                        type={
                                          showPassword ? 'text' : 'password'
                                        }
                                        className="form-control form_Input ps-5"
                                        name="password"
                                        required
                                        placeholder="Password"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={validation.values.password || ''}
                                        invalid={
                                          validation.touched.password &&
                                            validation.errors.password
                                            ? true
                                            : false
                                        }
                                      />
                                      {validation.touched.password &&
                                        validation.errors.password ? (
                                        <FormFeedback type="invalid">
                                          {validation.errors.password}
                                        </FormFeedback>
                                      ) : null}
                                      <span
                                        style={{
                                          position: 'absolute',
                                          right: '26px',
                                          top: '71%',
                                          transform: 'translateY(-50%)',
                                          cursor: 'pointer',
                                        }}
                                        onClick={togglePasswordVisibility}
                                      >
                                        {showPassword ? (
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="1.4em"
                                            height="1.4em"
                                            viewBox="0 0 24 24"
                                          >
                                            <path
                                              fill="currentColor"
                                              d="M21.92 11.6C19.9 6.91 16.1 4 12 4s-7.9 2.91-9.92 7.6a1 1 0 0 0 0 .8C4.1 17.09 7.9 20 12 20s7.9-2.91 9.92-7.6a1 1 0 0 0 0-.8M12 18c-3.17 0-6.17-2.29-7.9-6C5.83 8.29 8.83 6 12 6s6.17 2.29 7.9 6c-1.73 3.71-4.73 6-7.9 6m0-10a4 4 0 1 0 4 4a4 4 0 0 0-4-4m0 6a2 2 0 1 1 2-2a2 2 0 0 1-2 2"
                                            />
                                          </svg>
                                        ) : (
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="1.4em"
                                            height="1.4em"
                                            viewBox="0 0 24 24"
                                          >
                                            <path
                                              fill="currentColor"
                                              d="M10.94 6.08A6.93 6.93 0 0 1 12 6c3.18 0 6.17 2.29 7.91 6a15.23 15.23 0 0 1-.9 1.64a1 1 0 0 0-.16.55a1 1 0 0 0 1.86.5a15.77 15.77 0 0 0 1.21-2.3a1 1 0 0 0 0-.79C19.9 6.91 16.1 4 12 4a7.77 7.77 0 0 0-1.4.12a1 1 0 1 0 .34 2ZM3.71 2.29a1 1 0 0 0-1.42 1.42l3.1 3.09a14.62 14.62 0 0 0-3.31 4.8a1 1 0 0 0 0 .8C4.1 17.09 7.9 20 12 20a9.26 9.26 0 0 0 5.05-1.54l3.24 3.25a1 1 0 0 0 1.42 0a1 1 0 0 0 0-1.42Zm6.36 9.19l2.45 2.45A1.81 1.81 0 0 1 12 14a2 2 0 0 1-2-2a1.81 1.81 0 0 1 .07-.52M12 18c-3.18 0-6.17-2.29-7.9-6a12.09 12.09 0 0 1 2.7-3.79L8.57 10A4 4 0 0 0 14 15.43L15.59 17A7.24 7.24 0 0 1 12 18"
                                            />
                                          </svg>
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </Col>

                                <Col md={12} xs={12}>
                                  <div className="features feature-primary py-0">
                                    <div className="mb-3">
                                      <Label className="form-label label_text">
                                        Confirm Password{' '}
                                        <span className="text-danger">*</span>
                                      </Label>
                                      <div className="form-icon position-relative">
                                        <i>
                                          <FeatherIcon
                                            icon="key"
                                            className="fea icon-sm icons"
                                          />
                                        </i>
                                      </div>
                                      <Input
                                        type={
                                          showPassword ? 'text' : 'password'
                                        }
                                        className="form-control form_Input ps-5"
                                        name="confirmpassword"
                                        required
                                        placeholder="Confirm password"
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={
                                          validation.values.confirmpassword ||
                                          ''
                                        }
                                        invalid={
                                          validation.touched.confirmpassword &&
                                          validation.values.confirmpassword !==
                                          validation.values.password
                                        }
                                      />
                                      {validation.touched.confirmpassword &&
                                        validation.errors.confirmpassword ? (
                                        <FormFeedback type="invalid"></FormFeedback>
                                      ) : null}
                                      {validation.touched.confirmpassword &&
                                        validation.values.confirmpassword !==
                                        validation.values.password && (
                                          <span className="text-danger ps-1">
                                            Password does not matched
                                          </span>
                                        )}
                                      <span
                                        style={{
                                          position: 'absolute',
                                          right: '26px',
                                          top: '71%',
                                          transform: 'translateY(-50%)',
                                          cursor: 'pointer',
                                        }}
                                        onClick={togglePasswordVisibility}
                                      >
                                        {showPassword ? (
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="1.4em"
                                            height="1.4em"
                                            viewBox="0 0 24 24"
                                          >
                                            <path
                                              fill="currentColor"
                                              d="M21.92 11.6C19.9 6.91 16.1 4 12 4s-7.9 2.91-9.92 7.6a1 1 0 0 0 0 .8C4.1 17.09 7.9 20 12 20s7.9-2.91 9.92-7.6a1 1 0 0 0 0-.8M12 18c-3.17 0-6.17-2.29-7.9-6C5.83 8.29 8.83 6 12 6s6.17 2.29 7.9 6c-1.73 3.71-4.73 6-7.9 6m0-10a4 4 0 1 0 4 4a4 4 0 0 0-4-4m0 6a2 2 0 1 1 2-2a2 2 0 0 1-2 2"
                                            />
                                          </svg>
                                        ) : (
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="1.4em"
                                            height="1.4em"
                                            viewBox="0 0 24 24"
                                          >
                                            <path
                                              fill="currentColor"
                                              d="M10.94 6.08A6.93 6.93 0 0 1 12 6c3.18 0 6.17 2.29 7.91 6a15.23 15.23 0 0 1-.9 1.64a1 1 0 0 0-.16.55a1 1 0 0 0 1.86.5a15.77 15.77 0 0 0 1.21-2.3a1 1 0 0 0 0-.79C19.9 6.91 16.1 4 12 4a7.77 7.77 0 0 0-1.4.12a1 1 0 1 0 .34 2ZM3.71 2.29a1 1 0 0 0-1.42 1.42l3.1 3.09a14.62 14.62 0 0 0-3.31 4.8a1 1 0 0 0 0 .8C4.1 17.09 7.9 20 12 20a9.26 9.26 0 0 0 5.05-1.54l3.24 3.25a1 1 0 0 0 1.42 0a1 1 0 0 0 0-1.42Zm6.36 9.19l2.45 2.45A1.81 1.81 0 0 1 12 14a2 2 0 0 1-2-2a1.81 1.81 0 0 1 .07-.52M12 18c-3.18 0-6.17-2.29-7.9-6a12.09 12.09 0 0 1 2.7-3.79L8.57 10A4 4 0 0 0 14 15.43L15.59 17A7.24 7.24 0 0 1 12 18"
                                            />
                                          </svg>
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </Col>
                              </Col>
                              <Col md={6} className="my-auto">
                                <Col
                                  md={12}
                                  xs={12}
                                  className="d-flex justify-content-center flex-column align-items-center"
                                >
                                  <div
                                    className="profilepic "
                                    onClick={() => imageRef.current.click()}
                                  >
                                    <div className="">
                                      <input
                                        type="file"
                                        accept=".jpg, .jpeg, .png"
                                        ref={imageRef}
                                        style={{ display: 'none' }}
                                        onChange={(e) =>
                                          handleDocAttachement(e)
                                        }
                                      />
                                      {/* <input
                                        type="file"
                                        accept="image/png, image/jpeg"
                                        onChange={(e) => {
                                          setProfilePic(e.target.files[0]);
                                        }}
                                        onBlur={validation.handleBlur}
                                      /> */}
                                      <img
                                        className={`profilepic__image ${imageProfile === null
                                          ? 'no-image-opacity'
                                          : ''
                                          }`}
                                        src={
                                          imageProfile != null
                                            ? imageProfile
                                            : ggender == 1
                                              ? manavatar
                                              : ggender == 2
                                                ? femaleavatar
                                                : transgenderavatar
                                        }
                                        width="150"
                                        height="150"
                                        alt="Profibild"
                                      />

                                      <div className="profilepic__content">
                                        <span className="profilepic__icon">
                                          <i className="fas fa-camera"></i>
                                        </span>
                                        <span className="profilepic__text">
                                          Add Profile
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  {profilePicError && (
                                    <div className="text-danger text-center">
                                      Profile image is required
                                    </div>
                                  )}

                                  {/* <div className=" features feature-primary py-0">
                              <div className="mb-3">
                                <Label className="form-label label_text">
                                  Profile Picture{' '}
                                  <span className="text-danger">*</span>
                                </Label>
                                <input
                                  type="file"
                                  accept="image/png, image/jpeg"
                                  onChange={(e) => {
                                    setProfilePic(e.target.files[0]);
                                  }}
                                  onBlur={validation.handleBlur}
                                  // required
                                />

                                <img
                                  src={manavatar}
                                  style={{
                                    borderRadius: '50%',
                                    height: '12rem',
                                  }}
                                />
                              </div>
                            </div> */}
                                </Col>
                              </Col>
                            </>
                          }

                          <Col md={12} xs={12} className="my-2">
                            <Row>
                              <Col md={6} xs={12}>
                                <div className=" features feature-primary py-0">
                                  <div className="mb-3 flex-container">
                                    <a
                                      className="form-label label_text mt-1"
                                      onClick={() => emptyCart_modal()}
                                    >
                                      Recommender's Email{' '}
                                      <span className="text-danger">*</span>
                                    </a>
                                    &nbsp;
                                    <select
                                      style={{ width: '55%' }}
                                      onChange={changeHandler}
                                      className="form-select form-control form_Input"
                                      hidden
                                      placeholder="Select"
                                      id="recommendersSelect"
                                    >
                                      <option value="0" selected>
                                        Email
                                      </option>
                                      <option value="1">Phone Number</option>
                                    </select>
                                  </div>
                                </div>
                              </Col>

                              {isVisible ? (
                                <Col md={6} xs={12}>
                                  <div className=" features feature-primary py-0">
                                    <div className="mb-3">
                                      <PhoneInput
                                        className=" form-control form_Input"
                                        international
                                        // countryCallingCodeEditable={false}
                                        country={'in'}
                                        value={value1}
                                        minLength={10}
                                        maxLength={13}
                                        required
                                        onChange={
                                          handlerecommenderCountrycodechange
                                        }
                                      />
                                      <Input
                                        type="phone"
                                        className="form-control form_Input ps-5 phoneInput"
                                        placeholder="Phone"
                                        name="recommenderphone"
                                        minLength={10}
                                        maxLength={10}
                                        required
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={
                                          validation.values.recommenderphone ||
                                          ''
                                        }
                                        invalid={
                                          validation.touched.recommenderphone &&
                                            validation.errors.recommenderphone
                                            ? true
                                            : false
                                        }
                                      />
                                      {validation.touched.recommenderphone &&
                                        validation.errors.recommenderphone ? (
                                        <FormFeedback type="invalid">
                                          {validation.errors.recommenderphone}
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </div>
                                </Col>
                              ) : (
                                <Col md={6} xs={12}>
                                  <div className="features feature-primary py-0">
                                    <div className="mb-3">
                                      <div className="form-icon position-relative">
                                        <i>
                                          <FeatherIcon
                                            icon="mail"
                                            className="fea icon-sm icons"
                                          />
                                        </i>
                                      </div>
                                      <Input
                                        type="email"
                                        className="form-control form_Input ps-5 "
                                        placeholder="admin@mettamate.ai"
                                        name="recommenderemail"
                                        required
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                        value={
                                          validation.values.recommenderemail ||
                                          ''
                                        }
                                        invalid={
                                          validation.touched.recommenderemail &&
                                            validation.errors.recommenderemail
                                            ? true
                                            : false
                                        }
                                      />
                                      {validation.touched.recommenderemail &&
                                        validation.errors.recommenderemail ? (
                                        <FormFeedback type="invalid">
                                          {validation.errors.recommenderemail}
                                        </FormFeedback>
                                      ) : null}
                                    </div>
                                  </div>
                                </Col>
                              )}
                            </Row>
                          </Col>

                          <Col md={12} xs={12} className="text-center">
                            <span className="text-center">
                              <div className="features  feature-primary py-0">
                                <div className="mb-3">
                                  <div className="form-check">
                                    <Input
                                      type="checkbox"
                                      className="form-check-input"
                                      required
                                      id="customCheck1"
                                    />
                                    <Label
                                      className="form-check-label label_text"
                                      htmlFor="customCheck1"
                                    >
                                      {'     '}I Accept{' '}
                                      <Link
                                        to="#"
                                        className="text-primary"
                                        onClick={() => tog_modal()}
                                      >
                                        Terms And Condition
                                      </Link>
                                    </Label>
                                  </div>
                                </div>
                              </div>
                            </span>
                          </Col>
                          <Col md={12} xs={12}>
                            <div className="text-center features feature-primary ">
                              <Button
                                color="primary"
                                className="register_text"
                                type="submit"
                                disabled={!jobProfession}
                              >
                                Join MettaMate
                              </Button>
                            </div>
                          </Col>
                          <Col
                            md={12}
                            xs={12}
                            className="d-flex  justify-content-center mt-2"
                          >
                            <Button onClick={() => setStep(1)}>Step 1</Button>
                          </Col>
                        </Row>
                      )}


                    </Form>

                  </Card>
                </Col>
              </Row>
            </Container>
          </section>
        </>
      )}

      <Modal
        isOpen={emptyCartModal}
        toggle={() => {
          emptyCart_modal();
        }}
        id="empty-cart"
        tabIndex="-1"
        centered
      >
        <ModalBody className="py-5">
          <div className="text-center">
            <img
              src={mmlogo}
              className="avatar avatar-medium rounded-circle shadow"
              alt=""
            />
            <div className="mt-4">
              <h4>Welcome to MettaMate Platform</h4>
              <p className="text-muted">
                If you do not know anyone in MettaMate's network, then please
                reach out to admin@mettamate.ai and MettaMate's team would be
                able to help you to join this Ambedkarites platform !.
              </p>
              <div className="mt-4">
                <Link to="/index" className="btn btn-primary">
                  Join Now !
                </Link>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        size="lg"
        id="LoginForm"
        tabIndex="-1"
        isOpen={modal}
        toggle={() => {
          tog_modal();
        }}
        centered
      >
        <ModalHeader
          toggle={tog_modal}
          role="dialog"
          autoFocus={true}
          className="border-bottom modalTitle"
        >
          Terms & Conditions
        </ModalHeader>
        <ModalBody>
          <div className="bg-white p-3 rounded box-shadow">
            <p className="text-muted mb-0">
              Terms and Conditions General Site Usage Last Revised: December 16,
              2013 Welcome to www.lorem-ipsum.info. This site is provided as a
              service to our visitors and may be used for informational purposes
              only. Because the Terms and Conditions contain legal obligations,
              please read them carefully. 1. YOUR AGREEMENT By using this Site,
              you agree to be bound by, and to comply with, these Terms and
              Conditions. If you do not agree to these Terms and Conditions,
              please do not use this site. PLEASE NOTE: We reserve the right, at
              our sole discretion, to change, modify or otherwise alter these
              Terms and Conditions at any time. Unless otherwise indicated,
              amendments will become effective immediately. Please review these
              Terms and Conditions periodically. Your continued use of the Site
              following the posting of changes and/or modifications will
              constitute your acceptance of the revised Terms and Conditions and
              the reasonableness of these standards for notice of changes. For
              your information, this page was last updated as of the date at the
              top of these terms and conditions. 2. PRIVACY Please review our
              Privacy Policy, which also governs your visit to this Site, to
              understand our practices. 3. LINKED SITES This Site may contain
              links to other independent third-party Web sites ("Linked Sites”).
              These Linked Sites are provided solely as a convenience to our
              visitors. Such Linked Sites are not under our control, and we are
              not responsible for and does not endorse the content of such
              Linked Sites, including any information or materials contained on
              such Linked Sites. You will need to make your own independent
              judgment regarding your interaction with these Linked Sites. 4.
              FORWARD LOOKING STATEMENTS All materials reproduced on this site
              speak as of the original date of publication or filing. The fact
              that a document is available on this site does not mean that the
              information contained in such document has not been modified or
              superseded by events or by a subsequent document or filing. We
              have no duty or policy to update any information or statements
              contained on this site and, therefore, such information or
              statements should not be relied upon as being current as of the
              date you access this site. 5. DISCLAIMER OF WARRANTIES AND
              LIMITATION OF LIABILITY A. THIS SITE MAY CONTAIN INACCURACIES AND
              TYPOGRAPHICAL ERRORS. WE DOES NOT WARRANT THE ACCURACY OR
              COMPLETENESS OF THE MATERIALS OR THE RELIABILITY OF ANY ADVICE,
              OPINION, STATEMENT OR OTHER INFORMATION DISPLAYED OR DISTRIBUTED
              THROUGH THE SITE. YOU EXPRESSLY UNDERSTAND AND AGREE THAT: (i)
              YOUR USE OF THE SITE, INCLUDING ANY RELIANCE ON ANY SUCH OPINION,
              ADVICE, STATEMENT, MEMORANDUM, OR INFORMATION CONTAINED HEREIN,
              SHALL BE AT YOUR SOLE RISK; (ii) THE SITE IS PROVIDED ON AN "AS
              IS" AND "AS AVAILABLE" BASIS; (iii) EXCEPT AS EXPRESSLY PROVIDED
              HEREIN WE DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR
              IMPLIED, INCLUDING, BUT NOT LIMITED TO IMPLIED WARRANTIES OF
              MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, WORKMANLIKE
              EFFORT, TITLE AND NON-INFRINGEMENT; (iv) WE MAKE NO WARRANTY WITH
              RESPECT TO THE RESULTS THAT MAY BE OBTAINED FROM THIS SITE, THE
              PRODUCTS OR SERVICES ADVERTISED OR OFFERED OR MERCHANTS INVOLVED;
              (v) ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE
              OF THE SITE IS DONE AT YOUR OWN DISCRETION AND RISK; and (vi) YOU
              WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM
              OR FOR ANY LOSS OF DATA THAT RESULTS FROM THE DOWNLOAD OF ANY SUCH
              MATERIAL. B. YOU UNDERSTAND AND AGREE THAT UNDER NO CIRCUMSTANCES,
              INCLUDING, BUT NOT LIMITED TO, NEGLIGENCE, SHALL WE BE LIABLE FOR
              ANY DIRECT, INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE OR
              CONSEQUENTIAL DAMAGES THAT RESULT FROM THE USE OF, OR THE
              INABILITY TO USE, ANY OF OUR SITES OR MATERIALS OR FUNCTIONS ON
              ANY SUCH SITE, EVEN IF WE HAVE BEEN ADVISED OF THE POSSIBILITY OF
              SUCH DAMAGES. THE FOREGOING LIMITATIONS SHALL APPLY
              NOTWITHSTANDING ANY FAILURE OF ESSENTIAL PURPOSE OF ANY LIMITED
              REMEDY. 6. EXCLUSIONS AND LIMITATIONS SOME JURISDICTIONS DO NOT
              ALLOW THE EXCLUSION OF CERTAIN WARRANTIES OR THE LIMITATION OR
              EXCLUSION OF LIABILITY FOR INCIDENTAL OR CONSEQUENTIAL DAMAGES.
              ACCORDINGLY, OUR LIABILITY IN SUCH JURISDICTION SHALL BE LIMITED
              TO THE MAXIMUM EXTENT PERMITTED BY LAW. 7. OUR PROPRIETARY RIGHTS
              This Site and all its Contents are intended solely for personal,
              non-commercial use. Except as expressly provided, nothing within
              the Site shall be construed as conferring any license under our or
              any third party's intellectual property rights, whether by
              estoppel, implication, waiver, or otherwise. Without limiting the
              generality of the foregoing, you acknowledge and agree that all
              content available through and used to operate the Site and its
              services is protected by copyright, trademark, patent, or other
              proprietary rights. You agree not to: (a) modify, alter, or deface
              any of the trademarks, service marks, trade dress (collectively
              "Trademarks") or other intellectual property made available by us
              in connection with the Site; (b) hold yourself out as in any way
              sponsored by, affiliated with, or endorsed by us, or any of our
              affiliates or service providers; (c) use any of the Trademarks or
              other content accessible through the Site for any purpose other
              than the purpose for which we have made it available to you; (d)
              defame or disparage us, our Trademarks, or any aspect of the Site;
              and (e) adapt, translate, modify, decompile, disassemble, or
              reverse engineer the Site or any software or programs used in
              connection with it or its products and services. The framing,
              mirroring, scraping or data mining of the Site or any of its
              content in any form and by any method is expressly prohibited. 8.
              INDEMNITY By using the Site web sites you agree to indemnify us
              and affiliated entities (collectively "Indemnities") and hold them
              harmless from any and all claims and expenses, including (without
              limitation) attorney's fees, arising from your use of the Site web
              sites, your use of the Products and Services, or your submission
              of ideas and/or related materials to us or from any person's use
              of any ID, membership or password you maintain with any portion of
              the Site, regardless of whether such use is authorized by you. 9.
              COPYRIGHT AND TRADEMARK NOTICE Except our generated dummy copy,
              which is free to use for private and commercial use, all other
              text is copyrighted. generator.lorem-ipsum.info © 2013, all
              rights reserved 10. INTELLECTUAL PROPERTY INFRINGEMENT CLAIMS It
              is our policy to respond expeditiously to claims of intellectual
              property infringement. We will promptly process and investigate
              notices of alleged infringement and will take appropriate actions
              under the Digital Millennium Copyright Act ("DMCA") and other
              applicable intellectual property laws. Notices of claimed
              infringement should be directed to: generator.lorem-ipsum.info 126
              Electricov St. Kiev, Kiev 04176 Ukraine contact@lorem-ipsum.info
              11. PLACE OF PERFORMANCE This Site is controlled, operated and
              administered by us from our office in Kiev, Ukraine. We make no
              representation that materials at this site are appropriate or
              available for use at other locations outside of the Ukraine and
              access to them from territories where their contents are illegal
              is prohibited. If you access this Site from a location outside of
              the Ukraine, you are responsible for compliance with all local
              laws. 12. GENERAL A. If any provision of these Terms and
              Conditions is held to be invalid or unenforceable, the provision
              shall be removed (or interpreted, if possible, in a manner as to
              be enforceable), and the remaining provisions shall be enforced.
              Headings are for reference purposes only and in no way define,
              limit, construe or describe the scope or extent of such section.
              Our failure to act with respect to a breach by you or others does
              not waive our right to act with respect to subsequent or similar
              breaches. These Terms and Conditions set forth the entire
              understanding and agreement between us with respect to the subject
              matter contained herein and supersede any other agreement,
              proposals and communications, written or oral, between our
              representatives and you with respect to the subject matter hereof,
              including any terms and conditions on any of customer's documents
              or purchase orders. B. No Joint Venture, No Derogation of Rights.
              You agree that no joint venture, partnership, employment, or
              agency relationship exists between you and us as a result of these
              Terms and Conditions or your use of the Site. Our performance of
              these Terms and Conditions is subject to existing laws and legal
              process, and nothing contained herein is in derogation of our
              right to comply with governmental, court and law enforcement
              requests or requirements relating to your use of the Site or
              information provided to or gathered by us with respect to such
              use.
            </p>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={tog_modal} type="button">
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default RegistrationPhase1;
