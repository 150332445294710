// import ReactQuill from 'react-quill';
//import 'react-quill/dist/quill.snow.css';
import React, { useState, useEffect } from 'react';
import MenteeCard from './MenteeCard';
import {
  Container,
  Row,
  Col,
  Card,
  Form,
  CardBody,
  Label,
  Button,
  Input,
  FormFeedback,
  CardTitle,
  Table,
  ControlledCarousel,
  UncontrolledCollapse,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { useFormik } from 'formik';
import Select from 'react-select';
import axios from 'axios';
import '../../css/userHomePage.css';
import apiUrl from '../../../server';
import appUrl from '../../../config';
import razorKeyID from '../../../config';
import CommonModal from '../../../Home/modal';
import { Link } from 'react-router-dom';
import becomeMentorImg from '../../../../assets/images/mentorship/become-mentor-3.jpeg';

const SelfMenteePage = () => {
  const [configurationData, setConfigurationData] = useState(null);
  const [error, setError] = useState(null);
  const [firstName, setFirstName] = useState('');
  const [menteeStatus, setMenteeStatus] = useState(null); // Changed from mentorStatus
  const [isEditing, setIsEditing] = useState(false);
  const [existingMenteeData, setExistingMenteeData] = useState(null); // Changed from existingMentorData
  const [existingMenteeDetails, setExistingMenteeDetails] = useState(null); // Changed from existingMentorDetails
  const [isLoading, setIsLoading] = useState(true);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [success, setSuccess] = useState(null);

  useEffect(() => {
    let configurationDataFromStore = localStorage.getItem('configurationData');
    if (configurationDataFromStore) {
      const configurationDataLocal = JSON.parse(configurationDataFromStore);
      setConfigurationData(configurationDataLocal);
      setFirstName(configurationDataLocal.firstname);
    } else {
      // Redirect it to the index page
      window.location.href = '/index';
    }
    checkMenteeStatus(); // Changed from checkMentorStatus
    fetchProfileData();
  }, []);

  // Helper function to get status-specific styling
  const getStatusStyle = (status) => {
    switch (status) {
      case 'PENDING':
        return { borderColor: '#FCD34D', backgroundColor: '#FEF3C7' }; // Yellow
      case 'APPROVED':
        return { borderColor: '#34D399', backgroundColor: '#D1FAE5' }; // Green
      case 'REJECTED':
        return { borderColor: '#EF4444', backgroundColor: '#FEE2E2' }; // Red
      case 'BLOCKED':
        return { borderColor: '#6B7280', backgroundColor: '#F3F4F6' }; // Gray
      default:
        return { borderColor: 'blue', backgroundColor: '#ffffff' };
    }
  };

  // Helper function to get status message
  const getStatusMessage = (status) => {
    // alert('status: ' + status);
    switch (status) {
      case 'PENDING':
        return 'Your mentee profile is under review';
      case 'ACTIVATED':
        return 'You are an active mentee';
      case 'REJECTED':
        return 'Your mentee profile was not approved';
      case 'DEACTIVATED':
        return 'Your mentee profile is currently deactivated (unlisted)';
      default:
        return '';
    }
  };

  const checkMenteeStatus = async () => {
    // Changed from checkMentorStatus
    try {
      setIsLoading(true);
      const response = await axios.get(apiUrl + 'mentee/mentee-status', {
        // Changed endpoint
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + localStorage.getItem('token'),
        },
      });

      if (response.data.isMentee) {
        // Changed condition
        setMenteeStatus(response.data.status);
        setExistingMenteeData(response.data.menteeDetails);
      }
    } catch (error) {
      console.error('Error checking mentee status:', error);
      setError({
        title: 'Error',
        message: 'Failed to fetch mentee status',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const fetchProfileData = async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(
        apiUrl + 'mentee/fetch-mentee-details', // Changed endpoint
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        },
      );

      if (response.data) {
        setExistingMenteeDetails(response.data.menteeDetails); // Changed property name
      }
    } catch (error) {
      console.error('Error checking mentee status:', error);
      setError({
        title: 'Error',
        message: 'Failed to fetch mentee status',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleMenteeStatusToggle = async () => {
    setIsConfirmModalOpen(true);
    setModalMessage(
      `Are you sure you want to ${menteeStatus === 'ACTIVATED' ? 'deactivate' : 'activate'} your mentee profile?`
    );
  };

  const confirmMenteeStatusToggle = async () => {
    try {
      const newStatus = menteeStatus === 'ACTIVATED' ? 'DEACTIVATED' : 'ACTIVATED';
      const response = await axios.post(
        apiUrl + 'mentee/update-mentee-status',
        {
          status: newStatus,
          menteeUuid: existingMenteeData.mentee_uuid,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        },
      );

      if (response.status !== 200) {
        setError({
          title: 'Error',
          message: 'Failed to update profile status. Please try again.',
        });
        return;
      }

      // Update the status in your app state
      setMenteeStatus(newStatus);

      // Show success message
      setSuccess({
        title: 'Success!',
        message: `Profile successfully ${newStatus === 'ACTIVATED' ? 'activated' : 'deactivated'}`,
      });
    } catch (error) {
      console.error('Error updating mentee status:', error);
      setError({
        title: 'Error',
        message: 'Failed to update profile status. Please try again.',
      });
    } finally {
      setIsConfirmModalOpen(false);
    }
  };

  return (
    <React.Fragment>
      {/* Add success modal */}
      {success && (
        <CommonModal
          title={success.title}
          message={success.message}
          onClose={() => {
            setSuccess(null);
            checkMenteeStatus(); // Refresh the status after success
          }}
          status="success"
        />
      )}

      {/* Add error modal */}
      {error && (
        <CommonModal
          title={error.title}
          message={error.message}
          onClose={() => setError(null)}
          status="error"
        />
      )}
      <section className="section">
        <Container>
          <Row>
            <div className="text-center mb-8">
              <p className="fs-4 font-bold">
                <span className="text-primary">JaiBheem</span> {firstName}!
              </p>
              <p className="fs-5 mt-4 text-xl text-gray-600">
                Connect with experienced mentors from our community who can
                guide you towards your goals. Take advantage of their expertise
                and insights to accelerate your personal and professional
                growth. Our mentors are here to support your journey and help
                you unlock your full potential.
              </p>
            </div>
          </Row>
          <hr />
          <Row>
            {/* Show Become Mentee section */}
            {!menteeStatus && (
              <Col xs="12" lg="12" md="12" className="mb-4">
                <div
                  style={{
                    // border: '0px solid blue',
                    // borderRadius: '8px',
                    padding: '16px',
                    backgroundColor: '#ffffff',
                    // boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    textAlign: 'start',
                  }}
                >
                  <p className="fs-4 font-bold">Become Mentee</p>
                  {/* Image */}

                  <div className="d-flex justify-content-center">
                    <img
                      src={becomeMentorImg}
                      className="img-fluid"
                      alt="..."
                      style={{
                        width: '60%',
                        height: 'auto',
                        borderRadius: '10px',
                        objectFit: 'cover',
                      }}
                    />
                  </div>
                  {/* Updated Mentee description text */}

                  <p className="fs-5 mt-4 text-xl text-gray-600">
                    Take the next step in your journey by becoming a mentee at
                    MettaMate. Connect with experienced mentors who can provide
                    valuable guidance, share their knowledge, and help you
                    achieve your personal and professional goals. Our platform
                    makes it easy to find the right mentor who understands your
                    aspirations.
                  </p>
                  <Link to={'/become_mentee'}>
                    {' '}
                    {/* Updated link path */}
                    <div className="text-start">
                      <Button
                        color="primary"
                        style={{
                          height: '30px',
                          fontSize: '12px',
                        }}
                      >
                        Become Mentee {/* Updated button text */}
                      </Button>
                      <p className="fs-6 mt-4 text-xl text-gray-600">
                        Your mentee application will be reviewed to ensure the
                        best possible match with our mentors. Once approved,
                        you'll be able to connect with mentors who can guide you
                        on your path to success.
                      </p>
                    </div>
                  </Link>
                </div>
              </Col>
            )}

            {/* Show Edit Profile if already a mentee */}
            {menteeStatus && (
              <Col md="12" className="mb-4">
                <div
                  style={{
                    border: '1px solid blue',
                    borderRadius: '8px',
                    padding: '16px',
                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    textAlign: 'start',
                  }}
                >
                  <p className="fs-4 font-bold mb-2">
                    Mentee Profile Status:
                    <span className="fs-5 ">
                      {' '}
                      {getStatusMessage(menteeStatus)}
                    </span>
                  </p>
                  <Link to={'/become_mentee'}>
                    <div className="text-center">
                      <Button
                        color="primary"
                        style={{ height: '35px', fontSize: '14px' }}
                        disabled={menteeStatus === 'BLOCKED'}
                      >
                        Edit Mentee Profile
                      </Button>
                    </div>
                  </Link>
                  <hr />
                  <div className="text-center">
                    <Button
                      color="primary"
                      style={{ height: '35px', fontSize: '14px' }}
                      onClick={handleMenteeStatusToggle}
                      disabled={menteeStatus === 'REJECTED'}
                    >
                      {menteeStatus === 'ACTIVATED'
                        ? 'Deactivate My Mentee Profile'
                        : 'Activate My Mentee Profile'}
                    </Button>
                  </div>
                  <p className="fs-5 mb-2 mt-2">
                    If you have any questions please contact{' '}
                    <a href="mailto:admin@mettamate.ai">admin@mettamate.ai </a>
                  </p>
                </div>
              </Col>
            )}
          </Row>
          {/* Profile preview section - centered private view only */}
          <Row>
            {menteeStatus && existingMenteeDetails && (
              <div
                style={{
                  padding: '16px',
                  margin: '16px 0',
                }}
              >
                <Row className="justify-content-center">
                  <Col className="mt-2 pt-2 text-center col-xl-4 col-lg-5 col-md-6 col-sm-8 col-xs-12">
                    <Card
                      style={{
                        border: '2px solid blue',
                        borderRadius: '8px',
                        padding: '16px',
                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                        backgroundColor: '#fff8e1',
                        height: 'auto',
                        maxWidth: '500px',
                        margin: '0 auto',
                      }}
                    >
                      <CardBody>
                        <CardTitle tag="h5" className="text-primary">
                          Mentee Profile
                        </CardTitle>
                        <MenteeCard
                          menteeDetails={existingMenteeDetails}
                          menteeProfileData={existingMenteeData}
                          isAuthenticated={true}
                        />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </div>
            )}
          </Row>
        </Container>
      </section>
      <Modal
        isOpen={isConfirmModalOpen}
        toggle={() => setIsConfirmModalOpen(false)}
      >
        <ModalHeader toggle={() => setIsConfirmModalOpen(false)}>
          Confirm Action
        </ModalHeader>
        <ModalBody>{modalMessage}</ModalBody>
        <ModalFooter>
          <Button
            color="secondary"
            onClick={() => setIsConfirmModalOpen(false)}
          >
            Cancel
          </Button>
          <Button color="primary" onClick={confirmMenteeStatusToggle}>
            Confirm
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default SelfMenteePage;
