import React from 'react';
import { Card, CardBody, Col, Container, Row, CardImg } from 'reactstrap';
import { Link } from 'react-router-dom';
import mentalHealthImage from '../../assets/images/healthPage/mental-health-image.jpeg';
import CommunityOutreachImage from '../../assets/images/healthPage/Community-Outreach-image.jpeg';
import YogaImage from '../../assets/images/healthPage/yoga-image.jpeg';
import ReproductiveHealthImage from '../../assets/images/healthPage/reproductive-health-image.jpeg';
import CollaborationImage from '../../assets/images/healthPage/Collaboration-image.jpeg';

import flyer1 from '../../assets/images/flyersHealthPage/mental-health-flyer.jpeg';
import flyer2 from '../../assets/images/flyersHealthPage/yoga-flyer.jpeg';
import flyer3 from '../../assets/images/flyersHealthPage/health-awareness-flyer.jpeg';
import flyer4 from '../../assets/images/flyersHealthPage/decode-obesity-flyer.jpeg';
import flyer5 from '../../assets/images/flyersHealthPage/reproductive-health-flyers.jpeg';
import flyer6 from '../../assets/images/flyersHealthPage/pcos-awareness-flyer.jpeg';

const TransformLivesPage = () => {
  const HealthCard = [
    {
      id: 1,
      image: mentalHealthImage,
      title: 'Mental Wellness Programs',
      link: '#',
      text: `At MettaMate, we believe that mental well-being is
       just as important as physical health. Our mission is to provide
        a safe, supportive, and non-judgmental space for individuals to
         prioritize their mental health. We offer a range of services,
          including counseling and therapy, mindfulness and meditation,
          stress management, anxiety and depression support, and personal
          growth and development workshops.`,
    },
    {
      id: 2,
      image: CommunityOutreachImage,
      title: 'Community Outreach and Education',
      link: '#',
      text: `MettaMate's community outreach programs aim to promote
       holistic well-being and empower individuals to take control
        of their health. Our initiatives include mental health
        awareness  and counselling,sexual health and reproductive
        system awareness and counselling,health and wellness workshops,
         nutrition education and, and yoga classes.`,
    },

    {
      id: 3,
      image: YogaImage,
      title: 'Yoga and Wellness Programs',
      link: '#',
      text: `Join MettaMate's Nature Cure and Yoga Programmes and
       embark on a transformative journey! Immerse yourself in the
        healing power of nature and discover the ancient wisdom of yoga.
         Our expert-led programmes will guide you through meditation,
         pranayama, and asanas, nurturing your mind and body,Rejuvenate,
          restore, and rediscover your inner balance!"`,
    },
    {
      id: 4,
      image: ReproductiveHealthImage,
      title: 'Sexual and Reproductive Health Services',
      link: '#',
      text: `Mettamate's Reproductive and Sexual Health Awareness Programme
       and discover the power of informed choices! Our interactive workshops,
        expert talks, and supportive community will empower you to take control
         of your health, relationships, and well-being. Break the silence,
          shatter the stigma, and unleash your confidence!`,
    },
    {
      id: 5,
      image: CollaborationImage,
      title: 'Collaboration and Advocacy',
      link: '#',
      text: `At Mettamate, we believe collaboration and advocacy
       are key to driving meaningful impact in health programs.
        We bring together stakeholders to co-create innovative
        solutions, amplify marginalized voices, and champion
        policies promoting health equity through inclusive
        partnerships, evidence-based advocacy, and policy influence.`,
    },
  ];
  const flyersCard = [
    {
      id: 1,
      image: flyer1,
      link: '#',
    },
    {
      id: 2,
      image: flyer2,
      link: '#',
    },

    {
      id: 3,
      image: flyer3,
      link: '#',
    },
    {
      id: 4,
      image: flyer4,
      link: '#',
    },
    {
      id: 5,
      image: flyer5,
      link: '#',
    },
    {
      id: 6,
      image: flyer6,
      link: '#',
    },
  ];

  return (
    <React.Fragment>
      <section className="section">
        <Container>
          <Row>
            <h3 className="text-center">
              MettaMate's Holistic Approach to Health and Wellness for
              Underprivileged Communities
            </h3>
          </Row>

          <Row>
            {HealthCard.map((card, key) => (
              <Col xs="12" lg="12" className="mt-4 pt-2" key={key}>
                <Card className="shadow rounded border-0 overflow-hidden">
                  <Row className="g-0">
                    <Col
                      md={7}
                      className="d-flex flex-column align-items-start p-0 m-0"
                    >
                      <CardBody className="h-100 d-flex flex-column justify-content-start p-3">
                        {/* Title */}
                        <h5 className="card-title font-weight-bold mb-3 text-center">
                          <Link to="#" className="text-primary">
                            {card.title}
                          </Link>
                        </h5>
                        {/* Image */}
                        <img
                          src={card.image}
                          className="img-fluid"
                          alt="..."
                          style={{
                            width: '100%',
                            height: 'auto',
                            borderRadius: '10px',
                            objectFit: 'cover',
                          }}
                        />
                      </CardBody>
                    </Col>

                    <Col
                      md={5}
                      className="d-flex flex-column justify-content-center p-3"
                    >
                      <p className="fs-6 m-0">{card.text}</p>
                    </Col>
                  </Row>
                </Card>
              </Col>
            ))}
          </Row>

          <Row className="py-4">
            <h3 className="text-center">Currently running programs</h3>
          </Row>

          <Row className="shadshadow-md bg-body rounded">
            {flyersCard.map((flyersCard, key) => (
              <Col lg="4" md="6" className="" key={key} name="servicesCard">
                <Link to="" className="card-title title text-dark">
                  <Card className="blog rounded border-0 shadow overflow-hidden mb-4">
                    <div className="position-relative">
                      <CardImg
                        src={flyersCard.image}
                        className="img-fluid"
                        alt=""
                        style={{
                          width: '100%',
                          height: 'auto',
                          borderRadius: '10px',
                          objectFit: 'cover',
                        }}
                      />
                      <div className="overlay rounded-top bg-dark"></div>
                    </div>
                  </Card>
                </Link>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};

export default TransformLivesPage;
