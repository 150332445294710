import React, { useEffect, useMemo, useState } from 'react';
import '../../Home/css/userHomePage.css';
import CommonModal from '../modal.js';
import apiUrl from '../../server.js';
import axios from 'axios';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { Row, Col, Card, CardBody, Modal, ModalBody, ModalHeader, ModalFooter, PaginationItem, PaginationLink } from 'reactstrap';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import manavatar from '../../../assets/images/MettaMateImages/profileImages/maleAIIamge1.png';
// import femaleavatar from '../../../assets/images/MettaMateImages/profileImages/femaleAIImage3D.jpg';
import manavatar from '../../../assets/images/MettaMateImages/male_avatar.jpg'
import femaleavatar from '../../../assets/images/MettaMateImages/female_avatar.jpeg'
import transgenderavatar from '../../../assets/images/MettaMateImages/transgenderprofile.png';
import ProfileModal from '../../Home/Profile_modal';
import ContentLoader, { List } from 'react-content-loader';
import ModalBodySkeleton from '../../Home/skeleton.jsx';

import {
    Button,
} from 'reactstrap';

const MyReferralsFunction = () => {
    const [myReferralList, setmyReferralList] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [likedmemberuuid, setlikedmemberuuid] = useState({});
    const [likeStatus, setLikedStatus] = useState('Like')
    const [memberFullData, setMemberFullData] = useState([]);
    const [memberModal, setModal] = useState(false);
    const [dob, setDOB] = useState({});
    const [currentLocation, setCurrentLocation] = useState({});
    const [nativeLocation, setNativeLocation] = useState({});
    const [companyLocation, setCompanyLocation] = useState({});
    const [success, setSuccess] = useState();
    const [error, setError] = useState();
    const [isLoading, setLoading] = useState(true);
    const [isModalLoading, setModalLoading] = useState(true)

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState();
    const [totalCount, setTotalCount] = useState();
    const itemsPerPage = 6;


    useEffect(() => {
        fetchReferralDetails();
    }, []);

    const fetchReferralDetails = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${apiUrl}members/recommendation/${localStorage.getItem('memberID')}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            });
   	    const allMembers = response.data.details;
            setTotalCount(response.data.totalCount);
            setmyReferralList(allMembers.filter(member => member !== null));
            setLoading(false)
        //setmyReferralList(temp);
        setLoading(false);
        //setTotalCount(temp.length);
        //setTotalPages(Math.ceil((temp.length / itemsPerPage)));

        } catch (error) {
            setLoading(false);
            console.error('Error fetching members:', error);
        }
    //-----------------------------------temporary data----------------------------------------

        // const temp = [
        //     {
        //         "memberuuid": "f7b54d37-cd96-4f8b-8312-f166c3177a23",
        //         "firstname": "John",
        //         "lastname": "Doe",
        //         "gender": "Male",
        //         "membertype": "Alliance Seeker",
        //         "emailid": "johndoe@example.com"
        //     },
        //     {
        //         "memberuuid": "99a7a85d-6f3f-4766-8a9a-a922649b0327",
        //         "firstname": "Jane",
        //         "lastname": "Smith",
        //         "gender": "Female",
        //         "membertype": "Innovator",
        //         "emailid": "janesmith@example.com"
        //     },
        //     {
        //         "memberuuid": "c2c1c395-cfd8-4020-9e6b-842a41623165",
        //         "firstname": "Alex",
        //         "lastname": "Johnson",
        //         "gender": "Other",
        //         "membertype": "Industry Leader",
        //         "emailid": "alexjohnson@example.com"
        //     },
        //     {
        //         "memberuuid": "2c5b71be-ebb2-4573-ade1-558debccf712",
        //         "firstname": "Emily",
        //         "lastname": "Hernandez",
        //         "gender": "Female",
        //         "membertype": "New Entrant",
        //         "emailid": "emilyhernandez@example.com"
        //     },
        //     {
        //         "memberuuid": "d843ee94-e1b5-4148-91c0-609f2eb85f44",
        //         "firstname": "Brian",
        //         "lastname": "Wilson",
        //         "gender": "Male",
        //         "membertype": "Experienced Professional",
        //         "emailid": "brianwilson@example.com"
        //     },
        //     {
        //         "memberuuid": "a24c2519-bb84-4128-bf71-6c2be09c3c58",
        //         "firstname": "Maria",
        //         "lastname": "Gonzalez",
        //         "gender": "Female",
        //         "membertype": "Consultant",
        //         "emailid": "mariagonzalez@example.com"
        //     },
        //     {
        //         "memberuuid": "ec4c1a23-64bf-4a4f-ba4e-07a9e958ae3d",
        //         "firstname": "Tom",
        //         "lastname": "Baker",
        //         "gender": "Male",
        //         "membertype": "Field Expert",
        //         "emailid": "tombaker@example.com"
        //     },
        //     {
        //         "memberuuid": "cd5f718d-0a4f-45f2-a73e-56c3a1aef53c",
        //         "firstname": "Linda",
        //         "lastname": "Carter",
        //         "gender": "Female",
        //         "membertype": "Research Analyst",
        //         "emailid": "lindacarter@example.com"
        //     },
        //     {
        //         "memberuuid": "ff76b45c-05ab-4da8-ad29-5545cabc546b",
        //         "firstname": "Ishan",
        //         "lastname": "Patel",
        //         "gender": "Male",
        //         "membertype": "Senior Developer",
        //         "emailid": "ishanpatel@example.com"
        //     },
        //     {
        //         "memberuuid": "a17df5a6-31e7-402f-bb3d-1c68a90ad95c",
        //         "firstname": "Clara",
        //         "lastname": "Lee",
        //         "gender": "Female",
        //         "membertype": "Product Manager",
        //         "emailid": "claralee@example.com"
        //     },
        //     {
        //         "memberuuid": "f7b54d37-cd96-4f8b-8312-f166c3177a23",
        //         "firstname": "John",
        //         "lastname": "Doe",
        //         "gender": "Male",
        //         "membertype": "Alliance Seeker",
        //         "emailid": "johndoe@example.com"
        //     },
        //     {
        //         "memberuuid": "99a7a85d-6f3f-4766-8a9a-a922649b0327",
        //         "firstname": "Jane",
        //         "lastname": "Smith",
        //         "gender": "Female",
        //         "membertype": "Innovator",
        //         "emailid": "janesmith@example.com"
        //     },
        //     {
        //         "memberuuid": "c2c1c395-cfd8-4020-9e6b-842a41623165",
        //         "firstname": "Alex",
        //         "lastname": "Johnson",
        //         "gender": "Other",
        //         "membertype": "Industry Leader",
        //         "emailid": "alexjohnson@example.com"
        //     },
        //     {
        //         "memberuuid": "2c5b71be-ebb2-4573-ade1-558debccf712",
        //         "firstname": "Emily",
        //         "lastname": "Hernandez",
        //         "gender": "Female",
        //         "membertype": "New Entrant",
        //         "emailid": "emilyhernandez@example.com"
        //     },
        //     {
        //         "memberuuid": "d843ee94-e1b5-4148-91c0-609f2eb85f44",
        //         "firstname": "Brian",
        //         "lastname": "Wilson",
        //         "gender": "Male",
        //         "membertype": "Experienced Professional",
        //         "emailid": "brianwilson@example.com"
        //     },
        //     {
        //         "memberuuid": "a24c2519-bb84-4128-bf71-6c2be09c3c58",
        //         "firstname": "Maria",
        //         "lastname": "Gonzalez",
        //         "gender": "Female",
        //         "membertype": "Consultant",
        //         "emailid": "mariagonzalez@example.com"
        //     },
        //     {
        //         "memberuuid": "ec4c1a23-64bf-4a4f-ba4e-07a9e958ae3d",
        //         "firstname": "Tom",
        //         "lastname": "Baker",
        //         "gender": "Male",
        //         "membertype": "Field Expert",
        //         "emailid": "tombaker@example.com"
        //     },
        //     {
        //         "memberuuid": "cd5f718d-0a4f-45f2-a73e-56c3a1aef53c",
        //         "firstname": "Linda",
        //         "lastname": "Carter",
        //         "gender": "Female",
        //         "membertype": "Research Analyst",
        //         "emailid": "lindacarter@example.com"
        //     },
        //     {
        //         "memberuuid": "ff76b45c-05ab-4da8-ad29-5545cabc546b",
        //         "firstname": "Ishan",
        //         "lastname": "Patel",
        //         "gender": "Male",
        //         "membertype": "Senior Developer",
        //         "emailid": "ishanpatel@example.com"
        //     },
        //     {
        //         "memberuuid": "a17df5a6-31e7-402f-bb3d-1c68a90ad95c",
        //         "firstname": "Clara",
        //         "lastname": "Lee",
        //         "gender": "Female",
        //         "membertype": "Product Manager",
        //         "emailid": "claralee@example.com"
        //     }
        // ];
        // setmyReferralList(temp);
        // setLoading(false);
        // setTotalCount(temp.length);
        // setTotalPages(Math.ceil((temp.length / itemsPerPage)));
    }

    const calculate_age = (dob1) => {
        if (dob1 != null) {
            var today = new Date();
            var birthDate = new Date(dob1);  // create a date object directly from `dob1` argument
            var age_now = today.getFullYear() - birthDate.getFullYear();
            var m = today.getMonth() - birthDate.getMonth();
            if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                age_now--;
            }
            return age_now ? age_now : '00';
        }
        else {
            return '00'
        }
    }
    const openProfileModal = async (memberuuid) => {
        setModal(!memberModal);
        try {
            const result = await axios.get(`${apiUrl}members/fulldetails/${memberuuid}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            });
            const { data } = result;
            if (data && data.length > 0) {
                const member = data[0];
                const date = new Date(member.dob);
                setModalLoading(false)
                setDOB(`${getOrdinalSuffix(date.getDate())} ${getMonthName(date.getMonth())}, ${date.getFullYear()}`);
                setNativeLocation(member.nativelocationname || 'Location not updated');
                setCurrentLocation(member.currentlocationname || 'Location not updated');
                setCompanyLocation(member.companylocationname || 'Location not updated');
                setMemberFullData(member);
            }
        } catch (error) {
            console.error("Error fetching member details: ", error);
        }
    }
    const closeProfileModal = () => {
        setModal(!memberModal);
    }
    const getOrdinalSuffix = (day) => {
        const suffixes = ['th', 'st', 'nd', 'rd'];
        const v = day % 100;
        return day + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
    }
    const getMonthName = (month) => {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        return months[month];
    }

    const errorHandler = () => {
        setError(null);
    };

    const successHandler = () => {
        setSuccess(null);
    };

    const favoriteMember = async () => {
        try {
            let request = {
                likedmemberuuid: likedmemberuuid,
                memberuuid: localStorage.getItem('memberID')
            }
            let result = await axios.post(`${apiUrl}members/favourites`, request, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem("token")}`
                }
            }).then(async (res) => {
                fetchReferralDetails();
                setOpenModal(false)

            })

        } catch (error) {

        }
    }

    const openConfirmModal = (memberUUID, likedstatus) => {
        if (likedstatus === 1) {
            setlikedmemberuuid(memberUUID);
            setLikedStatus('Unlike');
            setOpenModal(true)
        }
        else {
            setlikedmemberuuid(memberUUID);
            setLikedStatus('Like');
            setOpenModal(true)
        }
    }
    const closelikeModal = () => {
        setOpenModal(false);
    }

    const displayImage = (imagepath, gender) => {
        if (!imagepath) {
            if (gender.toLowerCase() == "male") { return manavatar }
            else if (gender.toLowerCase() == "female") { return femaleavatar }
            else { return transgenderavatar }
        }
        else { return imagepath }
    }


    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
    };
    //client side pagination
    const handlePageClick = newPage => {
        setCurrentPage(newPage);
    };

    let currentItems = useMemo(() => {
        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        return myReferralList.slice(indexOfFirstItem, indexOfLastItem);
    }, [currentPage, itemsPerPage, myReferralList]);

    let pageNumbers = useMemo(() => {
        return Array.from({ length: totalPages }, (_, i) => i + 1);
    }, [totalPages]);

    pageNumbers = useMemo(() => {
        const pages = [1];  // Start with the first page

        // Calculate dynamic start and end indices
        const startPage = Math.max(2, currentPage - 1);
        const endPage = Math.min(currentPage + 1, totalPages - 1);

        // Add ellipsis if there's a gap after the first page
        if (startPage > 2) pages.push('...');

        // Add page numbers in the current range
        for (let i = startPage; i <= endPage; i++) {
            pages.push(i);
        }

        // Add ellipsis before the last page if there's a gap
        if (endPage < totalPages - 1) pages.push('...');

        // Include the last page if more than one page exists
        if (totalPages > 1) pages.push(totalPages);
        return pages;
    }, [currentPage, totalPages]);

    return (
        <>
            {error && (
                <CommonModal
                    title={error.title}
                    message={error.message}
                    onClose={errorHandler}
                    status="error"
                />
            )}
            {success && (
                <CommonModal
                    title={success.title}
                    message={success.message}
                    onClose={successHandler}
                    status="success"
                />
            )}
            <Col lg="12" className='Col_12'>
                <Row>
                    <Col md={12} xs={12} className=" ">
                        {isLoading ? (
                            <Row>
                                {[1, 2, 3, 4].map((key) => (
                                    <Col lg="3" md="6" xs="12" key={key} className="mt-4 pt-2 text-center">
                                        <Card className="catagories overflow-hidden rounded border-0">

                                            <ContentLoader
                                                speed={2}
                                                width={280}
                                                height={400}
                                                viewBox="0 0 280 400"
                                                backgroundColor="#f3f3f3"
                                                foregroundColor="#ecebeb"

                                            >
                                                {/* Image skeleton */}
                                                <rect x="0" y="0" rx="5" ry="5" width="280" height="200" />

                                                {/* Other content placeholders */}
                                                <rect x="0" y="220" rx="5" ry="5" width="280" height="20" />
                                                <rect x="0" y="250" rx="5" ry="5" width="280" height="20" />
                                                <rect x="0" y="280" rx="5" ry="5" width="140" height="20" />
                                                <rect x="150" y="280" rx="5" ry="5" width="130" height="20" />
                                                <circle cx="30" cy="330" r="15" />
                                                <circle cx="70" cy="330" r="15" />
                                                <circle cx="110" cy="330" r="15" />
                                            </ContentLoader>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        ) : (
                            <>
                                {currentItems.length == 0 ? (
                                    <div className='text-center mt-3 label_text' >No Referrals</div>
                                ) : (
                                    <Row>
                                        {(currentItems || []).map((item, key) => (
                                            // <Col lg="3" md="6" key={key} className="mt-4 pt-2 text-center">
                                            //     <Card className="catagories overflow-hidden rounded  border-0">
                                            //         <ul className="list-unstyled align-items-center " style={{ width: `100%`, zIndex: `9`, marginBottom: `0px` }}>
                                            //             <li className="list-inline-item float-end">
                                            //                 <Button className="like btn-transparent p-0" style={{ background: `none`, border: `none` }} onClick={() => openConfirmModal(item.memberuuid, item.likedstatus)} >
                                            //                     <i className=
                                            //                         {`mdi mdi-heart h5 mb-0 ${item.likedstatus === 1 ? 'redheart' : ''
                                            //                             }`}></i>
                                            //                 </Button>
                                            //             </li>
                                            //             <li className="list-inline-item float-start">
                                            //                 <span className="badge rounded bg-soft-success border-1 border-primary" style={{ fontSize: `14px` }}>

                                            //                     {item.maritalstatus == '' && (
                                            //                         <span> Unknown Status</span>
                                            //                     )}
                                            //                     {item.maritalstatus != '' && (
                                            //                         <span>  {item.maritalstatus}</span>
                                            //                     )}
                                            //                 </span>
                                            //             </li>
                                            //         </ul>

                                            //         <Slider className='imageSlider ' {...settings}>
                                            //             <img src={displayImage(item.photothumb1, item.gender)} className='img-fluid img_thumb' />
                                            //             <img src={displayImage(item.photothumb2, item.gender)} className='img-fluid img_thumb' />
                                            //             <img src={displayImage(item.photothumb3, item.gender)} className='img-fluid img_thumb' />
                                            //         </Slider>

                                            //         <CardBody style={{ border: `1px solid #d8ecff` }}>

                                            //             <p

                                            //                 className="text-dark h5 name"
                                            //             >
                                            //                 <span style={{ color: `#0D6EFD`, }}>{item.firstname}, </span> {' '} <span className="text-danger">{calculate_age(item.dob)}</span>
                                            //             </p>
                                            //             {item.jobprofession ? (
                                            //                 <p className=" my-1" style={{ fontSize: `16px` }}>{item.jobprofession}</p>) :
                                            //                 (<p className=" my-1" style={{ fontSize: `16px` }}>Unknown Profession</p>)}

                                            //             <small className="designation" style={{ fontSize: `15px` }}>
                                            //                 <span style={{ fontSize: `15px` }} ><i className="uil uil-map-marker text-primary"></i></span> {item.currentlocationname}
                                            //             </small>
                                            //             <div className='row mt-2'>
                                            //                 <div className='col-12 p-0'>
                                            //                     <button onClick={() => openProfileModal(item.memberuuid)} className="btn btn-outline-primary designation" style={{ width: `93%` }}> View Profile
                                            //                     </button>
                                            //                 </div>
                                            //             </div>

                                            //         </CardBody>
                                            //     </Card>
                                            // </Col>


                                            <Col key={key} className="mt-4 pt-2 text-center col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12  ">

                                                <Card className="catagories  rounded rounded-3 overflow-hidden border-1 border-primary shadow">

                                                    <CardBody className='rounded overflow-hidden p-0 '>


                                                        <ul className="list-unstyled align-items-center width100 position-absolute  " style={{ zIndex: `9`, marginBottom: `0px` }}>

                                                            <li className="list-inline-item float-start m-2">
                                                                <span className="badge rounded-pill text-dark border  bg-light border-1 border-dark font_size14">

                                                                    {(item.maritalstatus == '' || item.maritalstatus == null) && (
                                                                        <span className='font_size14'> Unknown Status</span>
                                                                    )}
                                                                    {item.maritalstatus != '' && (
                                                                        <span className='font_size14'>  {item.maritalstatus}</span>
                                                                    )}
                                                                </span>
                                                            </li>
                                                            <li className="list-inline-item float-end m-2">
                                                                <Button className="like btn-transparent p-0" style={{ background: `none`, border: `none` }} onClick={() => openConfirmModal(item.memberuuid, item.likedstatus)}>
                                                                    <i className={`mdi mdi-heart h5 mb-0 ${item.likedstatus === 1 ? 'redheart' : ''}`}></i>
                                                                </Button>
                                                            </li>
                                                        </ul>

                                                        <Slider className='imageSlider image-container' {...settings}>
                                                            <img src={displayImage(item.photothumb1, item.gender)} className='img-fluid img_thumb' />
                                                            <img src={displayImage(item.photothumb2, item.gender)} className='img-fluid img_thumb' />
                                                            <img src={displayImage(item.photothumb3, item.gender)} className='img-fluid img_thumb' />
                                                        </Slider>

                                                    </CardBody>
                                                </Card>
                                                <Card className="catagories rounded rounded-3 mt-1 overflow-hidden shadow">
                                                    <CardBody className='rounded shadow p-2 '>
                                                        <p className="text-dark h5 name mb-0">
                                                            <span style={{ color: `#0D6EFD` }}>{item.firstname}, </span>{' '}<span className="text-primary">{calculate_age(item.dob)}</span>
                                                        </p>


                                                        <p className="m-0 font_size12">
                                                            <FeatherIcon icon="briefcase" className="fea icon-sm me-1" />
                                                            {item.jobprofession ? item.jobprofession : "Unknown Profession"}
                                                        </p>

                                                        <p className="m-0 font_size12">
                                                            <FeatherIcon icon="map-pin" className="fea icon-sm  me-1" />
                                                            {item.currentlocationname || 'Location not provided' }
                                                        </p>

                                                        <div className=' d-flex justify-content-center'>
                                                            <div className='m-1'>
                                                                <button onClick={() => openProfileModal(item.memberuuid)} className="btn  designation btn-outline-primary viewBtn">
                                                                    {/* <FeatherIcon icon="eye" className="fea icon-sm icons" /> */}
                                                                    View Profile
                                                                </button>
                                                            </div>
                                                            <div className='m-1 d-none'>
                                                                <Link to={`/chat/${item.memberuuid}`} className="btn  designation btn-outline-info viewBtn">
                                                                    <FeatherIcon
                                                                        icon="mail"
                                                                        className="fea icon-sm icons" />
                                                                </Link>
                                                            </div>
                                                            <div className='m-1 d-none'>


                                                                {!item.connectionstatus &&
                                                                    <button className='btn btn-outline-secondary' onClick={() => this.openConnectionModal(item.memberuuid, item.connectionstatus)}>
                                                                        <span><i className="fa fa-handshake-o" aria-hidden="true"></i></span>
                                                                    </button>
                                                                }
                                                                {item.connectionstatus == 'Connecting' &&
                                                                    (<button className='btn btn-outline-danger'>
                                                                        <span><img src={ConnectingIcon} className='connectImg' /></span>
                                                                    </button>)}

                                                                {item.connectionstatus == 'Connected' &&

                                                                    <button className='btn btn-outline-success'>
                                                                        <FeatherIcon
                                                                            icon="user-check"
                                                                            className="fea icon-sm icons " />
                                                                    </button>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className='row d-none'>
                                                            <div className='col-md-12'>
                                                                <span style={{ border: `1px solid #0d6efd`, padding: `4px 10px 5px 10px`, borderRadius: ` 24px` }}>
                                                                    <label className="title text-dark font_weight300 font_size14 mt-2">Referred By:</label>
                                                                    <button onClick={() => openModal(item.recommendersuuid)} className='btn btn-transparent p-0 text-primary cursor-pointer font-size15 font_weight300'>
                                                                        {item.recommendertype === '' ? 'Unknown Referral' : item.recommendertype}
                                                                    </button>
                                                                </span>


                                                            </div>
                                                        </div>


                                                    </CardBody>
                                                </Card>
                                            </Col>



                                        ))}
                                        <Col xs={12} className="mt-4 pt-2" >
                                            {totalPages > 1 && (
                                                <>
                                                    {currentItems.length > 0 && (
                                                        <ul className="pagination justify-content-center mb-0">
                                                            <PaginationItem disabled={currentPage === 1}>
                                                                <PaginationLink
                                                                    to="#"
                                                                    aria-label="Previous"
                                                                    onClick={() => handlePageClick(currentPage - 1)}

                                                                >
                                                                    {'<<'}
                                                                </PaginationLink>
                                                            </PaginationItem>
                                                            {pageNumbers.map((number) =>
                                                            (
                                                                <PaginationItem active={number === currentPage} key={number} disabled={number === '...'}>
                                                                    <PaginationLink onClick={() => handlePageClick(number)}>
                                                                        {number}
                                                                    </PaginationLink>
                                                                </PaginationItem>
                                                            )
                                                            )}
                                                            <PaginationItem disabled={currentPage === totalPages}>
                                                                <PaginationLink
                                                                    to="#"
                                                                    aria-label="Next"
                                                                    onClick={() => handlePageClick(currentPage + 1)}

                                                                >
                                                                    {'>>'}
                                                                </PaginationLink>
                                                            </PaginationItem>
                                                        </ul>
                                                    )}

                                                </>
                                            )}

                                        </Col>
                                    </Row>
                                )}
                            </>
                        )}
                    </Col>
                </Row>
            </Col>
            {/* <BackToTop /> */}
            <Modal isOpen={memberModal}
                size="xl">
                <ModalHeader toggle={openModal}>Profile Details
                    <button type="button" className="close" aria-label="Close" onClick={closeProfileModal}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </ModalHeader>
                {isModalLoading ? (
                    <ModalBody>
                        <ModalBodySkeleton />
                    </ModalBody>
                ) : (
                    <>
                        {memberFullData && Object.keys(memberFullData).length > 0 &&
                            (
                                <ProfileModal memberData={memberFullData} location={[nativeLocation, currentLocation, companyLocation]} dob={dob} />
                            )}
                    </>
                )}
            </Modal>
            <Modal isOpen={openModal} toggle={closelikeModal}>
                <ModalHeader toggle={closelikeModal}>Confirmation
                    <button type="button" className="close" aria-label="Close" onClick={closelikeModal}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <Col lg="12">
                            <p className='mb-0'>Are You Sure to {likeStatus} ?</p>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter className='p-1'>
                    <Button
                        color="secondary"
                        onClick={closelikeModal}
                        type="button"
                    >
                        Close
                    </Button>
                    <Button color="danger" type="button" onClick={favoriteMember}>
                        {likeStatus}
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
};

export default MyReferralsFunction;
