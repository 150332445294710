import React from 'react';
import { Card, CardBody, Col, Container, Row, CardImg } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';

import ArgentinaLogo from '../../assets/images/flagLogo/argentina.jpeg';
import AustraliaLogo from '../../assets/images/flagLogo/australia.jpeg';
import AustriaLogo from '../../assets/images/flagLogo/austria.jpeg';
import BelgiumLogo from '../../assets/images/flagLogo/belgium.jpeg';
import BrazilLogo from '../../assets/images/flagLogo/brazil.jpeg';
import CanadaLogo from '../../assets/images/flagLogo/canada.jpeg';
import ChileLogo from '../../assets/images/flagLogo/chile.jpeg';
import ChinaLogo from '../../assets/images/flagLogo/china.jpeg';
import CzechRepublicLogo from '../../assets/images/flagLogo/czech-republic.jpeg';
import EuropeLogo from '../../assets/images/flagLogo/europe.jpeg';
import FranceLogo from '../../assets/images/flagLogo/france.jpeg';
import GreeceLogo from '../../assets/images/flagLogo/greece.jpeg';
import HongKongLogo from '../../assets/images/flagLogo/hongkong.jpeg';
import HungaryLogo from '../../assets/images/flagLogo/hungary.jpeg';
import IndiaLogo from '../../assets/images/flagLogo/india.jpeg';
import IrelandLogo from '../../assets/images/flagLogo/ireland.jpeg';
import ItalyLogo from '../../assets/images/flagLogo/italy.jpeg';
import JapanLogo from '../../assets/images/flagLogo/japan.jpeg';
import MexicoLogo from '../../assets/images/flagLogo/mexico.jpeg';
import NetherlandsLogo from '../../assets/images/flagLogo/netherlands.jpeg';
import SingaporeLogo from '../../assets/images/flagLogo/singapore.jpeg';
import SlovakiaLogo from '../../assets/images/flagLogo/slovakia.jpeg';
import SouthKoreaLogo from '../../assets/images/flagLogo/south-korea.jpeg';
import SpainLogo from '../../assets/images/flagLogo/spain.jpeg';
import SwedenLogo from '../../assets/images/flagLogo/sweden.jpeg';
import SwitzerlandLogo from '../../assets/images/flagLogo/switzerland.jpeg';
import UKLogo from '../../assets/images/flagLogo/uk.jpeg';
import USLogo from '../../assets/images/flagLogo/us.jpeg';

import './ScholarshipPage.css'; // Import the CSS file

const US_scholarships = [
  {
    Country: 'US',
    Title: 'Fulbright Foreign Student Program',
    Description:
      'Perhaps the most popular government-funded US scholarships for international students, Fulbright offers scholarships for graduate students, young professionals and artists to study in the US for one year or more. Open to international students in all fields excluding medicine.',
    LogoURL:
      'https://uz.usembassy.gov/wp-content/uploads/sites/78/Fulbright-Foreign-Student-Program-1140x684.jpg',
    Timeframe: 'February - October',
    Amount: '',
    Weblink:
      'https://foreign.fulbrightonline.org/about/foreign-student-program',
    Contact: 'https://wa.me/+917022484674',
  },
  {
    Country: 'US',
    Title: 'Hubert Humphrey Fellowship Program',
    Description:
      'This is a non-degree scholarship program for international, experienced professionals wishing to undertake 10 months of academic study in the US. Although the funding doesn’t support degree qualifications, students are able to study and develop their knowledge at academic institutions across the nation.',
    LogoURL:
      'https://rs.usembassy.gov/wp-content/uploads/sites/77/2022/05/hhh-felowship-2019.jpg',
    Timeframe: 'August - January',
    Amount: '',
    Weblink: 'https://www.humphreyfellowship.org/',
    Contact: 'https://wa.me/+917022484674',
  },
  {
    Country: 'US',
    Title: 'Abbey Road Summer Scholarships',
    Description:
      'Abbey Road are offering US scholarships worth US$1,000 for language and art students, as well as US$500 fellowships for “summer enrichment” at institutions within the states.',
    LogoURL:
      'https://d3hk6w1rfu80ox.cloudfront.net/media/uploads/listings/joBVnTXMOogo.jpg',
    Timeframe: 'June - August',
    Amount: '$1,000',
    Weblink: 'https://www.goabbeyroad.com',
    Contact: 'https://wa.me/+917022484674',
  },
  {
    Country: 'US',
    Title: 'Civil Society Leadership Awards',
    Description:
      'Fully-funded scholarships are available for students from eligible countries to study in the US or another host country at master’s level.',
    LogoURL: 'https://www.american.edu/customcf/assets/crest-logotype-blue.svg',
    Timeframe: 'January - March',
    Amount: '',
    Weblink: 'https://www.american.edu/',
    Contact: 'https://wa.me/+917022484674',
  },
  {
    Country: 'US',
    Title: 'The Next Gen Scholarship Fund',
    Description:
      'Scholarships to study in the US for non-residents and international students. Applicants must have at least a 3.0 GPA (grade point average) and be enrolled on a program at an accredited university within Washington DC, Maryland or Virginia. Worth US$1,000.',
    LogoURL:
      'https://cdn.internationalscholarships.com/assets/ISCH/images/international-scholarships-logo-inverse-BW.png',
    Timeframe: '',
    Amount: '$1,000',
    Weblink: 'https://www.internationalscholarships.com/',
    Contact: '',
  },
  {
    Country: 'US',
    Title: 'Tortuga Backpacks Study Abroad Scholarship',
    Description:
      'Awarded twice a year to passionate international students wishing to study in the US. Worth US$1,000.',
    LogoURL: 'https://www.tortugabackpacks.com/cdn/shop/files/logo_1.png',
    Timeframe: '',
    Amount: '$1,000',
    Weblink: 'https://www.tortugabackpacks.com/',
    Contact: '',
  },
  {
    Country: 'US',
    Title: 'David P. Shapiro Autism Scholarship',
    Description:
      'A one-time scholarship worth US$1,000 open to current or prospective students in the US diagnosed with autism.',
    LogoURL: 'https://www.davidpshapirolaw.com/wp-content/uploads/logo1.png',
    Timeframe: '',
    Amount: '$1,000',
    Weblink: 'https://www.davidpshapirolaw.com/',
    Contact: '',
  },
  {
    Country: 'US',
    Title: 'Preply Scholarship',
    Description:
      "Three winners will receive an award of up to $2,000. Open to all students (domestic and international, and at any study level) aged between 16 and 35. To enter, you'll need to submit a 500-word essay on “The importance of multilingualism to personal and professional development”.",
    LogoURL: '',
    Timeframe: '',
    Amount: '$2,000',
    Weblink: 'https://www.topuniversities.com/',
    Contact: '',
  },
  {
    Country: 'US',
    Title: 'East-West Center Scholarships and Fellowships',
    Description:
      'Scholarships to study in the US within selected institutions, aimed at international students from the Asia-Pacific region.',
    LogoURL:
      'https://www.eastwestcenter.org/themes/custom/ewc/images/EWCLOGO-new.svg',
    Timeframe: '',
    Amount: '',
    Weblink: 'https://www.eastwestcenter.org/',
    Contact: '',
  },
  {
    Country: 'US',
    Title: 'Wesleyan Freeman Asian Scholarship Program',
    Description:
      'Scholarships for 11 exceptional Asian students to study in the US at Wesleyan University in Connecticut. Covers the full cost of tuition and student fees.',
    LogoURL: 'https://www.wesleyan.edu/assets/images/logo.svg',
    Timeframe: '',
    Amount: '',
    Weblink: 'https://www.wesleyan.edu/',
    Contact: '',
  },
  {
    Country: 'US',
    Title: 'Rotary Peace Fellowships',
    Description:
      'Scholarships to study in the US awarded to master’s degree students studying within one of Rotary’s Peace Centers (such as at Duke University or the University of North Carolina at Chapel Hill).',
    LogoURL:
      'https://www.rotary.org/sites/all/themes/rotary_rotaryorg/images/rotary-logo-color-2019-simplified.svg',
    Timeframe: '',
    Amount: '',
    Weblink: 'https://www.rotary.org/',
    Contact: '',
  },
  {
    Country: 'US',
    Title: 'American University Emerging Global Leader Scholarship',
    Description:
      'Undergraduate scholarships to study in the US at American University in Washington DC. Aimed at international students who are showing leadership potential.',
    LogoURL: 'https://www.american.edu/customcf/assets/crest-logotype-blue.svg',
    Timeframe: '',
    Amount: '',
    Weblink:
      'https://www.american.edu/admissions/international/au-egls-apply.cfm',
    Contact: '',
  },
  {
    Country: 'US',
    Title: 'Brandeis University Wien International Scholarship Program',
    Description:
      'Funding opportunity for high-achieving students with an interest in student community contribution.',
    LogoURL:
      'https://cdn.internationalscholarships.com/assets/ISCH/images/international-scholarships-logo-inverse-BW.png',
    Timeframe: 'January 1',
    Amount: '$24,350',
    Weblink: 'https://www.internationalscholarships.com/',
    Contact: '',
  },
  {
    Country: 'US',
    Title: 'Clark University Global Scholars Program',
    Description:
      'Scholarships of no less than US$10,000 as well as a guaranteed $2,500 taxable stipend for a paid internship are available for first-year international applicants.',
    LogoURL:
      'https://www.clarku.edu/wp-content/themes/newclarku/assets/img/logo-seal-only.svg',
    Timeframe: '',
    Amount: '',
    Weblink: 'https://www.clarku.edu/',
    Contact: '',
  },
  {
    Country: 'US',
    Title: 'Emory University Needs-Based Scholarship Program',
    Description:
      'Funding opportunities for international students at Emory University. Students must demonstrate financial need.',
    LogoURL: '',
    Timeframe: 'November 15',
    Amount: '',
    Weblink: 'https://apply.emory.edu/',
    Contact: '',
  },
  {
    Country: 'US',
    Title: 'Harvard University Scholarships',
    Description:
      'Harvard offers a range of (very competitive) international scholarships for students from all over the globe.',
    LogoURL: '',
    Timeframe: '',
    Amount: '',
    Weblink: 'https://college.harvard.edu/',
    Contact: '',
  },
  {
    Country: 'US',
    Title: 'Illinois State University International Awards',
    Description:
      'Illinois State offers tuition waivers, merit awards and graduate assistantships to incoming international students.',
    LogoURL: '',
    Timeframe: '',
    Amount: '$5,000',
    Weblink: 'https://illinoisstate.edu/',
    Contact: '',
  },
];

const Canada_scholarships = [
  {
    Country: 'Canada',
    Title: 'Banting Postdoctoral Fellowships',
    Description:
      'Canadian government scholarships and fellowships offered to international students undertaking postgraduate study within the natural and social sciences or health research.',
    LogoURL: '',
    Timeframe: '',
    Amount: '$70,000',
    Weblink: 'https://banting.fellowships-bourses.gc.ca/',
    Contact: '',
  },
  {
    Country: 'Canada',
    Title: 'Canada Graduate Scholarships – Master’s Program',
    Description:
      'Scholarships for Canadian students enrolled on a master’s degree or PhD at a (CGS-M allocated) university in Canada.',
    LogoURL: '',
    Timeframe: 'December 1',
    Amount: '$27,000',
    Weblink: 'https://www.nserc-crsng.gc.ca/',
    Contact: '',
  },
  {
    Country: 'Canada',
    Title: 'IDRC Research Awards',
    Description:
      'Canadian government scholarships for students from developing countries to undertake a master’s or doctoral-level research degree at a recognized Canadian university.',
    LogoURL: '',
    Timeframe: '',
    Amount: '',
    Weblink: 'https://idrc-crdi.ca/',
    Contact: '',
  },
  {
    Country: 'Canada',
    Title: 'NSERC Postgraduate scholarships',
    Description:
      'Various Canadian government scholarships, grants and awards available to outstanding students undertaking study in the natural sciences or engineering at an accredited Canadian university. Funded by the NSERC.',
    LogoURL:
      'https://cdn.internationalscholarships.com/assets/ISCH/images/international-scholarships-logo-inverse-BW.png',
    Timeframe: '',
    Amount: '$17,300 to $19,100',
    Weblink: 'https://www.nserc-crsng.gc.ca/',
    Contact: '',
  },
  {
    Country: 'Canada',
    Title: 'Organization of American States (OAS) Academic Scholarship Program',
    Description: '',
    LogoURL: 'https://www.educanada.ca/images/educanada-en.png',
    Timeframe: '',
    Amount: '',
    Weblink: 'https://www.oas.org/',
    Contact: '',
  },
  {
    Country: 'Canada',
    Title: 'Vanier Canada Graduate Scholarships Program',
    Description:
      'Canadian government scholarships available to doctoral students internationally, to study in Canada at a participating university.',
    LogoURL: '',
    Timeframe: '',
    Amount: '',
    Weblink: 'https://vanier.gc.ca/',
    Contact: '',
  },
  {
    Country: 'Canada',
    Title: 'Anne Vallee Ecological Fund',
    Description:
      'Scholarships for international students undertaking animal research at doctoral level at an accredited Canadian university in Québec or British Columbia.',
    LogoURL: '',
    Timeframe: '',
    Amount: '',
    Weblink: 'https://scholarshipdb.net/',
    Contact: '',
  },
  {
    Country: 'Canada',
    Title: 'Trudeau Scholarships and Fellowships',
    Description:
      'The Trudeau Foundation offers a number of scholarships, fellowships and mentorships to international students at doctoral level undertaking study in Canada at selected institutions.',
    LogoURL: '',
    Timeframe: '',
    Amount: '',
    Weblink: 'https://www.trudeaufoundation.ca/',
    Contact: '',
  },
  {
    Country: 'Canada',
    Title: 'Carleton University Awards for International Students',
    Description:
      'Scholarships to study in Canada at Carleton University, open to all graduate students, who are automatically considered for the awards upon application.',
    LogoURL: '',
    Timeframe: '',
    Amount: '',
    Weblink: 'https://graduate.carleton.ca/',
    Contact: '',
  },
  {
    Country: 'Canada',
    Title: 'Concordia University International Undergraduate Awards',
    Description:
      'Various scholarships for international students to study in Canada at Concordia University in Montréal, open to international students at undergraduate level.',
    LogoURL: '',
    Timeframe: '',
    Amount: '',
    Weblink: 'https://www.concordia.ca/',
    Contact: '',
  },
  {
    Country: 'Canada',
    Title: 'Dalhousie University Scholarships',
    Description:
      'Scholarships for current and prospective international/domestic students of all levels studying at Dalhousie University in Halifax.',
    LogoURL: '',
    Timeframe: '',
    Amount: '',
    Weblink: 'https://www.dal.ca/',
    Contact: '',
  },
  {
    Country: 'Canada',
    Title: 'Fairleigh Dickinson Scholarships for International Students',
    Description:
      'Scholarships for international students of all levels undertaking study in Canada at Fairleigh Dickinson University in Vancouver.',
    LogoURL: '',
    Timeframe: '',
    Amount: '',
    Weblink: 'https://www.fdu.edu/',
    Contact: '',
  },
  {
    Country: 'Canada',
    Title: 'HEC Montréal Scholarships',
    Description:
      'Various merit- and need-based Canadian scholarships for international students at HEC Montréal, including mobility scholarships for international students.',
    LogoURL: '',
    Timeframe: '',
    Amount: '',
    Weblink: 'https://www.hec.ca/',
    Contact: '',
  },
  {
    Country: 'Canada',
    Title: 'International Student Scholarships at Humber College Canada',
    Description:
      'A range of Canadian scholarships for international students at undergraduate level to study in Canada at Humber College in Toronto.',
    LogoURL: '',
    Timeframe: '',
    Amount: '',
    Weblink: 'https://humber.ca/',
    Contact: '',
  },
];

const Australia_scholarships = [
  {
    Country: 'Australia',
    Title: 'Australia Awards Scholarships (AAS)',
    Description:
      'Scholarships to study in Australia for students of all degree levels (bachelors, masters, PhD) hailing from selected countries within the Asia-Pacific region, Africa and the Middle East. Applicants from Indonesia can also apply for the Allison Sudradjat Prize and/or the Hadi Soesastro Prize.',
    LogoURL: '',
    Timeframe: '',
    Amount: '',
    Weblink: 'https://www.dfat.gov.au/',
    Contact: '',
  },
  {
    Country: 'Australia',
    Title: 'Endeavour Postgraduate Scholarships for International Students',
    Description:
      'A range of merit-based international scholarships to study in Australia. Funded by the Australian government and available to postgraduate students from the Asia-Pacific region, Middle East, Europe and the Americas.',
    LogoURL: '',
    Timeframe: '',
    Amount: '',
    Weblink: 'https://leverageedu.com/',
    Contact: '',
  },
  {
    Country: 'Australia',
    Title: 'Research Training Program (RTP)',
    Description:
      'Postgraduate-level Australian scholarships for domestic and international students to study a research degree at a participating Australian university.',
    LogoURL: '',
    Timeframe: '',
    Amount: '',
    Weblink: 'https://www.education.gov.au/',
    Contact: '',
  },
  {
    Country: 'Australia',
    Title: 'Australian Catholic University International Student Scholarships',
    Description:
      'Scholarships for commencing international undergraduate and postgraduate students, awarded based on academic merit.',
    LogoURL: '',
    Timeframe: '',
    Amount: '',
    Weblink: 'https://www.acu.edu.au/',
    Contact: '',
  },
  {
    Country: 'Australia',
    Title: 'Australian National University',
    Description:
      'Various scholarships available for international students at all study levels, which can be found using a search tool on the university site.',
    LogoURL: '',
    Timeframe: '',
    Amount: '',
    Weblink: 'https://www.anu.edu.au/',
    Contact: '',
  },
];

const UK_scholarships = [
  {
    Country: 'UK',
    Title: 'British Chevening Scholarships',
    Description:
      'UK scholarships for international students funded by the UK Foreign and Commonwealth Office and partner organizations. The scholarships provide full or part funding for full-time graduate programs in any subject.',
    LogoURL: '',
    Timeframe: '',
    Amount: '',
    Weblink: 'https://www.chevening.org/',
    Contact: '',
  },
  {
    Country: 'UK',
    Title: 'Commonwealth Scholarships for Developing Commonwealth Countries',
    Description:
      'Scholarships for international students from developing countries in the Commonwealth, for studies at master’s and PhD level. The full list of eligible countries is available here.',
    LogoURL: '',
    Timeframe: '',
    Amount: '',
    Weblink: 'https://www.britishcouncil.in/',
    Contact: '',
  },
  {
    Country: 'UK',
    Title: 'Commonwealth Shared Scholarship Scheme at UK Universities',
    Description:
      'UK scholarships for international students undertaking taught master’s programs in the UK. Programs studied must be demonstrably relevant to the development of technology, economy or society within the student’s country of origin.',
    LogoURL: '',
    Timeframe: '',
    Amount: '',
    Weblink: 'https://educationvibes.in/',
    Contact: '',
  },
  {
    Country: 'UK',
    Title: 'Marshall Scholarships',
    Description:
      'Postgraduate scholarships for US students showing academic merit, leadership potential and ambassadorial potential to study in the UK. Excludes MBAs and certain courses.',
    LogoURL: '',
    Timeframe: '',
    Amount: '',
    Weblink: 'https://www.marshallscholarship.org/',
    Contact: '',
  },
];

const europe_scholarships = [
  {
    Country: 'Europe',
    Title: 'Erasmus Mundus Scholarships',
    Description:
      'Initiative led by the European Commission to provide funding for students all over the world to study in Europe at selected institutions.',
    LogoURL: '',
    Timeframe: '',
    Amount: '',
    Weblink: 'https://www.euroculturemaster.eu/',
    Contact: '',
  },
  {
    Country: 'Europe',
    Title: 'GoEuro European Study Abroad Scholarship',
    Description:
      '10 individual scholarships of €2,000 for students enrolled in any eligible university in Europe, as well as two €500 Instagram prizes.',
    LogoURL: '',
    Timeframe: '',
    Amount: '',
    Weblink: 'https://www.internationalscholarships.com/',
    Contact: '',
  },
];

const ScholarshipPage = () => {
  const navigate = useNavigate();
  const handleCountryClick = (countryData) => {
    // Navigate to scholarship list with data

    navigate('/scholarshipListPage', {
      state: { scholarships: countryData },
    });
  };
  const flagLogoCard = [
    {
      id: 28,
      logo: USLogo,
      link: '',
      title: 'US',
      scholarshipData: US_scholarships,
    },
    {
      id: 6,
      logo: CanadaLogo,
      link: '#',
      title: 'Canada',
      scholarshipData: Canada_scholarships,
    },
    {
      id: 2,
      logo: AustraliaLogo,
      link: '#',
      title: 'Australia',
      scholarshipData: Australia_scholarships,
    },
    { id: 1, logo: ArgentinaLogo, link: '#', title: 'Argentina' },
    { id: 3, logo: AustriaLogo, link: '#', title: 'Austria' },
    { id: 4, logo: BelgiumLogo, link: '#', title: 'Belgium' },
    { id: 5, logo: BrazilLogo, link: '#', title: 'Brazil' },
    { id: 7, logo: ChileLogo, link: '#', title: 'Chile' },
    { id: 8, logo: ChinaLogo, link: '#', title: 'China' },
    { id: 9, logo: CzechRepublicLogo, link: '#', title: 'Czech Republic' },
    {
      id: 10,
      logo: EuropeLogo,
      link: '#',
      title: 'Europe',
      scholarshipData: europe_scholarships,
    },
    { id: 11, logo: FranceLogo, link: '#', title: 'France' },
    { id: 12, logo: GreeceLogo, link: '#', title: 'Greece' },
    { id: 13, logo: HongKongLogo, link: '#', title: 'HongKong' },
    { id: 14, logo: HungaryLogo, link: '#', title: 'Hungary' },
    { id: 15, logo: IndiaLogo, link: '#', title: 'India' },
    { id: 16, logo: IrelandLogo, link: '#', title: 'Ireland' },
    { id: 17, logo: ItalyLogo, link: '#', title: 'Italy' },
    { id: 18, logo: JapanLogo, link: '#', title: 'Japan' },
    { id: 19, logo: MexicoLogo, link: '#', title: 'Mexico' },
    { id: 20, logo: NetherlandsLogo, link: '#', title: 'Netherlands' },
    { id: 21, logo: SingaporeLogo, link: '#', title: 'Singapore' },
    { id: 22, logo: SlovakiaLogo, link: '#', title: 'Slovakia' },
    { id: 23, logo: SouthKoreaLogo, link: '#', title: 'South Korea' },
    { id: 24, logo: SpainLogo, link: '#', title: 'Spain' },
    { id: 25, logo: SwedenLogo, link: '#', title: 'Sweden' },
    { id: 26, logo: SwitzerlandLogo, link: '#', title: 'Switzerland' },
    {
      id: 27,
      logo: UKLogo,
      link: '#',
      title: 'UK',
      scholarshipData: UK_scholarships,
    },
    {
      id: 28,
      logo: USLogo,
      link: '',
      title: 'US',
      scholarshipData: US_scholarships,
    },
  ];

  return (
    <React.Fragment>
      <section className="section">
        <Container>
          <Row className="text-center pb-2">
            <h1 className="card-title title text-dark">
              Scholarship Information
            </h1>
          </Row>
          <Row>
            {flagLogoCard.map((flag) => (
              <Col key={flag.id} sm="4" md="3" className="mb-4 text-center">
                <Card
                  className="blog d-flex flex-column align-items-center bg-color border-primary"
                  onClick={() => handleCountryClick(flag)}
                >
                  <CardImg
                    top
                    src={flag.logo}
                    alt={`${flag.title} Logo`}
                    className="logo-img pt-2"
                  />
                  <CardBody>
                    <h5 className="card-title title text-primary">
                      {flag.title}
                    </h5>
                  </CardBody>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </React.Fragment>
  );
};
export default ScholarshipPage;
