import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';
import MMlogo from '../../assets/images/mettamate/logo.svg';
import mentorIcon from '../../assets/images/mentorship/mentor-icon-1.png';
import menteeIcon from '../../assets/images/mentorship/mentee-icon.png';
import serviceProviderIcon from '../../assets/images/mentorship/service-provider-icon.png';


const MobileHeader = ({ getTargetURL, getKeyMenus, getMenuItems, logout, isLoggedIn }) => {
  const [isHighlighted, setIsHighlighted] = useState(false);
  const [isOpen, setIsOpen] = useState(false); // Initially closed dropdown
  const dropdownRef = useRef(null); // Reference to the dropdown

  // Function to toggle dropdown visibility
  const toggleDropdown = () => setIsOpen((prevState) => !prevState);

  // Close dropdown if clicked outside of it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false); // Close dropdown if click is outside
      }
    };

    // Add event listener for clicks outside
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup event listener on component unmount
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleOnClick = () => {
    setIsHighlighted(!isHighlighted); // Toggle highlight state
  };

  const showLoginIcon = ['/index', '/user-registration-page'].includes(location.pathname);

  const showRegistrationIcon = location.pathname === '/user-login-page';

  return (
    <div>
      {/* Header Section */}
      <header
        id="topnav"
        className="defaultscroll sticky"
        style={{
          flexShrink: 0,
          position: 'relative',
          zIndex: '100',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Container
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '100%',
            position: 'relative',
          }}
        >
          {isLoggedIn && (
            <Link to="/my-profile-edit-page">
              <ul
                className={`buy-button list-inline mb-0 ${isHighlighted ? 'highlighted' : ''}`}
                style={{
                  position: 'absolute',
                  left: 0,
                  bottom: '5px',
                  margin: '10px',
                }}
                onClick={handleOnClick}
              >
                {getKeyMenus()}
              </ul>
            </Link>
          )}
          {/* Logo */}
          <div style={{ margin: '0 auto', justifyContent: 'center', textAlign: 'center' }}>
            <Link className="logo" to={getTargetURL()}>
              <img src={MMlogo} height="42" className="logo-light-mode" alt="Logo" />
              <img src={MMlogo} height="42" className="logo-dark-mode" alt="Logo" />
            </Link>
          </div>

          {/* Settings Icon */}
          {isLoggedIn && (
            <div style={{ position: 'absolute', right: '10px' }}>
              <div>
                <i
                  className="fas fa-cog"
                  style={{
                    fontSize: '28px',
                    color: '#2F2F2F',
                    cursor: 'pointer',
                  }}
                  title="Settings"
                  onClick={toggleDropdown} // Open/close dropdown on click
                ></i>
                {isOpen && ( // Show dropdown only if isOpen is true
                  <div
                    ref={dropdownRef} // Attach the reference to the dropdown menu
                    style={{
                      position: 'absolute',
                      top: '35px',
                      right: '0',
                      backgroundColor: '#fff',
                      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                      padding: '10px',
                      borderRadius: '5px',
                      zIndex: 1000,
                      width: '200px', // Increased width
                    }}
                  >
                    {/* Updated Play a role section */}
                    <div className="role-menu">
                      <div className="role-header py-2 px-1">
                        <span className="fw-medium">Play A Role @ MettaMate</span>
                      </div>
                      <hr className="my-2" />
                      <div className="role-options">
                        <Link to="/self-mentor-page" className="role-option">
                          <div className="d-flex align-items-center p-2">
                            <img
                              src={mentorIcon}
                              alt="Mentor"
                              style={{
                                width: '24px',
                                height: '24px',
                                marginRight: '8px'
                              }}
                            />
                            <span>Mentor</span>
                          </div>
                        </Link>
                        <Link to="#" className="role-option">
                          <div className="d-flex align-items-center p-2">
                            <img
                              src={menteeIcon}
                              alt="Mentee"
                              style={{
                                width: '24px',
                                height: '24px',
                                marginRight: '8px'
                              }}
                            />
                            <span>Mentee</span>
                          </div>
                        </Link>
                        <Link to="#" className="role-option">
                          <div className="d-flex align-items-center p-2">
                            <img
                              src={serviceProviderIcon}
                              alt="Service Provider"
                              style={{
                                width: '24px',
                                height: '24px',
                                marginRight: '8px'
                              }}
                            />
                            <span>Service Provider</span>
                          </div>
                        </Link>
                      </div>
                      <hr className="my-2" />
                    </div>

                    {/* Account Settings and Logout options */}
                    <Link to="/settings" className="settings-option">
                      <div className="d-flex align-items-center p-2">
                        <i className="fas fa-cog me-2"></i>
                        <span>Account Settings</span>
                      </div>
                    </Link>
                    <Link
                      to="/user-login-page"
                      onClick={logout}
                      className="logout-option"
                    >
                      <div className="d-flex align-items-center p-2 text-danger">
                        <i className="fas fa-sign-out-alt me-2"></i>
                        <span>Logout</span>
                      </div>
                    </Link>
                  </div>
                )}
              </div>
            </div>
          )}

          {/* Login Icon (Shown only on /index route) */}
          {showLoginIcon && (
            <div style={{ position: 'absolute', right: '10px' }}>
              <Link to="/user-login-page">
                <i
                  className="fas fa-user"
                  style={{
                    fontSize: '28px',
                    color: '#FF7F00',
                    cursor: 'pointer',
                  }}
                  title="Login"
                ></i>
              </Link>
            </div>
          )}

          {/* Login Icon (Shown only on /index route) */}
          {showRegistrationIcon && (
            <div style={{ position: 'absolute', right: '10px' }}>
              <Link to="/user-registration-page">
                <i
                  className="fas fa-pencil-alt p-1"
                  style={{
                    fontSize: '28px',
                    color: '#2062C4',
                    cursor: 'pointer',
                    border: '1px solid #2062C4'
                  }}
                  title="Login"
                ></i>
              </Link>
            </div>
          )}
        </Container>
      </header>

      {/* Fixed Navigation Menu at the Bottom */}
      <div
        id="navigation"
        style={{
          position: 'fixed',
          top: '90%',
          bottom: '0',
          left: '0',
          width: '100%',
          backgroundColor: '#fff',
          zIndex: '100',
          boxShadow: '0 -2px 5px rgba(0, 0, 0, 0.1)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ul
          className="navigation-menu nav-dark"
          id="top-menu"
          style={{ display: 'flex', padding: '0', margin: '0', gap: '5px', zIndex: '9999', top: '20px' }}
        >
          {getMenuItems()}
        </ul>
      </div>
    </div>
  );
};

// Add these styles
const styles = `
.role-option, .settings-option, .logout-option {
  text-decoration: none;
  color: #333;
  display: block;
  transition: background-color 0.2s;
}

.role-option:hover, .settings-option:hover {
  background-color: #f8f9fa;
  text-decoration: none;
}

.logout-option:hover {
  background-color: #fee2e2;
  text-decoration: none;
}

.role-header {
  color: #666;
}
`;

export default MobileHeader;
