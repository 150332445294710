import ReactQuill from 'react-quill-new';
import 'react-quill-new/dist/quill.snow.css';

import React, { useState } from 'react';
import { Calendar, MessageSquare, Video, ExternalLink } from 'lucide-react';
import FeatherIcon from 'feather-icons-react';
import { Link } from 'react-router-dom';
import {
  Row,
  Col,
  CardBody,
  Card,
  Label,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  PaginationItem,
  PaginationLink,
  Alert,
  Fade,
  Progress,
} from 'reactstrap';
import Slider from 'react-slick';
import manavatar from '../../../../assets/images/MettaMateImages/male_avatar.jpg';
import '../../../../assets/css/mentorship.css';

const MenteeCard = ({ menteeDetails, menteeProfileData, isAuthenticated }) => {
  // Quill editor configurations
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link'],
      ['clean'],
    ],
  };

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'list',
    'bullet',
    'link',
  ];

  const [modal, setModal] = useState(false);
  const [menteeBiodata, setMenteeBiodata] = useState(false);
  const [menteeCategories, setMenteeCategories] = useState(false);
  const toggleMenteeCategoriesModal = () =>
    setMenteeCategories(!menteeCategories);

  const toggleModal = () => setModal(!modal);
  const toggleMenteeBiodataModal = () => setMenteeBiodata(!menteeBiodata);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };

  return (
    <div>
      <Card className="catagories rounded rounded-3 overflow-hidden border-1 border-primary job-box job-primary candidate-list border-0 shadow card">
        <CardBody className="rounded overflow-hidden p-0 ">
          <Slider className="imageSlider image-container" {...settings}>
            <img
              src={menteeDetails.photothumb1 || manavatar}
              className="img-fluid img_thumb"
            />
          </Slider>
        </CardBody>
      </Card>
      <Card className="catagories rounded rounded-3 mt-1 shadow">
        <CardBody className="rounded shadow p-2 fixHeight">
          <p className="text-dark h5 name mb-0">
            <span style={{ color: `#0D6EFD` }}>{menteeDetails.firstname}</span>
          </p>
          <p className="fs-5 mb-1 mt-1">{menteeProfileData.mentee_title}</p>

          <p className="fs-7 mb-1 mt-1">
            <FeatherIcon icon="map-pin" className="fea icon-sm me-1" />
            {menteeDetails.currentlocationname ||
              menteeDetails.nativelocationname ||
              'Location Not Found'}
          </p>

          <div className=" d-flex justify-content-center">
            <div className="m-1">
              <Button
                color="primary"
                style={{ height: '35px', fontSize: '14px' }}
                onClick={toggleMenteeBiodataModal}
              >
                Mentorship Requirements
              </Button>
            </div>
          </div>
        </CardBody>
      </Card>
      {/** Mentee bioddata **/}
      {
        <Modal
          isOpen={menteeBiodata}
          toggle={toggleMenteeBiodataModal}
          size="lg"
        >
          <ModalHeader toggle={toggleMenteeBiodataModal}>
            {menteeDetails.firstname}'s Brief Profile
          </ModalHeader>
          <ModalBody>
            {/* Bio Section */}
            <div className="profile-section mb-4">
              <h5 className="d-flex align-items-center mb-3">
                <i className="fas fa-user-circle text-primary me-2"></i>
                Mentorship Requirements
              </h5>
              <div className="bio-content px-3">
                <ReactQuill
                  theme="bubble"
                  value={menteeProfileData.mentee_bio}
                  readOnly={true}
                  style={{ minHeight: '100px', fontSize: '16px' }}
                />
              </div>
            </div>

            {/* Categories Section */}
            <div className="categories-section mb-4">
              <h5 className="d-flex align-items-center mb-3">
                <i className="fas fa-tags text-info me-2"></i>
                Categories
              </h5>
              <div className="px-3">
                {menteeProfileData.categories?.map((category, index) => (
                  <span
                    key={index}
                    className="badge bg-light text-dark me-2 mb-2 p-2 fs-6"
                  >
                    {category.categoryname}
                  </span>
                ))}
              </div>
            </div>

            {/* Specializations Section */}
            <div className="specializations-section mb-4">
              <h5 className="d-flex align-items-center mb-3">
                <i className="fas fa-certificate text-warning me-2"></i>
                Specializations
              </h5>
              <div className="px-3">
                {menteeProfileData.subcategories?.map((subcategory, index) => (
                  <span
                    key={index}
                    className="badge bg-soft-warning text-dark fs-6 me-2 mb-2 p-2"
                  >
                    <i className="fas fa-arrow-right me-1"></i>
                    {subcategory.subcategoryname}
                  </span>
                ))}
                {!menteeProfileData.subcategories && (
                  <p className="text-muted mb-0">
                    No specializations specified
                  </p>
                )}
              </div>
            </div>

            {/* Mentorship Type Section */}
            {isAuthenticated && (
              <div className="mentorship-type-section mb-4">
                <h5 className="d-flex align-items-center mb-3">
                  <i className="fas fa-handshake text-success me-2"></i>
                  Mentorship Type
                </h5>
                <div className="px-3">
                  <div className="d-flex align-items-center">
                    <i
                      className={`fas fa-${
                        menteeProfileData.mentorship_preference === 'PAID'
                          ? 'dollar-sign text-warning'
                          : (menteeProfileData.mentorship_preference) ===
                              'FREE'
                            ? 'heart text-danger'
                            : 'handshake text-success'
                      } me-2`}
                    ></i>
                    <span>
                      {menteeProfileData.mentorship_preference === 'PAID'
                        ? 'Paid Mentorship'
                        : menteeProfileData.mentorship_preference === 'FREE'
                          ? 'Free Mentorship'
                          : 'Both Paid and Free Mentorship'}
                    </span>
                  </div>
                </div>
              </div>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleMenteeBiodataModal}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      }

      {
        <Modal isOpen={menteeCategories} toggle={toggleMenteeCategoriesModal}>
          <ModalHeader toggle={toggleMenteeCategoriesModal}>
            {menteeDetails.firstname}'s Expertise
          </ModalHeader>
          <ModalBody>
            {/* Areas of Expertise Section */}
            <div className="expertise-section mb-4">
              <h5 className="d-flex align-items-center mb-3">
                <i className="fas fa-star text-warning me-2"></i>
                Areas of Expertise
              </h5>
              <div className="categories-list ms-3">
                {menteeDetails.categories?.map((category) => (
                  <div
                    key={category.categoryid}
                    className="category-item d-flex align-items-center py-2"
                  >
                    <i className="fas fa-check-circle text-success me-2"></i>
                    <span>{category.categoryname}</span>
                  </div>
                ))}
                {(!menteeDetails.categories ||
                  menteeDetails.categories.length === 0) && (
                  <div className="text-muted">
                    No areas of expertise specified
                  </div>
                )}
              </div>
            </div>

            {/* Specializations Section */}
            <div className="specializations-section">
              <h5 className="d-flex align-items-center mb-3">
                <i className="fas fa-certificate text-primary me-2"></i>
                Specializations
              </h5>
              <div className="subcategories-list ms-3">
                {menteeDetails.subcategories?.map((subcategory) => (
                  <div
                    key={subcategory.subcategoryid}
                    className="subcategory-item d-flex align-items-center py-2"
                  >
                    <i className="fas fa-arrow-right text-secondary me-2"></i>
                    <span>{subcategory.subcategoryname}</span>
                  </div>
                ))}
                {(!menteeDetails.subcategories ||
                  menteeDetails.subcategories.length === 0) && (
                  <div className="text-muted">No specializations specified</div>
                )}
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleMenteeCategoriesModal}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      }
    </div>
  );
};

export default MenteeCard;
