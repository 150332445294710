import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';
import MMlogo from '../../assets/images/mettamate/logo.svg';

const DesktopHeader = ({ getTargetURL, getKeyMenus, getMenuItems, isOpen, toggleLine }) => {
  return (
    <header id="topnav" className="defaultscroll sticky" style={{ flexShrink: 0 }}>
      <Container>
        <div>
          {/* Logo */}
          <Link className="logo" to={getTargetURL()}>
            <img src={MMlogo} height="42" className="logo-light-mode" alt="Logo" />
            <img src={MMlogo} height="42" className="logo-dark-mode" alt="Logo" />
          </Link>
        </div>

        <div className="menu-extras">
          <div className="menu-item">
            <Link
              to="#"
              onClick={toggleLine}
              className={isOpen ? 'navbar-toggle open' : 'navbar-toggle'}
            >
              <div className="lines">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </Link>
          </div>
        </div>

        <ul className="buy-button  list-inline mb-0" style={{borderRadius: '50%'}}>{getKeyMenus()}</ul>

        <div id="navigation" style={{ display: isOpen ? 'block' : 'none', paddingTop: '7px' }}>
          {/* Menu Dropdown */}
          <ul className="navigation-menu nav-dark" id="top-menu">
            {getMenuItems()}
          </ul>
        </div>
      </Container>
    </header>
  );
};

export default DesktopHeader;
