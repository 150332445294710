import React, { useEffect, useState, Component, useMemo } from 'react';
import {
  Row,
  Col,
  CardBody,
  Card,
  Label,
  Input,
  Form,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  PaginationItem,
  PaginationLink,
  FormFeedback,
  Alert,
  Fade,
  Progress,
  FormGroup,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import ContentLoader, { List } from 'react-content-loader';
import Slider from 'react-slick';
import defaultEventImage from '../../../assets/images/MettaMateImages/eventSamples/defaultEvent.jpeg';
import defaultEventImage1 from '../../../assets/images/MettaMateImages/eventSamples/defaultEvent1.png';
import '../../Home/css/userHomePage.css';
import axios from 'axios';
import apiUrl from 'apppages/server';
import { Select } from 'antd';
import CommonModal from '../../Home/modal';

const GlobalEventsFunction = () => {
  const [isLoading, setIsLoading] = useState(false);
  //currentItems
  const [currentItems, setCurrentItems] = useState([]);
  const [eventcategoryList, setEventCategoryList] = useState([]);

  const [modalMessage, setModalMessage] = useState(false);
  const [eventAddress, setEventAddress] = useState('');

  useEffect(() => {
    fetchGlobalEvents();
    fetchAllCategories();
  }, []);

  const fetchGlobalEvents = () => {
    setIsLoading(true);
    axios
      .get(`${apiUrl}eb/getallebposts`, {
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => {
        if (res.data?.length) {
          setCurrentItems(res.data);
        }
      })
      .catch((err) => console.error(err))
      .finally(setIsLoading(false));
  };

  const fetchAllCategories = () => {
    axios
      .get(`${apiUrl}eb/getebcategories`, {
        headers: {
          'Content-Type': 'application/json',
          authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      })
      .then((res) => setEventCategoryList(res.data))
      .catch((err) => console.error(err));
  };

  const ImageSliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };
  function addEventCategories(eventCategories) {
    const filledEventCategories = [];
    for (let i = 0; i < eventCategories.length; i++) {
      filledEventCategories.push(
        <li className="list-inline-item float-start m-2">
          <span className="badge rounded-pill text-dark border  bg-light border-1 border-dark font_size14">
            <span className="font_size14">
              {' '}
              {eventCategories[i].categoryname}
            </span>
          </span>
        </li>,
      );
    }
    return filledEventCategories;
  }
  //with usememo
  const memoizedAddEventCategories = useMemo(() => {
    return (
      eventcategoryList.length &&
      eventcategoryList.map((category) => ({
        label: category.categoryname,
        value: category.categoryuuid,
      }))
    );
  }, [eventcategoryList]);

  return (
    <>
      <div className="contianer-fluid">
        {modalMessage && (
          <CommonModal
            title={modalMessage.title}
            message={modalMessage.message}
            onClose={() => setModalMessage(false)}
            status="warning"
          />
        )}
        <Row>
          <Col lg="12" className="Col_12">
            <Row className="mt-3 d-flex justify-content-between">
              <Col md={3} xs={12} className="">
                <Select
                  mode="tags"
                  style={{
                    width: '100%',
                  }}
                  placeholder="Event Type"
                  // onChange={handleChange}
                  options={[
                    {
                      value: 1,
                      label: 'virtual',
                    },
                    {
                      value: 2,
                      label: 'in-person',
                    },
                    {
                      value: 3,
                      label: 'mix',
                    },
                  ]}
                />
              </Col>
              <Col md={4} xs={12} className="">
                <form
                  className="srch_frm_sction"
                  onSubmit={(e) => {
                    e.preventDefault();
                    // handleSearchItem();
                  }}
                >
                  <Input
                    type="text"
                    placeholder="Search by event title, tosting orgnization, location"
                    // value={searchItem}
                    className="search_input rounded-pill px-3 border d-inline me-1"
                    // onChange={(e) => setSearchItem(e.target.value)}
                  />

                  {/* <button
                    className={`btn btn-outline-primary me-2`}
                  // disabled={!searchItem}
                  // onClick={() => handleSearchItem()}
                  >
                    Search
                  </button> */}
                  <div
                    className="position-absolute"
                    style={{ right: 30 }}
                    onClick={() => {
                      // getAllDetails(1);
                      // setSearchItem('');
                      // setCurrentPage(1);
                      // setCurrentPageNo(1);
                      // setFilterData(null);
                      // setCustumFilterData(null);
                      // setActiveFilterLink('');
                    }}
                  >
                    <i
                      className={`fa fa-search position-static fa-lg refresh-button`}
                      aria-hidden="true"
                    ></i>{' '}
                    {/* Refresh */}
                  </div>
                </form>
              </Col>
              <Col md={3} xs={12} className="d-flex jusitify-content-end">
                <Input
                  type="text"
                  className="me-2"
                  placeholder="Search Neighborhood, City or Zip"
                />
              </Col>
            </Row>
            <Row className="">
              <Col md={3} className="mt-2 me-2">
                {/* <div className="my-3 row ms-2"> */}
                <Select
                  mode="tags"
                  style={{
                    width: '100%',
                  }}
                  placeholder="Event Category"
                  // onChange={handleChange}
                  options={memoizedAddEventCategories}
                />
                {/* </div> */}
              </Col>
            </Row>
            <Row>
              <Col
                md={12}
                xs={12}
                className="d-flex justify-content-center mt-3"
              >
                <h1>Global Events & Nearby Events</h1>
              </Col>
            </Row>
            <Row>
              <Col md={12} xs={12} className="">
                {isLoading ? (
                  <Row>
                    {[1, 2, 3, 4].map((key) => (
                      <Col
                        lg="3"
                        md="6"
                        xs="12"
                        key={key}
                        className="mt-4 pt-2 text-center"
                      >
                        <Card className="catagories overflow-hidden rounded border-0">
                          <ContentLoader
                            speed={2}
                            width={280}
                            height={400}
                            viewBox="0 0 280 400"
                            backgroundColor="#f3f3f3"
                            foregroundColor="#ecebeb"
                          >
                            {/* Image skeleton */}
                            <rect
                              x="0"
                              y="0"
                              rx="5"
                              ry="5"
                              width="280"
                              height="200"
                            />

                            {/* Other content placeholders */}
                            <rect
                              x="0"
                              y="220"
                              rx="5"
                              ry="5"
                              width="280"
                              height="20"
                            />
                            <rect
                              x="0"
                              y="250"
                              rx="5"
                              ry="5"
                              width="280"
                              height="20"
                            />
                            <rect
                              x="0"
                              y="280"
                              rx="5"
                              ry="5"
                              width="140"
                              height="20"
                            />
                            <rect
                              x="150"
                              y="280"
                              rx="5"
                              ry="5"
                              width="130"
                              height="20"
                            />
                            <circle cx="30" cy="330" r="15" />
                            <circle cx="70" cy="330" r="15" />
                            <circle cx="110" cy="330" r="15" />
                          </ContentLoader>
                        </Card>
                      </Col>
                    ))}
                  </Row>
                ) : currentItems.length ? (
                  <Row>
                    {(currentItems || []).map((eventObject, key) => (
                      <Col
                        key={key}
                        className="mt-4 pt-2 col-xl-3 col-lg-4 col-md-6 col-sm-6 col-xs-12  ps-2"
                      >
                        <Card className="catagories  rounded rounded-3 overflow-hidden border-1 border-primary shadow">
                          {/* Top Display on the card and where is the IMAGE ? */}
                          <CardBody className="rounded overflow-hidden p-0 ">
                            <ul
                              className="list-unstyled align-items-center width100 position-absolute  "
                              style={{
                                zIndex: `9`,
                                marginBottom: `0px`,
                              }}
                            >
                              <li className="list-inline-item d-flex m-2 justify-content-between">
                                <span className="badge rounded-pill text-dark border  bg-light border-1 border-dark font_size14 evntstatusbadge">
                                  {eventObject.eventstatus != '' && (
                                    <span className="font_size14">
                                      {' '}
                                      {eventObject.eventstatus}
                                    </span>
                                  )}
                                </span>
                                <div
                                  className="d-inline"
                                  onClick={() =>
                                    setModalMessage({
                                      title: 'Stay Tune',
                                      message:
                                        'Upcoming Feature is comming to help you!',
                                    })
                                  }
                                >
                                  <i
                                    className="fa fa-bookmark-o text-white position-static"
                                    style={{
                                      fontSize: '2.5rem',
                                      cursor: 'pointer',
                                    }}
                                    aria-hidden="true"
                                  ></i>
                                </div>
                              </li>
                            </ul>
                            <Slider
                              className="imageSlider image-container"
                              {...ImageSliderSettings}
                            >
                              <img
                                // src={eventObject.eventimagepath}
                                src={defaultEventImage}
                                className="img-fluid img_thumb"
                              />
                            </Slider>
                          </CardBody>
                        </Card>
                        <Card className="catagories rounded rounded-3 mt-1 overflow-hidden shadow">
                          <CardBody className="rounded shadow p-2">
                            <div className="justify-content-left">
                              <p className="text-dark h5 name mb-0">
                                <span style={{ color: `#0D6EFD` }}>
                                  {eventObject.eventtitle},{' '}
                                </span>{' '}
                              </p>
                              <p className="m-0 font_size14">
                                <FeatherIcon
                                  icon="calendar"
                                  className="fea icon-sm me-1"
                                />
                                {eventObject.eventstartdate} -{' '}
                                {eventObject.eventenddate}
                              </p>
                              <p className="m-0 font_size14">
                                <FeatherIcon
                                  icon="calendar"
                                  className="fea icon-sm me-1"
                                />
                                {eventObject.eventstarttime} -{' '}
                                {eventObject.eventendtime}
                              </p>
                              <p className="m-0 font_size14">
                                <FeatherIcon
                                  icon="map-pin"
                                  className="fea icon-sm me-1"
                                />

                                {eventObject.eventlocation}
                              </p>
                              <p className="m-0 font_size14">
                                <ul>
                                  {/* {eventcategoryList?.length &&
                                    addEventCategories(eventcategoryList)} */}
                                </ul>
                              </p>
                            </div>
                            <div className="d-flex justify-content-center">
                              <div className="m-0">
                                <button
                                  // onClick={() =>
                                  //   openEventDetailModel(
                                  //     eventObject.eventuuid,
                                  //   )
                                  // }
                                  className="btn designation btn-primary viewBtn"
                                >
                                  <FeatherIcon
                                    icon="eye"
                                    className="fea icon-sm icons"
                                  />{' '}
                                  Event Details
                                </button>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    ))}
                    {/* <Col xs={12} className="mt-4 pt-2">
                          {totalPages > 1 && (
                            <>
                              {currentItems.length > 0 && (
                                <ul className="pagination justify-content-center mb-0">
                                  <PaginationItem disabled={currentPage === 1}>
                                    <PaginationLink
                                      to="#"
                                      aria-label="Previous"
                                      onClick={() =>
                                        handlePageClick(currentPage - 1)
                                      }
                                    >
                                      {'<<'}
                                    </PaginationLink>
                                  </PaginationItem>
                                  {pageNumbers.map((number) => (
                                    <PaginationItem
                                      active={number === currentPage}
                                      key={number}
                                      disabled={number === '...'}
                                    >
                                      <PaginationLink
                                        onClick={() => handlePageClick(number)}
                                      >
                                        {number}
                                      </PaginationLink>
                                    </PaginationItem>
                                  ))}
                                  <PaginationItem
                                    disabled={currentPage === totalPages}
                                  >
                                    <PaginationLink
                                      to="#"
                                      aria-label="Next"
                                      onClick={() =>
                                        handlePageClick(currentPage + 1)
                                      }
                                    >
                                      {'>>'}
                                    </PaginationLink>
                                  </PaginationItem>
                                </ul>
                              )}
                            </>
                          )}
                        </Col> */}
                  </Row>
                ) : (
                  <div className="text-center mt-3 label_text">Loading...</div>
                )}
              </Col>
            </Row>
          </Col>

          {/* {currentContents == 'postAnEvent' && (
            <Row className=" m-0  inner_row_personal">
              <h5 className="title title_bar">Upload Your Photos</h5>
              <Col md={12} xs={12} className=" p-0">
                {' '}
                Create a beautiful form for Accepting Events here
              </Col>
            </Row>
          )} */}

          {/* {currentContents == 'postAnEvent' && (
            <Row className=" m-0  inner_row_personal">
              <h5 className="title title_bar">Upload Your Photos</h5>
              <Col md={12} xs={12} className=" p-0">
                <EventForm />
              </Col>
            </Row>
          )} */}
        </Row>
      </div>
    </>
  );
};

export default GlobalEventsFunction;
