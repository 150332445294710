import React, { useState, useEffect } from 'react';
import axios from 'axios';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { Row, Col, Label, Button, Form } from 'reactstrap';
import CommonModal from '../../Home/modal';
import apiUrl from '../../server';
import { Link } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';

const RegionalFunction = () => {
  const [currentaddress, setCurrentAddress] = useState('');
  const [selectedPlaces, setSelectedPlace] = useState([]);
  const [nativeaddress, setNativeAddress] = useState('');
  const [nativeselectedPlaces, setnativeSelectedPlace] = useState([]);
  const [selectedplaceId, setSelectedplaceId] = useState([]);
  const [selectedNativeplaceId, setSelectedNativeplaceId] = useState([])
  const [languageList, setlanguageList] = useState([]);

  const [error, setError] = useState();
  const [success, setSuccess] = useState();
  const [locError, setLocationError] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [regionalFormData, setRegionalFormData] = useState({
    memberuuid: localStorage.getItem("memberID"),
    currentlocationid: [],
    nativelocationid: [],
    mothertongue: [],
    additionallanguages: [],
  });

  let memberID = localStorage.getItem('memberID');

  useEffect(() => {
    fetchRegionalData();
    fetchlanguageList();
  }, []);

  const fetchlanguageList = async () => {
    try {
      const response = await axios.get(apiUrl + 'codes/language', {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      setlanguageList(response.data.list);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const fetchRegionalData = async () => {
    try {
      const response = await axios.get(
        apiUrl + 'preference/regional/' + memberID,
        {
          headers: {
            Authorization: 'Bearer' + ' ' + localStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
        },
      );
      if (response.data != null) {
        let regionalData = response.data;
        setSelectedPlace([regionalData.currentlocationname]);
        setnativeSelectedPlace([regionalData.nativelocationname]);

        setRegionalFormData({
          memberuuid: localStorage.getItem('memberID'),
          currentlocationid: regionalData.currentlocationid ? regionalData.currentlocationid : [],
          nativelocationid: regionalData.nativelocationid ? regionalData.nativelocationid : [],
          additionallanguages: regionalData.additionallanguages ? regionalData.additionallanguages : [],
          mothertongue: regionalData.mothertongue ? regionalData.mothertongue : []
        })

      } else {
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleCurrentAddressChange = (address) => {
    setCurrentAddress(address);
  };

  const handleCurrentAddressSelect = (address, placeid) => {
    setCurrentAddress('');
    if (placeid == null) {
      setError({
        title: 'Error!',
        message: 'Invalid Location',
      });
    } else {
      if (!regionalFormData.currentlocationid.includes((placeid))) {
        setSelectedPlace(prevSelectedPlaces => [...prevSelectedPlaces, address]);
        setSelectedplaceId(prevSelectedPlaceid => [...prevSelectedPlaceid, placeid])
        regionalFormData.currentlocationid = [...regionalFormData.currentlocationid, placeid]
      } else {
        setError({
          title: 'Error!',
          message: 'Already exist',
        });
      }
    }
  };

  const handleRemovePlace = (index) => {
    setSelectedPlace(prevSelectedPlaces =>
      prevSelectedPlaces.filter((place, i) => i !== index)
    );
    regionalFormData.currentlocationid = regionalFormData.currentlocationid.filter((item, i) => i !== index)
  };

  const handleNativeAddressChange = (address) => {
    setNativeAddress(address)
  };

  const handleNativeAddressSelect = (address, placeid) => {
    setNativeAddress('');
    if (placeid == null) {
      setError({
        title: 'Error!',
        message: 'Invalid Location',
      });
    } else {
      if (!regionalFormData.nativelocationid.includes((placeid))) {
        setnativeSelectedPlace(prevSelectedPlaces => [...prevSelectedPlaces, address]);
        setSelectedNativeplaceId(prevSelectednativePlaceid => [...prevSelectednativePlaceid, placeid])
        regionalFormData.nativelocationid = [...regionalFormData.nativelocationid, placeid]
      }
      else {
        setError({
          title: 'Error!',
          message: 'Already exist',
        });
      }
    }
  };

  const handlenativeRemovePlace = (index) => {
    setnativeSelectedPlace(prevSelectedPlaces =>
      prevSelectedPlaces.filter((place, i) => i !== index)
    );
    regionalFormData.nativelocationid = regionalFormData.nativelocationid.filter((item, i) => i !== index)
  };

  const handleMotherTongue = (checkedFlag, mothertongueobj) => {
    let selectedMotherTongue = regionalFormData.mothertongue;
    if (checkedFlag) {
      if (!selectedMotherTongue.includes(mothertongueobj.value)) {
        selectedMotherTongue.push(mothertongueobj.value);
      }
    }
    if (!checkedFlag) {
      if (selectedMotherTongue.includes(mothertongueobj.value)) {
        selectedMotherTongue.splice(selectedMotherTongue.findIndex(x => mothertongueobj.value === x), 1);
      }
    }
    setRegionalFormData({ ...regionalFormData, mothertongue: selectedMotherTongue });

    // if (checkedFlag) {
    //   if (!mt.includes(mothertongueobj.value)) {
    //     mt.push(mothertongueobj);
    //   }
    // }
    // if (!checkedFlag) {
    //   if (!mt.includes(mothertongueobj.value)) {
    //     mt.splice(mt.findIndex(x => mothertongueobj.value === x), 1)
    //   }
    // }
  }

  const handleAltLang = (checkedFlag, additionallangobj) => {
    let selectedAddionalLang = regionalFormData.additionallanguages;
    if (checkedFlag) {
      if (!selectedAddionalLang.includes(additionallangobj.value)) {
        selectedAddionalLang.push(additionallangobj.value);
      }
    }
    if (!checkedFlag) {
      if (selectedAddionalLang.includes(additionallangobj.value)) {
        selectedAddionalLang.splice(selectedAddionalLang.findIndex(x => additionallangobj.value === x), 1);
      }
    }
    setRegionalFormData({ ...regionalFormData, additionallanguages: selectedAddionalLang });
  }

  const errorHandler = () => {
    setError(null);
  };
  const successHandler = () => {
    setSuccess(null);
  };
  const handleReset = () => {
    setRegionalFormData({
      memberuuid: localStorage.getItem("memberID"),
      currentlocationid: [],
      nativelocationid: [],
      mothertongue: [],
      additionallanguages: [],
    });
    setSelectedPlace([]);
    setnativeSelectedPlace([]);
  };
  const LoaderComponent = () => (
    <div id="preloader">
      <div id="status">
        <div className="spinner">
          <div className="double-bounce1"></div>
          <div className="double-bounce2"></div>
        </div>
      </div>
    </div>
  );
  const handleSubmit = async () => {

    try {
      const response = await axios
        .post(apiUrl + 'preference/regional/', regionalFormData, {
          headers: {
            Authorization: 'Bearer' + ' ' + localStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
        })
        .then((res) => {
          fetchRegionalData();
          setIsLoading(false);
          setSuccess({
            title: 'SUCCESS!',
            message: res.data.message,
          });
        })
        .catch((err) => {
          setIsLoading(false);
          setError({
            title: 'An Error Occured!',
            message: err.response.data.message,
          });
        });
    } catch (error) { }
  }
  return (
    <React.Fragment>
      {error && (
        <CommonModal
          title={error.title}
          message={error.message}
          onClose={errorHandler}
          status="error"
        />
      )}

      {success && (
        <CommonModal
          title={success.title}
          message={success.message}
          onClose={successHandler}
          status="success"
        />
      )}
      {locError && (
        <CommonModal
          title={success.title}
          message={success.message}
          onClose={successHandler}
          status="success"
        />
      )}

      {
        isLoading ? <LoaderComponent /> :
          (
            <Form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <Row className="inner_row mt-1">
                <h6 className="title  title_bar">
                  Region/Location
                </h6>

                <Label className="form-label label_text">Current Location</Label>
                <Col md={6}>
                  <div className="features feature-primary p-1 filter">
                    <div className="mb-3">

                      <PlacesAutocomplete
                        value={currentaddress}
                        onChange={handleCurrentAddressChange}
                        onSelect={handleCurrentAddressSelect}
                        className="form-control form_input"
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => {
                          const uniqueSuggestions = new Set();

                          return (
                            <div>
                              <input
                                {...getInputProps({
                                  placeholder: 'Search Places ...',
                                  className: 'location-search-input',
                                })}
                              />
                              <div className="autocomplete-dropdown-container">
                                {loading && <div>Loading...</div>}
                                {suggestions.map((suggestion, index) => {
                                  const className = suggestion.active
                                    ? 'suggestion-item--active'
                                    : 'suggestion-item';

                                  const words = suggestion.description.split(',');
                                  const trimmedArray = words.map((str) => str.trim());
                                  const lastThreeWords = trimmedArray
                                    .slice(-3)
                                    .join(',');
                                  // Check if the suggestion is unique based on place_id
                                  if (!uniqueSuggestions.has(lastThreeWords)) {
                                    uniqueSuggestions.add(lastThreeWords);

                                    // inline style for demonstration purpose
                                    const style = suggestion.active
                                      ? {
                                        backgroundColor: '#fafafa',
                                        cursor: 'pointer',
                                      }
                                      : {
                                        backgroundColor: '#ffffff',
                                        cursor: 'pointer',
                                      };

                                    return (
                                      <div
                                        key={index}
                                        {...getSuggestionItemProps(suggestion, {
                                          className,
                                          style,
                                        })}
                                      >
                                        <span>{lastThreeWords}</span>
                                      </div>
                                    );
                                  } else {
                                    return null;
                                  }
                                })}
                              </div>
                            </div>
                          );
                        }}
                      </PlacesAutocomplete>

                    </div>
                  </div>
                </Col>
                <Col md={12}>
                  <div className="selectedPlaces">
                    {selectedPlaces.map((place, index) => (
                      <div key={index} className="selected-place">
                        <span>{place}</span>

                        <Link onClick={() => handleRemovePlace(index)} className="rounded p-2">
                          <FeatherIcon
                            icon="delete"
                            className="fea icon-sm fea-social text-danger"
                          />
                        </Link>
                      </div>
                    ))}
                  </div>
                </Col>

                <hr />
                <Label className="form-label label_text">Native Location</Label>

                <Row>
                  <Col md={6}>
                    <div className="features feature-primary p-1 filter">
                      <div className="mb-3">

                        <PlacesAutocomplete
                          value={nativeaddress}
                          onChange={handleNativeAddressChange}
                          onSelect={handleNativeAddressSelect}
                          className="form-control form_input"
                        >
                          {({
                            getInputProps,
                            suggestions,
                            getSuggestionItemProps,
                            loading,
                          }) => {
                            const uniqueSuggestions = new Set();

                            return (
                              <div>
                                <input
                                  {...getInputProps({
                                    placeholder: 'Search Places ...',
                                    className: 'location-search-input',
                                  })}
                                />
                                <div className="autocomplete-dropdown-container">
                                  {loading && <div>Loading...</div>}
                                  {suggestions.map((suggestion, index) => {
                                    const className = suggestion.active
                                      ? 'suggestion-item--active'
                                      : 'suggestion-item';

                                    const words = suggestion.description.split(',');
                                    const trimmedArray = words.map((str) => str.trim());
                                    const lastThreeWords = trimmedArray
                                      .slice(-3)
                                      .join(',');
                                    // Check if the suggestion is unique based on place_id
                                    if (!uniqueSuggestions.has(lastThreeWords)) {
                                      uniqueSuggestions.add(lastThreeWords);

                                      // inline style for demonstration purpose
                                      const style = suggestion.active
                                        ? {
                                          backgroundColor: '#fafafa',
                                          cursor: 'pointer',
                                        }
                                        : {
                                          backgroundColor: '#ffffff',
                                          cursor: 'pointer',
                                        };

                                      return (
                                        <div
                                          key={index}
                                          {...getSuggestionItemProps(suggestion, {
                                            className,
                                            style,
                                          })}
                                        >
                                          <span>{lastThreeWords}</span>
                                        </div>
                                      );
                                    } else {
                                      return null; // Skip rendering duplicate suggestions
                                    }
                                  })}
                                </div>
                              </div>
                            );
                          }}
                        </PlacesAutocomplete>

                      </div>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="selectedPlaces">
                      {nativeselectedPlaces.map((place, index) => (
                        <div key={index} className="selected-place">
                          <span>{place}</span>

                          <Link onClick={() => handlenativeRemovePlace(index)} className="rounded p-2">
                            <FeatherIcon
                              icon="delete"
                              className="fea icon-sm fea-social text-danger"
                            />
                          </Link>
                        </div>
                      ))}
                    </div>
                  </Col>
                </Row>

                <hr />

                <Col md={8} sm={12}>
                  <div className="features feature-primary p-1">
                    <div className="mb-3">
                      <Label className="form-label label_text">Mother Tongue</Label>
                      <div className="form-icon position-relative">
                        <ul className="ks-cboxtags p-0">
                          {languageList.map((response, index) => (
                            <div className="form-check form-check-inline" key={index}>
                              <li>
                                <div className="mb-0">
                                  <div className="form-check p-0">
                                    <input type="checkbox"
                                      id={response.label + "monthertong"}
                                      checked={regionalFormData.mothertongue.includes(response.value)}
                                      onChange={event => handleMotherTongue(event.target.checked, response)}
                                    />
                                    <Label className="form-check-label" htmlFor={response.label + "monthertong"}>{response.label}  </Label>
                                  </div>
                                </div>
                              </li>
                            </div>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </Col>
                <hr />

                <Col md={8} sm={12}>
                  <div className="features feature-primary p-1">
                    <div className="mb-3">
                      <Label className="form-label label_text">Additional Language</Label>
                      <div className="form-icon position-relative">
                        <ul className="ks-cboxtags p-0">
                          {languageList.map((response, index) => (
                            <div className="form-check form-check-inline" key={index}>
                              <li>
                                <div className="mb-0">
                                  <div className="form-check p-0">
                                    <input type="checkbox"
                                      id={response.label + "alternativelang"}
                                      checked={regionalFormData.additionallanguages.includes(response.value)}
                                      onChange={event => handleAltLang(event.target.checked, response)}
                                    />
                                    <Label className="form-check-label" htmlFor={response.label + "alternativelang"}>{response.label}  </Label>
                                  </div>
                                </div>
                              </li>
                            </div>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                </Col>

                <div className="mt-md-4 mt-3 mb-2 mt-sm-0 text-md-end">
                  <button
                    type="submit"
                    className="btn btn-outline-primary mt-2 "

                  >
                    {' '}
                    Save Changes{' '}
                  </button>{' '}
                  <button
                    onClick={handleReset}
                    className="btn btn-outline-secondary mt-2 ms-2"
                    type='reset'
                  >
                    Reset
                  </button>
                </div>
              </Row>
            </Form>
          )
      }
    </React.Fragment>
  );
};
export default RegionalFunction;
